import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Modal,
  Button,
  Dropdown,
  Table,
  Pagination,
} from "react-bootstrap";

import { db } from "../../../firebase";
import Toast from "../toast/SetToast";

import {
  query,
  endAt,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  where,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
  collectionGroup,
} from "firebase/firestore";
import { notifyError, notifyTopRight } from "../toast/SetToast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import {
  setEvent,
  eventTmp,
  paymentPrivilages,
  orderList,
  colorList,
  statusList,
  typeList,
} from "./FollowupUtils";
import {
  PatientDropDownForm,
  PatientInputText,
  PatientInputTextArea,
  PatientTextInfo,
  PatientLinkInfo,
} from "./FollowupUtils";

const FollowupTable = ({
  text,
  sortBy,
  typeBy,
  statusBy,
  triggerRerender,
  onResetTriggerRerender,
}) => {
  const [latestDoc, setLatestDoc] = useState(null);
  const [firstDoc, setFirstDoc] = useState();
  const [loading, setLoading] = useState(true);
  const [goNext, setGoNext] = useState(false);
  const [goPrev, setGoPrev] = useState(true);
  const [page, setPage] = useState(1);
  const [bookingsCount, setBookingsCount] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [docCount, setdocCount] = useState(0);

  const itemsPerPage = 33;

  // console.log("text:", text);
  // console.log("sortBy:", sortBy);
  // console.log("typeBy:", typeBy);
  // console.log("statusBy:", statusBy);

  const refDeleteDoc = async (bookingInfo) => {
    const refDelete = doc(db, "followup", bookingInfo.extendedProps.uuid);
    await deleteDoc(refDelete)
      .then(async (res) => {
        // notifyTopRight("Delete Success!", res);
        swal("Your booking event has been deleted!", {
          icon: "success",
        });

        // refresh useEffect
        setBookingsCount(bookingsCount - 1);
        console.log("bookingsCount delete:", bookingsCount);
      })
      .catch((err) => {
        notifyError("Detele Error ", err);
      });
  };

  // // SET DEFAULT
  let bookingsCollectionGroupRef = collection(db, "followup");

  // let queryHandler = query(
  //   collection(db, "nodes"),
  //   orderBy(sortBy),
  //   limit(itemsPerPage)
  // );
  let queryHandler = query(
    bookingsCollectionGroupRef,
    orderBy(sortBy),
    // where(attributeName, '==', attributeValue),
    limit(itemsPerPage)
  );

  let count;

  // //  NEXT PAGE
  const nextPage = async () => {
    setLoading(true);
    let bookingsCollectionGroupRef = collection(db, "followup");
    let queryHandler;

    if (sortBy == "start") {
      if (statusBy == "ทั้งหมด") {
        if (typeBy == "ทั้งหมด") {
          queryHandler = query(
            bookingsCollectionGroupRef,
            orderBy(sortBy),
            startAfter(latestDoc),
            limit(itemsPerPage)
          );
        } else {
          queryHandler = query(
            bookingsCollectionGroupRef,
            orderBy(sortBy),
            where("extendedProps.type", "==", typeBy),
            startAfter(latestDoc),
            limit(itemsPerPage)
          );
        }
      } else {
        if (typeBy == "ทั้งหมด") {
          queryHandler = query(
            bookingsCollectionGroupRef,
            where("extendedProps.status", "==", statusBy),
            orderBy(sortBy),
            startAfter(latestDoc),
            limit(itemsPerPage)
          );
        } else {
          queryHandler = query(
            bookingsCollectionGroupRef,
            where("extendedProps.status", "==", statusBy),
            where("extendedProps.type", "==", typeBy),
            orderBy(sortBy),
            startAfter(latestDoc),
            limit(itemsPerPage)
          );
        }
      }
      count = await getCountFromServer(
        queryHandler
        // query(bookingsCollectionGroupRef(db, "bookings"), orderBy(sortBy))
      );
    } else {
      if (statusBy == "ทั้งหมด") {
        if (typeBy == "ทั้งหมด") {
          queryHandler = query(
            bookingsCollectionGroupRef,
            orderBy("extendedProps." + sortBy),
            startAt(text),
            endAt(text + "\uf8ff"),
            startAfter(latestDoc),
            limit(itemsPerPage)
          );
        } else {
          queryHandler = query(
            bookingsCollectionGroupRef,
            orderBy("extendedProps." + sortBy),
            where("extendedProps.type", "==", typeBy),
            startAt(text),
            endAt(text + "\uf8ff"),
            startAfter(latestDoc),
            limit(itemsPerPage)
          );
        }
      } else {
        if (typeBy == "ทั้งหมด") {
          queryHandler = query(
            bookingsCollectionGroupRef,
            where("extendedProps.status", "==", statusBy),
            orderBy("extendedProps." + sortBy),
            startAfter(latestDoc),
            limit(itemsPerPage)
          );
        } else {
          queryHandler = query(
            bookingsCollectionGroupRef,
            where("extendedProps.status", "==", statusBy),
            where("extendedProps.type", "==", typeBy),
            orderBy("extendedProps." + sortBy),
            startAfter(latestDoc),
            limit(itemsPerPage)
          );
        }
      }
      count = await getCountFromServer(
        // queryHandler
        query(
          bookingsCollectionGroupRef,
          orderBy("extendedProps." + sortBy),
          startAt(text),
          endAt(text + "\uf8ff")
        )
      );
    }
    const tempFollowup = [];
    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No documents found");
    } else {
      docSnap.forEach((booking) => {
        const bookingData = booking.data();
        const dateStart = new Date(bookingData.start.toDate());
        dateStart.setHours(dateStart.getHours() + 7);
        bookingData.start = dateStart.toISOString().slice(0, 10);

        tempFollowup.push({ ...bookingData });
      });
    }
    const pageTmp = page + 1;
    setLoading(false);
    setBookingsCount(count.data().count);
    setPage(pageTmp);
    setFollowupList(tempFollowup);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setFirstDoc(docSnap.docs[0]);
    const totalPagesTmp = Math.ceil(count.data().count / itemsPerPage);
    setTotalPages(totalPagesTmp);
    // console.log('totalPageTmp: ',totalPagesTmp)
    if (pageTmp >= totalPages) {
      setGoNext(false);
    }
    setGoPrev(true);
  };

  // //  PREV PAGE
  const previousPage = async () => {
    setLoading(true);

    let bookingsCollectionGroupRef = collection(db, "followup");
    let queryHandler;

    if (sortBy == "start") {
      if (statusBy == "ทั้งหมด") {
        if (typeBy == "ทั้งหมด") {
          queryHandler = query(
            bookingsCollectionGroupRef,
            orderBy(sortBy),
            endBefore(firstDoc),
            limit(itemsPerPage)
          );
        } else {
          queryHandler = query(
            bookingsCollectionGroupRef,
            orderBy(sortBy),
            where("extendedProps.type", "==", typeBy),
            endBefore(firstDoc),
            limit(itemsPerPage)
          );
        }
      } else {
        if (typeBy == "ทั้งหมด") {
          queryHandler = query(
            bookingsCollectionGroupRef,
            where("extendedProps.status", "==", statusBy),
            orderBy(sortBy),
            endBefore(firstDoc),
            limit(itemsPerPage)
          );
        } else {
          queryHandler = query(
            bookingsCollectionGroupRef,
            where("extendedProps.status", "==", statusBy),
            where("extendedProps.type", "==", typeBy),
            orderBy(sortBy),
            endBefore(firstDoc),
            limit(itemsPerPage)
          );
        }
      }
      count = await getCountFromServer(
        queryHandler
        // query(bookingsCollectionGroupRef(db, "bookings"), orderBy(sortBy))
      );
    } else {
      if (statusBy == "ทั้งหมด") {
        if (typeBy == "ทั้งหมด") {
          queryHandler = query(
            bookingsCollectionGroupRef,
            orderBy("extendedProps." + sortBy),
            startAt(text),
            endAt(text + "\uf8ff"),
            endBefore(firstDoc),
            limit(itemsPerPage)
          );
        } else {
          queryHandler = query(
            bookingsCollectionGroupRef,
            orderBy("extendedProps." + sortBy),
            where("extendedProps.type", "==", typeBy),
            startAt(text),
            endAt(text + "\uf8ff"),
            endBefore(firstDoc),
            limit(itemsPerPage)
          );
        }
      } else {
        if (typeBy == "ทั้งหมด") {
          queryHandler = query(
            bookingsCollectionGroupRef,
            where("extendedProps.status", "==", statusBy),
            orderBy("extendedProps." + sortBy),
            endBefore(firstDoc),
            limit(itemsPerPage)
          );
        } else {
          queryHandler = query(
            bookingsCollectionGroupRef,
            where("extendedProps.status", "==", statusBy),
            where("extendedProps.type", "==", typeBy),
            orderBy("extendedProps." + sortBy),
            endBefore(firstDoc),
            limit(itemsPerPage)
          );
        }
      }
      count = await getCountFromServer(
        // queryHandler
        query(
          bookingsCollectionGroupRef,
          orderBy("extendedProps." + sortBy),
          startAt(text),
          endAt(text + "\uf8ff")
        )
      );
    }
    const tempBooking = [];
    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No documents found");
    } else {
      docSnap.forEach((booking) => {
        const bookingData = booking.data();
        const dateStart = new Date(bookingData.start.toDate());
        dateStart.setHours(dateStart.getHours() + 7);
        bookingData.start = dateStart.toISOString().slice(0, 10);
        tempBooking.push({ ...bookingData });
      });
    }

    const pageTmp = page - 1;
    setLoading(false);
    setBookingsCount(count.data().count);
    setPage(pageTmp);
    setFollowupList(tempBooking);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setFirstDoc(docSnap.docs[0]);
    setGoNext(true);
    if (page <= 2) {
      setGoPrev(false);
    }
  };

  // const typeList = typeList;
  // const statusList = statusList;
  // const colorList = colorList;
  // const orderList = orderList;
  // const paymentPrivilages = paymentPrivilages;
  const onSearchPatient = async (e) => {
    const q = query(
      collection(db, "patients"),
      where("hn", "==", hnRef.current.value)
    );
    const querySnapshot = await getDocs(q);
    let listPatient = [];
    // console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      listPatient.push(doc.data());
    });
    if (listPatient[0] == undefined) {
      setIsNewPatient(true);
    } else {
      try {
        firstnameRef.current.value = listPatient[0].firstnameTh;
        lastnameRef.current.value = listPatient[0].lastnameTh;
        nationalIdRef.current.value = listPatient[0].username;
        phoneRef.current.value = listPatient[0].mobile;
        if (
          listPatient[0].healthInsurance == "" ||
          listPatient[0].healthInsurance == "please-select"
        ) {
          healthInsuranceRef.current.value = "กรุณาเลือก";
        } else {
          healthInsuranceRef.current.value = listPatient[0].healthInsurance;
        }
      } catch (err) {
        console.log(err);
      }
      setIsNewPatient(false);
    }
  };
  const drop = (bookingInfo) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="" className="table-dropdown icon-false">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              handleBookingClick(bookingInfo);
              if (bookingInfo.extendedProps.status == "Scheduled") {
                setIsDatetime(true);
              } else {
                setIsDatetime(false);
              }
            }}
          >
            Order Detail
          </Dropdown.Item>
          <Dropdown.Item href={`/patient-page/${bookingInfo.extendedProps.hn}`}>
            Patient Page
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={() => {
              // refDeleteDoc(bookingInfo);
              swal({
                title: "Are you sure to delete ?",
                text: "Once deleted, you will not be able to recover this booking event !",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  refDeleteDoc(bookingInfo);
                  // swal("Your booking event has been deleted!", {
                  //   icon: "success",
                  // });
                }
              });
            }}
            className="text-danger"
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  const [followupList, setFollowupList] = useState([]);
  const [nodeList, setNodeList] = useState([]);
  const [nodeIdDocs, setNodeIdDocs] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [addEventModal, setAddEventModal] = useState(false);
  const [editEventModal, setEditEventModal] = useState(false);
  const [editBookingDetail, setEditBookingDetail] = useState(false);
  const [technicianList, setTechnicianList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [isDatetime, setIsDatetime] = useState(false);
  const [items, setItems] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [isModalReady, setIsModalReady] = useState(false);
  const [loadingTimeout, setLoadingTimeout] = useState(false); // New state for timeout
  const dateRef = useRef();
  const titleRef = useRef();
  const idRef = useRef();
  const orderRef = useRef();
  const nodeRef = useRef();
  const customerRef = useRef();
  const hnRef = useRef();
  const typeRef = useRef();
  const statusRef = useRef();
  const colorRef = useRef();
  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const nationalIdRef = useRef();
  const healthInsuranceRef = useRef();
  const phoneRef = useRef();
  const noteRef = useRef();
  const itemsRef = useRef();
  const techRef = useRef();
  const docRef = useRef();
  const handleBookingClick = (bookingInfo) => {
    setCurrentBooking(bookingInfo);
    setIsModalReady(true);
    setEditEventModal(true);
    setItems(bookingInfo.extendedProps.items);
    setTotalValue(bookingInfo.extendedProps.totalValue);
  };

  const handleCloseModal = () => {
    setEditEventModal(false);
  };
  const onUpdateItemValue = (key, index, value) => {
    var tmpItems = items;
    tmpItems[index][key] = value.target.value;
    tmpItems[index].total =
      tmpItems[index].unitcost * tmpItems[index].qty - tmpItems[index].discount;
    setItems(tmpItems);
    const newTotalValue = calculateTotalValue(tmpItems);
    setTotalValue(newTotalValue);
  };

  const handleStatusChange = () => {
    const selectedStatus = statusRef.current.value;
    setCurrentBooking((prevBooking) => ({
      ...prevBooking,
      extendedProps: {
        ...prevBooking.extendedProps,
        status: selectedStatus,
      },
    }));
    if (selectedStatus == "Scheduled") {
      setIsDatetime(true);
    } else {
      setIsDatetime(false);
    }
  };
  const handleDate = (moment, props) => {
    let storeMoment = moment.toISOString().slice(0, 10);
    setCurrentBooking({ ...currentBooking, start: storeMoment });
  };
  const handleTime = (moment, props) => {
    setCurrentBooking((prevBooking) => ({
      ...prevBooking,
      extendedProps: {
        ...prevBooking.extendedProps,
        time: moment.target.value,
      },
    }));
    // var evtmp = { ...currentBooking };
    // evtmp.extendedProps.time = moment.target.value;
    // setCurrentBooking({ ...currentBooking, time: moment.target.value });
  };
  const handleAddItem = () => {
    // Create a new copy of items array and add the new item
    const newItem = {
      id: items.length + 1,
      itemcode: itemList[0].itemcode,
      item: itemList[0].item,
      unitcost: itemList[0].unitcost,
      qty: 1,
      discount: 0,
      total: itemList[0].unitcost,
    };
    const updatedItems = [...items, newItem];
    // Update the items state and calculate the total value
    setItems(updatedItems);
    const newTotalValue = calculateTotalValue(updatedItems);
    setTotalValue(newTotalValue);
  };

  const calculateTotalValue = (itemsList) => {
    let totalValueTmp = 0;
    itemsList.forEach((item) => {
      totalValueTmp += parseInt(item.total);
    });
    return totalValueTmp;
  };

  const onEditEvent = () => {
    var currentBookingTmp = currentBooking;
    currentBookingTmp.extendedProps.order = orderRef.current.value;
    currentBookingTmp.extendedProps.hn = hnRef.current.value;
    try {
      // currentBookingTmp.extendedProps.nationalId = nationalIdRef.current.value;
      currentBookingTmp.extendedProps.firstname = firstnameRef.current.value;
      currentBookingTmp.extendedProps.lastname = lastnameRef.current.value;
      currentBookingTmp.extendedProps.phone = phoneRef.current.value;
    } catch (err) {
      console.log("get current patient info");
    }
    currentBookingTmp.extendedProps.type = typeRef.current.value;
    // currentBookingTmp.extendedProps.healthInsurance =
    //   healthInsuranceRef.current.value;
    currentBookingTmp.extendedProps.status = statusRef.current.value;
    currentBookingTmp.extendedProps.note = noteRef.current.value;
    // currentBookingTmp.extendedProps.node = nodeRef.current.value;
    // currentBookingTmp.extendedProps.customer = customerRef.current.value;
    currentBooking.extendedProps.technician = techRef.current.value;
    currentBooking.extendedProps.doctor = docRef.current.value;
    currentBookingTmp.extendedProps.items = items;
    currentBookingTmp.extendedProps.totalValue = totalValue;
    currentBookingTmp.id = 5;
    currentBookingTmp.title = orderRef.current.value;
    var indexOfStatusList = statusList.findIndex(
      (obj) => obj === statusRef.current.value
    );
    currentBookingTmp.color = colorList[indexOfStatusList];
    if (statusRef.current.value != "Scheduled") {
      currentBookingTmp.extendedProps.time = "";
    }
    setCurrentBooking(currentBookingTmp);
    setEvent(currentBookingTmp, undefined, nodeIdDocs, nodeList);
    setEditEventModal(false);
  };
  // const setEvent = async (eventAdd, oldEvent = undefined) => {
  //   console.log(eventAdd);
  //   try {
  //     if (
  //       eventAdd.extendedProps.uuid == undefined ||
  //       eventAdd.extendedProps.uuid == null
  //     ) {
  //       const ref = doc(
  //         collection(db, "nodes", eventAdd.extendedProps.node, "bookings")
  //       );
  //       await setDoc(ref, {
  //         title: eventAdd.title,
  //         id: eventAdd.id,
  //         start: new Date(eventAdd.start),
  //         end: eventAdd.end,
  //         color: eventAdd.color,
  //         extendedProps: {
  //           order: eventAdd.extendedProps.order,
  //           hn: eventAdd.extendedProps.hn,
  //           type: eventAdd.extendedProps.type,
  //           status: eventAdd.extendedProps.status,
  //           firstname: eventAdd.extendedProps.firstname,
  //           lastname: eventAdd.extendedProps.lastname,
  //           nationalId: eventAdd.extendedProps.nationalId,
  //           healthInsurance: eventAdd.extendedProps.healthInsurance,
  //           phone: eventAdd.extendedProps.phone,
  //           node: eventAdd.extendedProps.node,
  //           customer: eventAdd.extendedProps.customer,
  //           note: eventAdd.extendedProps.note,
  //           items: eventAdd.extendedProps.items,
  //           totalValue: eventAdd.extendedProps.totalValue,
  //           time: eventAdd.extendedProps.time,
  //           doctor: eventAdd.extendedProps.doctor,
  //           technician: eventAdd.extendedProps.technician,
  //         },
  //       });
  //       eventAdd.setExtendedProp("uuid", ref.id);
  //       const refUpdate = doc(
  //         db,
  //         "nodes",
  //         eventAdd.extendedProps.node,
  //         "bookings",
  //         eventAdd.extendedProps.uuid
  //       );

  //       await updateDoc(refUpdate, {
  //         title: eventAdd.title,
  //         id: eventAdd.id,
  //         start: new Date(eventAdd.start),
  //         color: eventAdd.color,
  //         extendedProps: {
  //           order: eventAdd.extendedProps.order,
  //           hn: eventAdd.extendedProps.hn,
  //           type: eventAdd.extendedProps.type,
  //           status: eventAdd.extendedProps.status,
  //           uuid: eventAdd.extendedProps.uuid,
  //           firstname: eventAdd.extendedProps.firstname,
  //           lastname: eventAdd.extendedProps.lastname,
  //           nationalId: eventAdd.extendedProps.nationalId,
  //           healthInsurance: eventAdd.extendedProps.healthInsurance,
  //           phone: eventAdd.extendedProps.phone,
  //           node: eventAdd.extendedProps.node,
  //           customer: eventAdd.extendedProps.customer,
  //           note: eventAdd.extendedProps.note,
  //           items: eventAdd.extendedProps.items,
  //           totalValue: eventAdd.extendedProps.totalValue,
  //           time: eventAdd.extendedProps.time,
  //           doctor: eventAdd.extendedProps.doctor,
  //           technician: eventAdd.extendedProps.technician,
  //         },
  //       });
  //     } else {
  //       const refUpdate = doc(
  //         db,
  //         "nodes",
  //         eventAdd.extendedProps.node,
  //         "bookings",
  //         eventAdd.extendedProps.uuid
  //       );

  //       await updateDoc(refUpdate, {
  //         title: eventAdd.title,
  //         id: eventAdd.id,
  //         start: new Date(eventAdd.start),
  //         color: eventAdd.color,
  //         extendedProps: {
  //           order: eventAdd.extendedProps.order,
  //           hn: eventAdd.extendedProps.hn,
  //           type: eventAdd.extendedProps.type,
  //           status: eventAdd.extendedProps.status,
  //           uuid: eventAdd.extendedProps.uuid,
  //           firstname: eventAdd.extendedProps.firstname,
  //           lastname: eventAdd.extendedProps.lastname,
  //           nationalId: eventAdd.extendedProps.nationalId,
  //           healthInsurance: eventAdd.extendedProps.healthInsurance,
  //           phone: eventAdd.extendedProps.phone,
  //           node: eventAdd.extendedProps.node,
  //           customer: eventAdd.extendedProps.customer,
  //           note: eventAdd.extendedProps.note,
  //           items: eventAdd.extendedProps.items,
  //           totalValue: eventAdd.extendedProps.totalValue,
  //           time: eventAdd.extendedProps.time,
  //           doctor: eventAdd.extendedProps.doctor,
  //           technician: eventAdd.extendedProps.technician,
  //         },
  //       });
  //     }
  //     notifyTopRight("Update booking success !");
  //   } catch (err) {
  //     console.log(err);
  //     notifyError("Please fill out patient information !");

  //     let api = this.calendarComponentRef.current.getApi();
  //     api.addEvent({
  //       title: oldEvent.title,
  //       start: oldEvent.start,
  //       id: oldEvent.id,
  //       allDay: true,
  //       color: "brown",
  //       extendedProps: {
  //         order: oldEvent.extendedProps.order,
  //         hn: oldEvent.extendedProps.hn,
  //         type: oldEvent.extendedProps.type,
  //         status: oldEvent.extendedProps.status,
  //         firstname: oldEvent.extendedProps.firstname,
  //         lastname: oldEvent.extendedProps.lastname,
  //         nationalId: oldEvent.extendedProps.nationalId,
  //         healthInsurance: oldEvent.extendedProps.healthInsurance,
  //         phone: oldEvent.extendedProps.phone,
  //         note: oldEvent.extendedProps.note,
  //         node: oldEvent.extendedProps.node,
  //         customer: oldEvent.extendedProps.customer,
  //         items: oldEvent.extendedProps.items,
  //         totalValue: oldEvent.extendedProps.totalValue,
  //         time: oldEvent.extendedProps.time,
  //         doctor: oldEvent.extendedProps.doctor,
  //         technician: oldEvent.extendedProps.technician,
  //       },
  //     });
  //     // eventAdd.remove();
  //   }
  // };
  useEffect(async () => {
    setLoading(true);

    const fetchData = async () => {
      try {
        let bookingsCollectionGroupRef = collection(db, "followup");
        let queryHandler;

        if (sortBy == "start") {
          if (statusBy == "ทั้งหมด") {
            if (typeBy == "ทั้งหมด") {
              queryHandler = query(
                bookingsCollectionGroupRef,
                orderBy(sortBy),
                startAfter(null),
                limit(itemsPerPage)
              );
              count = await getCountFromServer(
                query(
                  bookingsCollectionGroupRef,
                  orderBy(sortBy),
                  startAfter(null)
                )
              );
            } else {
              queryHandler = query(
                bookingsCollectionGroupRef,
                orderBy(sortBy),
                where("extendedProps.type", "==", typeBy),
                startAfter(null),
                limit(itemsPerPage)
              );
              count = await getCountFromServer(
                query(
                  bookingsCollectionGroupRef,
                  orderBy(sortBy),
                  where("extendedProps.type", "==", typeBy),
                  startAfter(null)
                )
              );
            }
          } else {
            if (typeBy == "ทั้งหมด") {
              queryHandler = query(
                bookingsCollectionGroupRef,
                orderBy(sortBy),
                where("extendedProps.status", "==", statusBy),
                startAfter(null),
                limit(itemsPerPage)
              );
              count = await getCountFromServer(
                query(
                  bookingsCollectionGroupRef,
                  orderBy(sortBy),
                  where("extendedProps.status", "==", statusBy),
                  startAfter(null)
                )
              );
            } else {
              queryHandler = query(
                bookingsCollectionGroupRef,
                orderBy(sortBy),
                where("extendedProps.status", "==", statusBy),
                where("extendedProps.type", "==", typeBy),
                startAfter(null),
                limit(itemsPerPage)
              );
              count = await getCountFromServer(
                query(
                  bookingsCollectionGroupRef,
                  orderBy(sortBy),
                  where("extendedProps.status", "==", statusBy),
                  where("extendedProps.type", "==", typeBy),
                  startAfter(null)
                )
              );
            }
          }
        } else {
          if (statusBy == "ทั้งหมด") {
            if (typeBy == "ทั้งหมด") {
              queryHandler = query(
                bookingsCollectionGroupRef,
                orderBy("extendedProps." + sortBy),
                where("extendedProps." + sortBy, ">=", text),
                startAt(text),
                endAt(text + "\uf8ff"),
                startAfter(null),
                limit(itemsPerPage)
              );
              count = await getCountFromServer(
                query(
                  bookingsCollectionGroupRef,
                  orderBy("extendedProps." + sortBy),
                  where("extendedProps." + sortBy, ">=", text),
                  startAt(text),
                  endAt(text + "\uf8ff"),
                  startAfter(null)
                )
              );
            } else {
              queryHandler = query(
                bookingsCollectionGroupRef,
                orderBy("extendedProps." + sortBy),
                where("extendedProps.type", "==", typeBy),
                where("extendedProps." + sortBy, ">=", text),
                startAt(text),
                endAt(text + "\uf8ff"),
                startAfter(null),
                limit(itemsPerPage)
              );
              count = await getCountFromServer(
                query(
                  bookingsCollectionGroupRef,
                  orderBy("extendedProps." + sortBy),
                  where("extendedProps.type", "==", typeBy),
                  where("extendedProps." + sortBy, ">=", text),
                  startAt(text),
                  endAt(text + "\uf8ff"),
                  startAfter(null)
                )
              );
            }
          } else {
            if (typeBy == "ทั้งหมด") {
              queryHandler = query(
                bookingsCollectionGroupRef,
                orderBy("extendedProps." + sortBy),
                where("extendedProps.status", "==", statusBy),
                startAfter(null),
                limit(itemsPerPage)
              );
              count = await getCountFromServer(
                query(
                  bookingsCollectionGroupRef,
                  orderBy("extendedProps." + sortBy),
                  where("extendedProps.status", "==", statusBy),
                  startAfter(null)
                )
              );
            } else {
              queryHandler = query(
                bookingsCollectionGroupRef,
                orderBy("extendedProps." + sortBy),
                where("extendedProps.status", "==", statusBy),
                where("extendedProps.type", "==", typeBy),
                startAfter(null),
                limit(itemsPerPage)
              );
              count = await getCountFromServer(
                query(
                  bookingsCollectionGroupRef,
                  orderBy("extendedProps." + sortBy),
                  where("extendedProps.status", "==", statusBy),
                  where("extendedProps.type", "==", typeBy),
                  startAfter(null)
                )
              );
            }
          }
        }
        const tempBooking = [];
        const docSnap = await getDocs(queryHandler);
        if (docSnap.empty) {
          console.log("No documents found");
        } else {
          docSnap.forEach((booking) => {
            const bookingData = booking.data();
            const dateStart = new Date(bookingData.start.toDate());
            dateStart.setHours(dateStart.getHours() + 7);
            bookingData.start = dateStart.toISOString().slice(0, 10);
            tempBooking.push({ ...bookingData });
          });
        }
        console.log("count: ", count.data().count);

        setFollowupList(tempBooking);
        var totalPagesTmp = Math.ceil(count.data().count / itemsPerPage);
        setBookingsCount(count.data().count);
        setTotalPages(totalPagesTmp);
        setPage(1);

        if (docSnap.docs.length >= 1) {
          setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
          setFirstDoc(docSnap.docs[0]);
        } else {
          setLatestDoc(null);
          setFirstDoc(null);
        }

        // You can do whatever you want with the tempBooking array here
        if (count.data().count <= itemsPerPage) {
          console.log("no more doc");
          setGoNext(false);
          setGoPrev(false);
        } else {
          setGoNext(true);
          setGoPrev(false);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchAnotherData = async () => {
      try {
        // Use Promise.all for parallel fetching
        const [itemsDocs, techDocs, docDocs] = await Promise.all([
          // getDocs(collection(db, "nodes")),
          getDocs(collection(db, "items")),
          getDocs(collection(db, "sleeptechnicians")),
          getDocs(collection(db, "doctors")),
          // getDocs(collectionGroup(db,"customers"))
        ]);

        // Transform data and set state
        // const allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        // const allNodesId = nodesDocs.docs.map((doc) => doc.id);
        // let allCustomers = [];
        // for (const nodeDoc of nodesDocs.docs) {
        //   const customerDocs = await getDocs(
        //     collection(db, "nodes", nodeDoc.id, "customers")
        //   );
        //   const customersData = customerDocs.docs.map((customer) => {
        //     const customerData = customer.id;
        //     return customerData;
        //   });
        //   allCustomers = [...allCustomers, ...customersData];
        // }
        const allItems = itemsDocs.docs.map((doc) => ({
          ...doc.data(),
          itemcode: doc.id,
        }));
        const allTechs = [
          "กรุณาเลือก",
          ...techDocs.docs.map((doc) => doc.data().name),
        ];
        const allDocs = [
          "กรุณาเลือก",
          ...docDocs.docs.map((doc) => doc.data().name),
        ];
        // setCustomerList(allCustomers);
        // setNodeList(allNodes);
        // setNodeIdDocs(allNodesId);
        setDoctorList(allDocs);
        setTechnicianList(allTechs);
        setItemList(allItems);
        // if (nodeList != []){
        //   setLoading(false);
        // }
      } catch (error) {
        console.log("Error fetching data: ", error);
      }
    };

    // Call the fetchData function to get the tempFollowup array
    await fetchAnotherData();
    await fetchData();
    if (triggerRerender) {
      onResetTriggerRerender();
    }
  }, [
    text,
    sortBy,
    typeBy,
    statusBy,
    bookingsCount,
    triggerRerender,
    onResetTriggerRerender,
    ,
  ]);
  return (
    <Fragment>
      <Toast />
      {!loading ? (
        <>
          {" "}
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body className="card-body">
                  <Table className="table-responsive-12 mb-0 table-striped">
                    <thead>
                      <tr>
                        <th> </th>
                        <th>Follow up Order</th>
                        <th>Name</th>
                        <th>HN</th>
                        <th>TYPE</th>
                        <th>STATUS</th>
                        <th>Activity Date</th>
                        <th>Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody id="booking-table">
                      {followupList.map((element, index) => {
                        return (
                          <tr>
                            <td className="py-0">
                              {index + 1 + itemsPerPage * (page - 1)}
                            </td>
                            <td className="py-0">
                              <Link
                                onClick={() => {
                                  handleBookingClick(element);
                                  if (
                                    element.extendedProps.status == "Scheduled"
                                  ) {
                                    setIsDatetime(true);
                                  } else {
                                    setIsDatetime(false);
                                  }
                                }}
                              >
                                {element.extendedProps.order}
                              </Link>
                            </td>
                            <td className="py-0">
                              <Link
                                // target="_blank"
                                to={"/patient-page/" + element.extendedProps.hn}
                              >
                                {" "}
                                {element.extendedProps.firstname +
                                  " " +
                                  element.extendedProps.lastname}
                              </Link>
                            </td>
                            <td className="py-0">
                              <Link
                                // target="_blank"
                                to={"/patient-page/" + element.extendedProps.hn}
                              >
                                {" "}
                                {element.extendedProps.hn}
                              </Link>
                            </td>
                            <td className="py-0">
                              {element.extendedProps.type}
                            </td>
                            <td className="py-0">
                              {element.extendedProps.status}
                            </td>
                            <td className="py-0">{element.start}</td>
                            <td className="py-0">
                              {element.extendedProps.totalValue}
                            </td>
                            <td className="py-0">{drop(element)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <div className="d-flex justify-content-center mt-3">
                    <Pagination
                      className="pagination-primary pagination-circle"
                      size="lg"
                    >
                      <Button
                        onClick={previousPage}
                        disabled={!goPrev}
                        className="page-link"
                      >
                        <i className="la la-angle-left" />
                      </Button>
                      {/* {Page }{totalPages} */}
                      <div className="d-flex justify-content-center mr-5 ml-5 mt-2">
                        {page}
                        {" / "}
                        {totalPages}
                      </div>

                      <Button
                        onClick={nextPage}
                        disabled={!goNext}
                        className="page-link"
                      >
                        <i className="la la-angle-right" />
                      </Button>
                    </Pagination>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {currentBooking && editEventModal && isModalReady && (
            <Modal
              className="fade bd-example-modal-xl"
              show={editEventModal}
              onHide={() => {
                setEditEventModal(false);
                // setCurrentBooking(eventTmp);
              }}
              size="xl"
            >
              <Modal.Header>
                <Modal.Title>Edit Event</Modal.Title>
                <Button
                  variant=""
                  className="close"
                  onClick={() => {
                    setEditBookingDetail(false);
                    // setCurrentBooking(eventTmp);
                    setEditEventModal(false);
                  }}
                >
                  <span>&times;</span>
                </Button>
              </Modal.Header>
              <Modal.Body>
                <div className="ml-4">
                  <Button
                    variant=""
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setEditBookingDetail(!editBookingDetail);
                    }}
                    size="sm"
                  >
                    Edit
                  </Button>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-12">
                      {editBookingDetail ? (
                        <>
                          <PatientInputText
                            title={"Order"}
                            refvalue={orderRef}
                            defaultValue={currentBooking.extendedProps.order}
                          ></PatientInputText>
                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            >
                              HN
                            </label>

                            <div className="col-lg-7">
                              <input
                                type="text"
                                className="form-control"
                                id="val-hn"
                                name="val-hn"
                                placeholder="HN"
                                ref={hnRef}
                                defaultValue={currentBooking.extendedProps.hn}
                                // ref={emailRef}
                                // value=""
                              />
                            </div>
                            <div className="col-lg-2">
                              <Button
                                variant=""
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => onSearchPatient()}
                                size="sm"
                              >
                                Search
                              </Button>
                            </div>
                          </div>
                          {isNewPatient ? (
                            <div className="form-group row">
                              <label
                                className="col-lg-3 col-form-label"
                                htmlFor="val-username"
                              ></label>

                              <div className="col-lg-4">
                                <label className="text-danger">
                                  * New Patient
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {currentBooking.extendedProps.hn == "" ? (
                            <>
                              {" "}
                              <div className="form-group row">
                                <label
                                  className="col-lg-3 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Full Name
                                </label>
                                <div className="col-lg-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-hn"
                                    name="val-hn"
                                    placeholder="Firstname"
                                    ref={firstnameRef}
                                    defaultValue={
                                      currentBooking.extendedProps.firstname
                                    }
                                  />
                                </div>
                                <div className="col-lg-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="val-hn"
                                    name="val-hn"
                                    placeholder="Lastname"
                                    ref={lastnameRef}
                                    defaultValue={
                                      currentBooking.extendedProps.lastname
                                    }
                                  />
                                </div>
                              </div>
                              <PatientInputText
                                title={"Phone"}
                                refvalue={phoneRef}
                                defaultValue={
                                  currentBooking.extendedProps.phone
                                }
                              ></PatientInputText>
                            </>
                          ) : (
                            <>
                              {" "}
                              <PatientTextInfo
                                title="Full Name"
                                value={
                                  currentBooking.extendedProps.firstname +
                                  " " +
                                  currentBooking.extendedProps.lastname
                                }
                                bold={true}
                              />
                              <PatientTextInfo
                                title="Phone"
                                value={currentBooking.extendedProps.phone}
                                bold={true}
                              />
                            </>
                          )}
                          {/* <PatientDropDownForm
                            title="Health Insurance"
                            id="healthInsurance"
                            refvalue={healthInsuranceRef}
                            selected={
                              currentBooking.extendedProps.healthInsurance
                            }
                            options={paymentPrivilages}
                          ></PatientDropDownForm> */}
                          <PatientDropDownForm
                            title="Type"
                            id="type"
                            refvalue={typeRef}
                            selected={currentBooking.extendedProps.type}
                            options={typeList}
                          ></PatientDropDownForm>
                          <PatientDropDownForm
                            title="Status"
                            id="status"
                            refvalue={statusRef}
                            selected={currentBooking.extendedProps.status}
                            options={statusList}
                            onChange={handleStatusChange}
                            defaultValue={currentBooking.extendedProps.status}
                          ></PatientDropDownForm>
                          <PatientDropDownForm
                            title="Technician"
                            id="technician"
                            refvalue={techRef}
                            selected={currentBooking.extendedProps.technician}
                            options={technicianList}
                          ></PatientDropDownForm>
                          <PatientDropDownForm
                            title="Doctor"
                            id="doctor"
                            refvalue={docRef}
                            selected={currentBooking.extendedProps.doctor}
                            options={doctorList}
                            // onChange={handleStatusChange}
                          ></PatientDropDownForm>
                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            >
                              Date
                            </label>
                            <div className="col-lg-2 mt-2">
                              <DatePicker
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                selected={
                                  currentBooking.start
                                    ? new Date(currentBooking.start)
                                    : null
                                }
                                // defaultValue={new Date(currentBooking.start)}
                                onChange={(event, props) =>
                                  handleDate(event, props)
                                }
                                // value={new Date(currentBooking.start)}
                              />
                            </div>
                          </div>
                          {isDatetime ? (
                            <>
                              {" "}
                              <div className="form-group row">
                                <label
                                  className="col-lg-3 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Time
                                </label>
                                <div className="col-lg-2 mt-2">
                                  <input
                                    type={"time"}
                                    className="form-control"
                                    defaultValue={
                                      currentBooking.extendedProps.time
                                    }
                                    // defaultValue={new Date(state.dateTmp + "T00:00")}
                                    onChange={(event, props) =>
                                      handleTime(event, props)
                                    }
                                  ></input>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <PatientInputTextArea
                            title={"Note"}
                            refvalue={noteRef}
                            defaultValue={currentBooking.extendedProps.note}
                          ></PatientInputTextArea>
                          <div className="form-group row">
                            <div className="col-lg-5 col-form-label mt-2">
                              <b htmlFor="val-username">Add Item</b>
                              <Button
                                variant="secondary"
                                size="xs"
                                className="ml-2"
                                onClick={handleAddItem}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="center">No.</th>
                                  <th className="center width300">Item Name</th>
                                  <th className="center width100">Item Code</th>
                                  <th className="center width150">
                                    Unit Cost (THB)
                                  </th>
                                  <th className="center width100">Qty</th>
                                  <th className="center">Discount (THB)</th>
                                  <th className="center">Total (THB)</th>
                                  <th className="center"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((element, index) => {
                                  return (
                                    <tr>
                                      <td className="">{element.id}</td>
                                      <td>
                                        <select
                                          className="form-control"
                                          id={"item" + String(index)}
                                          name={"item" + String(index)}
                                          onChange={(evt) => {
                                            var item = evt.target.value;
                                            var tmpItems = items;

                                            var indexOfItemList =
                                              itemList.findIndex(
                                                (obj) => obj.item === item
                                              );
                                            console.log(
                                              "selected item: ",
                                              itemList[indexOfItemList]
                                            );
                                            console.log(
                                              "unitcost: ",
                                              itemList[indexOfItemList].unitcost
                                            );
                                            tmpItems[index].itemcode =
                                              itemList[
                                                indexOfItemList
                                              ].itemcode;
                                            tmpItems[index].item =
                                              itemList[indexOfItemList].item;
                                            tmpItems[index].unitcost =
                                              itemList[
                                                indexOfItemList
                                              ].unitcost;
                                            tmpItems[index].qty = 1;
                                            tmpItems[index].discount = 0;
                                            tmpItems[index].total =
                                              itemList[
                                                indexOfItemList
                                              ].unitcost;
                                            setItems(tmpItems);
                                            const newTotalValue =
                                              calculateTotalValue(tmpItems);
                                            setTotalValue(newTotalValue);
                                          }}
                                        >
                                          {itemList.map((value) => (
                                            <option
                                              value={value.item}
                                              selected={
                                                items[index].item == value.item
                                                  ? "selected"
                                                  : ""
                                              }
                                            >
                                              {value.item}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td className="left strong">
                                        <b>{items[index].itemcode}</b>
                                      </td>
                                      <td className="left">
                                        {" "}
                                        <input
                                          type="number"
                                          className="form-control"
                                          id={"val-unitcost" + String(index)}
                                          name={"val-unitcost" + String(index)}
                                          placeholder="Unit Cost"
                                          onChange={(data) =>
                                            onUpdateItemValue(
                                              "unitcost",
                                              index,
                                              data
                                            )
                                          }
                                          // defaultValue={
                                          //   state.items[index].unitcost
                                          // }
                                          value={items[index].unitcost}
                                        />
                                      </td>
                                      <td className="right">
                                        {" "}
                                        <input
                                          type="number"
                                          className="form-control"
                                          id={"val-qty" + String(index)}
                                          name={"val-qty" + String(index)}
                                          placeholder="QTY"
                                          onChange={(data) =>
                                            onUpdateItemValue(
                                              "qty",
                                              index,
                                              data
                                            )
                                          }
                                          // defaultValue={state.items[index].qty}
                                          value={items[index].qty}
                                        />
                                      </td>
                                      <td className="center">
                                        {" "}
                                        <input
                                          type="number"
                                          className="form-control"
                                          id={"val-discount" + String(index)}
                                          name={"val-discount" + String(index)}
                                          placeholder="Discount"
                                          onChange={(data) => {
                                            onUpdateItemValue(
                                              "discount",
                                              index,
                                              data
                                            );
                                          }}
                                          // defaultValue={
                                          //   state.items[index].discount
                                          // }
                                          value={items[index].discount}
                                        />
                                      </td>
                                      <td className="right">
                                        {" "}
                                        <input
                                          type="number"
                                          className="form-control"
                                          id={"val-total" + String(index)}
                                          name={"val-total" + String(index)}
                                          placeholder="Total"
                                          onChange={(data) =>
                                            onUpdateItemValue(
                                              "total",
                                              index,
                                              data
                                            )
                                          }
                                          // defaultValue={state.items[index].total}
                                          value={items[index].total}
                                        />
                                      </td>
                                      <td>
                                        <Button
                                          className="btn btn-danger btn-xs sharp"
                                          onClick={() => {
                                            var tmpItems = items;
                                            console.log("items: ", tmpItems);
                                            tmpItems = tmpItems.filter(
                                              (item) =>
                                                item.id != tmpItems[index].id
                                            );
                                            for (
                                              let i = 0;
                                              i < tmpItems.length;
                                              i++
                                            ) {
                                              tmpItems[i].id = i + 1;
                                            }
                                            console.log("items: ", tmpItems);
                                            setItems(tmpItems);
                                            const newTotalValue =
                                              calculateTotalValue(tmpItems);
                                            setTotalValue(newTotalValue);
                                          }}
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-5 ml-auto">
                              <table className="table table-clear">
                                <tbody>
                                  <tr>
                                    <td className="left">
                                      <strong>Total</strong>
                                    </td>
                                    <td className="right">
                                      <strong>{totalValue + " THB"}</strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <PatientTextInfo
                            title="Order"
                            value={currentBooking.extendedProps.order}
                            bold={true}
                          />
                          <PatientLinkInfo
                            title="HN"
                            value={currentBooking.extendedProps.hn}
                            bold={true}
                            path={
                              "/patient-page/" + currentBooking.extendedProps.hn
                            }
                          />
                          <PatientLinkInfo
                            title="Full Name"
                            value={
                              currentBooking.extendedProps.firstname +
                              " " +
                              currentBooking.extendedProps.lastname
                            }
                            bold={true}
                            path={
                              "/patient-page/" + currentBooking.extendedProps.hn
                            }
                          />
                          <PatientTextInfo
                            title="Phone"
                            value={currentBooking.extendedProps.phone}
                            bold={true}
                          />
                          {/* <PatientTextInfo
                            title="Health Insurance"
                            value={currentBooking.extendedProps.healthInsurance}
                            bold={true}
                          /> */}
                          <PatientTextInfo
                            title="Type"
                            value={currentBooking.extendedProps.type}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Status"
                            value={currentBooking.extendedProps.status}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Technician"
                            value={currentBooking.extendedProps.technician}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Doctor"
                            value={currentBooking.extendedProps.doctor}
                            bold={true}
                          />
                          <PatientTextInfo
                            title="Date"
                            value={currentBooking.start}
                            bold={true}
                          />
                          {currentBooking.extendedProps.status ==
                            "Scheduled" && (
                            <PatientTextInfo
                              title="Time"
                              value={currentBooking.extendedProps.time}
                              bold={true}
                            />
                          )}
                          <PatientTextInfo
                            title="Note"
                            value={currentBooking.extendedProps.note}
                            bold={true}
                          />
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="center">No.</th>
                                  <th className="center">Item Name</th>
                                  <th className="center">Item Code</th>
                                  <th className="center">Unit Cost (THB)</th>
                                  <th className="center">Qty</th>
                                  <th className="center">Discount (THB)</th>
                                  <th className="center">Total (THB)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((element, index) => {
                                  return (
                                    <tr>
                                      <td className="">
                                        <b>{element.id}</b>
                                      </td>
                                      <td>
                                        <b>{element.item}</b>
                                      </td>
                                      <td className="left strong">
                                        <b>{element.itemcode}</b>
                                      </td>
                                      <td className="left">
                                        {" "}
                                        <b>{element.unitcost}</b>
                                      </td>
                                      <td className="right">
                                        {" "}
                                        <b>{element.qty}</b>
                                      </td>
                                      <td className="center">
                                        {" "}
                                        <b>{element.discount}</b>
                                      </td>
                                      <td className="right">
                                        {" "}
                                        <b>{element.total}</b>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-5 ml-auto">
                              <table className="table table-clear">
                                <tbody>
                                  <tr>
                                    <td className="left">
                                      <strong>Total</strong>
                                    </td>
                                    <td className="right">
                                      <strong>{totalValue + " THB"}</strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="justify-content-between">
                <div>
                  <Button
                    variant=""
                    className="btn btn-danger"
                    onClick={() => {
                      refDeleteDoc(currentBooking);
                      setEditBookingDetail(false);
                      setEditEventModal(false);
                    }}
                  >
                    Remove
                  </Button>
                </div>

                <div>
                  <Button
                    variant="light"
                    onClick={() => {
                      setEditBookingDetail(false);
                      setEditEventModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant=""
                    type="button"
                    className="btn btn-primary ml-2"
                    onClick={() => {
                      var allEvents = followupList.filter((obj) => {
                        return (
                          obj.start === currentBooking.start &&
                          obj.extendedProps.hn !== ""
                        );
                      });
                      let listHNEvent = allEvents.filter((element) => {
                        return (
                          element.extendedProps.hn ===
                          currentBooking.extendedProps.hn
                        );
                      });
                      console.log("listHNEvent 1954", listHNEvent);

                      if (listHNEvent.length > 1) {
                        swal({
                          title: `HN${currentBooking.extendedProps.hn} is already booking on this day !`,
                          text: `Please recheck booking on ${currentBooking.start}`,
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((willBooking) => {
                          if (willBooking) {
                            onEditEvent();
                          }
                        });
                      } else {
                        onEditEvent();
                      }
                    }}
                  >
                    Done
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          )}
        </>
      ) : (
        <div class="media d-flex align-items-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
      )}
    </Fragment>
  );
};

//   const handleDate = (date) => {
//     if (date != undefined) {
//       try {
//         var datetime = new Date(
//           date.toDate().setHours(new Date(date.toDate()).getHours() + 7)
//         )
//           .toISOString()
//           .replace("T", " ")
//           .slice(0, 19);
//         return datetime;
//       } catch {
//         // console.log(new Date(date).getHours());
//         // console.log(date);
//         var newDate = new Date(date);
//         var hours = newDate.getHours() + 7;
//         newDate = new Date(newDate.setHours(hours));
//         var newString = newDate.toISOString().replace("T", " ").slice(0, 19);

//         return newString;
//       }
//     } else {
//       return "";
//     }
//   };

export default FollowupTable;
