import React, { useState, useEffect } from "react";
import { db, storage } from "../../../firebase";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  addDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { ref, listAll, getDownloadURL, uploadBytes } from "firebase/storage";
import { saveAs } from "file-saver";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, Input } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
// import { Input, Button } from "reactstrap";
import "./Backup.css"; // Import your CSS file for styling

const Backup = () => {
  const [log, setLog] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [backupTime, setBackupTime] = useState("00:00");
  const [nextBackup, setNextBackup] = useState(null);
  const [lastBackup, setLastBackup] = useState(null);
  const [countdown, setCountdown] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleRestoreClick = () => {
    setError("");
    toggleModal();
  };

  const handleConfirmPassword = () => {
    if (password === "braindynamics12345") {
      toggleModal();
      if (selectedFile) {
        confirmRestore();
      } else if (selectedLog) {
        handleImportBackup();
      }
    } else {
      setError("Incorrect password");
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  // useEffect(() => {
  //   if (nextBackup) {
  //     const interval = setInterval(() => {
  //       updateCountdown();
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   }
  // }, [nextBackup]);

  const fetchLogs = async () => {
    const logsCollection = collection(db, "backupLogs");
    const logsSnapshot = await getDocs(logsCollection);
    let logs = logsSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        timestamp:
          data.timestamp instanceof Timestamp
            ? data.timestamp
            : Timestamp.fromDate(new Date(data.timestamp)),
      };
    });

    logs = logs.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
    logs = logs.slice(0, 20);

    setLog(logs);

    if (logs.length > 0) {
      setLastBackup(logs[0].timestamp.toDate());
    }
  };

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Asia/Bangkok",
    };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );
    return formattedDate.replace(
      /(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2}):(\d{2})/,
      "$1-$2-$3 $4:$5:$6"
    );
  };

  // const backupAll = async () => {
  //   try {
  //     console.log("loading...")
  //     const backupData = await backupFirestore();
  //     const storageData = await backupStorage();
  //     const combinedBackupData = {
  //       firestore: backupData,
  //       storage: storageData,
  //     };
  //     console.log("backupData: ", combinedBackupData);
  
  //     const backupBlob = new Blob([JSON.stringify(combinedBackupData)], {
  //       type: "application/json",
  //     });
  //     const backupFileName = `firebase-backup-${new Date().toISOString()}.json`;
  
  //     saveAs(backupBlob, backupFileName);
  
  //     const storageRef = ref(storage, `backups/${backupFileName}`);
  //     await uploadBytes(storageRef, backupBlob);
  
  //     await logBackup("Manual backup completed", backupFileName);
  //     fetchLogs();
  //   } catch (error) {
  //     if (error instanceof Error) {
  //       console.error("Error during backup:", error);
  //       await logBackup(`Manual backup failed: ${error.message}`);
  //     } else {
  //       console.error("Unknown error during backup:", error);
  //       await logBackup(`Manual backup failed: Unknown error`);
  //     }
  //   }
  // };

  const backupAll = async () => {
    try {
      console.log("Loading...");
      const backupData = await backupFirestore();
      const storageData = await backupStorage();
      const combinedBackupData = {
        firestore: backupData,
        storage: storageData,
      };
      console.log("backupData: ", combinedBackupData);
  
      // Convert the backup data to a Buffer (similar to how it's done in the Cloud Function)
      const backupBlob = new Blob([JSON.stringify(combinedBackupData)], {
        type: "application/json",
      });
      const backupFileName = `firebase-backup-${new Date().toISOString()}.json`;
  
      // Save file locally
      saveAs(backupBlob, backupFileName);
  
      // Upload to Firebase Storage
      const storageRef = ref(storage, `backups/${backupFileName}`);
      console.log("Uploading backup to Firebase Storage...");
      const backupFileArrayBuffer = await backupBlob.arrayBuffer(); // Convert Blob to ArrayBuffer
      const backupFileUint8Array = new Uint8Array(backupFileArrayBuffer); // Convert ArrayBuffer to Uint8Array
      const backupFileBuffer = Buffer.from(backupFileUint8Array); // Convert Uint8Array to Buffer
  
      await uploadBytes(storageRef, backupFileBuffer);
      console.log("Backup uploaded to Firebase Storage:", backupFileName);
  
      // Log the backup completion
      await logBackup("Manual backup completed", backupFileName);
      fetchLogs();
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error during backup:", error);
        await logBackup(`Manual backup failed: ${error.message}`);
      } else {
        console.error("Unknown error during backup:", error);
        await logBackup(`Manual backup failed: Unknown error`);
      }
    }
  };
  
  

  const handleFileChange = (event) => {
    const file = (event.target.files && event.target.files[0]) || null;
    setSelectedFile(file);
  };

  const confirmRestore = async () => {
    if (!selectedFile) return;

    try {
      const fileContent = await selectedFile.text();
      const backupData = JSON.parse(fileContent);

      await restoreFirestore(backupData.firestore);
      // await restoreStorage(backupData.storage);
      await logBackup("Restore from file completed");
      fetchLogs();
      setSelectedFile(null); // Reset the selected file
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error during restore:", error);
        await logBackup(`Restore failed: ${error.message}`);
      } else {
        console.error("Unknown error during restore:", error);
        await logBackup(`Restore failed: Unknown error`);
      }
    }
  };


  // const backupFirestore = async () => {
  //   const backupData = {};
  //   const collections = [
  //     "activities",
  //     "admins",
  //     "borrowings",
  //     "cpaps",
  //     "doctors",
  //     "followup",
  //     "items",
  //     "nodes",
  //     "orders",
  //     "patients",
  //     "products",
  //     "sales",
  //     "sleeptechnicians",
  //     "trial",
  //   ];
  
  //   const backupCollection = async (collectionRef) => {
  //     const collectionBackup = [];
  //     const collectionName = collectionRef.path.split('/').pop(); // Get the collection name from the path
  //     console.log(`Starting backup for collection: ${collectionName}`);
  
  //     const querySnapshot = await getDocs(collectionRef);
  //     console.log(`Found ${querySnapshot.size} documents in collection: ${collectionName}`);
  
  //     for (const doc of querySnapshot.docs) {
  //       const docId = doc.id;
  //       console.log(`Backing up document: ${docId} in collection: ${collectionName}`);
  
  //       const docData = {
  //         id: doc.id,
  //         data: doc.data(),
  //         subcollections: {},
  //       };
  
  //       // Use the custom listCollections function to find subcollections
  //       const subcollections = await listCollections(doc.ref);
  //       console.log(`Found ${subcollections.length} subcollections in document: ${docId}`);
  
  //       for (const subcollection of subcollections) {
  //         const subcollectionName = subcollection.path.split('/').pop(); // Get subcollection name from the path
  //         console.log(`Backing up subcollection: ${subcollectionName} in document: ${docId}`);
  //         const subcollectionData = await backupCollection(subcollection);
  //         docData.subcollections[subcollection.id] = subcollectionData;
  //       }
  
  //       collectionBackup.push(docData);
  //     }
  
  //     console.log(`Completed backup for collection: ${collectionName}`);
  //     return collectionBackup;
  //   };
  
  //   for (const collectionName of collections) {
  //     const collectionRef = collection(db, collectionName);
  //     console.log(`Starting backup for top-level collection: ${collectionName}`);
  //     backupData[collectionName] = await backupCollection(collectionRef);
  //     console.log(`Completed backup for top-level collection: ${collectionName}`);
  //   }
  
  //   console.log('Backup process completed');
  //   return backupData;
  // };
  
  const backupFirestore = async () => {
    const backupData = {};
    const collections = [
      { name: "activities", subcollections: [] },
      { name: "admins", subcollections: [] },
      { name: "borrowings", subcollections: [] },
      { name: "cpaps", subcollections: ["borrowing"] },
      { name: "doctors", subcollections: ["sleepStudyCases"] },
      { name: "followup", subcollections: [] },
      { name: "items", subcollections: [] },
      { name: "mail", subcollections: [] },
      { name: "nodes", subcollections: ["bookings", "customers"] },
      { name: "orders", subcollections: [] },
      { name: "patients", subcollections: [] },
      { name: "products", subcollections: ["cpapfile", "logs"] },
      { name: "sales", subcollections: [] },
      { name: "sleeptechnicians", subcollections: [] },
      { name: "trial", subcollections: [] },
    ];
  
    const backupCollection = async (collectionRef, subcollections) => {
      const collectionBackup = [];
      const querySnapshot = await getDocs(collectionRef);
      console.log(`Found ${querySnapshot.size} documents in collection: ${collectionRef.id}`);
  
      for (const doc of querySnapshot.docs) {
        const docData = {
          id: doc.id,
          data: doc.data(),
          subcollections: {},
        };
  
        for (const subcollectionName of subcollections) {
          const subcollectionRef = collection(doc.ref, subcollectionName);
          console.log(`Backing up subcollection: ${subcollectionName} in document: ${doc.id}`);
          const subcollectionData = await backupCollection(subcollectionRef, []);
          docData.subcollections[subcollectionName] = subcollectionData;
        }
  
        collectionBackup.push(docData);
      }
  
      console.log(`Completed backup for collection: ${collectionRef.id}`);
      return collectionBackup;
    };
  
    for (const { name, subcollections } of collections) {
      const collectionRef = collection(db, name);
      console.log(`Starting backup for collection: ${name}`);
      backupData[name] = await backupCollection(collectionRef, subcollections);
      console.log(`Completed backup for collection: ${name}`);
    }
  
    console.log('Backup process completed');
    return backupData;
  };
  
  
  
  const listCollections = async (docRef) => {
    // This function manually checks for the existence of common subcollections.
    const subcollections = [];
  
    // Define possible subcollections names if you know them
    const possibleSubcollections = ["customers", "bookings", "anotherSubcollection"];
  
    for (const subcollectionName of possibleSubcollections) {
      const subcollectionRef = collection(docRef, subcollectionName);
      const subcollectionSnapshot = await getDocs(subcollectionRef);
      if (!subcollectionSnapshot.empty) {
        subcollections.push(subcollectionRef);
      }
    }
  
    return subcollections;
  };
  
  
  const backupStorage = async () => {
    const storageRef = ref(storage);
    const list = await listAll(storageRef);
    const storageData = {};
  
    await Promise.all(
      list.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        storageData[itemRef.name] = url;
      })
    );
  
    return storageData;
  };
  

  const restoreFirestore = async (backupData) => {
    const restoreCollection = async (collectionRef, docs) => {
      for (const docData of docs) {
        const docRef = doc(collectionRef, docData.id);
        await setDoc(docRef, docData.data);
  
        // Restore subcollections if they exist
        if (docData.subcollections) {
          for (const subcollectionName in docData.subcollections) {
            const subcollectionRef = collection(docRef, subcollectionName);
            await restoreCollection(subcollectionRef, docData.subcollections[subcollectionName]);
          }
        }
      }
    };
  
    for (const collectionName in backupData) {
      const collectionRef = collection(db, collectionName);
      await restoreCollection(collectionRef, backupData[collectionName]);
    }
  };
  
  

  // const restoreStorage = async (backupData) => {
  //   for (const fileName in backupData) {
  //     console.log(
  //       `Restoring storage file: ${fileName}, URL: ${backupData[fileName]}`
  //     );
  //   }
  //   await logBackup("Storage restore completed");
  // };

  const logBackup = async (message, backupFileName) => {
    const newLog = {
      message,
      timestamp: Timestamp.now(),
      backupFileName: backupFileName ? backupFileName : "",
    };
    console.log(newLog);
    console.log("logsBackup");
    const docRef = await addDoc(collection(db, "backupLogs"), newLog);
    fetchLogs();
  };

  const handleSelectLog = async (event) => {
    const logId = event.target.value;
    setSelectedLog(logId);
  };

  const handleImportBackup = async () => {
    console.log("handleImport");
    try {
      if (!selectedLog) return;

      const logDoc = await getDoc(doc(db, "backupLogs", selectedLog));
      console.log("logDoc: ", logDoc);
      if (!logDoc.exists()) return;

      const backupFileName = logDoc.data().backupFileName;
      if (!backupFileName) return;

      const backupFileUrl = await getDownloadURL(
        ref(storage, `backups/${backupFileName}`)
      );

      const response = await fetch(backupFileUrl);
      const backupBlob = await response.blob();
      const file = new File([backupBlob], backupFileName, {
        type: "application/json",
      });
      console.log("backup");
      const fileContent = await file.text();
      const backupData = JSON.parse(fileContent);
      console.log(backupData.firestore)

      await restoreFirestore(backupData.firestore);
      // await restoreStorage(backupData.storage);
      await logBackup(`Restore from logs completed`);
      fetchLogs();
      setSelectedLog(null);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error during import backup:", error);
        await logBackup(`Restore import backup failed: ${error.message}`);
      } else {
        console.error("Unknown error during import backup:", error);
        await logBackup(`Restore import backup failed: Unknown error`);
      }
    }
  };

  // const handleBackupTimeChange = (event) => {
  //   setBackupTime(event.target.value);
  // };

  // const saveBackupTime = async () => {
  //   const functions = getFunctions();
  //   const setBackupTime = httpsCallable(functions, "setBackupTime");
  //   try {
  //     const result = await setBackupTime({ time: backupTime });
  //     console.log(result.data);
  //     calculateNextBackup(backupTime);
  //   } catch (error) {
  //     console.error("Error setting backup time:", error);
  //   }
  // };

  // const loadBackupTime = async () => {
  //   const backupTimeDoc = doc(db, "settings", "backupTime");
  //   const docSnapshot = await getDoc(backupTimeDoc);
  //   if (docSnapshot.exists()) {
  //     const data = docSnapshot.data();
  //     if (data && data.time) {
  //       setBackupTime(data.time);
  //       calculateNextBackup(data.time);
  //     }
  //   }
  // };

  // const calculateNextBackup = (time) => {
  //   const [hours, minutes] = time.split(":").map(Number);
  //   const now = new Date();
  //   const nextBackupDate = new Date(
  //     now.getFullYear(),
  //     now.getMonth(),
  //     now.getDate(),
  //     hours,
  //     minutes,
  //     0
  //   );

  //   if (nextBackupDate.getTime() < now.getTime()) {
  //     nextBackupDate.setDate(nextBackupDate.getDate() + 1);
  //   }

  //   setNextBackup(nextBackupDate);
  // };

  // const updateCountdown = () => {
  //   if (!nextBackup) return;

  //   const now = new Date();
  //   const timeDiff = nextBackup.getTime() - now.getTime();
  //   if (timeDiff <= 0) {
  //     setCountdown("Time for backup!");
  //     return;
  //   }

  //   const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  //   const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  //   const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  //   setCountdown(`${hours}h ${minutes}m ${seconds}s`);
  // };

  return (
    <div className="backup-page">
      {!isLoading ? (
        <>
          <div className="backup-left">
            <div>
              <h2 className="mb-3">Backup Database</h2>
              <Button className="btn btn-primary" onClick={backupAll}>
                Manual Backup
              </Button>
            </div>
            <div className="mt-5">
              <h2>Restore Database</h2>
              <div className="mt-3">
                <h5>Manual import file restore</h5>
                <input
                  className="mt-2"
                  type="file"
                  onChange={handleFileChange}
                  style={{ maxWidth: "600px" }}
                />
                {selectedFile && (
                  <Button
                    className="btn btn-warning mt-2 ml-2"
                    onClick={handleRestoreClick}
                  >
                    Restore
                  </Button>
                )}
              </div>
              <div className="mt-3">
                <h5>Import file restore from logs</h5>
                <select
                  className="mt-2"
                  style={{ maxWidth: "600px" }}
                  onChange={handleSelectLog}
                  value={selectedLog || ""}
                >
                  <option value="" disabled>
                    Select a backup log to import
                  </option>
                  {log
                    .filter((entry) => entry.backupFileName !== "")
                    .map((entry) => (
                      <option key={entry.id} value={entry.id}>
                        {formatDate(entry.timestamp)}: {entry.message}
                      </option>
                    ))}
                </select>
                {selectedLog && (
                  <Button
                    className="btn btn-warning mt-2 ml-2"
                    onClick={handleRestoreClick}
                  >
                    Restore
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="backup-right">
            <h2 className="mb-3">Backup Logs</h2>
            <ul>
              {log.map((entry) => (
                <li key={entry.id}>
                  {formatDate(entry.timestamp)}: {entry.message}
                </li>
              ))}
            </ul>
          </div>

          <Modal show={isModalOpen} onHide={toggleModal}>
            <Modal.Header closeButton>
              <Modal.Title>Enter Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter password"
                className="form-control"
              />
              {error && <div className="text-danger mt-2">{error}</div>}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleConfirmPassword}>
                Confirm
              </Button>
              <Button variant="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
};

export default Backup;
