import React from "react";
import { useLocation, useHistory } from "react-router-dom";
// utils
import { createQueryString, queryStringToObject } from "../utils/helpers";

function useQueryParams(options) {
  const { search } = useLocation();
  const history = useHistory();

  // get query params
  const queryParams = React.useMemo(
    () => queryStringToObject(search, options),
    [search]
  );

  // updates the query params
  function setQueryParams(queryObj) {
    history.push({
      search: createQueryString(queryObj),
    });
  }

  return { queryParams, setQueryParams };
}

export default useQueryParams;
