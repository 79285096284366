import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { getMessageFromErrorCode } from "../ErrorMessage";
import Toast from "../components/toast/SetToast";
import { notifyTopRight, notifyError } from "../components/toast/SetToast";
const ForgotPassword = ({ history }) => {
  const { resetPassword } = useAuth();
  const emailRef = useRef();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    await resetPassword(emailRef.current.value)
      .then(() => {
        notifyTopRight("Check your inbox");
      })
      .catch((error) => {
        notifyError(getMessageFromErrorCode(error.code));
      });

    setLoading(false);
  };
  return (
    <div className="authincation h-100 p-meddle">
      <Toast />
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">Forgot Password</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                        <label>
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          ref={emailRef}
                          required
                        />
                      </div>
                      <div className="text-center">
                        <input
                          type="submit"
                          value="RESET PASSWORD"
                          className="btn btn-primary btn-block"
                          disabled={loading}
                        />
                      </div>
                      <div className="form-group text-center mt-4">
                        <Link to="/page-login">Login</Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
