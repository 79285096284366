import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Badge } from "react-bootstrap";
import filepicture from "../../../images/file.png";
const PatientNoteEditForm = (props) => {
  const noteData = {
    title: props.note ? props.note.title : "",
    detail: props.note ? props.note.detail : "",
    filename: props.note ? props.note.filename : [],
    hn: props.note ? props.note.hn : props.hn,
    createBy: props.note ? props.note.createBy : props.createBy,
    updateAt: new Date(),
    createAt: props.note ? props.note.createAt : new Date(),
  };

  const [isHide, setIsHide] = useState(false);
  const [newNote, setNewNote] = useState(noteData);
  const [selectedFilesToUpload, setSelectedFilesToUpload] = useState([]);
  const [selectedFilesToRemove, setSelectedFilesToRemove] = useState([]);
  const [active, setActive] = useState([]);
  const [fileNames, setFilenames] = useState([]);
  const [fileNameOnEdit, setFileNameOnEdit] = useState(noteData.filename);
  const titleRef = useRef("");
  const detailRef = useRef("");

  const onTitleChange = (e) => {
    e.preventDefault();
    noteData = {
      ...noteData,
      title: e.target.value,
    };
    setNewNote(noteData);
    // const title = e.target.value;
    // setNewNote(...newNote, { title });
  };

  const onDetailChange = (e) => {
    e.preventDefault();
    noteData = {
      ...noteData,
      detail: e.target.value,
    };
    setNewNote(noteData);
    // const detail = e.target.value;
    // setNewNote(...newNote, { detail });
  };

  const onFilesChange = (e) => {
    let files = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const f = e.target.files[i];
      setSelectedFilesToUpload((prevState) => [...prevState, f]);
      setFilenames((prevState) => [...prevState, f.name]);
    }
  };

  // const onHNChange = (e) => {
  //   setNewNote({ hn: e.target.value });
  // };

  // const onCreateByChange = (e) => {
  //   setNewNote( { createBy: e.target.value });
  // };

  // const onUpdateAtChange = (e) => {
  //   setNewNote({ updateAt: e.target.value });
  // };

  // const onCreateAtChange = (e) => {
  //   setNewNote({ createAt: e.target.value });
  // };
  const onSeletedToRemove = (file, index) => {
    setSelectedFilesToRemove((prevFiles) => [...prevFiles, file]);
    setActive((prevState) => [...prevState, index]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let newFileArray = [];
    if (selectedFilesToRemove.length > 0) {
      newFileArray = noteData.filename.filter(
        (file) => !selectedFilesToRemove.some((f) => file === f)
      );
    }

    props.onSubmit(
      {
        ...noteData,
        title: titleRef.current.value,
        detail: detailRef.current.value,
        filename:
          selectedFilesToRemove.length === 0 ? noteData.filename : newFileArray,
        updateAt: noteData.updateAt,
      },
      selectedFilesToUpload
    );
    titleRef.current.value = "";
    detailRef.current.value = "";
    setIsHide(true);
    setFilenames([]);
    setActive([]);
    setSelectedFilesToUpload([]);
  };

  const onCancel = () => {
    props.onCancel();
    setIsHide(true);
    setFilenames([]);
    setActive([]);
    setSelectedFilesToUpload([]);
  };

  useEffect(() => {
    setFileNameOnEdit(
      fileNameOnEdit.filter(
        (file) => !selectedFilesToRemove.some((f) => file === f)
      )
    );
  }, [active]);

  return (
    <>
      <div className="col-lg-12 mb-5">
        <b>Edit Note</b>

        <div className="col-lg-6">
          <div className="input-group mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Title..."
              defaultValue={noteData.title}
              ref={titleRef}
            ></input>
          </div>
          {!isHide ? (
            <>
              <textarea
                className="form-control mt-3 mb-3"
                id="val-suggestions"
                name="val-suggestions"
                rows="5"
                placeholder="Add note here..."
                ref={detailRef}
                defaultValue={noteData.detail}
              ></textarea>
              <div className="basic-form custom_file_input">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Upload</span>
                  </div>
                  <div className="custom-file">
                    <input
                      type="file"
                      multiple
                      className="custom-file-input"
                      onChange={onFilesChange}
                    />
                    <label className="custom-file-label">{fileNames}</label>
                  </div>
                </div>
              </div>
              {fileNameOnEdit.map((file, index) => {
                return (
                  <>
                    <Link
                      className="text-muted"
                      key={index}
                      to="#"
                      // onClick={() => onSeletedToRemove(file, index)}
                    >
                      <Badge variant={"outline-light badge-rounded mr-3 mt-1"}>
                        <small className="mb-0">
                          <img
                            src={filepicture}
                            alt="profile"
                            width={"25"}
                            height={"25"}
                            className="mr-1"
                          />
                          {file}
                          <button
                            type="button"
                            className="btn btn-sm close"
                            aria-label="Close"
                            onClick={() => onSeletedToRemove(file, index)}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </small>
                      </Badge>
                    </Link>
                  </>
                );
              })}
              <div className="form-group row ml-1 mt-1">
                <Button className="sm " onClick={onSubmit}>
                  Save
                </Button>
                <Button className="sm ml-2" variant="light" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientNoteEditForm;
