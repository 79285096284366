let CpapBorrowingData = {
  SerialNumber: "",
  Status: "",
  HN: "",
  Admin: "",
  Mask: "",
  MaskSize: "",
  Humidifier: "",
  RampTime: "",
  InitialPressure: "",
  MinPressure: "",
  MaxPressure: "",
  ReturnDate: "",
  BorrowDate: "",
  DueDate: "",
  RampTime: "",
  BorrowCondition: "",
  ReturnCondition: "",
  Note: "",
  CreateAt: "",
  Activity: "",
  Tel: "",
  FirstName: "",
  LastName: "",
};

const CpapBorrowingOptions = [
  "กรุณาเลือก",
  "ยืมเครื่อง",
  "คืนเครื่อง",
  "ส่งทำความสะอาด",
  "ส่งซ่อม",
  "ส่งเครื่องกลับศูนย์",
  "ลงทะเบียน",
  "ยกเลิกใช้งาน",
];

// const MaskSizes = ["S", "M", "L", "XL"];
const MaskSizes = ["S", "M", "L", "Free Size"];

// const Masks = ["Nasal Mask", "Full Face Mask", "Under Nose", "Others"];
const Masks = [
  "Nasal Mask ResComf",
  "Full Face Mask ResComf",
  "Nasal Mask AirFit N20",
  "Nasal Mask AirFit N30",
  "Full Face Mask AirFit F20",
  "Full Face Mask AirTouch F20",
  "Nasal Pillow AirFit P10",
  "Nasal Mask Wizard 310",
  "Full Face Mask Wizard 320",
];

const RampTimeOptions = ["0", "5", "15", "20", "25", "30", "35", "40", "45"];

const PressureOptions = [
  4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
  25,
];

const HumidifierOptions = ["No", "Yes"];

const TypeOptions = ["Trial", "Rental"];

export {
  CpapBorrowingData,
  CpapBorrowingOptions,
  Masks,
  MaskSizes,
  RampTimeOptions,
  PressureOptions,
  HumidifierOptions,
  TypeOptions,
};
