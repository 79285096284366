export default [
  {
    label: "Item ID",
    name: "itemId",
    type: "text",
    required: true,
  },
  {
    label: "Item",
    name: "item",
    type: "text",
    required: true,
  },
  { label: "UnitCost", name: "unitcost", type: "text", required: true },
  {
    label: "Description",
    name: "description",
    type: "text",
  },
  {
    label: "Node",
    name: "node",
    type: "select",
    options: ["ALL", "BD", "CHG", "BKH", "LPH", "ANYA", "RPH", "BMH", "BPK3"],
  },
  {
    label: "Category",
    name: "category",
    type: "select",
    options: ["service", "cpap", "mask", "accessory"],
  },
  {
    label: "Status",
    name: "IsActive",
    type: "select",
    options: ["Active", "Inactive"],
  },
];
