const sleepHabitFormTitle = "ข้อมูลเกี่ยวกับตารางการนอนหลับ"

const sleepHabitQuestions = {

    Q1 : "ท่านเข้านอนเวลาใด",
    Q2 : "กว่าจะหลับท่านใช่เวลาเท่าใด",
    Q3 : "ระหว่างคืนท่านตื่นหรือไม่",
    Q31 : "ถ้าตื่น ท่านตื่น กี่ครั่ง",
    Q32 : "ตื่นรวมกันนานกี่ชั่วโมง",
    Q4 : "ท่านตื่นนอนเวลาใด (เช่น 6.00)",
    Q5 : "ท่านคิดว่าท่านได้นอนเป็นเวลานานเท่าใด",
    Q7 : "ท่านทำงานเป็นกะหรือไม่",
    Q71 : "ท่านทำงานกะเช้า กี่วันต่อสัปดาห์",
    Q72 : "ท่านทำงานกะเช้า เริ่มเวลา",
    Q73 : "ท่านทำงานกะเช้า ถึงเวลา",
    Q74 : "ท่านทำงานกะบ่าย กี่วันต่อสัปดาห์",
    Q75 : "ท่านทำงานกะบ่าย เริ่มเวลา",
    Q76 : "ท่านทำงานกะบ่าย ถึงเวลา",
    Q77 : "ท่านทำงานกะดึก กี่วันต่อสัปดาห์",
    Q78 : "ท่านทำงานกะดึก เริ่มเวลา",
    Q79 : "ท่านทำงานกะดึก ถึงเวลา",
    Q8 : "ท่านนอนงีบหลังจากตื่นนอนตอนเช้าหรือไม่",
    Q9 : "ถ้างีบ งีบเวลาใด (เช่น 15.00)",
    Q10 : "ท่านงีบหลับ สัปดาห์ละกี่ครั้ง",
    Q11 : "ท่านงีบโดยเฉลี่ยครั้งละ กี่นาที"
}

const sleepHabitData = {

workday : {
    sleep_time : "",
    sleep_latency : "",
    wake_after_sleep : "",
    wake_after_sleep_numbers : "",
    wake_after_sleep_onset_durations :"",
    wake_time: "",
    total_sleep_time: ""
} ,
holiday : {
    sleep_time : "",
    sleep_latency : "",
    wake_after_sleep : "",
    wake_after_sleep_numbers : "",
    wake_after_sleep_onset_durations :"",
    wake_time: "",
    total_sleep_time: ""
} ,
shift_work: "",
morning_shift_work : {
    days_per_week: "",
    start_time: "",
    end_time : ""
},
afternoon_shift_work : {
    days_per_week: "",
    start_time: "",
    end_time : ""
},
night_shift_work : {
    days_per_week: "",
    start_time: "",
    end_time : ""
},
daytime_nap: "",
nap_time: "",
nap_times_per_week : "",
nap_duration_each_time: ""
}

export {sleepHabitData , sleepHabitFormTitle , sleepHabitQuestions}