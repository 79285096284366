import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
// import DoctorsAccordion from "./DoctorsAccordion";
import { PatientTable } from "./PatientUtils";
import { AuthContext } from "../../../context/AuthContext";

class PatientList extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = { textSearch: "", sortBy: "firstnameTh" };
  }

  onClick = (e) => {
    e.preventDefault();
    console.log("onclick..");
  };

  onSearchChange = (e) => {
    setTimeout(() => {
      this.setState({ textSearch: e.target.value });
    }, 800);
  };

  onFilterChange = (option) => {
    this.setState({ sortBy: `${option}` });
  };

  render() {
    var FilterOption = "HN";

    this.state.sortBy == "hn"
      ? (FilterOption = "HN  ")
      : (FilterOption = "Name");

    // console.log("sort by ", this.state.sortBy);

    return (
      <React.Fragment>
        <PageTitle
          activeMenu="Search Patient"
          motherMenu="Patient"
          motherLink="patient-list"
        />
        <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
          {
            !["sale", "sleep_technician", "staff"].includes(
              this.context.currentUser.roles[0] && (
                <Link to="/patient-register" className="btn btn-danger">
                  + New Patient
                </Link>
              )
            )
          }
          <div className="input-group search-area ml-auto d-inline-flex">
            <input
              type="text"
              className="form-control"
              placeholder="Search here"
              onChange={this.onSearchChange}
            />
            <div className="input-group-append">
              <span className="input-group-text c-pointer">
                <i className="flaticon-381-search-2"></i>
              </span>
            </div>
          </div>
          <Dropdown className="ml-3 width200" onSelect={this.onFilterChange}>
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
              <i className="flaticon-381-controls-3 "></i> by {FilterOption}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item href="#" eventKey={"firstnameTh"}>
                Name
              </Dropdown.Item>
              <Dropdown.Item href="#" eventKey={"hn"}>
                HN
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <Link onClick={this.onClick} className="btn btn-outline-primary ml-3">
            <i className="flaticon-381-menu-1 mr-0"></i>
          </Link>
          <Link onClick={this.onClick} className="btn btn-light ml-3">
            <i className="flaticon-381-pad mr-0"></i>
          </Link> */}
        </div>

        {/* <DoctorsAccordion /> */}
        <PatientTable text={this.state.textSearch} sortBy={this.state.sortBy} />
      </React.Fragment>
    );
  }
}

export default PatientList;
