import { useReducer, useEffect, useState } from "react";
import { db } from "../firebase";
import {
  setDoc,
  doc,
  collection,
  updateDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return { isPending: true, document: null, success: false, error: null };
    case "ADDED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "DELETED_DOCUMENT":
      return { isPending: false, document: null, success: true, error: null };
    case "ERROR":
      console.log(action.payload);
      return {
        isPending: false,
        document: null,
        success: false,
        error: action.payload,
      };
    case "UPDATED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    default:
      return state;
  }
};

export const useFirestore = (col) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);

  // collection ref

  const ref = collection(db, col);

  // only dispatch if not cancelled
  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      // console.log(action);
      // console.log("cancel?", isCancelled);
      dispatch(action);
    }
  };

  // add a document
  const addDocument = async (doc) => {
    dispatch({ type: "IS_PENDING" });
    const createdAt = new Date();

    const addedDocument = await addDoc(ref, { ...doc, createdAt })
      .then((res) => {
        dispatchIfNotCancelled({
          type: "ADDED_DOCUMENT",
          payload: res.id,
        });
      })
      .catch((error) => {
        dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
      });
  };

  // add a document with id
  const addDocumentWithId = async (id, document) => {
    dispatch({ type: "IS_PENDING" });
    const createdAt = new Date();

    const ref = doc(db, col, id);

    const addedDocumentWithId = await setDoc(ref, { ...document, createdAt })
      .then((res) => {
        dispatchIfNotCancelled({
          type: "ADDED_DOCUMENT",
          payload: addedDocumentWithId,
        });
      })
      .catch((error) => {
        dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
      });
  };

  // delete a document
  const deleteDocument = async (id) => {
    dispatch({ type: "IS_PENDING" });

    const ref = doc(db, col, id);
    await deleteDoc(ref)
      .then(() => {
        dispatchIfNotCancelled({ type: "DELETED_DOCUMENT" });
      })
      .catch((error) => {
        dispatchIfNotCancelled({ type: "ERROR", payload: "could not delete" });
      });

    // try {
    //   await deleteDoc(ref);
    //   dispatchIfNotCancelled({ type: "DELETED_DOCUMENT" });
    // } catch (err) {
    //   dispatchIfNotCancelled({ type: "ERROR", payload: "could not delete" });
    // }
  };

  // update a document
  const updateDocument = async (id, updates) => {
    dispatch({ type: "IS_PENDING" });
    const updatedAt = new Date();
    const ref = doc(db, col, id);
    const updatedDocument = await updateDoc(ref, { ...updates, updatedAt })
      .then((res) => {
        console.log(res.data());
        dispatchIfNotCancelled({
          type: "UPDATED_DOCUMENT",
          payload: updates,
        });
        return updates;
      })
      .catch((error) => {
        dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
        return null;
      });

    // try {
    //   const updatedDocument = await updateDoc(ref, { ...updates, updatedAt });
    //   dispatchIfNotCancelled({
    //     type: "UPDATED_DOCUMENT",
    //     payload: updatedDocument,
    //   });
    //   return updatedDocument;
    // } catch (error) {
    //   dispatch({ type: "ERROR", payload: error });
    //   // dispatchIfNotCancelled({ type: "ERROR", payload: error });
    //   return null;
    // }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return {
    addDocument,
    addDocumentWithId,
    deleteDocument,
    updateDocument,
    response,
  };
};
