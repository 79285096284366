import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import filepicture from "../../../images/file.png";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import { Formik } from "formik";
import * as Yup from "yup";
import { db } from "../../../firebase";
import { Button, Modal } from "react-bootstrap";
import Toast from "../toast/SetToast";

import {
  getDocs,
  collection,
  where,
  orderBy,
  query,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import OrderModal from "./OrderModal";
import { useAuth } from "../../../context/AuthContext";

const PatientActivity = (profile) => {
  let text = {
    order: null,
    createDate: null,
    title: null,
    activity: null,
    infoDate1: null,
    infoDate2: null,
    info1: null,
    info2: null,
  };
  const { currentUser } = useAuth();

  const [createModal, setCreateModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [activity, setActivity] = useState([]);
  const [isLoding, setIsLoading] = useState(true);
  const itemsPerPage = 5;

  const PatientTextInfo = ({ title = "", value = "", bold = false }) => {
    return (
      <div className="form-group row">
        <label className="col-lg-3 col-form-label" htmlFor="val-username">
          {title}
        </label>
        <div className="col-lg-8">
          {bold ? (
            <>
              <b>{value}</b>
            </>
          ) : (
            <>
              <text>{value}</text>
            </>
          )}
        </div>
      </div>
    );
  };

  const onViewOrder = async (index) => {
    console.log(activity[index]);

    let docRef = null;
    if (activity[index].title == "bookings") {
      const nodename = activity[index].info1;
      const collectionRef = collection(db, "nodes");
      const q = query(collectionRef, where("nodename", "==", nodename));
      const querySnapshot = await getDocs(q);
      var nodeId = "";
      querySnapshot.forEach((doc) => {
        console.log(`Document ID: ${doc.id}`); // This will log the document ID
        nodeId = doc.id;
      });

      docRef = doc(
        db,
        "nodes",
        // activity[index].info1,
        nodeId,
        "bookings",
        activity[index].id
      );
    } else if (activity[index].title == "followup") {
      docRef = doc(db, "followup", activity[index].id);
    }

    setIsLoading(true);

    await getDoc(docRef)
      .then((doc) => {
        setOrderInfo(doc.data());
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
    setCreateModal(true);
  };

  const onDelete = (index) => {
    // console.log(activity[index]);
    // console.log(activity[index].actId);

    const ref = doc(db, "activities", activity[index].actId);
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteDoc(ref).then(() => {
          swal(`Poof! Your file has been deleted!`, {
            icon: "success",
          });
        });
        setActivity(activity.filter(({ id }) => id !== activity[index].id));
      } else {
        swal("Canceled");
      }
    });
  };
  const handleOnHideModal = () => {
    setCreateModal(false);
    setIsLoading(true);
  };

  useEffect(async () => {
    const queryRef = query(
      collection(db, "activities"),
      where("hn", "==", profile.profile.hn),
      orderBy("createdAt", "desc")
    );
    const tempActivity = [];
    const docSnap = await getDocs(queryRef);

    docSnap.forEach((doc) => {
      text = {
        actId: doc.id,
        id: doc.data().id,
        order: doc.data().order,
        createDate: doc.data().createdAt,
        title: doc.data().title,
        activity: doc.data().activity,
        infoDate1: doc.data().startDate,
        infoDate2: doc.data().dueDate,
        info1: doc.data().detail1,
        info2: doc.data().detail2,
        note: doc.data().note ? doc.data().note : "",
        createBy: doc.data().createBy ? doc.data().createBy : "",
      };

      if (doc.data().activity == "คืนเครื่อง") {
        text = {
          ...text,
          infoDate1: doc.data().endDate,
        };
      }

      tempActivity.push({ ...text });
    });

    setActivity(
      tempActivity.sort((a, b) => (a.createDate < b.createDate ? 1 : -1))
    );
  }, []);

  return (
    <Fragment>
      <Toast />
      {!isLoding && (
        <OrderModal
          createModal={createModal}
          orderInfo={orderInfo}
          onHideModal={handleOnHideModal}
        />
      )}
      <div className="col-xl-6 col-xxl-6 col-lg-6">
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4 className="card-title">Timeline</h4>
          </div>
          <div className="card-body">
            <PerfectScrollbar
              style={{ height: "100%" }}
              id="DZ_W_TimeLine"
              className="widget-timeline dz-scroll height370 ps ps--active-y"
            >
              <ul className="timeline">
                {activity.map((doc, index) => {
                  const createDate = new Date(
                    doc.createDate.seconds * 1000 +
                      doc.createDate.nanoseconds / 1000000
                  );
                  const infoDate1 = doc.infoDate1
                    ? new Date(
                        doc.infoDate1.seconds * 1000 +
                          doc.infoDate1.nanoseconds / 1000000
                      )
                    : null;
                  const infoDate2 = doc.infoDate2
                    ? new Date(
                        doc.infoDate2.seconds * 1000 +
                          doc.infoDate2.nanoseconds / 1000000
                      )
                    : null;

                  return (
                    <li key={index}>
                      <div className="timeline-badge info"></div>

                      {doc.title === "bookings" && (
                        <span className="timeline-panel text-muted">
                          <span>
                            {createDate.toLocaleString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                            {["super_admin"].includes(currentUser.roles[0]) && (
                              <Button
                                variant=""
                                className="close"
                                ata-dismiss="alert"
                                aria-label="Close"
                                onClick={() => onDelete(index)}
                              >
                                <span>
                                  <i className="mdi mdi-close"></i>
                                </span>
                              </Button>
                            )}
                          </span>
                          <h6 className="mb-0">
                            {doc.activity ? doc.activity : ""}{" "}
                            <strong className="text-info">
                              {infoDate1
                                ? infoDate1.toLocaleDateString("th-TH", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })
                                : ""}
                            </strong>
                          </h6>
                          <p className="mb-0">
                            {doc.info1 ? "Node : " + doc.info1 : ""}
                          </p>
                          <p className="mb-0">
                            {doc.info2 ? "Type : " + doc.info2 : ""}
                          </p>

                          <Link
                            className="text-muted"
                            to="#"
                            onClick={() => {
                              onViewOrder(index);
                            }}
                          >
                            <p className="mb-0">
                              {doc.order ? "Order : " + doc.order : ""}
                            </p>
                          </Link>
                          <p className="mb-0">
                            {doc.note ? "Note : " + doc.note : ""}
                          </p>
                          <p className="mb-0 justify-content-end align-item-end">
                            {doc.createBy ? "CreatedBy : " + doc.createBy : ""}
                          </p>
                        </span>
                      )}

                      {doc.title === "followup" && (
                        <span className="timeline-panel text-muted">
                          <span>
                            {createDate.toLocaleString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                            {["super_admin"].includes(currentUser.roles[0]) && (
                              <Button
                                variant=""
                                className="close"
                                ata-dismiss="alert"
                                aria-label="Close"
                                onClick={() => onDelete(index)}
                              >
                                <span>
                                  <i className="mdi mdi-close"></i>
                                </span>
                              </Button>
                            )}
                          </span>
                          <h6 className="mb-0">
                            {doc.activity ? doc.activity : ""}{" "}
                            <strong className="text-info">
                              {infoDate1
                                ? infoDate1.toLocaleDateString("th-TH", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })
                                : ""}
                            </strong>
                          </h6>
                          <p className="mb-0">
                            {doc.info1 ? "Node : " + doc.info1 : ""}
                          </p>
                          <p className="mb-0">
                            {doc.info2 ? "Type : " + doc.info2 : ""}
                          </p>
                          <Link
                            className="text-muted"
                            to="#"
                            onClick={() => {
                              onViewOrder(index);
                            }}
                          >
                            <p className="mb-0">
                              {doc.order ? "Order : " + doc.order : ""}
                            </p>
                          </Link>
                        </span>
                      )}

                      {doc.title === "borrowings" && (
                        <span className="timeline-panel text-muted">
                          <span>
                            {createDate.toLocaleString("en-GB", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                            {["super_admin"].includes(currentUser.roles[0]) && (
                              <Button
                                variant=""
                                className="close"
                                ata-dismiss="alert"
                                aria-label="Close"
                                onClick={() => onDelete(index)}
                              >
                                <span>
                                  <i className="mdi mdi-close"></i>
                                </span>
                              </Button>
                            )}
                          </span>
                          <h6 className="mb-0">
                            {doc.activity ? doc.activity + " CPAP" : ""}{" "}
                            <strong className="text-info">
                              {infoDate1
                                ? infoDate1.toLocaleDateString("th-TH", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })
                                : ""}
                            </strong>
                          </h6>
                          <p className="mb-0">
                            {infoDate2
                              ? "กำหนดคืนเครื่อง " +
                                infoDate2.toLocaleDateString("th-TH", {
                                  weekday: "long",
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })
                              : ""}
                          </p>
                          <p className="mb-0">
                            {doc.order ? "Order : " + doc.order : ""}
                          </p>
                          <p className="mb-0">
                            {"Serial Number : "}
                            <Link to={"/cpap-page/" + doc.info1}>
                              {doc.info1}
                            </Link>
                          </p>
                          <p className="mb-0">
                            {doc.note ? "Note : " + doc.note : ""}
                          </p>
                          <p className="mb-0">
                            {doc.createBy ? "CreatedBy : " + doc.createBy : ""}
                          </p>
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PatientActivity;
