// Cpaps Reducer

const ordersReducerDefaultState = [
  // {
  //   id: "5654fghdhy",
  //   productId: "12156487513154",
  //   productSn: "23455345",
  //   receiptId: "re-4",
  //   amount: "20000",
  //   hn: "23121",
  //   status: "กำลังดำเนินการ",
  //   receiptDate: new Date("2023-06-01T00:00:00"),
  //   createdBy: "admin",
  //   createdAt: new Date("2023-06-01T00:00:00"),
  // },
];

export default (state = ordersReducerDefaultState, action) => {
  switch (action.type) {
    case "ADD_ORDER":
      return [...state, action.order];
    case "REMOVE_ORDER":
      return state.filter(({ id }) => id !== action.id);
    case "EDIT_ORDER":
      return state.map((order) => {
        if (order.id === action.id) {
          return {
            ...order,
            ...action.updates,
          };
        } else {
          return order;
        }
      });
    case "SET_ORDER":
      return action.orders;
    default:
      return state;
  }
};
