import React, { Fragment, useEffect, useState, useRef } from "react";
import swal from "sweetalert";
import {
  Link,
  useLocation,
  useSearchParams,
  createSearchParams,
  useNavigate,
  useHistory,
} from "react-router-dom";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { db } from "../../../../firebase";
import {
  collection,
  updateDoc,
  doc,
  query,
  limit,
  deleteDoc,
  orderBy,
  where,
  Query,
  or,
  and,
} from "firebase/firestore";

import PageTitle from "../../../layouts/PageTitle";
import TechItem from "./TechItem";
import TechRegister from "./TechRegister";
import TechEdit from "./TechEdit";

import { usePagination } from "../../../../hook/usePagination";
import { useFirestore } from "../../../../hook/useFirestore";

import { useQuery } from "../../../../hook/useQuery";

const TechItemList = () => {
  const [techItemList, setTechItemList] = useState([]);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [itemEdit, setItemEdit] = useState({});

  const history = useHistory();
  const searchParams = useQuery();
  const param = searchParams.get("q");

  const searchRef = useRef(null);

  let QueryFilterConstraint = [];
  let queryRef = query(
    collection(db, "sleeptechnicians"),
    orderBy("name", "asc")
  );

  if (param !== null) {
    QueryFilterConstraint.push(
      where("name", ">=", param),
      where("name", "<=", param + "\uf8ff")
    );

    queryRef = query(
      collection(db, "sleeptechnicians"),
      and(...QueryFilterConstraint),
      orderBy("name", "asc")
    );
  }

  const {
    items: techInfo,
    isLoading,
    isStart,
    isEnd,
    getPrev,
    getNext,
  } = usePagination(queryRef, { limit: 20 });

  const { deleteDocument } = useFirestore("sleeptechnicians");

  const handleSearchChange = () => {
    history.replace({
      search: new URLSearchParams({ q: searchRef.current.value }).toString(),
    });
  };

  const handleOnNewItem = () => {
    setShowRegisterModal(true);
  };

  const handleOnEdit = async (id) => {
    setItemEdit(techItemList.find((c) => c.id === id));
    setShowEditModal(true);
  };

  const handleOnRegisterHide = (sn) => {
    setShowRegisterModal(false);
  };
  const handleOnEditHide = (sn) => {
    setShowEditModal(false);
  };

  const handleOnDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        deleteDocument(id)
          .then(() => {
            swal(`Poof! Your file has been deleted!`, {
              icon: "success",
            });
          })
          .catch((err) => {
            swal(`Poof! ${err}`, {
              icon: "error",
            });
          });
      } else {
        swal("Canceled");
      }
    });
  };

  useEffect(() => {
    setTechItemList(techInfo);
  }, [techInfo]);

  // console.log(techItemList);

  return (
    <Fragment>
      <PageTitle activeMenu="Item List" motherMenu="Items" />
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-center">
        <Button
          onClick={handleOnNewItem}
          className="btn btn-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Item Registration"
        >
          + New Technician
        </Button>

        <div className="input-group search-area ml-auto d-inline-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search Name"
            onChange={handleSearchChange}
            ref={searchRef}
          />
          <div className="input-group-append">
            <span className="input-group-text c-pointer">
              <i className="flaticon-381-search-2"></i>
            </span>
          </div>
        </div>

        {/* <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={true}
            />
            Name
          </label>
        </div> */}
        {/* <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={true}
            />
            Node
          </label>
        </div>
        <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={true}
            />
            ID
          </label>
        </div> */}
        {/* Dropdown 1 | ITEM */}
        {/* <Dropdown
          className="align-items-end d-inline-flex ml-2"
          onSelect={handleSortBy}
        >
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-controls-3 "></i> by {sortBy}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-right">
            {sortByList.map((element, index) => {
              return (
                <Dropdown.Item href="#" eventKey={element}>
                  {element}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown> */}

        {/* Dropdown 2 | CATEGORY */}
        {/* <Dropdown
          className="align-items-end d-inline-flex ml-2"
          onSelect={handleCategory}
        >
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-menu-1"></i>
            {category == "ทั้งหมด" && "Category"} {category}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-right">
            {categoryList.map((element, index) => {
              return (
                <Dropdown.Item href="#" eventKey={element}>
                  {element}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown> */}

        {/* Dropdown 4 | STATUS */}
        {/* <Dropdown
          className="align-items-end d-inline-flex ml-2"
          onSelect={handleStatus}
        >
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-menu-1"></i>
            {status == "ทั้งหมด" && "Status"} {status}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-right">
            {statusList.map((element, index) => {
              return (
                <Dropdown.Item href="#" eventKey={element}>
                  {element}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
      <TechRegister show={showRegisterModal} hide={handleOnRegisterHide} />
      <TechEdit item={itemEdit} show={showEditModal} hide={handleOnEditHide} />
      <TechItem
        items={techItemList}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
    </Fragment>
  );
};

export default TechItemList;
