import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../toast/SetToast";
import Toast from "../../toast/SetToast";
import { useFirestore } from "../../../../hook/useFirestore";
import { SaleForm } from "./SaleForm";

const SaleRegister = (props) => {
  const { addDocument } = useFirestore("sales");
  const inputRef = useRef({
    saleId: "",
    name: "",
    node: "",
    email: "",
  });

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };
  const handleOnHideModal = () => {
    props.hide();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      saleId: inputRef.current.saleId,
      name: inputRef.current.name,
      node: inputRef.current.node,
      email: inputRef.current.email,
    };

    addDocument(formData)
      .then(() => {
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      })
      .catch((err) => {
        notifyError("บันทึกข้อมูลผิดพลาด ", err);
      });

    props.hide();
  };

  return (
    <Fragment>
      <Toast />
      <Modal
        className="fade bd-example-modal-lg"
        show={props.show}
        onHide={handleOnHideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Register Doctor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SaleForm onChange={handleChange} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>
          <Button
            className="sm ml-2"
            variant="light"
            onClick={handleOnHideModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default SaleRegister;
