import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import { setDoc, getDoc, updateDoc, doc } from "firebase/firestore";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";
import { Badge, Button } from "react-bootstrap";
import SleepStudySummary from "./ExportTab/SleepStudySummary";
import CPAPTrial from "./ExportTab/CPAPTrial";
import CPAPSale from "./ExportTab/CPAPSale";

import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

const ExportPage = () => {
  //   const { signup, currentUser } = useAuth();
  const history = useHistory();
  const { currentUser } = useAuth();

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [stateEdit, setStateEdit] = useState(true);

  const [showPage, setShowPage] = useState(0);
  const tabData = [
    {
      name: "Sleep Study Summary",
      icon: "user",
      content: function (props, setState) {
        return <SleepStudySummary />;
      },
    },
  ];
  if (["super_admin","sale"].includes(currentUser.roles[0])) {

    tabData.push(
      {
        name: "CPAP Trial",
        icon: "user",
        content: function (props, setState) {
          return <CPAPTrial />;
        },
      },
      {
        name: "CPAP Sale",
        icon: "user",
        content: function (props, setState) {
          return <CPAPSale />;
        },
      }
    );
  }
  // const tabData = [
  //   {
  //     name: "Sleep Study Summary",
  //     icon: "user",
  //     content: function (props, setState) {
  //       return <SleepStudySummary />;
  //     },
  //   },
  //   {
  //     name: "CPAP Trial",
  //     icon: "user",
  //     content: function (props, setState) {
  //       return <CPAPTrial />;
  //     },
  //   },
  //   {
  //     name: "CPAP Sale",
  //     icon: "user",
  //     content: function (props, setState) {
  //       return <CPAPSale />;
  //     },
  //   },
  // {
  //   name: "Sleep Study Summary",
  //   icon: "user",
  //   content: function (props, setState) {
  //     return <SleepStudySummary />;
  //   },
  // },
  //   {
  //     name: "Borrowing",
  //     icon: "clipboard",
  //     // content: function (props, setState) {
  //     //   return <CpapBorrowing cpapinfo={props} setShowCpapStatus={setState} />;
  //     // },
  //   },
  //   {
  //     name: "Activity",
  //     icon: "clipboard",
  //     // content: function (props, setState) {
  //     //   return <CpapActivity cpapinfo={props} setShowCpapStatus={setState} />;
  //     // },
  //   },
  // ];

  return (
    <>
      {/* <PageTitle activeMenu={CpapInfo.SerialNumber} motherMenu="CPAP" motherLink="/cpap-list" /> */}
      <Card>
        <Card.Header>
          <Card.Title>Export Data</Card.Title>
          {/* <Card.Title>
                <Badge variant="success light">{showCpapStatus}</Badge>
              </Card.Title> */}
        </Card.Header>
        <Card.Body>
          {/* <!-- Nav tabs --> */}
          <div className="default-tab">
            <Tab.Container
              defaultActiveKey={tabData[showPage].name.toLowerCase()}
            >
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.name.toLowerCase()}>
                      <i className={`la la-${data.icon} mr-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="pt-4">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                    {/* <PatientDetail profile={patientInfo} /> */}
                    {data.content()}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ExportPage;
