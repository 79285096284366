import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Toast from "../../toast/SetToast";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../toast/SetToast";
import { useFirestore } from "../../../../hook/useFirestore";

import { DoctorEditForm } from "./DcotorEditForm";

const DoctorEdit = (props) => {
  const { updateDocument } = useFirestore("doctors");

  const inputRef = useRef({
    doctorId: "",
    name: "",
    fullnameEn: "",
    fullnameTh: "",
    node: "",
    accessToken: "",
    email: "",
    status: "",
  });

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };
  const handleOnHideModal = () => {
    props.hide();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      doctorId: inputRef.current.doctorId
        ? inputRef.current.doctorId
        : props.item.doctorId,
      name: inputRef.current.name ? inputRef.current.name : props.item.name,
      fullnameEn: inputRef.current.fullnameEn
        ? inputRef.current.fullnameEn
        : props.item.fullnameEn,
      fullnameTh: inputRef.current.fullnameTh
        ? inputRef.current.fullnameTh
        : props.item.fullnameTh,
      node: inputRef.current.node ? inputRef.current.node : props.item.node,
      accessToken: inputRef.current.accessToken
        ? inputRef.current.accessToken
        : props.item.accessToken,
      email: inputRef.current.email ? inputRef.current.email : props.item.email,
      status: inputRef.current.status
        ? inputRef.current.status
        : props.item.status,
    };
    updateDocument(props.item.id, formData)
      .then(() => {
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      })
      .catch((err) => {
        notifyError("บันทึกข้อมูลผิดพลาด ", err);
      });

    props.hide();

    inputRef.current.doctorId = "";
    inputRef.current.name = "";
    inputRef.current.fullnameEn = "";
    inputRef.current.fullnameTh = "";
    inputRef.current.node = "";
    inputRef.current.accessToken = "";
    inputRef.current.email = "";
    inputRef.current.status = "";
  };

  return (
    <Fragment>
      <Toast />
      <Modal
        className="fade bd-example-modal-lg"
        show={props.show}
        onHide={handleOnHideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Edit Doctor Datails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DoctorEditForm onChange={handleChange} initailValues={props.item} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>

          <Button
            className="sm ml-2"
            variant="light"
            onClick={handleOnHideModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default DoctorEdit;
