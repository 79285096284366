const demographicData = {
    gender: "",
    dateOfBirth: "",
    age: "",
    weight: "",
    height: "",
    mostWeightEver: "",
    weightFiveYearsAgo: "",
  
    embolism: "",
    heartattack: "",
    highBloodPressure: "",
    bloodClots: "",
    diabetes: "",
    hyperlipidemia: "",
    kidneyDisease: "",
    fattyLiver: "",
    overactiveThyroid: "",
    underactiveThtroid: "",
    pale: "",
    ironDeficiency: "",
    thalassemia: "",
    asthma: "",
    emphysema: "",
    pulmonaryHypertension: "",
    parkinson: "",
    muscleWeakness: "",
    depressed: "",
    other: "",
    otherNote: "",
    regularMedicine: "",
    regularMedicineNote: "",
  
    smoke: "",
    smokeTotalYear: "",
    smokePerDay: "",
    stopSmokeYear: "",
    stopSmokeTotalYear: "",
    stopSmokePerDay: "",
  
    alc: "",
    alcTotalYear: "",
    alcPerDay: "",
    stopAlcYear: "",
    stopAlcTotalYear: "",
    stopAlcPerDay: "",
  
    coffee: "",
    coffeePerDay: "",
    coffeePerWeek: "",
  
    soda: "",
    sodaPerDay: "",
    sodaPerWeek: "",
  
    apnea: "",
    apneaDetail: "",
    snore: "",
    snoreDetail: "",
    unusuallySleepy: "",
    unusuallySleepyDetail: "",
    insomnia: "",
    insomniaDetail: "",
  };

  
  export default demographicData