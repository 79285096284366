import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import {
  query,
  endAt,
  setDoc,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  where,
  collectionGroup,
} from "firebase/firestore";
import { db } from "../../../firebase";

const ProductItem = ({ cpaps, onEdit, onDelete }) => {
  const [loading, setLoading] = useState(false);

  const handleDate = (date) => {
    if (date != undefined) {
      try {
        var datetime = new Date(date.toDate()).toLocaleDateString();

        return datetime;
      } catch {
        var newDate = new Date(date);
        var hours = newDate.getHours() + 7;
        newDate = new Date(newDate.setHours(hours));
        var newString = newDate.toLocaleDateString();

        return newString;
      }
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table mb-0 table-striped">
                  <thead>
                    <tr>
                      <th> </th>
                      <th>Serial Number</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Type</th>
                      <th>Date Added</th>
                      <th>Status</th>
                      {/* <th>Activity</th> */}
                      {/* <th>HN</th> */}
                      {/* <th>Due Date</th> */}
                      {/* <th>Last Update</th> */}
                    </tr>
                  </thead>
                  <tbody id="cpaps-table">
                    {!loading ? (
                      cpaps.map((cpap, index) => {
                        var variantData = "";
                        var newDateOptions = {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        };

                        if (cpap.status == "ว่าง") {
                          variantData = "info dark";
                        } else if (cpap.status == "ไม่ว่าง") {
                          variantData = "warning dark";
                        } else if (cpap.status == "ยกเลิกใช้งาน") {
                          variantData = "danger dark";
                        } else if (cpap.status == "ขาย") {
                          variantData = "success light";
                        }

                        var variantActivity = "";
                        if (
                          [
                            "ลงทะเบียน",
                            "คืนเครื่อง",
                            "ส่งทำความสะอาด",
                          ].includes(cpap["Activity"])
                        ) {
                          variantActivity = "success light";
                        } else if (
                          ["ยืมเครื่อง", "ส่งซ่อม"].includes(cpap["Activity"])
                        ) {
                          variantActivity = "info";
                        } else if (
                          ["ยกเลิกใช้งาน"].includes(cpap["Activity"])
                        ) {
                          variantActivity = "danger";
                        }
                        return (
                          <tr className="btn-reveal-trigger">
                            <td className="py-2">{index + 1}</td>
                            <td className="py-2">
                              <Link to={"/product-page/" + cpap.serialNumber}>
                                {cpap.serialNumber}
                              </Link>
                            </td>
                            <td className="py-2">{cpap.brand}</td>
                            <td className="py-2">{cpap.model}</td>
                            <td className="py-2">{cpap.type}</td>
                            <td className="py-2">
                              {handleDate(cpap.incomingDate)}
                              {/* {cpap.incomingDate
                                ? cpap.incomingDate.toDate().toISOString()
                                : ""} */}
                            </td>
                            <td className="py-2">
                              <Badge variant={variantData}>{cpap.status}</Badge>
                            </td>
                            {/* <td className="py-2">
                              <Badge variant={variantActivity}>
                                {cpap["Activity"]}
                              </Badge>
                            </td> */}
                            {/* <td className="py-2">{cpap["LastHN"]}</td> */}
                            {/* <td className="py-2">
                              {cpap["LastDueDate"]
                                ? cpap["LastDueDate"]
                                    .toDate()
                                    .toLocaleDateString(newDateOptions)
                                : ""}
                            </td> */}
                            {/* <td className="py-2">
                              {cpap["UpdatedDateTime"]
                                .toDate()
                                .toLocaleString(newDateOptions)}
                            </td> */}
                            <td className="py-2 text-right">
                              {/* {drop(cpap.serialNumber)} */}
                              <div className="d-flex">
                                <Link
                                  // target="_blank"
                                  to={
                                    "/product-order/" +
                                    cpap.id +
                                    "/" +
                                    cpap.serialNumber +
                                    "/" +
                                    cpap.deviceNo
                                  }
                                  className="btn btn-primary shadow btn-xs sharp mr-2 "
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Create order"
                                >
                                  <i className="fa fa-shopping-cart" />
                                </Link>
                                <Link
                                  className="btn btn-info shadow btn-xs sharp mr-2"
                                  to="#"
                                  onClick={() => onEdit(cpap.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit product details"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() => onDelete(cpap.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete product"
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="media d-flex align-items-center">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden"></span>
                        </div>
                      </div>
                    )}
                  </tbody>
                </table>

                {/* <div className="d-flex justify-content-end">
                  <Pagination
                    className="pagination-primary pagination-circle"
                    size="lg"
                  >
                    <Button
                      onClick={previousPage}
                      disabled={!goPrev}
                      className="page-link"
                    >
                      <i className="la la-angle-left" />
                    </Button>
                    <Button
                      onClick={nextPage}
                      disabled={!goNext}
                      className="page-link"
                    >
                      <i className="la la-angle-right" />
                    </Button>
                  </Pagination>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductItem;
