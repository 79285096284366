const preSleepInfoData = {
  nap: "",
  firstNap: "",
  secondNap: "",
  thirdNap: "",
  AverageNap: "",
  alc: "",
  alcbrand: "",
  tea: "",
  teabrand: "",
  coffee: "",
  coffeebrand: "",
  soda: "",
  sodabrand: "",
  worry: "",
  worryNote: "",
  tired: "",
  sleepy: "",
  awake: "",
};

export default preSleepInfoData
