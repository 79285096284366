import React, { Fragment, useEffect, useState, useRef } from "react";
import swal from "sweetalert";
import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import { useHistory } from "react-router-dom";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { db } from "../../../firebase";
import {
  collection,
  updateDoc,
  doc,
  query,
  limit,
  deleteDoc,
  orderBy,
  where,
  Query,
  or,
  getDocs,
  and,
} from "firebase/firestore";
import Item from "./Item";
import ItemRegister from "./ItemRegiter";
import ItemEdit from "./ItemEdit";
import { useFirestore } from "../../../hook/useFirestore";
import { usePagination } from "../../../hook/usePagination";
import PageTitle from "../../layouts/PageTitle";
import useQueryParams from "../../../hook/useQueryParam";

const ItemList = () => {
  const [itemList, setItemList] = useState([]);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [itemEdit, setItemEdit] = useState({});
  const [copyItem, setCopyItem] = useState({});
  const [sortBy, setSortBy] = useState("item");
  const [sortDrirection, setSortDrirection] = useState("asc");
  const [node, setNode] = useState("ALL");
  const [statusFilter, setStatusFilter] = useState("ทั้งหมด");
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [searchItem, setSearchItem] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState("ทั้งหมด");

  const searchRef = useRef(null);

  const sortByList = ["asc", "desc"];
  const sortDrirectionList = ["asc", "desc"];
  const categoryList = ["ทั้งหมด", "service", "cpap", "mask", "accessory"];
  const nodeList = ["ALL", "BD", "CHG", "BKH", "LPH", "RPH", "BMH"];
  const statusList = ["ทั้งหมด", "Active", "Inactive"];

  let QueryFilterConstraint = [];
  let queryRef = query(
    collection(db, "items"),
    orderBy(sortBy, sortDrirection)
  );

  if (searchItem !== null) {
    QueryFilterConstraint.push(
      where("item", ">=", searchItem),
      where("item", "<=", searchItem + "\uf8ff")
    );

    queryRef = query(
      collection(db, "items"),
      and(...QueryFilterConstraint),
      orderBy(sortBy, sortDrirection)
    );
  }

  if (categoryFilter !== null || statusFilter !== null) {
    queryRef = query(
      collection(db, "items"),
      and(
        ...QueryFilterConstraint,
        or(
          where(
            "category",
            "==",
            categoryFilter !== null ? categoryFilter : ""
          ),
          where("IsActive", "==", statusFilter !== null ? statusFilter : "")
        )
      ),
      orderBy(sortBy, sortDrirection)
    );

    if (categoryFilter === "ทั้งหมด") {
      queryRef = query(
        collection(db, "items"),
        and(
          ...QueryFilterConstraint,
          or(
            where(
              "IsActive",
              "==",

              statusFilter !== null ? statusFilter : ""
            )
          )
        ),
        orderBy(sortBy, sortDrirection)
      );
    }

    if (statusFilter === "ทั้งหมด") {
      queryRef = query(
        collection(db, "items"),
        and(
          ...QueryFilterConstraint,
          or(
            where(
              "category",
              "==",
              categoryFilter !== null ? categoryFilter : ""
            )
          )
        ),
        orderBy(sortBy, sortDrirection)
      );
    }

    if (statusFilter === "ทั้งหมด" && categoryFilter === "ทั้งหมด") {
      queryRef = query(
        collection(db, "items"),
        orderBy(sortBy, sortDrirection)
      );

      if (searchItem !== null) {
        QueryFilterConstraint.push(
          where("item", ">=", searchItem),
          where("item", "<=", searchItem + "\uf8ff")
        );

        queryRef = query(
          collection(db, "items"),
          and(...QueryFilterConstraint),
          orderBy(sortBy, sortDrirection)
        );
      }
    }

    if (searchItem !== null) {
      QueryFilterConstraint.push(
        where("item", ">=", searchItem),
        where("item", "<=", searchItem + "\uf8ff")
      );

      queryRef = query(
        collection(db, "items"),
        and(...QueryFilterConstraint),
        orderBy(sortBy, sortDrirection)
      );
    }
  }

  const {
    items: itemInfo,
    isLoading,
    isStart,
    isEnd,
    getPrev,
    getNext,
    totalItems,
    totalPages,
    currentPage,
  } = usePagination(queryRef, { limit: itemsPerPage });

  const { addDocument, deleteDocument } = useFirestore("items");

  // const handleSortBy = (option) => {
  //   setSortBy(option);
  //   // getItems(category, status, textRef.current.value, option);
  // };

  // const handleCategory = (option) => {
  //   setCategory(option);
  //   console.log(option);

  // history.push({
  //   filter: new URLSearchParams({
  //     filter: option,
  //   }),
  // });
  // getItems(option, status, textRef.current.value, sortBy);
  // };

  // const handleNode = (option) => {
  //   setNode(option);
  //   getItems(option, status, textRef.current.value);
  // };

  // const handleStatus = (option) => {
  //   setStatus(option);
  //   var active;
  //   if (option == "ทั้งหมด") {
  //     active = "ทั้งหมด";
  //   } else if (option == "Active") {
  //     active = true;
  //   } else {
  //     active = false;
  //   }
  //   getItems(category, active, textRef.current.value, sortBy);
  // };
  // const onChangeText = (text) => {
  //   getItems(category, status, text.target.value, sortBy);
  // };

  const handleOnNewItem = () => {
    setShowRegisterModal(true);
  };

  const handleOnEdit = async (id) => {
    const ref = doc(db, "items", id);
    setItemEdit(itemList.find((c) => c.id === id));
    setShowEditModal(true);
  };

  const handleOnCopy = async (id) => {
    const ref = doc(db, "items", id);
    setCopyItem(itemList.find((c) => c.id === id));

    const copy = itemList.find((c) => c.id === id);
    delete copy.id;

    let updateCopy = {
      ...copy,
      item: "Copy " + copy.item,
    };

    addDocument(updateCopy)
      .then(() => {
        notifyTopRight("คัดลอกข้อมูลสำเร็จ");
      })
      .catch((err) => {
        notifyError("คัดลอกข้อมูลผิดพลาด ", err);
      });
  };

  const handleOnRegisterHide = (sn) => {
    setShowRegisterModal(false);
  };
  const handleOnEditHide = (sn) => {
    setShowEditModal(false);
  };

  const handleOnDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        deleteDocument(id).then(() => {
          swal(`Poof! Your file has been deleted!`, {
            icon: "success",
          });
        });
      } else {
        swal("Canceled");
      }
    });
  };

  const editUpdateItem = (id, update) => {
    setItemList(
      itemList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            item: update.item,
            unitcost: update.unitcost,
            description: update.description,
            category: update.category,
            IsActive: update.IsActive,
          };
        }
        return item;
      })
    );
  };

  const handleSearchChange = () => {
    setSearchItem(searchRef.current.value);
  };

  const handleCategoryChange = (category) => {
    setCategoryFilter(category);
  };

  const handleStatusChange = (status) => {
    setStatusFilter(status);
  };
  const handleSortDrirectionChange = (drirection) => {
    setSortDrirection(drirection);
  };

  useEffect(async () => {
    setItemList(itemInfo);
  }, [itemInfo]);

  return (
    <Fragment>
      <PageTitle activeMenu="Item List" motherMenu="Items" />
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <Button
          onClick={handleOnNewItem}
          className="btn btn-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Item Registration"
        >
          + New Item
        </Button>

        <div className="input-group search-area ml-auto d-inline-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search Item"
            ref={searchRef}
            onChange={handleSearchChange}
          />
          <div className="input-group-append">
            <span className="input-group-text c-pointer">
              <i className="flaticon-381-search-2"></i>
            </span>
          </div>
        </div>
        {/* Dropdown 1 | ITEM */}
        <Dropdown
          className="align-items-end d-inline-flex ml-2"
          onSelect={(option) => handleSortDrirectionChange(option)}
        >
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-controls-3 "></i> by{" "}
            {sortDrirection === "asc" ? "A-Z" : "Z-A"}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-right">
            {sortDrirectionList.map((element, index) => {
              return (
                <Dropdown.Item href="#" eventKey={element}>
                  {element === "asc" ? "A-Z" : "Z-A"}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        {/* Dropdown 2 | CATEGORY */}
        <Dropdown
          className="align-items-end d-inline-flex ml-2"
          onSelect={(option) => handleCategoryChange(option)}
        >
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-menu-1"></i>
            {categoryFilter === "ทั้งหมด" && "Category"} {categoryFilter}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-right">
            {categoryList.map((element, index) => {
              return (
                <Dropdown.Item href="#" eventKey={element}>
                  {element}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        {/* Dropdown 4 | STATUS */}
        <Dropdown
          className="align-items-end d-inline-flex ml-2"
          onSelect={(option) => handleStatusChange(option)}
        >
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-menu-1"></i>
            {statusFilter === "ทั้งหมด" && "Status"} {statusFilter}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-right">
            {statusList.map((element, index) => {
              return (
                <Dropdown.Item href="#" eventKey={element}>
                  {element}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <ItemRegister show={showRegisterModal} hide={handleOnRegisterHide} />
      <ItemEdit
        item={itemEdit}
        show={showEditModal}
        hide={handleOnEditHide}
        update={editUpdateItem}
      />
      {!isLoading && (
        <Item
          items={itemList}
          onEdit={handleOnEdit}
          onDelete={handleOnDelete}
          onCopy={handleOnCopy}
          onNext={!isEnd && getNext}
          onPrev={!isStart && getPrev}
          totalItems={totalItems}
          totalPages={totalPages}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
      )}
    </Fragment>
  );
};

export default ItemList;
