import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { db } from "../../../firebase";
import { useFirestore } from "../../../hook/useFirestore";
import { setDoc, getDoc, updateDoc, doc, collection } from "firebase/firestore";
import { ItemForm } from "./ItemForm";

const ItemRegister = (props) => {
  const { addDocument } = useFirestore("items");
  const inputRef = useRef({
    itemId: "",
    item: "",
    unitcost: "",
    description: "",
    node: "",
    category: "",
    IsActive: "",
  });

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };
  const handleOnHideModal = () => {
    props.hide();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const ref = doc(db, "items", inputRef.current.id);
    const formData = {
      itemId: inputRef.current.itemId,
      item: inputRef.current.item,
      unitcost: inputRef.current.unitcost,
      description: inputRef.current.description,
      node: inputRef.current.node,
      category: inputRef.current.category,
      IsActive: inputRef.current.IsActive,
    };
    addDocument(formData)
      .then(() => {
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      })
      .catch((err) => {
        console.log(err);
        notifyError("บันทึกข้อมูลผิดพลาด ", err);
      });

    props.hide();
  };

  return (
    <Fragment>
      <Toast />
      <Modal
        className="fade bd-example-modal-lg"
        show={props.show}
        onHide={handleOnHideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Register Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemForm onChange={handleChange} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>
          <Button
            className="sm ml-2"
            variant="light"
            onClick={handleOnHideModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ItemRegister;
