import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";

const TechItem = ({ items, onEdit, onDelete }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table mb-0 table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Node</th>
                      <th>Technician ID</th>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Certificate</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody id="items-table">
                    {items.map((item, index) => {
                      return (
                        <tr key={index} className="btn-reveal-trigger">
                          <td className="py-1">{index + 1}</td>
                          <td className="py-1">{item.node}</td>
                          <td className="py-2">{item.techId}</td>
                          <td className="py-2">{item.name}</td>
                          <td className="py-2">
                            {item.roles
                              ? item.roles.map((i) => {
                                  if (i.status) {
                                    return i.role + "  ";
                                  }
                                })
                              : ""}
                          </td>

                          <td className="py-2">{item.certificate}</td>
                          <td className="py-1">{item.status}</td>
                          <td className="py-2 text-right">
                            <div className="d-flex">
                              <Link
                                className="btn btn-primary shadow btn-xs sharp mr-2"
                                to="#"
                                onClick={() => onEdit(item.id)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit item details"
                              >
                                <i className="fa fa-pencil"></i>
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-danger shadow btn-xs sharp"
                                onClick={() => onDelete(item.id)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Cancel item"
                              >
                                <i className="fa fa-close"></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TechItem;
