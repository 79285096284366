import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";

import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import {
  setDoc,
  getDocs,
  updateDoc,
  doc,
  collection,
  where,
  query,
} from "firebase/firestore";

import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { startAddCpap } from "../../../actions/cpaps";
import { RegisterForm } from "./RegisterForm";

const ProductRegister = (props) => {
  const { currentUser } = useAuth();
  const [isClear, setIsClear] = useState(false);
  const inputRef = useRef({
    serialNumber: "",
    brand: "",
    model: "",
    description: "",
    type: "",
    vendor: "",
    deviceNo: "",
    lotNo: "",
    incomingDate: null,
    note: "",
  });

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const productRef = query(
      collection(db, "products"),
      where("serialNumber", "==", inputRef.current.serialNumber)
    );

    const productQuery = await getDocs(productRef);

    if (productQuery.empty) {
      const formData = {
        serialNumber: inputRef.current.serialNumber,
        brand: inputRef.current.brand,
        model: inputRef.current.model,
        description: inputRef.current.description,
        type: inputRef.current.type,
        vendor: inputRef.current.vendor,
        deviceNo: inputRef.current.deviceNo,
        lotNo: inputRef.current.lotNo,
        incomingDate: inputRef.current.incomingDate
          ? new Date(inputRef.current.incomingDate)
          : null,
        status: "ว่าง",
        note: inputRef.current.note,
        createdBy: currentUser.displayName,
        updatedAt: new Date(),
        createdAt: new Date(),
      };

      props
        .startAddCpap(formData)
        .then(() => {
          notifyTopRight("บันทึกข้อมูลสำเร็จ");
        })
        .catch((err) => {
          notifyError("บันทึกข้อมูลผิดพลาด ", err);
        });

      inputRef.current.serialNumber = "";
      inputRef.current.brand = "";
      inputRef.current.model = "";
      inputRef.current.description = "";
      inputRef.current.type = "";
      inputRef.current.vendor = "";
      inputRef.current.deviceNo = "";
      inputRef.current.lotNo = "";
      inputRef.current.incomingDate = null;
      inputRef.current.note = "";

      e.target.reset();
    } else {
      notifyError("Product serial number already created.");
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu="Product Register"
        motherMenu="Sales"
        motherLink="/product-list"
      />
      <Toast />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Registration</h4>
            </div>
            <div className="card-body pb-0">
              <p className="">
                Register a new product with unique Serial Number. The product
                will be added to the stock and changing its status when it has
                been sold.
              </p>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <RegisterForm
                        onChange={handleChange}
                        initailValues={null}
                      />
                      <br /> <br />
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  startAddCpap: (cpap) => dispatch(startAddCpap(cpap)),
});

export default connect(undefined, mapDispatchToProps)(ProductRegister);
