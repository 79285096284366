import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { useAuth } from "../../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  updateDoc,
  doc,
  collection,
  getDocs,
  collectionGroup,
  where,
  query,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import {
  setEvent,
  eventTmp,
  paymentPrivilages,
  orderList,
  colorList,
  statusList,
  typeList,
  PatientLinkInfo,
  PatientDropDownForm,
  PatientTextInfo,
} from "../../Booking/BookingUtils";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from "xlsx";

import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { connectStorageEmulator } from "firebase/storage";

const CPAPTrial = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const buildQuery = (borrowingsCollectionGroupRef, start, end) => {
    let queryConstraints = [];
    queryConstraints.push(orderBy("BorrowDate"));
    const startDate = new Date(start);
    const endDate = new Date(end);

    const startDateTimestamp = Timestamp.fromDate(startDate);
    const endDateTimestamp = Timestamp.fromDate(endDate);

    console.log(startDateTimestamp);

    queryConstraints.push(where("Activity", "==", "ยืมเครื่อง"));

    queryConstraints.push(where("BorrowDate", ">=", startDateTimestamp));
    queryConstraints.push(where("BorrowDate", "<=", endDateTimestamp));

    return query(borrowingsCollectionGroupRef, ...queryConstraints);
  };

  const onExport = async () => {
    const start = startDate;
    const end = endDate;

    // Usage of the buildQuery function
    let borrowingsCollectionGroupRef = collection(db, "borrowings");
    // Pass your parameters here, including dates if searchTime is true
    let queryHandler = buildQuery(borrowingsCollectionGroupRef, start, end);

    const tempTrial = [];
    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No documents found");
    } else {
      docSnap.forEach((trial) => {
        tempTrial.push(trial.data());
      });
    }
    console.log("trial: ", tempTrial);

    createXLSX(tempTrial, start, end);
    // }
  };

  const formatDateRangeInThai = (startDateTimestamp, endDateTimestamp) => {
    const startDate = new Date(startDateTimestamp);
    const endDate = new Date(endDateTimestamp);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const thaiFormatter = new Intl.DateTimeFormat("th-TH", options);

    const startDay = startDate.getDate();
    const startMonth = startDate.toLocaleString("th-TH", { month: "long" });
    const startYear = startDate.getFullYear();

    const endDay = endDate.getDate();
    const endMonth = endDate.toLocaleString("th-TH", { month: "long" });
    const endYear = endDate.getFullYear();

    if (startYear === endYear) {
      if (startMonth === endMonth) {
        return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
      }
      return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
    }

    const formattedStartDate = thaiFormatter.format(startDate);
    const formattedEndDate = thaiFormatter.format(endDate);

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const getThaiFormat = (date) => {
    // Check if the input is an object with 'seconds' and 'nanoseconds' properties (Firestore Timestamp)
    if (date) {
      if (
        date &&
        typeof date === "object" &&
        "seconds" in date &&
        "nanoseconds" in date
      ) {
        date = new Date(date.seconds * 1000); // Convert to JavaScript Date
      }

      // Check if the input is a Unix timestamp (number)
      if (typeof date === "number") {
        date = new Date(date); // Convert Unix timestamp to JavaScript Date
      }

      const options = { year: "numeric", month: "long", day: "numeric" };
      const thaiFormatter = new Intl.DateTimeFormat("th-TH", options);
      return thaiFormatter.format(date);
    } else {
      return "";
    }
  };

  const createXLSX = async (trial, start, end) => {
    setIsLoading(true)
    const rangeDate = formatDateRangeInThai(start, end);
    const headerText = [
      rangeDate,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];
    const headers = [
      "ลำดับ",
      "วันที่ยืม",
      "วันที่ครบกำหนดคืน",
      "วันที่คืน",
      "Sleep Study No.",
      "โรงพยาบาลผู้ส่งตรวจ",
      "ชื่อ - นามสกุล",
      "รหัสโรงพยาบาล",
      "Serial Number",
      "CPAP ID",
      "Brand",
      "Model",
      "Mask",
      "Size",
      "ReturnCondition",
      "Note",
    ];

    const data = [headerText, headers];

    const bookingRef = collectionGroup(db, "bookings");
    const cpapRef = collection(db, "cpaps");

    for (const [index, borrow] of trial.entries()) {
      const row = [];

      row.push(index + 1);
      row.push(getThaiFormat(borrow.BorrowDate));
      row.push(getThaiFormat(borrow.DueDate));
      row.push(getThaiFormat(borrow.ReturnDate));

      const queryBookingRef = query(
        bookingRef,
        where("extendedProps.hn", "==", borrow.HN),
        orderBy("extendedProps.latestSleepStudyDate")
      );
      const bookingDocs = await getDocs(queryBookingRef);
      if (!bookingDocs.empty) {
        const booking = bookingDocs.docs[bookingDocs.docs.length - 1].data();
        row.push(booking.extendedProps.order);
        row.push(booking.extendedProps.customer);
        row.push(
          booking.extendedProps.firstname + " " + booking.extendedProps.lastname
        );
        row.push(booking.extendedProps.hospitalNumber);
      } else {
        row.push("");
        row.push("");
        row.push("");
        row.push("");
      }

      const queryCpapRef = query(
        cpapRef,
        where("SerialNumber", "==", borrow.SerialNumber)
      );
      const cpapDocs = await getDocs(queryCpapRef);
      if (!cpapDocs.empty) {
        const cpap = cpapDocs.docs[0].data();
        console.log(cpap);
        row.push(cpap.SerialNumber);
        row.push(cpap.CpapID);
        row.push(cpap.Brand);
        row.push(cpap.Model);
        row.push(cpap.Mask);
        row.push(cpap.Size);
        row.push(cpap.ReturnCondition);
        row.push(cpap.Note);
      } else {
        row.push("");
        row.push("");
        row.push("");
        row.push("");
        row.push("");
        row.push("");
        row.push("");
        row.push("");
      }

      data.push(row);
    }

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "CPAP Trial");
    XLSX.writeFile(wb, `CPAP Trial ${rangeDate}.xlsx`);
    setIsLoading(false)
  };

  return (
    <>
      {!isLoading ? (
        <div className="col-lg-12 mb-5">
          <b>CPAP Trial</b>

          <div className="col-xl-9 col-lg-12 mt-4">
            <div className="form-group row">
              <label className="col-lg-3 col-form-label">
                {"Range Date"}
                <span className="text-danger"></span>
              </label>
              <div className="row ml-1">
                <div className="col-lg-3">
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={(date) => setStartDate(date.setHours(0, 0, 0, 0))}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={null}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/MM/yyyy"
                    // todayButton={true}
                  />
                </div>
                <div className="mt-3">-</div>
                <div className="col-lg-3">
                  <DatePicker
                    className="form-control"
                    selected={endDate}
                    onChange={(date) =>
                      setEndDate(date.setHours(23, 59, 59, 999))
                    }
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/MM/yyyy"
                    todayBtn={true}
                  />
                </div>
              </div>
            </div>
            <Button onClick={onExport}>Export</Button>
          </div>
        </div>
      ) : (
        <Col xl={10}>
          <div class="media d-flex align-items-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

export default CPAPTrial;
