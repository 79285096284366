import React, { Component, Fragment, useState } from "react";
import { Col, Row, Card, Modal, Button } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import multiMonthPlugin from "@fullcalendar/multimonth";
import { Calendar } from "@fullcalendar/core";
import { db, functions, auth } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import {
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  collection,
  query,
  where,
  limit,
  startAfter,
  getCollections,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { async } from "@firebase/util";
import Alert from "sweetalert2";
// import { isExists } from "date-fns";
// import EditEvent from "./EditEvent";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";
import { useAuth, currentUser } from "../../../context/AuthContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { connectStorageEmulator } from "firebase/storage";
import {
  orderList,
  typeList,
  colorList,
  statusList,
  setEvent,
  paymentPrivilages,
  PatientDropDownForm,
  PatientTextInfo,
  PatientInputText,
  PatientInputTextArea,
  PatientLinkInfo,
} from "./FollowupUtils";
// import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { AuthContext } from "../../../context/AuthContext";

class EventCalendar extends Component {
  constructor(props) {
    super(props);
    this.calendarComponentRef = React.createRef();
    this.state = {
      calendarEvents: [],
      nodeList: [],
      customerList: [],
      itemList: [],
      events: [
        {
          title: "Followup",
          id: "5",
          order: "",
          hn: "",
          type: "",
          status: "",
          color: "green",
        },
      ],
      eventTmp: {
        title: "",
        startStr: "",
        id: "",
        extendedProps: {
          order: "",
          hn: "",
          type: "",
          status: "",
          firstname: "",
          lastname: "",
          phone: "",
          note: "",
          items: [],
          totalValue: 0,
          time: "",
          technician: "",
          doctor: "",
        },
      },
      items: [],
      technicianList: [],
      doctorList: [],
      totalValue: 0,
      dateTmp: new Date().toISOString().slice(0, 10),
      resetDateTmp: new Date().toISOString().slice(0, 10),
      isAddEventModal: false,
      isNoti: false,
      isEditEventModal: false,
      isNewPatient: false,
      isEditBookingDetail: false,
      errStatus: false,
      isDatetime: false,
      isLoading: true,
    };
    this._isMounted = false;
    this.cache = {}; // Initialize cache
  }
  static contextType = AuthContext;

  dateRef = React.createRef();
  titleRef = React.createRef();
  idRef = React.createRef();
  orderRef = React.createRef();
  nodeRef = React.createRef();
  customerRef = React.createRef();
  hnRef = React.createRef();
  typeRef = React.createRef();
  statusRef = React.createRef();
  colorRef = React.createRef();
  firstnameRef = React.createRef();
  lastnameRef = React.createRef();
  nationalIdRef = React.createRef();
  healthInsuranceRef = React.createRef();
  phoneRef = React.createRef();
  noteRef = React.createRef();
  itemsRef = React.createRef([]);
  techRef = React.createRef([]);
  docRef = React.createRef([]);

  nodeName = "brain dynamics";
  typeList = typeList;
  statusList = statusList;
  colorList = colorList;
  orderList = orderList;
  setEvent = setEvent;
  paymentPrivilages = paymentPrivilages;
  PatientDropDownForm = PatientDropDownForm;
  PatientTextInfo = PatientTextInfo;
  PatientInputText = PatientInputText;
  PatientInputTextArea = PatientInputTextArea;
  PatientLinkInfo = PatientLinkInfo;
  /**
   * adding dragable properties to external events through javascript
   */
  getPatienBySearch = httpsCallable(functions, "getPatienBySearch");
  createPatient = httpsCallable(functions, "createPatient");

  componentDidMount() {
    this._isMounted = true;
    this.fetchInitialData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  logFetchTime = (label, startTime) => {
    const endTime = Date.now();
    console.log(`${label} took ${endTime - startTime} ms`);
  };

  fetchInitialData = async () => {
    try {
      const startFetchTime = Date.now();

      // Fetch the most critical data first
      const followupDocs = await getDocs(collection(db, "followup"));

      if (!this._isMounted) return;

      this.logFetchTime('Fetching followups', startFetchTime);

      const allFollowup = followupDocs.docs.map((doc) => {
        const followupData = doc.data();
        const dateStart = new Date(followupData.start.toDate());
        dateStart.setHours(dateStart.getHours() + 7);
        followupData.start = dateStart.toISOString().slice(0, 10);
        return followupData;
      });

      this.setState({
        calendarEvents: allFollowup,
        isLoading: false,
      });

      // Fetch additional data in parallel
      this.fetchAdditionalData();

    } catch (error) {
      console.error('Error fetching initial data:', error);
      this.setState({ isLoading: false });
    }
  };

  fetchInBatches = async (collectionRef, batchSize = 50) => {
    let allDocs = [];
    let lastVisibleDoc = null;

    while (true) {
      let q = query(collectionRef, limit(batchSize));
      if (lastVisibleDoc) {
        q = query(collectionRef, startAfter(lastVisibleDoc), limit(batchSize));
      }

      const snapshot = await getDocs(q);
      if (snapshot.empty) break;

      const docs = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      allDocs = allDocs.concat(docs);
      lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
    }

    return allDocs;
  };

  fetchAdditionalData = async () => {
    try {
      const startFetchTime = Date.now();

      const [itemsDocs, techDocs, docDocs] = await Promise.all([
        this.fetchInBatches(collection(db, "items")),
        this.fetchInBatches(collection(db, "sleeptechnicians")),
        this.fetchInBatches(collection(db, "doctors")),
      ]);

      if (!this._isMounted) return;

      this.logFetchTime('Fetching items, techs, and docs', startFetchTime);

      const allItems = itemsDocs.map((doc) => ({
        ...doc,
        itemcode: doc.id,
      }));
      const allTechs = [
        "กรุณาเลือก",
        ...techDocs.map((doc) => doc.name),
      ];
      const allDocs = [
        "กรุณาเลือก",
        ...docDocs.map((doc) => doc.name),
      ];

      this.setState({
        itemList: allItems,
        technicianList: allTechs,
        doctorList: allDocs,
      });

      this.initializeDraggableEvents();

    } catch (error) {
      console.error('Error fetching additional data:', error);
    }
  };

  initializeDraggableEvents = () => {
    const draggableEl = document.getElementById("external-events");
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          const title = eventEl.getAttribute("title");
          const id = eventEl.getAttribute("id");
          const order = eventEl.getAttribute("order");
          const hn = eventEl.getAttribute("hn");
          const type = eventEl.getAttribute("type");
          const status = eventEl.getAttribute("status");
          const color = eventEl.getAttribute("color");
          const startStr = eventEl.getAttribute("startStr");
          return {
            title,
            id,
            startStr,
            order,
            hn,
            type,
            status,
            color,
          };
        },
      });
    }
  };



  onRemoveEvent = async (isExists) => {
    console.log(this.state.eventTmp.extendedProps.uuid);
    if (
      this.state.eventTmp.extendedProps.uuid != "" &&
      this.state.eventTmp.extendedProps.uuid != null &&
      this.state.eventTmp.extendedProps.uuid != undefined
    ) {
      const refDelete = doc(
        db,
        "followup",
        this.state.eventTmp.extendedProps.uuid
      );
      await deleteDoc(refDelete)
        .then(async (res) => {
          // notifyTopRight("Delete Success!", res);
          swal("Your booking event has been deleted!", {
            icon: "success",
          });
        })
        .catch((err) => {
          notifyError("Detele Error ", err);
        });
    }
    this.state.eventTmp.remove();
    // this.forceUpdate();
  };
  onSearchPatient = async (e) => {
    const q = query(
      collection(db, "patients"),
      where("hn", "==", this.hnRef.current.value)
    );
    const querySnapshot = await getDocs(q);
    let listPatient = [];
    // console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      listPatient.push(doc.data());
    });
    if (listPatient[0] == undefined) {
      this.setState({ isNewPatient: true });
    } else {
      try {
        this.firstnameRef.current.value = listPatient[0].firstnameTh;
        this.lastnameRef.current.value = listPatient[0].lastnameTh;
        this.phoneRef.current.value = listPatient[0].mobile;
        // if (
        //   listPatient[0].healthInsurance == "" ||
        //   listPatient[0].healthInsurance == "please-select"
        // ) {
        //   this.healthInsuranceRef.current.value = "กรุณาเลือก";
        // } else {
        //   this.healthInsuranceRef.current.value =
        //     listPatient[0].healthInsurance;
        // }
      } catch (err) {
        var getTmp = this.state.eventTmp;
        getTmp.setExtendedProp("firstname", listPatient[0].firstnameTh);
        getTmp.setExtendedProp("lastname", listPatient[0].lastnameTh);
        getTmp.setExtendedProp("phone", listPatient[0].mobile);
        // if (
        //   listPatient[0].healthInsurance == "" ||
        //   listPatient[0].healthInsurance == "please-select"
        // ) {
        //   getTmp.setExtendedProp("healthInsurance", "กรุณาเลือก");
        // } else {
        //   getTmp.setExtendedProp(
        //     "healthInsurance",
        //     listPatient[0].healthInsurance
        //   );
        // }
        this.setState({ eventTmp: getTmp });
      }
      this.setState({ isNewPatient: false });
    }
  };
  handleDate = (moment, props) => {
    let storeMoment = moment.toISOString().slice(0, 10);
    let evtmp = this.state.eventTmp;
    evtmp.setStart(moment);
    evtmp.setEnd(moment.getDate() + 1);
    this.setState({ dateTmp: storeMoment });
    this.setState({ eventTmp: evtmp });
  };
  handleTime = (moment, props) => {
    // console.log(moment.target.value);
    var evtmp = this.state.eventTmp;
    evtmp.setExtendedProp("time", moment.target.value);
    this.setState({ eventTmp: evtmp });
  };

  onHideAddEventModal = () => {
    this.setState({ items: [] }, () => {
      this.setState({ totalValue: 0 });
    });
    this.setState({ isAddEventModal: false });
    this.setState({ totalValue: 0 });
    this.onRemoveEvent(false);
  };

  onHideEditEventModal = () => {
    this.setState({ items: [] }, () => {
      this.setState({ totalValue: 0 });
      this.setState({ isEditEventModal: false });
      this.setState({ isNewPatient: false });
      this.setState({ isEditBookingDetail: false });
      // this.setState({ isEditBookingDetail: true });
      console.log("eventTmp: ", this.state.eventTmp.extendedProps.items);
      console.log("items: ", this.state.items);
    });

    console.log("Hide EditEvent");
  };

  onHideNoti = () => {
    this.setState({ items: [] }, () => {
      this.setState({ totalValue: 0 });
    });
    this.setState({ isNoti: false });
    this.setState({ isEditBookingDetail: false });
    // this.setState({ isEditBookingDetail: true });
  };

  onAddEvent = async () => {
    // e.preventDefault();
    let errStatus = false;
    let eventAdd = this.state.eventTmp;

    const currentUserName = this.context.currentUser.displayName;
    eventAdd.setExtendedProp("createBy",currentUserName)

    // if (this.state.eventTmp.extendedProps.hn == "") {
    //   eventAdd.setExtendedProp("order", eventAdd.title);
    // } else {
    //   eventAdd.setExtendedProp("order", eventAdd.extendedProps.order);
    // }
    eventAdd.setExtendedProp("order", this.orderRef.current.value);
    eventAdd.setExtendedProp("hn", this.hnRef.current.value);
    eventAdd.setExtendedProp("type", this.typeRef.current.value);
    eventAdd.setExtendedProp("technician", this.techRef.current.value);
    eventAdd.setExtendedProp("doctor", this.docRef.current.value);
    eventAdd.setProp("id", 5);

    try {
      eventAdd.setExtendedProp("firstname", this.firstnameRef.current.value);
      eventAdd.setExtendedProp("lastname", this.lastnameRef.current.value);
      eventAdd.setExtendedProp("phone", this.phoneRef.current.value);
    } catch (err) {
      eventAdd.setExtendedProp("firstname", eventAdd.extendedProps.firstname);
      eventAdd.setExtendedProp("lastname", eventAdd.extendedProps.lastname);
      eventAdd.setExtendedProp("phone", eventAdd.extendedProps.phone);
    }
    eventAdd.setProp(
      "title",
      eventAdd.extendedProps.order // + " HN" + this.hnRef.current.value
    );
    // eventAdd.setExtendedProp(
    //   "healthInsurance",
    //   this.healthInsuranceRef.current.value
    // );
    eventAdd.setExtendedProp("note", this.noteRef.current.value);
    eventAdd.setExtendedProp("items", this.state.items);
    eventAdd.setExtendedProp("totalValue", this.state.totalValue);

    var indexOfStatusList = this.statusList.findIndex(
      (obj) => obj === this.statusRef.current.value
    );
    eventAdd.setExtendedProp("status", this.statusRef.current.value);
    eventAdd.setProp("color", this.colorList[indexOfStatusList]);
    if (this.statusRef.current.value != "Scheduled") {
      eventAdd.setExtendedProp("time", "");
    }
    try {
      this.setEvent(eventAdd);
      this.setState({ eventTmp: eventAdd });
      this.setState({ isAddEventModal: false });
      this.setState({ isNewPatient: false });
      this.setState({ isNoti: true });
      notifyTopRight("Create success ");
    } catch (err) {
      this.setState({ isAddEventModal: false });
      this.setState({ isNewPatient: false });
      notifyError("Error, Please fill out the booking form");
      // this.onCancel(true);
      this.onRemoveEvent();
    }
  };

  onEditEvent = async () => {
    // console.log("onEditEvent");
    // e.preventDefault();
    let errStatus = false;
    let eventAdd = this.state.eventTmp;
    if (this.state.isEditBookingDetail == true) {

      const currentUserName = this.context.currentUser.displayName;
      eventAdd.setExtendedProp("createBy",currentUserName)
  
      eventAdd.setExtendedProp("order", this.orderRef.current.value);
      eventAdd.setExtendedProp("hn", this.hnRef.current.value);
      eventAdd.setExtendedProp("type", this.typeRef.current.value);
      eventAdd.setExtendedProp("items", this.state.items);
      eventAdd.setExtendedProp("totalValue", this.state.totalValue);
      eventAdd.setExtendedProp("technician", this.techRef.current.value);
      eventAdd.setExtendedProp("doctor", this.docRef.current.value);
      eventAdd.setProp("id", 5);

      try {
        eventAdd.setExtendedProp("firstname", this.firstnameRef.current.value);
        eventAdd.setExtendedProp("lastname", this.lastnameRef.current.value);
        eventAdd.setExtendedProp("phone", this.phoneRef.current.value);
      } catch (err) {
        eventAdd.setExtendedProp("firstname", eventAdd.extendedProps.firstname);
        eventAdd.setExtendedProp("lastname", eventAdd.extendedProps.lastname);
        eventAdd.setExtendedProp("phone", eventAdd.extendedProps.phone);
      }
      eventAdd.setProp(
        "title",
        eventAdd.extendedProps.order // + " HN" + this.hnRef.current.value
      );
      // eventAdd.setExtendedProp(
      //   "healthInsurance",
      //   this.healthInsuranceRef.current.value
      // );
      eventAdd.setExtendedProp("note", this.noteRef.current.value);

      var indexOfStatusList = this.statusList.findIndex(
        (obj) => obj === this.statusRef.current.value
      );
      eventAdd.setExtendedProp("status", this.statusRef.current.value);
      eventAdd.setProp("color", this.colorList[indexOfStatusList]);
      if (this.statusRef.current.value != "Scheduled") {
        eventAdd.setExtendedProp("time", "");
      }
      if (eventAdd.extendedProps.hn === "") {
        notifyError("Please fill out patient information");
        eventAdd.setProp("title", eventAdd.extendedProps.order);
        this.setState({ eventTmp: eventAdd });
        this.setState({ isEditEventModal: false });
      } else {
        try {
          this.setEvent(eventAdd);
          this.setState({ eventTmp: eventAdd });
          this.setState({ isEditEventModal: false });
          this.setState({ isNewPatient: false });
          this.setState({ isNoti: true });
          notifyTopRight("Create success ");
        } catch (err) {
          console.log(err);
          this.setState({ isEditEventModal: false });
          this.setState({ isNewPatient: false });
          // this.setDefaultEvent(eventAdd);
          notifyError("Error, Please fill out the booking form");
          this.onCancel(true);
        }
      }
    } else {
      this.setState({ eventTmp: eventAdd });
      this.setState({ isEditEventModal: false });
      this.setState({ isNewPatient: false });
      this.setState({ isNoti: true });
    }
  };

  setDefaultEvent = (eventInput) => {
    let api = this.calendarComponentRef.current.getApi();
    api.addEvent({
      title: eventInput.title,
      start: eventInput.startStr,
      id: eventInput.id,
      allDay: true,
      color: "brown",
      extendedProps: {
        order: eventInput.extendedProps.order,
        hn: eventInput.extendedProps.hn,
        type: eventInput.extendedProps.type,
        status: eventInput.extendedProps.status,
        firstname: eventInput.extendedProps.firstname,
        lastname: eventInput.extendedProps.lastname,
        phone: eventInput.extendedProps.phone,
        // healthInsurance: eventInput.extendedProps.healthInsurance,
        note: eventInput.extendedProps.note,
        items: eventInput.extendedProps.items,
        totalValue: eventInput.extendedProps.totalValue,
        time: eventInput.extendedProps.time,
        doctor: eventInput.extendedProps.doctor,
        technician: eventInput.extendedProps.technician,
        createBy : eventInput.extendedProps.createBy ? eventInput.extendedProps.createBy : "",
      },
    });
    eventInput.remove();
  };

  eventDrop = (eventDrop) => {
    this.setState({ eventTmp: eventDrop.event });
    if (this.state.eventTmp.extendedProps.hn == "") {
      this.setState({ isEditBookingDetail: true });
    }
    this.setState({ isAddEventModal: true });
    this.setState({ isNewPatient: false });
  };

  eventDrag = (eventDrag) => {
    let api = this.calendarComponentRef.current.getApi();
    var allEvents = api.getEvents().filter((obj) => {
      return (
        obj.startStr === eventDrag.event.startStr && obj.extendedProps.hn !== ""
      );
    });
    let listHNEvent = allEvents.filter((element) => {
      return element.extendedProps.hn === eventDrag.event.extendedProps.hn;
    });
    if (listHNEvent.length > 1) {
      swal({
        title: `HN${eventDrag.event.extendedProps.hn} is already booking on this day !`,
        text: `Please recheck booking on ${eventDrag.event.startStr}`,
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      }).then((willBooking) => {
        if (willBooking) {
          this.setState({ eventTmp: eventDrag.event });
          this.setEvent(this.state.eventTmp, eventDrag.oldEvent);
        } else {
          var colorEvent = "";
          var indexOfStatusList = this.statusList.findIndex(
            (obj) => obj === eventDrag.oldEvent.extendedProps.status
          );
          colorEvent = this.colorList[indexOfStatusList];
          api.addEvent({
            title: eventDrag.oldEvent.title,
            start: eventDrag.oldEvent.startStr,
            id: eventDrag.oldEvent.id,
            allDay: true,
            color: colorEvent,
            extendedProps: {
              order: eventDrag.oldEvent.extendedProps.order,
              hn: eventDrag.oldEvent.extendedProps.hn,
              type: eventDrag.oldEvent.extendedProps.type,
              status: eventDrag.oldEvent.extendedProps.status,
              firstname: eventDrag.oldEvent.extendedProps.firstname,
              lastname: eventDrag.oldEvent.extendedProps.lastname,
              // healthInsurance: eventDrag.oldEvent.extendedProps.healthInsurance,
              phone: eventDrag.oldEvent.extendedProps.phone,
              note: eventDrag.oldEvent.extendedProps.note,
              items: eventDrag.oldEvent.extendedProps.items,
              totalValue: eventDrag.oldEvent.extendedProps.totalValue,
              time: eventDrag.extendedProps.time,
              doctor: eventDrag.extendedProps.doctor,
              technician: eventDrag.extendedProps.technician,
            },
          });
          eventDrag.event.remove();
        }
      });
    } else {
      this.setState({ eventTmp: eventDrag.event });
      this.setEvent(this.state.eventTmp, eventDrag.oldEvent);
    }
  };

  eventClick = (eventClick) => {
    this.setState({ eventTmp: eventClick.event });
    this.setState({ dateTmp: this.state.eventTmp.startStr });
    this.setState({ resetDateTmp: this.state.eventTmp.startStr });
    if (this.state.eventTmp.extendedProps.hn == "") {
      this.setState({ isEditBookingDetail: true });
    }
    if (this.state.eventTmp.extendedProps.status == "Scheduled") {
      this.setState({ isDatetime: true });
    } else {
      this.setState({ isDatetime: false });
    }
    this.setState({ isEditEventModal: true });
    this.setState({ isNewPatient: false });
    const itemsTmp = JSON.parse(
      JSON.stringify(this.state.eventTmp.extendedProps.items)
    );
    const totalValueTmp = this.state.eventTmp.extendedProps.totalValue;
    this.setState({ items: itemsTmp });
    this.setState({ totalValue: totalValueTmp });
  };

  onCancel = (isClear = false) => {
    this.setState({ isEditBookingDetail: false });
    // this.setState({ isEditBookingDetail: true });
    let evtmp = this.state.eventTmp;
    if (isClear) {
      evtmp.setProp("title", this.state.eventTmp.extendedProps.order);
      evtmp.setProp("id", this.state.eventTmp.id);
      evtmp.setExtendedProp("order", this.state.eventTmp.extendedProps.order);
      evtmp.setExtendedProp("hn", "");
      evtmp.setExtendedProp("firstname", "");
      evtmp.setExtendedProp("lastname", "");
      // evtmp.setExtendedProp("healthInsurance", "กรุณาเลือก");
      evtmp.setExtendedProp("phone", "");
      evtmp.setProp("color", "green");
    }

    evtmp.setStart(new Date(this.state.resetDateTmp));
    evtmp.setEnd(new Date(this.state.resetDateTmp).getDate() + 1);
    this.setState({ eventTmp: evtmp });
  };
  onUpdateItemValue = (key, index, value) => {
    var tmpItems = this.state.items;
    tmpItems[index][key] = value.target.value;
    tmpItems[index].total =
      tmpItems[index].unitcost * tmpItems[index].qty - tmpItems[index].discount;

    this.setState({ items: tmpItems }, () => {
      this.getTotalValue();
    });
  };
  getTotalValue = () => {
    var totalValueTmp = 0;
    var tmpItems = this.state.items;
    for (let i = 0; i < tmpItems.length; i++) {
      totalValueTmp = parseInt(totalValueTmp) + parseInt(tmpItems[i].total);
    }
    this.setState({ totalValue: totalValueTmp });
  };
  checkStatus = (e) => {
    if (e.target.value == "CPAP-Consult") {
      this.setState({ isDatetime: true });
    } else {
      this.setState({ isDatetime: false });
    }
  };
  render() {
    return (
      <Fragment>
        <Toast />
        <div className="animated fadeIn demo-app">
          <Row>
            {!this.state.isLoading ? (
              <Col xl={10}>
                <Card>
                  <Card.Body
                    className="border p-0 my-0"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="demo-app-calendar" id="mycalendartest">
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          interactionPlugin,
                          listPlugin,
                          multiMonthPlugin,
                        ]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                          center: "title",
                          left: "today,listMonth,dayGridWeek,dayGridMonth,multiMonthYear",
                          right: "prev next",
                        }}
                        rerenderDelay={10}
                        eventDurationEditable={false}
                        editable={false}
                        droppable={false}
                        ref={this.calendarComponentRef}
                        weekends={this.state.calendarWeekends}
                        eventDrop={this.eventDrag}
                        eventReceive={this.eventDrop}
                        eventClick={this.eventClick}
                        events={this.state.calendarEvents}
                        // // Get the event description from the event object
                        // const description = info.event.extendedProps.description;

                        // // Create a new element for the event title and description
                        // const titleElement = document.createElement("div");
                        // titleElement.classList.add("fc-title");
                        // titleElement.classList.add("left-align"); // Add the CSS class for text alignment

                        // // Create a <span> element to wrap the description for bold styling
                        // const descriptionElement = document.createElement("span");
                        // descriptionElement.classList.add("bold-description");
                        // descriptionElement.innerText = description;

                        // // Set the innerHTML of the titleElement with the title and the wrapped description
                        // titleElement.innerHTML = `${info.event.title}<br>${descriptionElement.outerHTML}`;

                        // return { domNodes: [titleElement] };
                        eventContent={function (info) {
                          var fullname =
                            info.event.extendedProps.firstname +
                            " " +
                            info.event.extendedProps.lastname;
                          var type = info.event.extendedProps.type;
                          var status = info.event.extendedProps.status;
                          var total = info.event.extendedProps.totalValue;
                          var time = info.event.extendedProps.time;
                          var note = info.event.extendedProps.note;
                          var order = info.event.extendedProps.order;
                          var txt = `${fullname}\n${type}`;
                          var description = "";

                          if (note !== "") {
                            description = description + `\nN: ${note}`;
                          }
                          if (total == 0) {
                            description = description + "\nNo Amount";
                          } else {
                            var description = description + `\n฿${total}`;
                          }

                          if (status == "Scheduled" && time !== "") {
                            txt = `${fullname}\n${type}\nTime ${time}`;
                          }

                          const orderElement = document.createElement("div");
                          orderElement.classList.add(
                            "center-align",
                            "border-bottom",
                            "border-white"
                          );
                          orderElement.innerText = order;
                          orderElement.title = description;

                          // Create a new element for the event title and description
                          const titleElement = document.createElement("div");
                          titleElement.classList.add("fc-title");
                          titleElement.classList.add("left-align");
                          titleElement.innerText = txt;
                          titleElement.title = description;

                          const statusElement = document.createElement("div");
                          statusElement.classList.add(
                            "text-truncate",
                            "left-align"
                          );
                          statusElement.innerText = status;
                          statusElement.title = description;

                          return {
                            domNodes: [
                              orderElement,
                              titleElement,
                              statusElement,
                            ],
                          };
                        }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              <Col xl={10}>
                <div class="media d-flex align-items-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              </Col>
            )}

            <Col xl={2}>
              <Card>
                <Card.Body className="p-2">
                  <div className="col p-0 m-0">
                    {this.statusList.map((element, index) => {
                      return (
                        <div className="d-flex p-1 align-items-center">
                          <span
                            className="badge width30 height30 text-white"
                            style={{ backgroundColor: this.colorList[index] }}
                          >
                           {" "}
                          </span>
                          <text className="mb-2 mt-1 ml-1 pl-2 small">{element}</text>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row px-0 ml-2 mt-4">
                    <h4 className="text-black">
                      <b>Add Booking</b>
                    </h4>
                  </div>
                  <div className="mt-2" id="external-events">
                    {this.state.events.map((event) => (
                      <div
                        className="fc-event mt-0 ml-0 mb-2 btn btn-block btn-primary"
                        title={event.title}
                        data={event.id}
                        key={event.id}
                      >
                        {event.title}
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* addEventModal */}
          <Modal
            className="fade bd-example-modal-lg"
            show={this.state.isAddEventModal}
            onHide={() => this.onHideAddEventModal()}
            size="xl"
          >
            <Modal.Header>
              <Modal.Title>Add Event</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => this.onHideAddEventModal()}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12">
                    <this.PatientInputText
                      title="Order"
                      refvalue={this.orderRef}
                      defaultValue={this.state.eventTmp.extendedProps.order}
                    ></this.PatientInputText>
                    {/* <this.PatientDropDownForm
                      title="Node"
                      id="node"
                      refvalue={this.nodeRef}
                      selected={this.state.eventTmp.extendedProps.node}
                      options={this.state.nodeList}
                    ></this.PatientDropDownForm>
                    <this.PatientDropDownForm
                      title="Customer"
                      id="customer"
                      refvalue={this.customerRef}
                      selected={this.state.eventTmp.extendedProps.customer}
                      options={this.state.customerList}
                    ></this.PatientDropDownForm> */}

                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        HN
                      </label>

                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control"
                          id="val-hn"
                          name="val-hn"
                          placeholder="HN"
                          ref={this.hnRef}
                          defaultValue={this.state.eventTmp.extendedProps.hn}
                          // ref={emailRef}
                          // value=""
                        />
                      </div>
                      <div className="col-lg-2">
                        <Button
                          variant=""
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.onSearchPatient}
                          size="sm"
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                    {this.state.isNewPatient && (
                      <div className="form-group row">
                        <label
                          className="col-lg-3 col-form-label"
                          htmlFor="val-username"
                        ></label>

                        <div className="col-lg-4">
                          <label className="text-danger">* New Patient</label>
                        </div>
                      </div>
                    )}

                    {this.state.eventTmp.extendedProps.hn == "" ||
                    this.state.eventTmp.extendedProps.hn == null ||
                    this.state.eventTmp.extendedProps.hn == undefined ? (
                      <>
                        {" "}
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Full Name
                          </label>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              id="val-hn"
                              name="val-hn"
                              placeholder="Firstname"
                              ref={this.firstnameRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.firstname
                              }
                            />
                          </div>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              id="val-hn"
                              name="val-hn"
                              placeholder="Lastname"
                              ref={this.lastnameRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.lastname
                              }
                            />
                          </div>
                        </div>
                        {/* <this.PatientInputText
                          title="National ID"
                          refvalue={this.nationalIdRef}
                          defaultValue={
                            this.state.eventTmp.extendedProps.nationalId
                          }
                        ></this.PatientInputText> */}
                        <this.PatientInputText
                          title="Phone"
                          refvalue={this.phoneRef}
                          defaultValue={this.state.eventTmp.extendedProps.phone}
                        ></this.PatientInputText>
                      </>
                    ) : (
                      <>
                        {" "}
                        <this.PatientTextInfo
                          title="Full Name"
                          value={
                            this.state.eventTmp.extendedProps.firstname +
                            " " +
                            this.state.eventTmp.extendedProps.lastname
                          }
                          bold={true}
                        />
                        {/* <this.PatientTextInfo
                          title="National ID"
                          value={this.state.eventTmp.extendedProps.nationalId}
                          bold={true}
                        /> */}
                        <this.PatientTextInfo
                          title="Phone"
                          value={this.state.eventTmp.extendedProps.phone}
                          bold={true}
                        />
                      </>
                    )}

                    {/* <this.PatientDropDownForm
                      title="Health Insurance"
                      id="healthInsurance"
                      refvalue={this.healthInsuranceRef}
                      selected={
                        this.state.eventTmp.extendedProps.healthInsurance
                      }
                      options={this.paymentPrivilages}
                    ></this.PatientDropDownForm> */}
                    <this.PatientDropDownForm
                      title="Type"
                      id="type"
                      refvalue={this.typeRef}
                      selected={this.state.eventTmp.extendedProps.type}
                      options={this.typeList}
                    ></this.PatientDropDownForm>
                    <this.PatientDropDownForm
                      title="Status"
                      id="status"
                      refvalue={this.statusRef}
                      selected={this.state.eventTmp.extendedProps.status}
                      options={this.statusList}
                      onChange={this.checkStatus}
                    ></this.PatientDropDownForm>
                    <this.PatientDropDownForm
                      title="Technician"
                      id="technician"
                      refvalue={this.techRef}
                      selected={this.state.eventTmp.extendedProps.technician}
                      options={this.state.technicianList}
                    ></this.PatientDropDownForm>
                    <this.PatientDropDownForm
                      title="Doctor"
                      id="doctor"
                      refvalue={this.docRef}
                      selected={this.state.eventTmp.extendedProps.doctor}
                      options={this.state.doctorList}
                    ></this.PatientDropDownForm>
                    {this.state.isDatetime && (
                      <>
                        {" "}
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Time
                          </label>
                          <div className="col-lg-2 mt-2">
                            <input
                              type={"time"}
                              className="form-control"
                              defaultValue={
                                this.state.eventTmp.extendedProps.time
                              }
                              // defaultValue={new Date(this.state.dateTmp + "T00:00")}
                              onChange={(event, props) =>
                                this.handleTime(event, props)
                              }
                            ></input>
                          </div>
                        </div>
                      </>
                    )}
                    <this.PatientTextInfo
                      title="Date"
                      value={this.state.eventTmp.startStr}
                      bold={true}
                    />
                    <this.PatientInputTextArea
                      title={"Note"}
                      refvalue={this.noteRef}
                      defaultValue={this.state.eventTmp.extendedProps.note}
                    />

                    <div className="form-group row">
                      <div className="col-lg-5 col-form-label mt-2">
                        <b htmlFor="val-username">Add Item</b>
                        <Button
                          variant="secondary"
                          size="xs"
                          className="ml-2"
                          onClick={() => {
                            var tmpItems = this.state.items;
                            var tmpItemList = this.state.itemList;
                            var tmp = {
                              id: tmpItems.length + 1,
                              itemcode: tmpItemList[0].itemcode,
                              item: tmpItemList[0].item,
                              unitcost: tmpItemList[0].unitcost,
                              qty: 1,
                              discount: 0,
                              total: tmpItemList[0].unitcost,
                            };
                            tmpItems = [...tmpItems, tmp];
                            this.setState(
                              {
                                items: tmpItems,
                              },
                              () => {
                                this.getTotalValue();
                              }
                            );
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="center">No.</th>
                            <th className="center width300">Item Name</th>
                            <th className="center width100">Item Code</th>
                            <th className="center width150">Unit Cost (THB)</th>
                            <th className="center width100">Qty</th>
                            <th className="center">Discount (THB)</th>
                            <th className="center">Total (THB)</th>
                            <th className="center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.items.map((element, index) => {
                            return (
                              <tr>
                                <td className="">{element.id}</td>
                                <td>
                                  <select
                                    className="form-control"
                                    id={"item" + String(index)}
                                    name={"item" + String(index)}
                                    onChange={(evt) => {
                                      var item = evt.target.value;
                                      var tmpItems = this.state.items;

                                      var indexOfItemList =
                                        this.state.itemList.findIndex(
                                          (obj) => obj.item === item
                                        );
                                      console.log(
                                        "selected item: ",
                                        this.state.itemList[indexOfItemList]
                                      );
                                      console.log(
                                        "unitcost: ",
                                        this.state.itemList[indexOfItemList]
                                          .unitcost
                                      );
                                      tmpItems[index].itemcode =
                                        this.state.itemList[
                                          indexOfItemList
                                        ].itemcode;
                                      tmpItems[index].item =
                                        this.state.itemList[
                                          indexOfItemList
                                        ].item;
                                      tmpItems[index].unitcost =
                                        this.state.itemList[
                                          indexOfItemList
                                        ].unitcost;
                                      tmpItems[index].qty = 1;
                                      tmpItems[index].discount = 0;
                                      tmpItems[index].total =
                                        this.state.itemList[
                                          indexOfItemList
                                        ].unitcost;
                                      this.setState({ items: tmpItems }, () => {
                                        this.getTotalValue();
                                      });
                                    }}
                                  >
                                    {this.state.itemList.map((value) => (
                                      <option
                                        value={value.item}
                                        selected={
                                          this.state.items[index].item ==
                                          value.item
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {value.item}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td className="left strong">
                                  {" "}
                                  <b>{this.state.items[index].itemcode}</b>
                                </td>
                                <td className="left">
                                  {" "}
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={"val-unitcost" + String(index)}
                                    name={"val-unitcost" + String(index)}
                                    placeholder="Unit Cost"
                                    onChange={(value) =>
                                      this.onUpdateItemValue(
                                        "unitcost",
                                        index,
                                        value
                                      )
                                    }
                                    value={this.state.items[index].unitcost}
                                  />
                                </td>
                                <td className="right">
                                  {" "}
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={"val-qty" + String(index)}
                                    name={"val-qty" + String(index)}
                                    placeholder="QTY"
                                    onChange={(value) =>
                                      this.onUpdateItemValue(
                                        "qty",
                                        index,
                                        value
                                      )
                                    }
                                    // defaultValue={this.state.items[index].qty}
                                    value={this.state.items[index].qty}
                                  />
                                </td>
                                <td className="center">
                                  {" "}
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={"val-discount" + String(index)}
                                    name={"val-discount" + String(index)}
                                    placeholder="Discount"
                                    onChange={(value) =>
                                      this.onUpdateItemValue(
                                        "discount",
                                        index,
                                        value
                                      )
                                    }
                                    value={this.state.items[index].discount}
                                  />
                                </td>
                                <td className="right">
                                  {" "}
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={"val-total" + String(index)}
                                    name={"val-total" + String(index)}
                                    placeholder="Total"
                                    onChange={(value) =>
                                      this.onUpdateItemValue(
                                        "total",
                                        index,
                                        value
                                      )
                                    }
                                    value={this.state.items[index].total}
                                  />
                                </td>
                                <td>
                                  <Button
                                    className="btn btn-danger btn-xs sharp"
                                    onClick={() => {
                                      var tmpItems = this.state.items;
                                      console.log("items: ", tmpItems);
                                      tmpItems = tmpItems.filter(
                                        (item) => item.id != tmpItems[index].id
                                      );
                                      for (
                                        let i = 0;
                                        i < tmpItems.length;
                                        i++
                                      ) {
                                        tmpItems[i].id = i + 1;
                                      }
                                      console.log("items: ", tmpItems);
                                      this.setState({ items: tmpItems }, () => {
                                        this.getTotalValue();
                                      });
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-sm-5 ml-auto">
                        <table className="table table-clear">
                          <tbody>
                            <tr>
                              <td className="left">
                                <strong>Total</strong>
                              </td>
                              <td className="right">
                                <strong>
                                  {this.state.totalValue + " THB"}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                onClick={() => this.onHideAddEventModal()}
              >
                Cancel
              </Button>
              <Button
                variant=""
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  console.log("ondrop: ", this.state.eventTmp);
                  let api = this.calendarComponentRef.current.getApi();
                  var allEvents = api.getEvents().filter((obj) => {
                    return (
                      obj.startStr === this.state.eventTmp.startStr &&
                      obj.extendedProps.hn !== "" &&
                      obj.extendedProps.hn != null
                    );
                  });
                  console.log("allEvents:", allEvents);
                  let listHNEvent = allEvents.filter((element) => {
                    return (
                      element.extendedProps.hn === this.hnRef.current.value
                    );
                  });
                  console.log("listHNEvent 1403", listHNEvent);

                  if (listHNEvent.length >= 1) {
                    swal({
                      title: `HN${this.hnRef.current.value} is already booking on this day !`,
                      text: `Please recheck booking on ${this.state.eventTmp.startStr}`,
                      icon: "warning",
                      buttons: ["Cancel", "Confirm"],
                      dangerMode: true,
                    }).then((willBooking) => {
                      if (willBooking) {
                        this.onAddEvent();
                      }
                    });
                  } else {
                    this.onAddEvent();
                  }
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* NotiModal */}
          <Modal
            className="fade bd-example-modal-lg"
            show={this.state.isNoti}
            onHide={() => this.onHideNoti()}
            size="xl"
          >
            <Modal.Body>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12">
                    <this.PatientTextInfo
                      title="Order"
                      value={this.state.eventTmp.extendedProps.order}
                      bold={true}
                    />
                    <this.PatientTextInfo
                      title="HN"
                      value={this.state.eventTmp.extendedProps.hn}
                      bold={true}
                    />
                    <this.PatientTextInfo
                      title="Full Name"
                      value={
                        this.state.eventTmp.extendedProps.firstname +
                        " " +
                        this.state.eventTmp.extendedProps.lastname
                      }
                      bold={true}
                    />
                    {/* <this.PatientTextInfo
                      title="Health Insurance"
                      value={this.state.eventTmp.extendedProps.healthInsurance}
                      bold={true}
                    /> */}
                    <this.PatientTextInfo
                      title="Phone"
                      value={this.state.eventTmp.extendedProps.phone}
                      bold={true}
                    />
                    <this.PatientTextInfo
                      title="Type"
                      value={this.state.eventTmp.extendedProps.type}
                      bold={true}
                    />
                    <this.PatientTextInfo
                      title="Status"
                      value={this.state.eventTmp.extendedProps.status}
                      bold={true}
                    />
                    <this.PatientTextInfo
                      title="Technician"
                      value={this.state.eventTmp.extendedProps.technician}
                      bold={true}
                    />
                    <this.PatientTextInfo
                      title="Doctor"
                      value={this.state.eventTmp.extendedProps.doctor}
                      bold={true}
                    />
                    <this.PatientTextInfo
                      title="Date"
                      value={this.state.eventTmp.startStr}
                      bold={true}
                    />
                    <this.PatientTextInfo
                      title="Note"
                      value={this.state.eventTmp.extendedProps.note}
                      bold={true}
                    />
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="center">No.</th>
                            <th className="center">Item Name</th>
                            <th className="center">Item Code</th>
                            <th className="center">Unit Cost (THB)</th>
                            <th className="center">Qty</th>
                            <th className="center">Discount (THB)</th>
                            <th className="center">Total (THB)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.items.map((element, index) => {
                            return (
                              <tr>
                                <td className="">
                                  <b>{element.id}</b>
                                </td>
                                <td>
                                  <b>{element.item}</b>
                                </td>
                                <td className="left strong">
                                  <b>{element.itemcode}</b>
                                </td>
                                <td className="left">
                                  {" "}
                                  <b>{element.unitcost}</b>
                                </td>
                                <td className="right">
                                  {" "}
                                  <b>{element.qty}</b>
                                </td>
                                <td className="center">
                                  {" "}
                                  <b>{element.discount}</b>
                                </td>
                                <td className="right">
                                  {" "}
                                  <b>{element.total}</b>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-sm-5 ml-auto">
                        <table className="table table-clear">
                          <tbody>
                            <tr>
                              <td className="left">
                                <strong>Total</strong>
                              </td>
                              <td className="right">
                                <strong>
                                  {this.state.totalValue + " THB"}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center border-0">
              <Button
                variant=""
                type="button"
                className="btn btn-primary"
                onClick={() => this.onHideNoti()}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <EditEvent /> */}
          <Modal
            className="fade bd-example-modal-lg"
            show={this.state.isEditEventModal}
            onHide={() => {
              this.onHideEditEventModal();
              let evtmp = this.state.eventTmp;
              evtmp.setStart(new Date(this.state.resetDateTmp));
              evtmp.setEnd(new Date(this.state.resetDateTmp).getDate() + 1);
              this.setState({ eventTmp: evtmp });
            }}
            size="xl"
          >
            <Modal.Header>
              <Modal.Title>Edit Event</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => {
                  this.onHideEditEventModal();
                  this.onCancel();
                }}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="ml-4">
                <Button
                  variant=""
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    if (this.state.eventTmp.extendedProps.hn != "") {
                      this.setState({
                        isEditBookingDetail: !this.state.isEditBookingDetail,
                      });
                    }
                  }}
                  size="sm"
                >
                  Edit
                </Button>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12">
                    {this.state.eventTmp.extendedProps.hn == "" ||
                    this.state.isEditBookingDetail == true ? (
                      <>
                        <this.PatientInputText
                          title={"Order"}
                          refvalue={this.orderRef}
                          defaultValue={this.state.eventTmp.extendedProps.order}
                        />
                        {/* <this.PatientDropDownForm
                          title="Node"
                          id="node"
                          refvalue={this.nodeRef}
                          selected={this.state.eventTmp.extendedProps.node}
                          options={this.state.nodeList}
                        ></this.PatientDropDownForm> */}
                        {/* <this.PatientDropDownForm
                          title="Customer"
                          id="customer"
                          refvalue={this.customerRef}
                          selected={this.state.eventTmp.extendedProps.customer}
                          options={this.state.customerList}
                        ></this.PatientDropDownForm> */}
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            HN
                          </label>

                          <div className="col-lg-7">
                            <input
                              type="text"
                              className="form-control"
                              id="val-hn"
                              name="val-hn"
                              placeholder="HN"
                              ref={this.hnRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.hn
                              }
                              // ref={emailRef}
                              // value=""
                            />
                          </div>
                          <div className="col-lg-2">
                            <Button
                              variant=""
                              type="button"
                              className="btn btn-secondary"
                              onClick={this.onSearchPatient}
                              size="sm"
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                        {this.state.isNewPatient && (
                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            ></label>

                            <div className="col-lg-4">
                              <label className="text-danger">
                                * New Patient
                              </label>
                            </div>
                          </div>
                        )}
                        {this.state.eventTmp.extendedProps.hn == "" ? (
                          <>
                            {" "}
                            <div className="form-group row">
                              <label
                                className="col-lg-3 col-form-label"
                                htmlFor="val-username"
                              >
                                Full Name
                              </label>
                              <div className="col-lg-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-hn"
                                  name="val-hn"
                                  placeholder="Firstname"
                                  ref={this.firstnameRef}
                                  defaultValue={
                                    this.state.eventTmp.extendedProps.firstname
                                  }
                                />
                              </div>
                              <div className="col-lg-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-hn"
                                  name="val-hn"
                                  placeholder="Lastname"
                                  ref={this.lastnameRef}
                                  defaultValue={
                                    this.state.eventTmp.extendedProps.lastname
                                  }
                                />
                              </div>
                            </div>
                            <PatientInputText
                              title={"Phone"}
                              refvalue={this.phoneRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.phone
                              }
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <this.PatientTextInfo
                              title="Full Name"
                              value={
                                this.state.eventTmp.extendedProps.firstname +
                                " " +
                                this.state.eventTmp.extendedProps.lastname
                              }
                              bold={true}
                            />
                            {/* <this.PatientTextInfo
                              title="National ID"
                              value={
                                this.state.eventTmp.extendedProps.nationalId
                              }
                              bold={true}
                            /> */}
                            <this.PatientTextInfo
                              title="Phone"
                              value={this.state.eventTmp.extendedProps.phone}
                              bold={true}
                            />
                          </>
                        )}
                        <this.PatientDropDownForm
                          title="Health Insurance"
                          id="healthInsurance"
                          refvalue={this.healthInsuranceRef}
                          selected={
                            this.state.eventTmp.extendedProps.healthInsurance
                          }
                          options={this.paymentPrivilages}
                        ></this.PatientDropDownForm>
                        <this.PatientDropDownForm
                          title="Type"
                          id="type"
                          refvalue={this.typeRef}
                          selected={this.state.eventTmp.extendedProps.type}
                          options={this.typeList}
                        ></this.PatientDropDownForm>
                        <this.PatientDropDownForm
                          title="Status"
                          id="status"
                          refvalue={this.statusRef}
                          selected={this.state.eventTmp.extendedProps.status}
                          options={this.statusList}
                          onChange={this.checkStatus}
                        ></this.PatientDropDownForm>
                        <this.PatientDropDownForm
                          title="Technician"
                          id="technician"
                          refvalue={this.techRef}
                          selected={
                            this.state.eventTmp.extendedProps.technician
                          }
                          options={this.state.technicianList}
                          // onChange={this.checkStatus}
                        ></this.PatientDropDownForm>
                        <this.PatientDropDownForm
                          title="Doctor"
                          id="doctor"
                          refvalue={this.docRef}
                          selected={this.state.eventTmp.extendedProps.doctor}
                          options={this.state.doctorList}
                          // onChange={this.checkStatus}
                        ></this.PatientDropDownForm>
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Date
                          </label>
                          <div className="col-lg-2 mt-2">
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy-MM-dd"
                              selected={new Date(this.state.dateTmp)}
                              onChange={(event, props) =>
                                this.handleDate(event, props)
                              }
                            />
                          </div>
                        </div>
                        {this.state.isDatetime && (
                          <>
                            {" "}
                            <div className="form-group row">
                              <label
                                className="col-lg-3 col-form-label"
                                htmlFor="val-username"
                              >
                                Time
                              </label>
                              <div className="col-lg-2 mt-2">
                                <input
                                  type={"time"}
                                  className="form-control"
                                  defaultValue={
                                    this.state.eventTmp.extendedProps.time
                                  }
                                  // defaultValue={new Date(this.state.dateTmp + "T00:00")}
                                  onChange={(event, props) =>
                                    this.handleTime(event, props)
                                  }
                                ></input>
                              </div>
                            </div>
                          </>
                        )}
                        <PatientInputTextArea
                          title={"Note"}
                          refvalue={this.noteRef}
                          defaultValue={this.state.eventTmp.extendedProps.note}
                        ></PatientInputTextArea>
                        <div className="form-group row">
                          <div className="col-lg-5 col-form-label mt-2">
                            <b htmlFor="val-username">Add Item</b>
                            <Button
                              variant="secondary"
                              size="xs"
                              className="ml-2"
                              onClick={() => {
                                var tmpItems = this.state.items;
                                var tmpItemList = this.state.itemList;
                                var tmp = {
                                  id: tmpItems.length + 1,
                                  itemcode: tmpItemList[0].itemcode,
                                  item: tmpItemList[0].item,
                                  unitcost: tmpItemList[0].unitcost,
                                  qty: 1,
                                  discount: 0,
                                  total: tmpItemList[0].unitcost,
                                };
                                tmpItems = [...tmpItems, tmp];
                                this.setState(
                                  {
                                    items: tmpItems,
                                  },
                                  () => {
                                    this.getTotalValue();
                                  }
                                );
                              }}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th className="center">No.</th>
                                <th className="center width300">Item Name</th>
                                <th className="center width100">Item Code</th>
                                <th className="center width150">
                                  Unit Cost (THB)
                                </th>
                                <th className="center width100">Qty</th>
                                <th className="center">Discount (THB)</th>
                                <th className="center">Total (THB)</th>
                                <th className="center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.items.map((element, index) => {
                                return (
                                  <tr>
                                    <td className="">{element.id}</td>
                                    <td>
                                      <select
                                        className="form-control"
                                        id={"item" + String(index)}
                                        name={"item" + String(index)}
                                        onChange={(evt) => {
                                          var item = evt.target.value;
                                          var tmpItems = this.state.items;

                                          var indexOfItemList =
                                            this.state.itemList.findIndex(
                                              (obj) => obj.item === item
                                            );
                                          console.log(
                                            "selected item: ",
                                            this.state.itemList[indexOfItemList]
                                          );
                                          console.log(
                                            "unitcost: ",
                                            this.state.itemList[indexOfItemList]
                                              .unitcost
                                          );
                                          tmpItems[index].itemcode =
                                            this.state.itemList[
                                              indexOfItemList
                                            ].itemcode;
                                          tmpItems[index].item =
                                            this.state.itemList[
                                              indexOfItemList
                                            ].item;
                                          tmpItems[index].unitcost =
                                            this.state.itemList[
                                              indexOfItemList
                                            ].unitcost;
                                          tmpItems[index].qty = 1;
                                          tmpItems[index].discount = 0;
                                          tmpItems[index].total =
                                            this.state.itemList[
                                              indexOfItemList
                                            ].unitcost;
                                          this.setState(
                                            { items: tmpItems },
                                            () => {
                                              this.getTotalValue();
                                            }
                                          );
                                        }}
                                      >
                                        {this.state.itemList.map((value) => (
                                          <option
                                            value={value.item}
                                            selected={
                                              this.state.items[index].item ==
                                              value.item
                                                ? "selected"
                                                : ""
                                            }
                                          >
                                            {value.item}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td className="left strong">
                                      <b>{this.state.items[index].itemcode}</b>
                                    </td>
                                    <td className="left">
                                      {" "}
                                      <input
                                        type="number"
                                        className="form-control"
                                        id={"val-unitcost" + String(index)}
                                        name={"val-unitcost" + String(index)}
                                        placeholder="Unit Cost"
                                        onChange={(data) =>
                                          this.onUpdateItemValue(
                                            "unitcost",
                                            index,
                                            data
                                          )
                                        }
                                        // defaultValue={
                                        //   this.state.items[index].unitcost
                                        // }
                                        value={this.state.items[index].unitcost}
                                      />
                                    </td>
                                    <td className="right">
                                      {" "}
                                      <input
                                        type="number"
                                        className="form-control"
                                        id={"val-qty" + String(index)}
                                        name={"val-qty" + String(index)}
                                        placeholder="QTY"
                                        onChange={(data) =>
                                          this.onUpdateItemValue(
                                            "qty",
                                            index,
                                            data
                                          )
                                        }
                                        // defaultValue={this.state.items[index].qty}
                                        value={this.state.items[index].qty}
                                      />
                                    </td>
                                    <td className="center">
                                      {" "}
                                      <input
                                        type="number"
                                        className="form-control"
                                        id={"val-discount" + String(index)}
                                        name={"val-discount" + String(index)}
                                        placeholder="Discount"
                                        onChange={(data) => {
                                          this.onUpdateItemValue(
                                            "discount",
                                            index,
                                            data
                                          );
                                        }}
                                        // defaultValue={
                                        //   this.state.items[index].discount
                                        // }
                                        value={this.state.items[index].discount}
                                      />
                                    </td>
                                    <td className="right">
                                      {" "}
                                      <input
                                        type="number"
                                        className="form-control"
                                        id={"val-total" + String(index)}
                                        name={"val-total" + String(index)}
                                        placeholder="Total"
                                        onChange={(data) =>
                                          this.onUpdateItemValue(
                                            "total",
                                            index,
                                            data
                                          )
                                        }
                                        // defaultValue={this.state.items[index].total}
                                        value={this.state.items[index].total}
                                      />
                                    </td>
                                    <td>
                                      <Button
                                        className="btn btn-danger btn-xs sharp"
                                        onClick={() => {
                                          var tmpItems = this.state.items;
                                          console.log("items: ", tmpItems);
                                          tmpItems = tmpItems.filter(
                                            (item) =>
                                              item.id != tmpItems[index].id
                                          );
                                          for (
                                            let i = 0;
                                            i < tmpItems.length;
                                            i++
                                          ) {
                                            tmpItems[i].id = i + 1;
                                          }
                                          console.log("items: ", tmpItems);
                                          this.setState(
                                            { items: tmpItems },
                                            () => {
                                              this.getTotalValue();
                                            }
                                          );
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-5 ml-auto">
                            <table className="table table-clear">
                              <tbody>
                                <tr>
                                  <td className="left">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="right">
                                    <strong>
                                      {this.state.totalValue + " THB"}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <this.PatientTextInfo
                          title="Order"
                          value={this.state.eventTmp.extendedProps.order}
                          bold={true}
                        />
                        <this.PatientLinkInfo
                          title="HN"
                          value={this.state.eventTmp.extendedProps.hn}
                          bold={true}
                          path={
                            "/patient-page/" +
                            this.state.eventTmp.extendedProps.hn
                          }
                        />
                        <this.PatientLinkInfo
                          title="Full Name"
                          value={
                            this.state.eventTmp.extendedProps.firstname +
                            " " +
                            this.state.eventTmp.extendedProps.lastname
                          }
                          bold={true}
                          path={
                            "/patient-page/" +
                            this.state.eventTmp.extendedProps.hn
                          }
                        />
                        <this.PatientTextInfo
                          title="Phone"
                          value={this.state.eventTmp.extendedProps.phone}
                          bold={true}
                        />
                        {/* <this.PatientTextInfo
                          title="Health Insurance"
                          value={
                            this.state.eventTmp.extendedProps.healthInsurance
                          }
                          bold={true}
                        /> */}
                        <this.PatientTextInfo
                          title="Type"
                          value={this.state.eventTmp.extendedProps.type}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Status"
                          value={this.state.eventTmp.extendedProps.status}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Technician"
                          value={this.state.eventTmp.extendedProps.technician}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Doctor"
                          value={this.state.eventTmp.extendedProps.doctor}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Date"
                          value={this.state.eventTmp.startStr}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Note"
                          value={this.state.eventTmp.extendedProps.note}
                          bold={true}
                        />
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th className="center">No.</th>
                                <th className="center">Item Name</th>
                                <th className="center">Item Code</th>
                                <th className="center">Unit Cost (THB)</th>
                                <th className="center">Qty</th>
                                <th className="center">Discount (THB)</th>
                                <th className="center">Total (THB)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.items.map((element, index) => {
                                return (
                                  <tr>
                                    <td className="">
                                      <b>{element.id}</b>
                                    </td>
                                    <td>
                                      <b>{element.item}</b>
                                    </td>
                                    <td className="left strong">
                                      <b>{element.itemcode}</b>
                                    </td>
                                    <td className="left">
                                      {" "}
                                      <b>{element.unitcost}</b>
                                    </td>
                                    <td className="right">
                                      {" "}
                                      <b>{element.qty}</b>
                                    </td>
                                    <td className="center">
                                      {" "}
                                      <b>{element.discount}</b>
                                    </td>
                                    <td className="right">
                                      {" "}
                                      <b>{element.total}</b>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-5 ml-auto">
                            <table className="table table-clear">
                              <tbody>
                                <tr>
                                  <td className="left">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="right">
                                    <strong>
                                      {this.state.totalValue + " THB"}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <div>
                <Button
                  variant=""
                  className="btn btn-danger"
                  onClick={() => {
                    swal({
                      title: "Are you sure to delete ?",
                      text: "Once deleted, you will not be able to recover this booking event !",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        this.onRemoveEvent(true);
                        // swal("Your booking event has been deleted!", {
                        //   icon: "success",
                        // });
                      }
                    });
                    this.onHideEditEventModal();
                  }}
                >
                  Remove
                </Button>
              </div>

              <div>
                <Button
                  variant="light"
                  onClick={() => {
                    this.onHideEditEventModal();
                    this.onCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant=""
                  type="button"
                  className="btn btn-primary ml-2"
                  onClick={() => {
                    let api = this.calendarComponentRef.current.getApi();
                    var allEvents = api.getEvents().filter((obj) => {
                      return (
                        obj.startStr === this.state.eventTmp.startStr &&
                        obj.extendedProps.hn !== ""
                      );
                    });
                    let listHNEvent = allEvents.filter((element) => {
                      return (
                        element.extendedProps.hn ===
                        this.state.eventTmp.extendedProps.hn
                      );
                    });
                    console.log("listHNEvent 1954", listHNEvent);
                    let isExists = false;
                    // if (listHNEvent.length > 1) {
                    //   isExists = true;
                    // }

                    if (listHNEvent.length > 1) {
                      swal({
                        title: `HN${this.state.eventTmp.extendedProps.hn} is already booking on this day !`,
                        text: `Please recheck booking on ${this.state.eventTmp.startStr}`,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then((willBooking) => {
                        if (willBooking) {
                          this.onEditEvent();
                        }
                      });
                    } else {
                      this.onEditEvent();
                    }
                  }}
                >
                  Done
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default EventCalendar;
