// SET_TEXT_FILTER
export const setTextFilter = (text = "") => ({
  type: "SET_TEXT_FILTER",
  text,
});

// SET_SORTBY_FILTER
export const setSearchByFilter = (searchBy) => ({
  type: "SET_SEARCHBY_FILTER",
  searchBy,
});

// SET_START_DATE
export const sortByDate = () => ({
  type: "SORT_BY_DATE",
});

// SET_START_DATE
export const setSortByStatus = (sortByStatus) => ({
  type: "SET_SORTBY_STATUS",
  sortByStatus,
});
