export default [
  {
    label: "Serial Number",
    name: "serialNumber",
    type: "text",
    required: true,
  },
  {
    label: "Brand",
    name: "brand",
    type: "select",
    options: ["Apex", "ResMed", "Ventmed", "Other"],
  },
  {
    label: "Model",
    name: "model",
    type: "select",
    options: [
      "XT-Auto",
      "AirSense 10 AutoSet",
      "AirSense 10 AutoSet for Her",
      "AirMini AutoSet",
      "DF Series",
      "AirStart 10",
      "Other",
    ],
  },
  {
    label: "Type",
    name: "type",
    type: "select",
    options: ["APAP", "CPAP", "BPAP", "Other"],
  },
  {
    label: "Description",
    name: "description",
    type: "text",
  },
  {
    label: "Vendor",
    name: "vendor",
    type: "select",
    options: [
      "Skyline Medical",
      "SaintMed",
      "BluePoint",
      "Devers Health",
      "3N",
      "Other",
    ],
  },
  { label: "Device No", name: "deviceNo", type: "text" },
  { label: "Lot No", name: "lotNo", type: "text" },
  { label: "Incoming Date", name: "incomingDate", type: "date" },
  { label: "Note", name: "note", type: "textarea" },
];
