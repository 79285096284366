export default [
  { label: "Receipt No.", name: "receiptNo", type: "text", required: true },
  { label: "HN", name: "hn", type: "text", required: true },
  {
    label: "Activity",
    name: "activity",
    type: "select",
    options: ["purchase", "maintenance", "cleaning", "repair"],
  },
  {
    label: "Sale Package",
    name: "salePackage",
    type: "select",
    options: ["Apex", "ResMed", "VentMed Premium", "VentMed Standard"],
  },
  { label: "Amount", name: "amount", type: "text" },
  {
    label: "Mask",
    name: "mask",
    type: "select",
    options: [
      "Nasal Mask ResComf",
      "Full Face Mask ResComf",
      "Nasal Mask AirFit N20",
      "Nasal Mask AirFit N30",
      "Full Face Mask AirFit F20",
      "Full Face Mask AirTouch F20",
      "Nasal Pillow AirFit P10",
      "Nasal Mask Wizard 310",
      "Full Face Mask Wizard 320",
    ],
  },
  {
    label: "Mask Size",
    name: "maskSize",
    type: "select",
    options: ["S", "M", "L", "Free Size"],
  },
  { label: "Receipt Date", name: "receiptDate", type: "date" },
  { label: "Warranty Start", name: "warrantyStartDate", type: "date" },
  { label: "Warranty End", name: "warrantyEndDate", type: "date" },
  { label: "Ref Customer", name: "refCustomer", type: "text", disabled: true },
  { label: "Ref Hospital ID", name: "refHospital", type: "text", disabled: true },
  { label: "Ref Physician", name: "refPhysician", type: "text" },
  {
    label: "Sales Representative",
    name: "sales",
    type: "select",
    options: [
      "S.Proudnaphat",
      "P.Noppasit",
      "P.Preeyakorn",
      "N.Jinnaruk",
      "A.Rafa",
    ],
  },
  { label: "Note", name: "note", type: "textarea" },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: ["กำลังดำเนินการ", "เสร็จสิ้น", "ยกเลิก"],
  },
];
