import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Card, Nav, Col, Pagination, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { setCurrentPage } from "../../../actions/pagination";

const Paginator = (props) => {
  // console.log(props.totalPages);
  // console.log(props.pages.currentPage);
  // const active = 4;
  let items = [];
  for (let number = 1; number <= props.totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === props.pages.currentPage}
        onClick={() => getPageNumbers(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const getPageNumbers = (number) => {
    props.setCurrentPage(number);
  };

  const disablePreviousPageButton = () => props.pages.currentPage === 1;

  const disableNextPageButton = () =>
    props.pages.currentPage === props.totalPages;

  const onNextPage = () => {
    props.next();
    props.setCurrentPage(props.pages.currentPage + 1);
  };
  const onPrevPage = () => {
    props.prev();
    props.setCurrentPage(props.pages.currentPage - 1);
  };

  const pag = (size, gutter, variant, bg, circle) => (
    <Pagination
      size={size}
      className={`mt-4  ${gutter ? "pagination-gutter" : ""} ${
        variant && `pagination-${variant}`
      } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
    >
      <li className="page-item page-indicator">
        <Button
          className="page-link"
          onClick={onPrevPage}
          disabled={disablePreviousPageButton()}
          style={{
            cursor: `${disablePreviousPageButton() ? "auto" : "pointer"}`,
          }}
        >
          <i className="la la-angle-left" />
        </Button>
      </li>
      {/* {items} */}
      <div className="d-flex justify-content-center mr-5 ml-5 mt-2">
        {props.pages.currentPage}
        {" / "}
        {props.totalPages}
      </div>
      <li className="page-item page-indicator">
        <Button
          className="page-link"
          onClick={onNextPage}
          disabled={disableNextPageButton()}
          style={{
            cursor: `${disableNextPageButton() ? "auto" : "pointer"}`,
          }}
        >
          <i className="la la-angle-right" />
        </Button>
      </li>
    </Pagination>
  );
  return (
    <div className="d-flex justify-content-center mt-3">
      <Nav>{pag("", true, "", true, false)}</Nav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pages: state.pages,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
  // sortByDate: () => dispatch(sortByDate()),
  // setSearchByFilter: (searchBy) => dispatch(setSearchByFilter(searchBy)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Paginator);
