const demographicDataRevB = {
  //Personal Information
  gender: "",
  dateOfBirth: "",
  age: "",
  weight: "",
  height: "",

  occupation: "",
  workScheduleType: "",
  workHoursPerDay: "",
  workHoursPerWeek: "",
  //Medical Information
  chronicDiseases: "",
  pastSurgeries: "",
  pastSurgeriesNote: "",
  currentMedications: "",
  currentMedicationsNote: "",
  sleepingPills: "",
  sleepingPillsFrequency: "",

  smokingStatus: "",
  alcoholConsumption: "",
  caffeineConsumption: "",
  weightChangeLastYear: "",
  exerciseStatus: "",
  familySleepDisorders: "",
  familySleepDisordersNote: "",

  symptoms: "",
  symptomsNote: "",
  symptomsDuration: "",
  sleepingPosition: "",

  //SleepSchedule
  weekdayBedtime: "",
  weekdayWakeupTime: "",
  weekdayFallAsleepTime: "",
  weekdayAverageSleepHours: "",
  weekdayNightAwakenings: "",
  weekdayNightAwakeningsCount: "",
  weekdayNightAwakeDuration: "",

  weekendBedtime: "",
  weekendWakeupTime: "",
  weekendFallAsleepTime: "",
  weekendAverageSleepHours: "",
  weekendNightAwakenings: "",
  weekendNightAwakeningsCount: "",
  weekendNightAwakeDuration: "",

  napStatus: "",
  napCount: "",
  napTime: "",

  worksShifts: "",
  shiftStartTime: "",
  shiftEndTime: "",
  shiftDaysPerWeek: "",
  shiftDays: "",
  shiftDayBedtime: "",
  shiftDayWakeupTime: "",

  nonShiftDayBedtime: "",
  nonShiftDayWakeupTime: "",

  //SymptomAnalysis
  difficultyFallingAsleep: "",
  legCrampsAtNight: "",
  wakesUpAtNight: "",
  nightWakeReason: "",
  nightWakeFrequency: "",
  nightWakeTime: "",
  difficultyReturningToSleep: "",

  nightSweats: "",
  legCrampsOrCrawlingSensation: "",
  confusionBetweenRealityAndDream: "",
  snoring: "",
  snoringLoudness: "",
  breathingPausesDuringSleep: "",
  chokingSensationDuringSleep: "",
  fistClenchingDuringSleep: "",
  limbJerkingDuringSleep: "",
  restlessnessAndSweatingDuringSleep: "",
  sleepwalkingOrTalking: "",

  feelingAfterWakingUp: "",
  overallSleepQuality: "",
};

export default demographicDataRevB;
