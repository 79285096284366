import { db, storage } from "../firebase";
import {
  setDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytes, deleteObject, listAll } from "firebase/storage";

// ADD Report
export const addReport = (report) => ({
  type: "ADD_REPORT",
  report,
});

export const startAddReport = (reportData = {}, files, type = "report") => {
  // console.log("start add note", noteData, files);
  return async (dispatch) => {
    const { hn = "", createBy = "", createAt = 0 } = reportData;
    const report = { hn, createBy, createAt };
    // console.log("action note", note);

    const docRef = doc(collection(db, "patients", report.hn, type));
    await setDoc(docRef, report)
      .then(() => {
        // console.log("set doc", docRef);
        const promises = [];
        const f = [];
        files.map((file) => {
          const storageRef = ref(
            storage,
            `${type}s/${report.hn}/${docRef.id}/${file.name}`
          );
          f.push(file.name);
          const uploadTask = uploadBytes(storageRef, file);
          promises.push(uploadTask);
        });
        Promise.all(promises);
        // .then(() => console.log("All images uploaded"))
        // .catch((err) => console.log(err));
        updateDoc(doc(db, "patients", report.hn, type, docRef.id), {
          reportFile: f,
        });
        dispatch(
          addReport({
            id: docRef.id,
            ...report,
            reportFile: f,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// REMOVE Report
export const removeReport = ({ id } = {}) => ({
  type: "REMOVE_REPORT",
  id,
});

export const startRemoveReport = ({ hn, id, type = "report" }) => {
  return async (dispatch) => {
    const docRef = doc(db, "patients", hn, type, id);
    return await deleteDoc(docRef).then(() => {
      const storageRef = ref(storage, `${type}s/${hn}/${id}`);
      listAll(storageRef).then((res) => {
        res.items.forEach((itemRef) => {
          deleteObject(itemRef);
        });
      });

      dispatch(removeReport({ id }));
    });
  };
};

// EDIT Report
export const editReport = (id, updates) => ({
  type: "EDIT_REPORT",
  id,
  updates,
});

export const startEditReport = (id, updates, files, type = "report") => {
  return async (dispatch) => {
    const docRef = doc(db, "patients", updates.hn, type, id);

    // console.log("startEditNote", updates);
    const f = [];

    //delete file
    const storageRef = ref(storage, `files/${updates.hn}/${id}`);
    listAll(storageRef).then((res) => {
      const removeRef = res.items.filter(
        (itemRef) => !updates.filename.includes(itemRef.name)
      );
      removeRef.forEach((res) => {
        const storageRef = ref(
          storage,
          `files/${updates.hn}/${id}/${res.name}`
        );
        deleteObject(storageRef, res.name);
      });
    });
    //upload file
    if (files != null) {
      const promises = [];
      files.map((file) => {
        const storageRef = ref(
          storage,
          `files/${updates.hn}/${docRef.id}/${file.name}`
        );
        f.push(...updates.filename, file.name);
        const uploadTask = uploadBytes(storageRef, file);
        promises.push(uploadTask);
      });
      Promise.all(promises);
    }

    return await updateDoc(docRef, {
      ...updates,
      filename: f == 0 ? updates.filename : f,
    }).then(() => {
      dispatch(
        editReport(id, { ...updates, filename: f == 0 ? updates.filename : f })
      );
    });
  };
};

// SET Report
export const setReports = (reports) => ({
  type: "SET_REPORT",
  reports,
});
export const startSetReports = (hn, type = "report") => {
  return async (dispatch) => {
    console.log("type: ", type);
    const docRef = collection(db, "patients", hn, type);
    return await getDocs(docRef).then((snapshot) => {
      const reports = [];
      snapshot.forEach(async (childSnapshot) => {
        // console.log(childSnapshot);
        reports.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });

      dispatch(setReports(reports));
    });
  };
};
