import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";
import fontkit from "@pdf-lib/fontkit";
import { PDFDocument } from "pdf-lib";
import { Dropdown, Pagination, Badge, Button } from "react-bootstrap";
import { db } from "../../../firebase";
import Toast from "../toast/SetToast";

import {
  query,
  endAt,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  where,
  doc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { notifyError, notifyTopRight } from "../toast/SetToast";
import swal from "sweetalert";
const TextInputForm = ({
  title = "Test",
  htmlFor = "",
  id = "",
  name = "",
  placeholder = "",
  refvalue,
  type = "text",
  defaultValue = "",
  disabled = false,
}) => {
  return (
    <div className="form-group row">
      <label className="col-lg-4 col-form-label" htmlFor={htmlFor}>
        {title}
        <span className="text-danger">*</span>
      </label>
      <div className="col-lg-6">
        <input
          type={type}
          className="form-control"
          id={id}
          name={name}
          placeholder={placeholder}
          required
          ref={refvalue}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
const TextInputNotRequiredForm = ({
  title = "Test",
  htmlFor = "",
  id = "",
  name = "",
  placeholder = "",
  refvalue,
  type = "text",
  defaultValue = "",
  disabled = false,
  isBorrow = false,
  onClick,
}) => {
  return (
    <div className="form-group row">
      <label className="col-lg-4 col-form-label" htmlFor={htmlFor}>
        {title}
      </label>
      <div className="col-lg-6">
        <input
          type={type}
          className="form-control"
          id={id}
          name={name}
          placeholder={placeholder}
          ref={refvalue}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
      {isBorrow && (
        <div className="col-lg-1 mt-1">
          <Button
            variant=""
            type="button"
            className="btn btn-secondary"
            onClick={onClick}
            size="sm"
          >
            Search
          </Button>
        </div>
      )}
    </div>
  );
};

const NoteInputNotRequiredForm = ({
  title = "Note",
  htmlFor = "",
  id = "",
  name = "",
  placeholder = "",
  refvalue,
  type = "text",
  defaultValue = "",
  row = 3,
  col = 4,
}) => {
  return (
    <div className="form-group row">
      <label className="col-lg-4 col-form-label" htmlFor={htmlFor}>
        {title}
      </label>
      <div className="col-lg-6">
        <textarea
          type={type}
          row={row}
          className="form-control"
          id={id}
          name={name}
          placeholder={placeholder}
          ref={refvalue}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

const DropDownForm = ({
  title = "",
  htmlFor = "",
  id = "",
  name = "",
  refvalue,
  placeholder = "Please select",
  options = [""],
  defaultValue = "",
  disabled = false,
  onChange,
}) => {
  return (
    <div className="form-group row">
      <label className="col-lg-4 col-form-label" htmlFor={htmlFor}>
        {title}
        <span className="text-danger"></span>
      </label>
      <div className="col-lg-6">
        <select
          className="form-control"
          id={id}
          name={name}
          ref={refvalue}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={onChange}
        >
          <option value="please-select">{placeholder}</option>
          {options.map((value) => (
            <option value={value}>{value}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

const CpapTable = ({ text, sortBy, statusBy }) => {
  const [cpapsList, setCpaps] = useState([]);
  const [latestDoc, setLatestDoc] = useState();
  const [firstDoc, setFirstDoc] = useState();
  const [loading, setLoading] = useState(false);
  const [goNext, setGoNext] = useState(false);
  const [goPrev, setGoPrev] = useState(true);
  const [page, setPage] = useState(1);
  const [cpapsCount, setCpapsCount] = useState();
  const [docCount, setdocCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const itemsPerPage = 30;

  // console.log("text:", text);
  // console.log("sortBy:", sortBy);
  // console.log("statusBy:", statusBy);

  const downloadDoc = async (serialnumber) => {
    //get cpap information
    const cpapRef = doc(db, "cpaps", serialnumber);
    const cpapInfo = await getDoc(cpapRef);

    //get patient information
    const patientRef = doc(db, "patients", cpapInfo.data().LastHN);
    const patientInfo = await getDoc(patientRef);

    //get trial information
    const trialRef = collection(db, "trial");
    const trialQuery = query(
      trialRef,
      where("extendedProps.SerialNumber", "==", serialnumber),
      orderBy("start", "desc"),
      limit(1)
    );
    let trialInfo = [];
    const trialQuerySnapshot = await getDocs(trialQuery);
    trialQuerySnapshot.forEach((doc) => {
      trialInfo.push(doc.data());
    });

    //get borrowing information
    const borrowRef = collection(db, "borrowings");
    // const borrowRef = collection(db, `cpaps/${serialnumber}/borrowing`);
    const borrowQuery = query(
      borrowRef,
      where("SerialNumber", "==", serialnumber),
      where("Activity", "==", "ยืมเครื่อง"),
      orderBy("CreateAt", "desc"),
      limit(1)
    );
    let borrowInfo = [];
    const querySnapshot = await getDocs(borrowQuery);
    querySnapshot.forEach((doc) => {
      borrowInfo.push(doc.data());
    });

    const cpap = cpapInfo.data();
    const patient = patientInfo.data();
    console.log(patient);
    const borrow = borrowInfo[0];
    const trial = trialInfo[0];
    // trial, rental
    const type = trial.extendedProps.type;
    console.log("borrow", borrow);
    console.log("cpap", cpap);
    console.log("trial", trial);

    //Apex
    let url =
      "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Fborrowings%2FF-501-014-D%20%20Borrow%20Equipment%20Form%20-%20Apex.pdf?alt=media&token=e8a0019d-dd3d-4da4-a233-7a8d34cd15cd";
    let namePosition = 136;
    if (cpap.Brand === "ResMed") {
      //ResMed
      url =
        "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Fborrowings%2FF-501-014-D%20%20Borrow%20Equipment%20Form%20-%20ResMed.pdf?alt=media&token=2783a186-e96b-49a6-80f4-b00739144670";
      namePosition = 143;
    } else if (cpap.Brand === "Other") {
      //Other
      url =
        "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Fborrowings%2FF-501-014-D%20%20Borrow%20Equipment%20Form%20-%20VentMed.pdf?alt=media&token=f67a4ef0-0ff2-40b5-82b5-0eac604bbb73";
      namePosition = 143;
    }

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const fonturl = "./assets/fonts/Sarabun-Medium.ttf";
    const fontBytes = await fetch(fonturl).then((res) => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    // Register the `fontkit` instance
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes, { subset: true });

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    // Get the width and height of the first page
    const { width, height } = firstPage.getSize();

    // Draw a string of text diagonally across the first page
    firstPage.drawText(`${cpap.LastHN}`, {
      x: 180,
      y: height / 2 + 285,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`${trial.extendedProps.order}`, {
      x: 300,
      y: height / 2 + 285,
      size: 10,
      font: customFont,
    });

    if (patient.firstnameTh !== "" && patient.lastnameTh !== "") {
      firstPage.drawText(`${patient.firstnameTh} ${patient.lastnameTh}`, {
        x: 180,
        y: height / 2 + 265,
        size: 10,
        font: customFont,
      });
    } else {
      firstPage.drawText(`${patient.firstnameEn} ${patient.lastnameEn}`, {
        x: 180,
        y: height / 2 + 265,
        size: 10,
        font: customFont,
      });
    }

    firstPage.drawText(`${patient.mobile}`, {
      x: 180,
      y: height / 2 + 247,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(
      `${
        trial.extendedProps.BorrowDate !== null
          ? new Date(trial.extendedProps.BorrowDate).toLocaleString("th-TH", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : ""
      }`,
      {
        x: 180,
        y: height / 2 + 228,
        size: 10,
        font: customFont,
      }
    );

    firstPage.drawText(
      `${
        trial.extendedProps.DueDate !== null
          ? new Date(trial.extendedProps.DueDate).toLocaleString("th-TH", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : ""
      }`,
      {
        x: 400,
        y: height / 2 + 228,
        size: 10,
        font: customFont,
      }
    );

    firstPage.drawText(
      `${cpap.Brand === "Other" ? cpap.BrandOther : cpap.Brand}`,
      {
        x: 180,
        y: height / 2 + 160,
        size: 10,
        font: customFont,
      }
    );
    firstPage.drawText(`${cpap.Model}`, {
      x: 180,
      y: height / 2 + 142,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`${cpap.SerialNumber}`, {
      x: 180,
      y: height / 2 + 125,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`${borrow.Mask}`, {
      x: 220,
      y: height / 2 + 107,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`Size ${borrow.MaskSize}`, {
      x: 350,
      y: height / 2 + 107,
      size: 10,
      font: customFont,
    });

    if (patient.firstnameTh !== "" && patient.lastnameTh !== "") {
      firstPage.drawText(`${patient.firstnameTh} ${patient.lastnameTh}`, {
        x: 100,
        y: namePosition,
        size: 10,
        font: customFont,
      });
    } else {
      firstPage.drawText(`${patient.firstnameEn} ${patient.lastnameEn}`, {
        x: 100,
        y: namePosition,
        size: 10,
        font: customFont,
      });
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfDocBytes = await pdfDoc.save();

    const pdfUrl = URL.createObjectURL(
      new Blob([await pdfDoc.save()], { type: "application/pdf" })
    );
    window.open(pdfUrl, "_blank");
  };

  const downloadRentalDoc = async (serialnumber) => {
    //get cpap information
    const cpapRef = doc(db, "cpaps", serialnumber);
    const cpapInfo = await getDoc(cpapRef);

    //get patient information
    const patientRef = doc(db, "patients", cpapInfo.data().LastHN);
    const patientInfo = await getDoc(patientRef);

    //get trial information
    const trialRef = collection(db, "trial");
    const trialQuery = query(
      trialRef,
      where("extendedProps.SerialNumber", "==", serialnumber),
      orderBy("start", "desc"),
      limit(1)
    );
    let trialInfo = [];
    const trialQuerySnapshot = await getDocs(trialQuery);
    trialQuerySnapshot.forEach((doc) => {
      trialInfo.push(doc.data());
    });

    //get borrowing information
    const borrowRef = collection(db, "borrowings");
    // const borrowRef = collection(db, `cpaps/${serialnumber}/borrowing`);
    const borrowQuery = query(
      borrowRef,
      where("SerialNumber", "==", serialnumber),
      where("Activity", "==", "ยืมเครื่อง"),
      orderBy("CreateAt", "desc"),
      limit(1)
    );
    let borrowInfo = [];
    const querySnapshot = await getDocs(borrowQuery);
    querySnapshot.forEach((doc) => {
      borrowInfo.push(doc.data());
    });

    const cpap = cpapInfo.data();
    const patient = patientInfo.data();
    const borrow = borrowInfo[0];
    const trial = trialInfo[0];
    // trial, rental
    const type = trial.extendedProps.type;
    console.log("borrow", borrow);
    console.log("cpap", cpap);
    console.log("trial", trial);

    //Apex
    let url =
      "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Frental%2FF-501-017-A%20%20Rental%20Equipment%20Form%20-%20Apex.pdf?alt=media&token=db54dd07-852f-4a0b-a690-55cabd7602ac";
    let namePosition = 136;
    if (cpap.Brand === "ResMed") {
      //ResMed
      url =
        "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Frental%2FF-501-017-A%20%20Rental%20Equipment%20Form%20-%20ResMed.pdf?alt=media&token=661662bc-4371-48de-a653-38bc8d231e9f";
      namePosition = 143;
    } else if (cpap.Brand === "Other") {
      //Other
      url =
        "https://firebasestorage.googleapis.com/v0/b/braindynamics-197809/o/templates%2Frental%2FF-501-017-A%20%20Rental%20Equipment%20Form%20-%20VentMed.pdf?alt=media&token=20fbb080-da1d-476a-9cc8-495a2fa5b0cf";
      namePosition = 143;
    }

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const fonturl = "./assets/fonts/Sarabun-Medium.ttf";
    const fontBytes = await fetch(fonturl).then((res) => res.arrayBuffer());

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    // Register the `fontkit` instance
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes, { subset: true });

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    // Get the width and height of the first page
    const { width, height } = firstPage.getSize();

    // Draw a string of text diagonally across the first page
    firstPage.drawText(`${cpap.LastHN}`, {
      x: 180,
      y: height / 2 + 285,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`${trial.extendedProps.order}`, {
      x: 300,
      y: height / 2 + 285,
      size: 10,
      font: customFont,
    });

    if (patient.firstnameTh !== "" && patient.lastnameTh !== "") {
      firstPage.drawText(`${patient.firstnameTh} ${patient.lastnameTh}`, {
        x: 180,
        y: height / 2 + 265,
        size: 10,
        font: customFont,
      });
    } else {
      firstPage.drawText(`${patient.firstnameEn} ${patient.lastnameEn}`, {
        x: 180,
        y: height / 2 + 265,
        size: 10,
        font: customFont,
      });
    }

    firstPage.drawText(`${patient.mobile}`, {
      x: 180,
      y: height / 2 + 247,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(
      `${
        trial.extendedProps.BorrowDate !== null
          ? new Date(trial.extendedProps.BorrowDate).toLocaleString("th-TH", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : ""
      }`,
      {
        x: 180,
        y: height / 2 + 228,
        size: 10,
        font: customFont,
      }
    );

    firstPage.drawText(
      `${
        trial.extendedProps.DueDate !== null
          ? new Date(trial.extendedProps.DueDate).toLocaleString("th-TH", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : ""
      }`,
      {
        x: 400,
        y: height / 2 + 228,
        size: 10,
        font: customFont,
      }
    );

    firstPage.drawText(`${trial.extendedProps.totalValue}`, {
      x: 400,
      y: height / 2 + 210,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(
      `${cpap.Brand === "Other" ? cpap.BrandOther : cpap.Brand}`,
      {
        x: 180,
        y: height / 2 + 160,
        size: 10,
        font: customFont,
      }
    );
    firstPage.drawText(`${cpap.Model}`, {
      x: 180,
      y: height / 2 + 142,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`${cpap.SerialNumber}`, {
      x: 180,
      y: height / 2 + 125,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`${borrow.Mask}`, {
      x: 220,
      y: height / 2 + 107,
      size: 10,
      font: customFont,
    });

    firstPage.drawText(`Size ${borrow.MaskSize}`, {
      x: 350,
      y: height / 2 + 107,
      size: 10,
      font: customFont,
    });

    if (patient.firstnameTh !== "" && patient.lastnameTh !== "") {
      firstPage.drawText(`${patient.firstnameTh} ${patient.lastnameTh}`, {
        x: 100,
        y: namePosition,
        size: 10,
        font: customFont,
      });
    } else {
      firstPage.drawText(`${patient.firstnameEn} ${patient.lastnameEn}`, {
        x: 100,
        y: namePosition,
        size: 10,
        font: customFont,
      });
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfDocBytes = await pdfDoc.save();

    const pdfUrl = URL.createObjectURL(
      new Blob([await pdfDoc.save()], { type: "application/pdf" })
    );
    window.open(pdfUrl, "_blank");
  };

  const refDeleteDoc = async (serialnumber) => {
    const refDelete = doc(db, "cpaps", serialnumber);
    await deleteDoc(refDelete)
      .then(async (res) => {
        notifyTopRight("Delete Success!", res);

        // refresh useEffect
        setdocCount(docCount - 1);
        console.log("docCount delete:", docCount);
      })
      .catch((err) => {
        notifyError("Detele Error ", err);
      });
  };

  // SET DEFAULT
  let queryHandler = query(
    collection(db, "cpaps"),
    orderBy(sortBy),
    limit(itemsPerPage)
  );

  let count;

  //  NEXT PAGE
  const nextPage = async () => {
    setLoading(true);
    const totalPages = Math.floor(cpapsCount / itemsPerPage);

    if (sortBy == "LastDueDate") {
      if (statusBy == "ทั้งหมด") {
        queryHandler = query(
          collection(db, "cpaps"),
          orderBy(sortBy),
          startAfter(latestDoc),
          limit(itemsPerPage)
        );
      } else {
        queryHandler = query(
          collection(db, "cpaps"),
          where("Status", "==", statusBy),
          orderBy(sortBy),
          startAfter(latestDoc),
          limit(itemsPerPage)
        );
      }
    } else {
      if (statusBy == "ทั้งหมด") {
        queryHandler = query(
          collection(db, "cpaps"),
          orderBy(sortBy),
          startAt(text),
          endAt(text + "\uf8ff"),
          startAfter(latestDoc),
          limit(itemsPerPage)
        );
      } else {
        queryHandler = query(
          collection(db, "cpaps"),
          where("Status", "==", statusBy),
          limit(itemsPerPage),
          startAfter(latestDoc)
        );
      }
    }

    const docSnap = await getDocs(queryHandler);
    const tempCpap = [];
    docSnap.forEach((doc) => {
      tempCpap.push({ ...doc.data() });
    });

    if (page >= totalPages) {
      setGoNext(false);
    }
    setLoading(false);
    setPage(page + 1);
    setCpaps(tempCpap);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setFirstDoc(docSnap.docs[0]);
    setGoPrev(true);
  };

  //  PREV PAGE
  const previousPage = async () => {
    setLoading(true);

    if (sortBy == "LastDueDate") {
      if (statusBy == "ทั้งหมด") {
        queryHandler = query(
          collection(db, "cpaps"),
          orderBy(sortBy),
          endBefore(firstDoc),
          limitToLast(itemsPerPage)
        );
      } else {
        queryHandler = query(
          collection(db, "cpaps"),
          where("Status", "==", statusBy),
          orderBy(sortBy),
          endBefore(firstDoc),
          limitToLast(itemsPerPage)
        );
      }
    } else {
      if (statusBy == "ทั้งหมด") {
        queryHandler = query(
          collection(db, "cpaps"),
          orderBy(sortBy),
          startAt(text),
          endAt(text + "\uf8ff"),
          endBefore(firstDoc),
          limitToLast(itemsPerPage)
        );
      } else {
        queryHandler = query(
          collection(db, "cpaps"),
          where("Status", "==", statusBy),
          endBefore(firstDoc),
          limitToLast(itemsPerPage)
        );
      }
    }

    const docSnap = await getDocs(queryHandler);
    const tempCpap = [];
    docSnap.forEach((doc) => {
      tempCpap.push({ ...doc.data() });
    });
    if (page <= 2) {
      setGoPrev(false);
    }
    setLoading(false);
    setPage(page - 1);
    setCpaps(tempCpap);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setFirstDoc(docSnap.docs[0]);
    setGoNext(true);
  };

  const drop = (serialnumber, status, type) => {
    return (
      <div className="d-flex">
        <Link
          className={`btn shadow btn-xs sharp mr-2 ${
            status === "ไม่ว่าง" ? "btn-warning" : "btn-light"
          }`}
          to="#"
          onClick={() => downloadRentalDoc(serialnumber)}
          data-toggle="tooltip"
          data-placement="top"
          title="Export CPAP Rental"
        >
          <i className="fa fa-file-o"></i>
        </Link>
        <Link
          className={`btn shadow btn-xs sharp mr-2 ${
            status === "ไม่ว่าง" ? "btn-primary" : "btn-light"
          }`}
          to="#"
          onClick={() => downloadDoc(serialnumber)}
          data-toggle="tooltip"
          data-placement="top"
          title="Export CPAP Receipt and Warranty"
        >
          <i className="fa fa-file-pdf-o"></i>
        </Link>
        <Link
          className="btn btn-info shadow btn-xs sharp mr-2"
          to={"/cpap-page/" + serialnumber}
          data-toggle="tooltip"
          data-placement="top"
          title="CPAP details"
        >
          <i className="fa fa-info"></i>
        </Link>
        <Link
          to="#"
          className="btn btn-danger shadow btn-xs sharp"
          onClick={() => {
            swal({
              title: `Are you sure to delete CPAP ${serialnumber}?`,
              text: "Once deleted, you will not be able to recover this CPAP!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                refDeleteDoc(serialnumber);
              }
            });
          }}
          data-toggle="tooltip"
          data-placement="top"
          title="Cancel order"
        >
          <i className="fa fa-close"></i>
        </Link>
      </div>
    );
  };
  useEffect(async () => {
    setLoading(true);

    if (sortBy == "LastDueDate") {
      if (statusBy == "ทั้งหมด") {
        queryHandler = query(
          collection(db, "cpaps"),
          orderBy(sortBy),
          limit(itemsPerPage)
        );
      } else {
        queryHandler = query(
          collection(db, "cpaps"),
          where("Status", "==", statusBy),
          orderBy(sortBy),
          limit(itemsPerPage)
        );
      }
      count = await getCountFromServer(
        query(collection(db, "cpaps"), orderBy(sortBy))
      );
    } else {
      if (statusBy == "ทั้งหมด") {
        queryHandler = query(
          collection(db, "cpaps"),
          orderBy(sortBy),
          startAt(text),
          endAt(text + "\uf8ff"),
          limit(itemsPerPage)
        );
      } else {
        queryHandler = query(
          collection(db, "cpaps"),
          where("Status", "==", statusBy),
          orderBy(sortBy),
          startAt(text),
          endAt(text + "\uf8ff"),
          limit(itemsPerPage)
        );
      }

      count = await getCountFromServer(
        query(
          collection(db, "cpaps"),
          orderBy(sortBy),
          startAt(text),
          endAt(text + "\uf8ff")
        )
      );
    }

    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No document");
    }

    const tempCpap = [];
    docSnap.forEach((doc) => {
      tempCpap.push({ ...doc.data() });
    });

    var totalPagesTmp = Math.ceil(count.data().count / itemsPerPage);
    setTotalPages(totalPagesTmp);
    setCpaps(tempCpap);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setFirstDoc(docSnap.docs[0]);
    setCpapsCount(count.data().count);
    setGoNext(true);
    setGoPrev(false);
    setLoading(false);
    setPage(1);

    if (count.data().count <= itemsPerPage) {
      console.log("no more doc");
      setGoNext(false);
      setGoPrev(false);
    }
  }, [text, sortBy, statusBy, docCount]);

  const chackbox = document.querySelectorAll(".customer_shop_single input");
  const motherChackBox = document.querySelector(".customer_shop input");
  // console.log(document.querySelectorAll(".publish_review input")[0].checked);
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const chack = (i) => (
    <div className={`custom-control custom-checkbox ml-2`}>
      <input
        type="checkbox"
        className="custom-control-input "
        id={`checkAll${i}`}
        required=""
        onClick={() => chackboxFun()}
      />
      <label className="custom-control-label" htmlFor={`checkAll${i}`}></label>
    </div>
  );

  return (
    <Fragment>
      <Toast />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table mb-0 table-striped">
                  <thead>
                    <tr>
                      <th> </th>
                      <th>Serial Number</th>
                      <th>CPAP ID</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Activity</th>
                      <th>HN</th>
                      <th>Name</th>
                      <th>Due Date</th>
                      <th>Last Update</th>
                      <th>OPTIONS</th>
                    </tr>
                  </thead>
                  <tbody id="cpaps-table">
                    {!loading ? (
                      cpapsList.map((cpapElement, index) => {
                        var variantData = "";
                        var newDateOptions = {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        };

                        if (cpapElement["Status"] == "ว่าง") {
                          variantData = "success light";
                        } else if (cpapElement["Status"] == "ไม่ว่าง") {
                          variantData = "warning dark";
                        } else if (cpapElement["Status"] == "ยกเลิกใช้งาน") {
                          variantData = "danger dark";
                        }

                        var variantActivity = "";
                        if (
                          [
                            "ลงทะเบียน",
                            "คืนเครื่อง",
                            "ส่งทำความสะอาด",
                          ].includes(cpapElement["Activity"])
                        ) {
                          variantActivity = "success light";
                        } else if (
                          ["ยืมเครื่อง", "ส่งซ่อม"].includes(
                            cpapElement["Activity"]
                          )
                        ) {
                          variantActivity = "info";
                        } else if (
                          ["ยกเลิกใช้งาน"].includes(cpapElement["Activity"])
                        ) {
                          variantActivity = "danger";
                        }
                        return (
                          <tr className="btn-reveal-trigger">
                            <td className="py-2">
                              {index + 1 + itemsPerPage * (page - 1)}
                            </td>
                            <td className="py-2">
                              <Link
                                // target="_blank"
                                to={"/cpap-page/" + cpapElement["SerialNumber"]}
                              >
                                {cpapElement["SerialNumber"]}
                              </Link>
                            </td>
                            <td className="py-2">{cpapElement["CpapID"]}</td>
                            <td className="py-2">
                              {cpapElement["Brand"] != "Other"
                                ? cpapElement["Brand"]
                                : cpapElement["BrandOther"]}
                            </td>
                            <td className="py-2 width100">
                              {cpapElement["Model"]}
                            </td>
                            <td className="py-2">
                              {cpapElement["Type"] != "Other"
                                ? cpapElement["Type"]
                                : cpapElement["TypeOther"]}
                            </td>
                            <td className="py-2">
                              <Badge variant={variantData}>
                                {cpapElement["Status"]}
                              </Badge>
                            </td>
                            <td className="py-2">
                              <Badge variant={variantActivity}>
                                {cpapElement["Activity"]}
                              </Badge>
                            </td>
                            <td className="py-2">
                              <Link
                                // target="_blank"
                                to={"/patient-page/" + cpapElement["LastHN"]}
                                data-toggle="tooltip"
                                data-placement="top"
                                title={
                                  cpapElement["LastFirstName"] +
                                  " " +
                                  cpapElement["LastLastName"]
                                }
                              >
                                {cpapElement["LastHN"]}
                              </Link>
                            </td>
                            <td className="py-2 width250">
                              <Link
                                // target="_blank"
                                to={"/patient-page/" + cpapElement["LastHN"]}
                              >
                                {cpapElement["LastFirstName"] +
                                  " " +
                                  cpapElement["LastLastName"]}
                              </Link>
                            </td>
                            <td className="py-2">
                              {cpapElement["LastDueDate"]
                                ? cpapElement["LastDueDate"]
                                    .toDate()
                                    .toLocaleDateString(newDateOptions)
                                : ""}
                            </td>
                            <td className="py-2">
                              {cpapElement["UpdatedDateTime"]
                                .toDate()
                                .toLocaleString(newDateOptions)}
                            </td>
                            <td className="py-2 text-right">
                              {drop(
                                cpapElement["SerialNumber"],
                                cpapElement["Status"]
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="media d-flex align-items-center">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden"></span>
                        </div>
                      </div>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center mt-3">
                  <Pagination
                    className="pagination-primary pagination-circle"
                    size="lg"
                  >
                    <Button
                      onClick={previousPage}
                      disabled={!goPrev}
                      className="page-link"
                    >
                      <i className="la la-angle-left" />
                    </Button>
                    {/* {Page }{totalPages} */}
                    <div className="d-flex justify-content-center mr-5 ml-5 mt-2">
                      {page}
                      {" / "}
                      {totalPages}
                    </div>

                    <Button
                      onClick={nextPage}
                      disabled={!goNext}
                      className="page-link"
                    >
                      <i className="la la-angle-right" />
                    </Button>
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const handleDate = (date) => {
  if (date != undefined) {
    try {
      var datetime = new Date(
        date.toDate().setHours(new Date(date.toDate()).getHours() + 7)
      )
        .toISOString()
        .replace("T", " ")
        .slice(0, 19);
      return datetime;
    } catch {
      // console.log(new Date(date).getHours());
      // console.log(date);
      var newDate = new Date(date);
      var hours = newDate.getHours() + 7;
      newDate = new Date(newDate.setHours(hours));
      var newString = newDate.toISOString().replace("T", " ").slice(0, 19);

      return newString;
    }
  } else {
    return "";
  }
};

export default TextInputForm;

export {
  TextInputForm,
  TextInputNotRequiredForm,
  DropDownForm,
  CpapTable,
  NoteInputNotRequiredForm,
  handleDate,
};
