import moment from "moment";

// Get visible order

export default (orders) => {
  return orders.sort((a, b) => {
    return a.createdAt < b.createdAt ? 1 : -1;
    // if (sortBy === "date") {
    //   return a.createdAt < b.createdAt ? 1 : -1;
    // }
  });
};
