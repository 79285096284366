import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";
import { Button, Dropdown, Pagination } from "react-bootstrap";
import { db, functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import Toast from "../toast/SetToast";
import { notifyError, notifyTopRight } from "../toast/SetToast";
import { useAuth } from "../../../context/AuthContext";
/// images
import avartar5 from "../../../images/avatar/5.png";
import avartar1 from "../../../images/avatar/1.png";
import greenMark from "../../../images/avatar/green-check-mark.png";
import redMark from "../../../images/avatar/red-x.png";
import yellowMark from "../../../images/avatar/exclamation-mark.png";

import {
  query,
  endAt,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  where,
} from "firebase/firestore";
import swal from "sweetalert";
import { colors } from "@material-ui/core";

const PatientTextInputForm = ({
  title = "Test",
  htmlFor = "",
  id = "",
  name = "",
  placeholder = "",
  refvalue,
  type = "text",
  defaultValue = "",
}) => {
  return (
    <div className="form-group row">
      <label
        className="col-lg-4 col-form-label"
        style={{ color: "#424242" }}
        htmlFor={htmlFor}
      >
        {title}
        <span className="text-danger">*</span>
      </label>
      <div className="col-lg-6">
        <input
          type={type}
          className="form-control"
          id={id}
          name={name}
          placeholder={placeholder}
          required
          ref={refvalue}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};
const PatientTextInputNotRequiredForm = ({
  title = "Test",
  htmlFor = "",
  id = "",
  name = "",
  placeholder = "",
  refvalue,
  type = "text",
  defaultValue = "",
  description,
}) => {
  return (
    <div className="form-group row">
      <label
        className="col-lg-4 col-form-label"
        style={{ color: "#424242" }}
        htmlFor={htmlFor}
      >
        {title}
      </label>
      <div className="col-lg-6">
        <input
          type={type}
          className="form-control"
          id={id}
          name={name}
          placeholder={placeholder}
          ref={refvalue}
          defaultValue={defaultValue}
        />
        <small>{description}</small>
      </div>
    </div>
  );
};
const PatientDropDownForm = ({
  title = "",
  htmlFor = "",
  id = "",
  name = "",
  refvalue,
  placeholder = "Please select",
  options = [""],
  defaultValue = "",
  onChange,
}) => {
  return (
    <div className="form-group row">
      <label
        className="col-lg-4 col-form-label"
        style={{ color: "#424242" }}
        htmlFor={htmlFor}
      >
        {title}
        <span className="text-danger"></span>
      </label>
      <div className="col-lg-6">
        <select
          className="form-control"
          id={id}
          name={name}
          ref={refvalue}
          defaultValue={defaultValue}
          onChange={onChange}
        >
          <option value="">{placeholder}</option>
          {options.map((value) => (
            <option value={value}>{value}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

function containsNumbers(str) {
  return /\d/.test(str);
}

const PatientTable = ({ text, sortBy }) => {
  const { currentUser } = useAuth();
  const [patientsList, setPatients] = useState([]);
  const [latestDoc, setLatestDoc] = useState();
  const [firstDoc, setFirstDoc] = useState();
  const [loading, setLoading] = useState(false);
  const [goNext, setGoNext] = useState(false);
  const [goPrev, setGoPrev] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [patientsCount, setPatientsCount] = useState();
  const deleteUserByEmail = httpsCallable(functions, "deleteUserByEmail");
  const manageUser = httpsCallable(functions, "manageUser");
  const deleteAllowed = ["super_admin", "admin"];
  const [nodeName, setNodeName] = useState("");
  const itemsPerPage = 33;

  const deletePatient = async (email, hn) => {
    console.log(email, hn);
    if (email != "") {
      deleteUserByEmail({ email, hn })
        .then(() => {
          notifyTopRight("Delete Success!");
        })
        .catch((error) => {
          notifyError("Detele Error ", error);
        });
    } else {
      const ref = doc(db, "patients", hn);

      await deleteDoc(ref)
        .then(() => {
          notifyTopRight("Delete Success!");
        })
        .catch((error) => {
          notifyError("Detele Error ", error);
        });
    }
  };

  const accoutSetting = (email, hn, isActive) => {
    manageUser({ email, isActive })
      .then(async () => {
        const ref = doc(db, "patients", hn);
        await updateDoc(ref, { loginAccount: !isActive });

        notifyTopRight("Update Success!");
      })
      .catch((error) => {
        notifyError("Update Error ", error);
      });
  };

  let queryHandler = query(
    collection(db, "patients"),
    orderBy(sortBy),
    limit(itemsPerPage)
  );

  const nextPage = async () => {
    setLoading(true);
    const totalPages = Math.floor(patientsCount / itemsPerPage);
    if (
      currentUser.roles[0] == "super_admin" ||
      currentUser.roles[0] == "sale"
    ) {
      queryHandler = query(
        collection(db, "patients"),
        orderBy(sortBy),
        startAt(text),
        endAt(text + "\uf8ff"),
        startAfter(latestDoc),
        limit(itemsPerPage)
      );
    } else {
      queryHandler = query(
        collection(db, "patients"),
        orderBy(sortBy),
        where("nodeRef", "==", nodeName),
        startAt(text),
        endAt(text + "\uf8ff"),
        startAfter(latestDoc),
        limit(itemsPerPage)
      );
    }
    const docSnap = await getDocs(queryHandler);
    const tempPatient = [];
    docSnap.forEach((doc) => {
      tempPatient.push({ ...doc.data() });
    });

    setLoading(false);
    setPage(page + 1);
    setPatients(tempPatient);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setFirstDoc(docSnap.docs[0]);
    setGoPrev(true);
    if (page >= totalPages - 1) {
      setGoNext(false);
    }
  };

  const previousPage = async () => {
    setLoading(true);
    // console.log("count: ", patientsCount);
    // console.log("page: ", page);
    if (
      currentUser.roles[0] == "super_admin" ||
      currentUser.roles[0] == "sale"
    ) {
      queryHandler = query(
        collection(db, "patients"),
        orderBy(sortBy),
        startAt(text),
        endAt(text + "\uf8ff"),
        endBefore(firstDoc),
        limitToLast(itemsPerPage)
      );
    } else {
      queryHandler = query(
        collection(db, "patients"),
        orderBy(sortBy),
        where("nodeRef", "==", nodeName),
        startAt(text),
        endAt(text + "\uf8ff"),
        endBefore(firstDoc),
        limitToLast(itemsPerPage)
      );
    }
    const docSnap = await getDocs(queryHandler);
    const tempPatient = [];
    docSnap.forEach((doc) => {
      tempPatient.push({ ...doc.data() });
    });
    if (page <= 2) {
      setGoPrev(false);
    }
    setLoading(false);
    setPage(page - 1);
    setPatients(tempPatient);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setFirstDoc(docSnap.docs[0]);
    setGoNext(true);
  };

  useEffect(async () => {
    setLoading(true);
    var count;
    if (
      currentUser.roles[0] == "super_admin" ||
      currentUser.roles[0] == "sale"
    ) {
      queryHandler = query(
        collection(db, "patients"),
        orderBy(sortBy),
        startAt(text),
        endAt(text + "\uf8ff"),
        limit(itemsPerPage)
      );
      count = await getCountFromServer(
        query(
          collection(db, "patients"),
          orderBy(sortBy),
          startAt(text),
          endAt(text + "\uf8ff")
        )
      );
    } else {
      const currentAdmin = await getDoc(
        doc(db, "admins", currentUser.displayName)
      );
      const nodes = await getDocs(collection(db, "nodes"));
      const nodeCode = currentAdmin.data().node;
      const nodename = nodes.docs
        .find((doc) => doc.data().nodecode == nodeCode)
        .data().nodename;
      console.log(nodename);
      setNodeName(nodename);

      queryHandler = query(
        collection(db, "patients"),
        orderBy(sortBy),
        where("nodeRef", "==", nodename),
        startAt(text),
        endAt(text + "\uf8ff"),
        limit(itemsPerPage)
      );
      count = await getCountFromServer(
        query(
          collection(db, "patients"),
          orderBy(sortBy),
          where("nodeRef", "==", nodename),
          startAt(text),
          endAt(text + "\uf8ff")
        )
      );
    }

    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No document");
    }
    const tempPatient = [];
    docSnap.forEach((doc) => {
      tempPatient.push({ ...doc.data() });
    });

    setPatients(tempPatient);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setFirstDoc(docSnap.docs[0]);
    setPatientsCount(count.data().count);
    const totalPagesTmp = Math.ceil(count.data().count / itemsPerPage);
    setTotalPages(totalPagesTmp);
    setGoNext(true);
    setGoPrev(false);
    setLoading(false);
    setPage(1);
    if (count.data().count <= itemsPerPage) {
      setGoNext(false);
      setGoPrev(false);
    }
  }, [text, sortBy]);

  const drop = (email, hn) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="" className="table-dropdown icon-false">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <circle fill="#000000" cx="5" cy="12" r="2"></circle>
              <circle fill="#000000" cx="12" cy="12" r="2"></circle>
              <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            href="#"
            onClick={() => {
              accoutSetting(email, hn, false);
            }}
          >
            Active
          </Dropdown.Item>

          <Dropdown.Item
            href="#"
            onClick={() => {
              accoutSetting(email, hn, true);
            }}
          >
            Inactive
          </Dropdown.Item>

          <Dropdown.Item
            href="#"
            className="text-danger"
            // onClick={() => {
            //   deletePatient(email, hn);
            // }}
            onClick={() => {
              swal({
                title: `Are you sure to delete patient with ${hn}?`,
                text: "Once deleted, you will not be able to recover this patient !",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  deletePatient(email, hn);
                }
              });
            }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const chackbox = document.querySelectorAll(".customer_shop_single input");
  const motherChackBox = document.querySelector(".customer_shop input");
  // console.log(document.querySelectorAll(".publish_review input")[0].checked);
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const chack = (i) => (
    <div className={`custom-control custom-checkbox ml-2`}>
      <input
        type="checkbox"
        className="custom-control-input "
        id={`checkAll${i}`}
        required=""
        onClick={() => chackboxFun()}
      />
      <label className="custom-control-label" htmlFor={`checkAll${i}`}></label>
    </div>
  );

  return (
    <Fragment>
      <Toast />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive-sm">
                <div className="d-flex justify-content-center mt-3"></div>
                <table className="table mb-1 table-striped">
                  <thead>
                    <tr>
                      <th>
                        <h5 className="text-primary"> </h5>
                      </th>
                      <th className="col-2">
                        <h5 className="text-primary">HN</h5>
                      </th>
                      <th className="col-3">
                        <h5 className="text-primary">ชื่อ นามสกุล</h5>
                      </th>
                      <th className="col-1">
                        <h5 className="text-primary">เพศ</h5>
                      </th>
                      <th className="col-2 text-center">
                        <h5 className="text-primary">วันเดือนปีเกิด</h5>
                      </th>
                      <th className="col-2 text-center">
                        <h5 className="text-primary">เบอร์โทรศัพท์</h5>
                      </th>
                      <th className="col-3">
                        <h5 className="text-primary">ที่อยู่</h5>
                      </th>
                    </tr>
                  </thead>
                  {!loading ? (
                    <tbody id="customers">
                      {patientsList.map((patientElement, index) => {
                        return (
                          <tr className="btn-reveal-trigger">
                            <td className="py-1">
                              {index + 1 + itemsPerPage * (page - 1)}
                            </td>
                            <td className="py-1">{patientElement["hn"]}</td>
                            <td className="py-1">
                              <Link
                                // target="_blank"
                                to={"/patient-page/" + patientElement["hn"]}
                              >
                                <div className="media d-flex align-items-center">
                                  {patientElement["firstnameTh"]}{" "}
                                  {patientElement["lastnameTh"]}
                                </div>
                              </Link>
                            </td>
                            <td className="py-1">{patientElement["gender"]}</td>
                            <td className="py-1 text-center">
                              {patientElement["dateOfBirth"]}
                            </td>
                            <td className="py-1 text-center">
                              {patientElement["mobile"]}
                            </td>
                            <td className="py-1">
                              <span
                                className="d-inline-block text-truncate "
                                style={{ maxWidth: "350px" }}
                                data-toggle="address"
                                data-placement="top"
                                title={
                                  patientElement["address1"] +
                                  patientElement["address2"] +
                                  patientElement["province"]
                                }
                              >
                                {patientElement["address1"] +
                                  patientElement["address2"] +
                                  patientElement["province"]}
                              </span>
                            </td>
                            {deleteAllowed.some((allowed) =>
                              currentUser.roles.includes(allowed)
                            ) && (
                              <td className="py-2 text-right">
                                {drop(
                                  patientElement["email"],
                                  patientElement["hn"]
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <div class="media d-flex align-items-center">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden"></span>
                      </div>
                    </div>
                  )}
                </table>

                <div className="d-flex justify-content-center mt-3">
                  <Pagination
                    className="pagination-primary pagination-circle"
                    size="lg"
                  >
                    <Button
                      onClick={previousPage}
                      disabled={!goPrev}
                      className="page-link"
                    >
                      <i className="la la-angle-left" />
                    </Button>
                    {/* {Page }{totalPages} */}
                    <div className="d-flex justify-content-center mr-5 ml-5 mt-2">
                      {page}
                      {" / "}
                      {totalPages}
                    </div>

                    <Button
                      onClick={nextPage}
                      disabled={!goNext}
                      className="page-link"
                    >
                      <i className="la la-angle-right" />
                    </Button>
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PatientTextInputForm;

export {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
  PatientTable,
};
