export const setItemPerPage = (itemsPerPage) => ({
  type: "SET_ITEMS_PER_PAGE",
  itemsPerPage,
});

export const setCurrentPage = (currentPage) => ({
  type: "SET_CURRENT_PAGE",
  currentPage,
});

export const setFirstPage = (firstPage) => ({
  type: "SET_CURRENT_PAGE",
  firstPage,
});

export const setTotalPages = (totalPages, latestPage, firstPage) => ({
  type: "SET_TOTAL_PAGES",
  firstPage,
  totalPages,
  latestPage,
});

export const setResetPage = () => ({
  type: "SET_RESET_PAGE",
});
