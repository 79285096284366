import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import { useAuth } from "../../../context/AuthContext";
import swal from "sweetalert";
import Alert from "sweetalert2";

import { functions } from "../../../firebase";
import { db, storage } from "../../../firebase";
import {
  setDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  deleteObject,
  listAll,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  startAddReport,
  startSetReports,
  startRemoveReport,
  startEditReport,
} from "../../../actions/reports";
import { httpsCallable } from "firebase/functions";
import selectReports from "../../../selectors/reports";
import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import PatientReportForm from "./PatientReportForm";
import PatientReportItem from "./PatientReportItem";
import { file } from "jszip";
const PatientSleepStudy = ({
  profile,
  reportsList,
  startAddReport,
  startSetReports,
  startRemoveReport,
}) => {
  const getSignedUrl = httpsCallable(functions, "getSignedUrl");
  const { currentUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [reportEditData, setReportEditData] = useState(null);
  const [isLoding, setIsLoading] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [reports, setReports] = useState([]);
  const [sleepstudyList, setSleepStudyList] = useState([]);
  const [fileUrl, setFileUrl] = useState(null);

  const onSubmit = async (files) => {
    if (!isEdit) {
      const report = {
        hn: profile.hn,
        createBy: currentUser.displayName,
        createAt: new Date(),
      };
      const type = "sleepstudy";
      //   startAddReport(report, files, type);
      SetAddSleepStudy(report, files, type);
    } else {
      setIsEdit(false);
    }
  };

  const onDelete = ({ hn, id }) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const type = "sleepstudy";
        SetRemoveSleepStudy({ hn, id, type });

        swal(`Poof! Your file has been deleted!`, {
          icon: "success",
        });
      } else {
        swal("Canceled");
      }
    });
  };

  const onEdit = (data) => {
    setIsEdit(true);
    setReportEditData(data);
  };

  const onCancelEdit = () => {
    setIsEdit(false);
  };

  const onViewFile = (hn, id, file, type = "report") => {
    console.log(id);
    getSignedUrl({
      folder: `${type}s`,
      hn,
      id,
      filename: file,
    })
      .then((result) => {
        console.log("url", result.data);
        setFileUrl(result.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setCreateModal(true);
  };
  const SetAddSleepStudy = async (reportData = {}, files, type) => {
    const { hn = "", createBy = "", createAt = 0 } = reportData;
    const report = { hn, createBy, createAt };

    try {
      const docRef = doc(collection(db, "patients", report.hn, type));
      await setDoc(docRef, report);
      Alert.fire({
        title: "Uploading files...",
        html: "Please wait while the files are being uploaded.",
        allowOutsideClick: false,
        showConfirmButton: false,
        timerProgressBar: true,
        onBeforeOpen: () => {
          Alert.showLoading();
        },
      });
      const promises = files.map((file) => {
        const storageRef = ref(
          storage,
          `${type}s/${report.hn}/${docRef.id}/${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);
        // swal.showLoading();
        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Optional: Handle progress updates
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // console.log("Upload is " + progress + "% done");
              Alert.update({
                html: `Uploading ${file.name} : ${Math.round(progress)}% done`,
              });
            },
            (error) => {
              // Handle unsuccessful uploads
              console.log("error handle upload");
              Alert.close();
              reject(error);
            },
            () => {
              // Handle successful uploads on complete
              console.log("Upload successful");
              Alert.close();
              resolve(uploadTask.snapshot.ref.name);
            }
          );
        });
      });
      // Alert.hideLoading();

      const uploadedFiles = await Promise.all(promises);
      await updateDoc(doc(db, "patients", report.hn, type, docRef.id), {
        reportFile: uploadedFiles,
      });
      SetSleepStudy();
      swal(`Uploaded successfully`, {
        icon: "success",
      });
    } catch (error) {
      console.log(error);
      swal(`${error}`, {
        icon: "error",
      });
    }
  };

  const SetRemoveSleepStudy = async ({ hn, id, type }) => {
    const docRef = doc(db, "patients", hn, type, id);
    await deleteDoc(docRef).then(() => {
      const storageRef = ref(storage, `${type}s/${hn}/${id}`);
      listAll(storageRef).then((res) => {
        res.items.forEach((itemRef) => {
          deleteObject(itemRef);
        });
      });
    });
    SetSleepStudy();
  };

  const SetSleepStudy = async () => {
    const type = "sleepstudy";
    const docRef = collection(db, "patients", profile.hn, type);
    await getDocs(docRef).then((snapshot) => {
      const sleepstudys = [];
      snapshot.forEach(async (childSnapshot) => {
        sleepstudys.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });
      setSleepStudyList(sleepstudys);
    });
  };
  const onDownLoad = (item) => {
    const type = "sleepstudy";
    SetDownLoadSleepStudy(item.hn, item.id, type, item.reportFile[0]);
  };
  const SetDownLoadSleepStudy = async (hn, id, type, fileName) => {
    console.log(`${type}s/${hn}/${id}/${fileName}`);
    const storageRef = ref(storage, `${type}s/${hn}/${id}/${fileName}`);
    getDownloadURL(storageRef)
      .then((url) => {
        downloadFile(url, fileName);
        // console.log(url)
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error getting file download URL", error);
      });
  };
  const downloadFile = (url, fileName) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  useEffect(async () => {
    setIsLoading(true);
    SetSleepStudy();
    setIsLoading(false);
  }, [profile]);

  return (
    <Fragment>
      <Toast />
      <Modal
        className="fade bd-example-modal-lg"
        show={createModal}
        onHide={() => setCreateModal(false)}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <div
            className="card-body mx-0 p-0 col-lg-12"
            style={{ height: "85vh" }}
          >
            <iframe
              className="col-lg-12 m-0 p-0"
              src={fileUrl + "#toolbar=0"}
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <PatientReportForm
        hn={profile.hn}
        onSubmit={onSubmit}
        text={"Add Sleep Study"}
      />
      <text>
        Compress sleep study folder in to .zip then uploaded the compress file
      </text>
      {/* {!isEdit ? (
        <PatientReportForm
          onSubmit={onSubmit}
          hn={profile.hn}
          createBy={currentUser.displayName}
        />
      ) : (
        <PatientReportEditForm
          onSubmit={onSubmit}
          onCancel={onCancelEdit}
          hn={profile.hn}
          createBy={currentUser.displayName}
          note={noteEditData}
        />
      )} */}
      {!isLoding && (
        <PatientReportItem
          reports={sleepstudyList}
          hn={profile.hn}
          onEdit={onEdit}
          onDelete={onDelete}
          onViewFile={onViewFile}
          isDownLoad={true}
          onDownLoad={onDownLoad}
        />
      )}
    </Fragment>
  );
};

export default PatientSleepStudy;
// const mapStateToProps = (state, props) => ({
//   reportsList: selectReports(state.reports),
// });

// const mapDispatchToProps = (dispatch) => ({
//   startSetReports: (reports, type) => dispatch(startSetReports(reports, type)),
//   startEditReport: (id, report, files, type) =>
//     dispatch(startEditReport(id, report, files, type)),
//   startRemoveReport: (data, type) => dispatch(startRemoveReport(data, type)),
//   startAddReport: (report, files, type) =>
//     dispatch(startAddReport(report, files, type)),
// });

// export default connect(undefined, mapDispatchToProps)(PatientSleepStudy);
