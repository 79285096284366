import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Table, Pagination } from "react-bootstrap";

const Item = ({
  items,
  onEdit,
  onDelete,
  onCopy,
  onNext,
  onPrev,
  totalItems,
  totalPages,
  currentPage,
  itemsPerPage,
}) => {
  const numberPagination = Array(Math.ceil(totalItems / itemsPerPage))
    .fill()
    .map((_, i) => i + 1);

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              {/* <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {currentPage * itemsPerPage + 1} to{" "}
                  {totalItems < (currentPage + 1) * itemsPerPage
                    ? totalItems
                    : (currentPage + 1) * itemsPerPage}{" "}
                  of {totalItems} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <Pagination
                    className="pagination-primary pagination-circle"
                    size="lg"
                  >
                    <li className="page-item page-indicator " onClick={onPrev}>
                      <Link className="page-link" to="#">
                        <i className="la la-angle-left" />
                      </Link>
                    </li>
                    {numberPagination.map((number, i) => (
                      <Pagination.Item
                        className={currentPage === i ? "active" : ""}
                        disabled
                      >
                        {number}
                      </Pagination.Item>
                    ))}
                    <li className="page-item page-indicator" onClick={onNext}>
                      <Link className="page-link" to="#">
                        <i className="la la-angle-right" />
                      </Link>
                    </li>
                  </Pagination>
                </div>
              </div> */}
              <div className="table-responsive">
                <table className="table mb-0 table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Node</th>
                      <th>Item</th>
                      <th>Item ID</th>
                      <th>UnitCost</th>
                      <th>Description</th>
                      <th>Categoty</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody id="items-table">
                    {items.map((item, index) => {
                      return (
                        <tr className="btn-reveal-trigger">
                          <td className="py-1">
                            {index + (currentPage * itemsPerPage + 1)}
                          </td>
                          <td className="py-1">{item.node}</td>
                          <td className="py-2">{item.item}</td>
                          <td className="py-2">{item.itemId}</td>
                          <td className="py-2">{item.unitcost}</td>
                          <td className="py-3">{item.description}</td>
                          <td className="py-1">{item.category}</td>
                          <td className="py-1">{item.IsActive}</td>
                          <td className="py-2 text-right">
                            <div className="d-flex">
                              <Link
                                className="btn btn-primary shadow btn-xs sharp mr-1"
                                to="#"
                                onClick={() => onEdit(item.id)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit item details"
                              >
                                <i className="fa fa-pencil"></i>
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-info shadow btn-xs sharp mr-1"
                                onClick={() => onCopy(item.id)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Copy item"
                              >
                                <i className="fa fa-copy"></i>
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-danger shadow btn-xs sharp mr-1"
                                onClick={() => onDelete(item.id)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Cancel item"
                              >
                                <i className="fa fa-close"></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {currentPage * itemsPerPage + 1} to{" "}
                  {totalItems < (currentPage + 1) * itemsPerPage
                    ? totalItems
                    : (currentPage + 1) * itemsPerPage}{" "}
                  of {totalItems} entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <Pagination
                    className="pagination-primary pagination-circle"
                    size="lg"
                  >
                    <li className="page-item page-indicator " onClick={onPrev}>
                      <Link className="page-link" to="#">
                        <i className="la la-angle-left" />
                      </Link>
                    </li>
                    {numberPagination.map((number, i) => (
                      <Pagination.Item
                        className={currentPage === i ? "active" : ""}
                        disabled
                      >
                        {number}
                      </Pagination.Item>
                    ))}
                    <li className="page-item page-indicator" onClick={onNext}>
                      <Link className="page-link" to="#">
                        <i className="la la-angle-right" />
                      </Link>
                    </li>
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Item;
