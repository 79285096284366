const postQuestionnairesFormTitle = "แบบสอบถามหลังการตรวจการนอนหลับ";

const postQuestionnairesQuestions = {
  Q1: "ท่านคิดว่าท่านอยู่บนเตียงนานเท่าไร",
  Q2: "ท่านใช้เวลานานกี่ชั่วโมงกว่าจะหลับ",
  Q3: "ท่านคิดว่าท่านได้นอนเป็นเวลาเท่าใด",
  Q4: "ท่านคิดว่าท่านฝันหรือไม่",
  Q5: "ขณะนี้ท่านรู้สึกอย่างไรเมื่อเปรียบเทียบกับก่อนที่จะหลับ",
  Q6: "ขณะหลับ ท่านมีอาการอ่อนแรงขยับแขนขาไม่ได้เป็นพัก ๆ และ/หรือได้ยินเสียงแว่วหรือเห็นภาพหลอนหรือไม่",
  Q6_1: "ถ้าใช่กรุณาอธิบาย:",
  Q7: "เมื่อเปรียบเทียบกับการนอนปกติที่บ้าน ท่านคิดว่าท่านรู้สึก",
  Q8: "กรณีท่านได้ทดสอบการใส่เครื่องอัดอากาศแรงดันบวก ท่านรู้สึกถึงการหายใจของท่าน",
  Q8_1: "คอแห้ง / เจ็บคอ",
  Q8_2: "ปากแห้ง",
  Q8_3: "ปัญหาที่หน้ากาก",
  Q8_4: "ปัญหาที่สายรัดคาง",
  Q8_5: "ลมรั่วรบกวนการนอน",
  Q8_6: "จมูกแห้ง / แสบจมูก",
  Q8_7: "คัดจมูก",
  Q8_8: "อึดอัดหายใจไม่ออก",
  Q8_9: "กลัวที่แคบ",
  Q8_10: "แสบตา",
  Q8_11: "อื่น ๆ",
  Q8_11_note: "",
};

const postQuestionnairesData = {
  Q1: "",
  Q2: "",
  Q3: "",
  Q4: "",
  Q5: "",
  Q6: "",
  Q6_1: "",
  Q7: "",
  Q8: "",
  Q8_1: "",
  Q8_2: "",
  Q8_3: "",
  Q8_4: "",
  Q8_5: "",
  Q8_6: "",
  Q8_7: "",
  Q8_8: "",
  Q8_9: "",
  Q8_10: "",
  Q8_11: "",
  Q8_11_note: "",
};

const postQuestionnaires = {
  assign: false,
  formname: postQuestionnairesFormTitle,
  id: "9",
  info: postQuestionnairesData,
  submit: false,
};

const postQuestionnairesKeys = Object.keys(postQuestionnairesData);

export {
  postQuestionnairesQuestions,
  postQuestionnairesData,
  postQuestionnaires,
  postQuestionnairesKeys,
};
