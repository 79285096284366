// Files Reducer

const filesReducerDefaultState = [];

export default (state = filesReducerDefaultState, action) => {
  switch (action.type) {
    case "ADD_FILE":
      return [...state, action.file];
    case "REMOVE_FILE":
      return state.filter(({ id }) => id !== action.id);
    case "SET_FILE":
      return action.files;
    default:
      return state;
  }
};
