import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "../PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { connectStorageEmulator } from "firebase/storage";
import { Checkbox } from "@material-ui/core";
import profileData from "../formData/profileData";

const infoData = {
  position: "",
  snore: "",
  snoreLevel: "",
  dream: "",
  muscleWeakness: "",
  hallucination: "",
  apnea: "",
  nocturia: "",
  headache: "",
  dryThroat: "",
  daydream: "",
  daydreamTalk: "",
  daydreamWalk: "",
  daydreamScream: "",
  daydreamEat: "",
  daydreamSex: "",
  daydreamOther: "",
  daydreamOtherNote: "",
  daydreamLikeDream: "",
  daydreamLikePunch: "",
  daydreamLikeOther: "",
  daydreamLikeOtherNote: "",
  spasm: "",
  spasmNeck: "",
  spasmArm: "",
  spasmLeg: "",
  spasmBody: "",
  spasmFace: "",
  spasmOther: "",
  spasmOtherNote: "",
  bruxism: "",
};

const NightTimeHabitData = {
  assign: false,
  formname: "ข้อมูลพฤติกรรมตอนกลางคืน",
  id: "4",
  info: infoData,
  submit: false,
};
const NightTimeHabit = () => {
  // const profileRef = useRef(profileData);
  const nightTimeHabitRef = useRef(infoData);
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [nightTimeHabitDoc, setNightTimeHabitDoc] =
    useState(NightTimeHabitData);
  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();
  const DropdownBox = ({
    title = "",
    htmlFor = "",
    id = "",
    name = "",
    refvalue,
    placeholder = "Please select",
    options = [""],
    defaultValue,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-lg-5 col-form-label" htmlFor={htmlFor}>
          {title}
          <span className="text-danger"></span>
        </label>
        <div className="col-lg-6">
          <select
            className="form-control"
            id={id}
            name={name}
            ref={refvalue}
            defaultValue={defaultValue}
          >
            {options.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const InputBox = ({
    title = "",
    value = "",
    placeholder = "",
    id = "",
    measure = "",
    refValue,
    noBox = false,
    defaultValue,
    bold = false,
    required
  }) => {
    return (
      <div className="form-group row">
        {!bold ? (
          <label className="col-xl-5 col-form-label mt-2">{title}</label>
        ) : (
          <label className="col-xl-6 col-form-label mt-2">
            <b>{title}</b>
          </label>
        )}
        <div className="col-xl-4">
          {noBox == false && (
            <input
              type="text"
              className="form-control"
              placeholder={placeholder == "" ? title : placeholder}
              id={id}
              ref={refValue}
              required={required}
              defaultValue={defaultValue}
            />
          )}
        </div>
        <div className="ml-3 mt-3">
          <text>{measure}</text>
        </div>
      </div>
    );
  };
  const InputArea = ({
    title = "",
    value = "",
    placeholder = "",
    id = "",
    measure = "",
    refValue,
    noBox = false,
    defaultValue,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-5 col-form-label mt-2">{title}</label>
        <div className="col-xl-6">
          {noBox == false && (
            <textarea
              className="form-control"
              id={id}
              name="val-suggestions"
              rows="4"
              placeholder={placeholder}
              ref={refValue}
              defaultValue={defaultValue}
            ></textarea>
          )}
        </div>
        <div className="ml-3 mt-3">
          <text>{measure}</text>
        </div>
      </div>
    );
  };

  const InputRadio = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
    required,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        required={required}
                        name={name}
                        value={value}
                        // id={id}
                        onChange={(e) => {
                          nightTimeHabitRef.current[refValue] = e.target.value;
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  nightTimeHabitRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };
  const InputCheckBox = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        value={value}
                        // id={id}
                        onChange={(e) => {
                          if (e.target.checked) {
                            nightTimeHabitRef.current[refValue] =
                              e.target.value;
                          } else {
                            nightTimeHabitRef.current[refValue] = "";
                          }
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  nightTimeHabitRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    var info = {
      position: nightTimeHabitRef.current["position"],
      snore: nightTimeHabitRef.current["snore"],
      snoreLevel: nightTimeHabitRef.current["snoreLevel"],
      dream: nightTimeHabitRef.current["dream"],
      muscleWeakness: nightTimeHabitRef.current["muscleWeakness"],
      hallucination: nightTimeHabitRef.current["hallucination"],
      apnea: nightTimeHabitRef.current["apnea"],
      nocturia: nightTimeHabitRef.current["nocturia"],
      headache: nightTimeHabitRef.current["headache"],
      dryThroat: nightTimeHabitRef.current["dryThroat"],
      daydream: nightTimeHabitRef.current["daydream"],
      daydreamTalk: nightTimeHabitRef.current["daydreamTalk"],
      daydreamWalk: nightTimeHabitRef.current["daydreamWalk"],
      daydreamScream: nightTimeHabitRef.current["daydreamScream"],
      daydreamEat: nightTimeHabitRef.current["daydreamEat"],
      daydreamSex: nightTimeHabitRef.current["daydreamSex"],
      daydreamOther: nightTimeHabitRef.current["daydreamOther"],
      daydreamOtherNote: nightTimeHabitRef.current["daydreamOtherNote"].value,
      daydreamLikeDream: nightTimeHabitRef.current["daydreamLikeDream"],
      daydreamLikePunch: nightTimeHabitRef.current["daydreamLikePunch"],
      daydreamLikeOther: nightTimeHabitRef.current["daydreamLikeOther"],
      daydreamLikeOtherNote:
        nightTimeHabitRef.current["daydreamLikeOtherNote"].value,
      spasm: nightTimeHabitRef.current["spasm"],
      spasmNeck: nightTimeHabitRef.current["spasmNeck"],
      spasmArm: nightTimeHabitRef.current["spasmArm"],
      spasmLeg: nightTimeHabitRef.current["spasmLeg"],
      spasmBody: nightTimeHabitRef.current["spasmBody"],
      spasmFace: nightTimeHabitRef.current["spasmFace"],
      spasmOther: nightTimeHabitRef.current["spasmOther"],
      spasmOtherNote: nightTimeHabitRef.current["spasmOtherNote"].value,
      bruxism: nightTimeHabitRef.current["bruxism"],
    };
    console.log(info);

    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };
  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      setNightTimeHabitDoc(docSnapForm.data());

      nightTimeHabitRef.current["position"] = docSnapForm.data().info.position;
      nightTimeHabitRef.current["snore"] = docSnapForm.data().info.snore;
      nightTimeHabitRef.current["snoreLevel"] =
        docSnapForm.data().info.snoreLevel;
      nightTimeHabitRef.current["dream"] = docSnapForm.data().info.dream;
      nightTimeHabitRef.current["muscleWeakness"] =
        docSnapForm.data().info.muscleWeakness;
      nightTimeHabitRef.current["hallucination"] =
        docSnapForm.data().info.hallucination;
      nightTimeHabitRef.current["apnea"] = docSnapForm.data().info.apnea;
      nightTimeHabitRef.current["nocturia"] = docSnapForm.data().info.nocturia;
      nightTimeHabitRef.current["headache"] = docSnapForm.data().info.headache;
      nightTimeHabitRef.current["dryThroat"] =
        docSnapForm.data().info.dryThroat;
      nightTimeHabitRef.current["daydream"] = docSnapForm.data().info.daydream;
      nightTimeHabitRef.current["daydreamTalk"] =
        docSnapForm.data().info.daydreamTalk;
      nightTimeHabitRef.current["daydreamWalk"] =
        docSnapForm.data().info.daydreamWalk;
      nightTimeHabitRef.current["daydreamScream"] =
        docSnapForm.data().info.daydreamScream;
      nightTimeHabitRef.current["daydreamEat"] =
        docSnapForm.data().info.daydreamEat;
      nightTimeHabitRef.current["daydreamSex"] =
        docSnapForm.data().info.daydreamSex;
      nightTimeHabitRef.current["daydreamOther"] =
        docSnapForm.data().info.daydreamOther;
      nightTimeHabitRef.current["daydreamLikeDream"] =
        docSnapForm.data().info.daydreamLikeDream;
      nightTimeHabitRef.current["daydreamLikePunch"] =
        docSnapForm.data().info.daydreamLikePunch;
      nightTimeHabitRef.current["daydreamLikeOther"] =
        docSnapForm.data().info.daydreamLikeOther;
      nightTimeHabitRef.current["spasm"] = docSnapForm.data().info.spasm;
      nightTimeHabitRef.current["spasmNeck"] =
        docSnapForm.data().info.spasmNeck;
      nightTimeHabitRef.current["spasmArm"] = docSnapForm.data().info.spasmArm;
      nightTimeHabitRef.current["spasmLeg"] = docSnapForm.data().info.spasmLeg;
      nightTimeHabitRef.current["spasmBody"] =
        docSnapForm.data().info.spasmBody;
      nightTimeHabitRef.current["spasmFace"] =
        docSnapForm.data().info.spasmFace;
      nightTimeHabitRef.current["spasmOther"] =
        docSnapForm.data().info.spasmOther;
      nightTimeHabitRef.current["bruxism"] = docSnapForm.data().info.bruxism;
      setLoading(false);
      console.log("nightTimeHabitRef: ", nightTimeHabitRef);
      return;
    }

    const ref = await doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);
  console.log(nightTimeHabitDoc);

  return (
    <Fragment>
      <Toast />
      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>ข้อมูลพฤติกรรมตอนกลางคืน (Night time habit)</Card.Title>
          </Card.Header>
          <Card.Body>
            {!isLoading ? (
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-7">
                      <h4 className="mb-4">
                        <b>ข้อมูลพฤติกรรมตอนกลางคืน</b>
                      </h4>
                      <InputRadio
                        title="ปกติท่านนอนท่าไหนมากที่สุด"
                        required={true}
                        options={[
                          "หงาย",
                          "ตะแคงขวา",
                          "ตะแคงซ้าย",
                          "คว่ำ",
                          "นั่งนอน",
                        ]}
                        name="ปกติท่านนอนท่าไหนมากที่สุด"
                        checked={nightTimeHabitDoc.info.position}
                        refValue={"position"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านคิดว่าท่านกรนหรือไม่"
                        required={true}
                        options={["กรน", "ไม่กรน"]}
                        name="ท่านคิดว่าท่านกรนหรือไม่"
                        checked={nightTimeHabitDoc.info.snore}
                        refValue={"snore"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านคิดว่า (หรือคนอื่นบอกท่านว่า) เสียงกรนของท่านดังแค่ไหน"
                        required={true}
                        options={[
                          "เสียงดังเล็กน้อย ต้องเข้าไปใกล้ถึงจะได้ยิน",
                          "เสียงดังปานกลาง สามารถได้ยินลอดออกไปนอกห้อง ถ้าประตูห้องนอนเปิด",
                          "เสียงดังมาก สามารถได้ยินลอดออกไปนอกห้อง แม้แต่เวลาประตูห้องนอนปิด",
                        ]}
                        name="ท่านคิดว่า (หรือคนอื่นบอกท่านว่า) เสียงกรนของท่านดังแค่ไหน"
                        checked={nightTimeHabitDoc.info.snoreLevel}
                        refValue={"snoreLevel"}
                      ></InputRadio>
                      <InputRadio
                        title="โดยปกติท่านฝันหรือไม่"
                        required={true}
                        options={[
                          "เกือบทุกคืน",
                          "3-4 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อเดือน",
                          "แทบจะไม่เคยเลย หรือไม่เคยเลย",
                        ]}
                        name="โดยปกติท่านฝันหรือไม่"
                        checked={nightTimeHabitDoc.info.dream}
                        refValue={"dream"}
                      ></InputRadio>
                      <InputRadio
                        title="ขณะหลับ ท่านมีอาการอ่อนแรงขยับแขนขาไม่ได้เป็นพัก ๆ หรือไม่"
                        required={true}
                        options={[
                          "เกือบทุกคืน",
                          "3-4 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อเดือน",
                          "แทบจะไม่เคยเลย หรือไม่เคยเลย",
                        ]}
                        name="ขณะหลับ ท่านมีอาการอ่อนแรงขยับแขนขาไม่ได้เป็นพัก ๆ หรือไม่"
                        checked={nightTimeHabitDoc.info.muscleWeakness}
                        refValue={"muscleWeakness"}
                      ></InputRadio>
                      <InputRadio
                        title="ในช่วงใกล้จะหลับหรือใกล้จะตื่นนอน ท่านได้ยินเสียงแว่ว และ/หรือ เห็นภาพหลอนหรือไม่"
                        required={true}
                        options={[
                          "เกือบทุกคืน",
                          "3-4 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อเดือน",
                          "แทบจะไม่เคยเลย หรือไม่เคยเลย",
                        ]}
                        name="ในช่วงใกล้จะหลับหรือใกล้จะตื่นนอน ท่านได้ยินเสียงแว่ว และ/หรือ เห็นภาพหลอนหรือไม่"
                        checked={nightTimeHabitDoc.info.hallucination}
                        refValue={"hallucination"}
                      ></InputRadio>
                      <InputRadio
                        title="ในช่วงชีวิตของท่าน เคยมีคนบอกว่าท่านหยุดหายใจเป็นพัก ๆ ขณะหลับหรือไม่"
                        required={true}
                        options={[
                          "เกือบทุกคืน",
                          "3-4 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อเดือน",
                          "แทบจะไม่เคยเลย หรือไม่เคยเลย",
                        ]}
                        name="ในช่วงชีวิตของท่าน เคยมีคนบอกว่าท่านหยุดหายใจเป็นพัก ๆ ขณะหลับหรือไม่"
                        checked={nightTimeHabitDoc.info.apnea}
                        refValue={"apnea"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านมีอาการตื่นขึ้นมาปัสสาวะตอนกลางคืนหรือไม่"
                        required={true}
                        options={[
                          "เกือบทุกคืน",
                          "3-4 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อเดือน",
                          "แทบจะไม่เคยเลย หรือไม่เคยเลย",
                        ]}
                        name="ท่านมีอาการตื่นขึ้นมาปัสสาวะตอนกลางคืนหรือไม่"
                        checked={nightTimeHabitDoc.info.nocturia}
                        refValue={"nocturia"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านมีอาการปวดศีรษะหลังตื่นนอนตอนเช้าหรือไม่"
                        required={true}
                        options={[
                          "เกือบทุกคืน",
                          "3-4 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อเดือน",
                          "แทบจะไม่เคยเลย หรือไม่เคยเลย",
                        ]}
                        name="ท่านมีอาการปวดศีรษะหลังตื่นนอนตอนเช้าหรือไม่"
                        checked={nightTimeHabitDoc.info.headache}
                        refValue={"headache"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านมีอาการ ตื่นขึ้นมาแล้วรู้สึกว่าคอแห้งหรือไม่"
                        required={true}
                        options={[
                          "เกือบทุกคืน",
                          "3-4 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อเดือน",
                          "แทบจะไม่เคยเลย หรือไม่เคยเลย",
                        ]}
                        name="ท่านมีอาการ ตื่นขึ้นมาแล้วรู้สึกว่าคอแห้งหรือไม่"
                        checked={nightTimeHabitDoc.info.dryThroat}
                        refValue={"dryThroat"}
                      ></InputRadio>
                      <InputRadio
                        title="ขณะหลับ ท่านมีละเมอหรือไม่"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ขณะหลับ ท่านมีละเมอหรือไม่"
                        checked={nightTimeHabitDoc.info.daydream}
                        refValue={"daydream"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้ามี:
                        ท่านมีละเมอชนิดใดบ้าง (สามารถตอบได้มากกว่าหนึ่งข้อ) เกิดบ่อย
                        "
                        bold={true}
                        noBox={true}
                      ></InputBox>
                      <InputCheckBox
                        title=""
                        options={["ละเมอพูด"]}
                        name="ละเมอพูด"
                        checked={nightTimeHabitDoc.info.daydreamTalk}
                        refValue={"daydreamTalk"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["ละเมอเดิน"]}
                        name="ละเมอเดิน"
                        checked={nightTimeHabitDoc.info.daydreamWalk}
                        refValue={"daydreamWalk"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["ละเมอกรีดร้องหรือสับสน"]}
                        name="ละเมอกรีดร้องหรือสับสน"
                        checked={nightTimeHabitDoc.info.daydreamScream}
                        refValue={"daydreamScream"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["ละเมอกิน"]}
                        name="ละเมอกิน"
                        checked={nightTimeHabitDoc.info.daydreamEat}
                        refValue={"daydreamEat"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["ละเมอมีกิจกรรมทางเพศ"]}
                        name="ละเมอมีกิจกรรมทางเพศ"
                        checked={nightTimeHabitDoc.info.daydreamSex}
                        refValue={"daydreamSex"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["อื่นๆ"]}
                        name="ละเมออื่นๆ"
                        checked={nightTimeHabitDoc.info.daydreamOther}
                        refValue={"daydreamOther"}
                      ></InputCheckBox>
                      <InputBox
                        title=""
                        refValue={(el) =>
                          (nightTimeHabitRef.current["daydreamOtherNote"] = el)
                        }
                        defaultValue={nightTimeHabitDoc.info.daydreamOtherNote}
                      ></InputBox>
                      <InputRadio
                        title="ลักษณะการละเมอของท่านมีพฤติกรรมเหมือนที่ท่านฝันหรือไม่ (เช่น ฝันเตะต่อย แล้วท่านละเมอเตะต่อย เป็นต้น)"
                        options={["มี", "ไม่มี"]}
                        required={true}
                        name="ลักษณะการละเมอของท่านมีพฤติกรรมเหมือนที่ท่านฝันหรือไม่ (เช่น ฝันเตะต่อย แล้วท่านละเมอเตะต่อย เป็นต้น)"
                        checked={nightTimeHabitDoc.info.daydreamLikeDream}
                        refValue={"daydreamLikeDream"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้ามี:
                        กรุณาระบุรายละเอียดของการละเมอ (ให้เลือก สามารถเลือกได้มากกว่าหนึ่งข้อ)
                        "
                        bold={true}
                        noBox={true}
                      ></InputBox>
                      <InputCheckBox
                        title=""
                        options={["เตะต่อย"]}
                        name="เตะต่อยอื่นๆ"
                        checked={nightTimeHabitDoc.info.daydreamLikePunch}
                        refValue={"daydreamLikePunch"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["อื่น ๆ"]}
                        name="อื่น ๆ 2"
                        checked={nightTimeHabitDoc.info.daydreamLikeOther}
                        refValue={"daydreamLikeOther"}
                      ></InputCheckBox>
                      <InputBox
                        title=""
                        refValue={(el) =>
                          (nightTimeHabitRef.current["daydreamLikeOtherNote"] =
                            el)
                        }
                        defaultValue={
                          nightTimeHabitDoc.info.daydreamLikeOtherNote
                        }
                      ></InputBox>
                      <InputRadio
                        title="ท่านมีอวัยวะใดกระตุกขณะหลับหรือไม่"
                        options={["มี", "ไม่มี"]}
                        required={true}
                        name="ท่านมีอวัยวะใดกระตุกขณะหลับหรือไม่"
                        checked={nightTimeHabitDoc.info.spasm}
                        refValue={"spasm"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้ามี:
                        ท่านมีอวัยวะใดกระตุกขณะหลับบ้าง (สามารถตอบได้มากกว่าหนึ่งข้อ) เกิดบ่อยแค่ไหน                        
                        "
                        bold={true}
                        noBox={true}
                      ></InputBox>
                      <InputCheckBox
                        title=""
                        options={["คอ"]}
                        name="คอกระตุก"
                        checked={nightTimeHabitDoc.info.spasmNeck}
                        refValue={"spasmNeck"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["แขน"]}
                        name="แขนกระตุก"
                        checked={nightTimeHabitDoc.info.spasmArm}
                        refValue={"spasmArm"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["ขา"]}
                        name="ขากระตุก"
                        checked={nightTimeHabitDoc.info.spasmLeg}
                        refValue={"spasmLeg"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["ลำตัว"]}
                        name="ลำตัวกระตุก"
                        checked={nightTimeHabitDoc.info.spasmBody}
                        refValue={"spasmBody"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["กล้ามเนื้อใบหน้า"]}
                        name="กล้ามเนื้อใบหน้ากระตุก"
                        checked={nightTimeHabitDoc.info.spasmFace}
                        refValue={"spasmFace"}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={["อื่นๆ"]}
                        name="กล้ามเนื้ออื่นๆกระตุก"
                        checked={nightTimeHabitDoc.info.spasmOther}
                        refValue={"spasmOther"}
                      ></InputCheckBox>
                      <InputBox
                        title=""
                        refValue={(el) =>
                          (nightTimeHabitRef.current["spasmOtherNote"] = el)
                        }
                        defaultValue={nightTimeHabitDoc.info.spasmOtherNote}
                      ></InputBox>
                      <InputRadio
                        title="ท่านมีอาการกัดฟันขณะหลับหรือไม่"
                        required={true}
                        options={[
                          "เกือบทุกคืน",
                          "3-4 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อสัปดาห์",
                          "1-2 ครั้งต่อเดือน",
                          "แทบจะไม่เคยเลย หรือไม่เคยเลย",
                        ]}
                        name="ท่านมีอาการกัดฟันขณะหลับหรือไม่"
                        checked={nightTimeHabitDoc.info.bruxism}
                        refValue={"bruxism"}
                      ></InputRadio>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        บันทึกข้อมูล
                      </button>
                      <button
                        onClick={() => {
                          history.push(`/patient-page/${id}`);
                        }}
                        type="submit"
                        className="btn btn-light ml-2"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default NightTimeHabit;
