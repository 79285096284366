import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Dropdown, Modal, Badge } from "react-bootstrap";
import { useAuth } from "../../../../context/AuthContext";

const ProductFileUploadForm = (props) => {
  const [createModal, setCreateModal] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFilenames] = useState([]);
  const { currentUser } = useAuth();

  const onFilesChange = (e) => {
    let files = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const f = e.target.files[i];
      files.push(f);
      setSelectedFiles((prevState) => [...prevState, f]);
      setFilenames((prevState) => [...prevState, f.name]);
    }
    props.onSubmit(files);
    setSelectedFiles([]);
    setFilenames([]);
  };

  return (
    <>
      <div className="col-xl-9 col-lg-12 mb-5">
        <b>{props.text ? props.text : "Add File"}</b>

        <div className="basic-form custom_file_input">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">Upload</span>
            </div>
            <div className="custom-file">
              <input
                type="file"
                multiple
                className="custom-file-input"
                onChange={onFilesChange}
              />
              <label className="custom-file-label">{fileNames}</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductFileUploadForm;
