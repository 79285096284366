import React from "react";

export default ({
  name,
  label,
  type,
  value,
  initailValue,
  checked,
  onChange,
  options,
  disabled,
  required,
  onChecked,
}) => {
  return (
    <div className="form-group row m-1">
      <label label className="col-lg-4 col-form-label">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <div className="col-lg-6">
        {type === "select" ? (
          <select
            key={name}
            className="form-control"
            defaultValue={initailValue}
            onChange={onChange}
            disabled={disabled}
          >
            {!initailValue && <option value={null}>please-select</option>}
            {options.map((opt) => (
              <option value={opt}>{opt}</option>
            ))}
          </select>
        ) : type === "text" || type === "date" ? (
          <input
            key={name}
            className="form-control"
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            defaultValue={initailValue}
            disabled={disabled}
            required={required}
          />
        ) : type === "textarea" ? (
          <textarea
            key={name}
            className="form-control"
            type={type}
            name={name}
            value={value}
            rows={"3"}
            onChange={onChange}
            defaultValue={initailValue}
            disabled={disabled}
            required={required}
          />
        ) : type === "checkbox" ? (
          <div className="form-check">
            {options.map((item, idx) => (
              <label key={idx} className="form-check-label px-3">
                <input
                  type={type}
                  className="form-check-input"
                  onChange={(e) => onChecked(e, idx)}
                  defaultChecked={checked[idx]}
                  id={name}
                  name={name}
                  required={required}
                />
                {item.role}
              </label>
            ))}
          </div>
        ) : (
          {}
        )}
      </div>
    </div>
  );
};
