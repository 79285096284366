import React, { Fragment, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { notifyTopRight, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { db } from "../../../firebase";
import { setDoc, doc } from "firebase/firestore";
import { NodeForm } from "./NodeForm";

const NodeRegister = (props) => {
  const inputRef = useRef({
    nodename: "",
    nodecode: "",
  });

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };

  const handleOnHideModal = () => {
    props.hide();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ref = doc(db, "nodes", inputRef.current.nodename);
    const formData = {
      nodename: inputRef.current.nodename,
      nodecode: inputRef.current.nodecode,
    };
    await setDoc(ref, formData)
      .then(() => {
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      })
      .catch((err) => {
        notifyError("บันทึกข้อมูลผิดพลาด ", err);
      });
    props.hide();
    props.item({ nodename: inputRef.current.nodename, ...formData });

    console.log(formData);
  };

  return (
    <Fragment>
      <Toast />
      <Modal
        className="fade bd-example-modal-lg"
        show={props.show}
        onHide={handleOnHideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Add new node</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NodeForm onChange={handleChange} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>
          <Button
            className="sm ml-2"
            variant="light"
            onClick={handleOnHideModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default NodeRegister;
