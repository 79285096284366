import React, { Fragment, useRef } from "react";
import _ from "lodash";
import RegisterFields from "../Items/RegisterFields";
import formFields from "./node-formfields";

export const NodeForm = (props) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(formFields, ({ label, name, type, options, required }) => {
      return (
        <RegisterFields
          key={name}
          name={name}
          label={label}
          type={type}
          value={inputRef.current[name]}
          options={options}
          required={required}
          onChange={props.onChange(name)}
        />
      );
    });
  };

  return <Fragment>{renderFields()}</Fragment>;
};
