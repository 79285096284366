export default [
  {
    label: "Node",
    name: "node",
    type: "text",
    required: true,
  },
  {
    label: "Tech ID",
    name: "techId",
    type: "text",
    required: true,
  },
  {
    label: "Name",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Full Name",
    name: "fullname",
    type: "text",
  },
  {
    label: "Email",
    name: "email",
    type: "text",
  },
  {
    label: "Tel",
    name: "tel",
    type: "text",
  },
  {
    label: "Token",
    name: "accessToken",
    type: "text",
  },
  {
    label: "Role",
    name: "roles",
    type: "checkbox",
    options: [{ role: "Record" }, { role: "Score" }],
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: ["Available", "Unavailable"],
  },
  {
    label: "Certificate",
    name: "certificate",
    type: "select",
    options: ["Trainee", "Basic", "Advance"],
  },
];
