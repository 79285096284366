import React, { Fragment, useState, useEffect } from "react";

const DropdownBox = ({
  title = "",
  htmlFor = "",
  id = "",
  name = "",
  refvalue,
  placeholder = "Please select",
  options = [""],
  defaultValue,
  classStr = "col-lg-6",
  required,
  onChange,
}) => {
  return (
    <div className="form-group row">
      <label className="col-lg-5 col-form-label" htmlFor={htmlFor}>
        {title}
        <span className="text-danger"></span>
      </label>
      <div className={classStr}>
        <select
          className="form-control"
          id={id}
          name={name}
          ref={refvalue}
          required={required}
          defaultValue={defaultValue}
          onChange={onChange}
        >
          {options.map((value) => (
            <option value={value}>{value}</option>
          ))}
        </select>
      </div>
    </div>
  );
};
const InputBox = ({
  title = "",
  value = "",
  placeholder = "",
  id = "",
  measure = "",
  refValue,
  noBox = false,
  bold = false,
  defaultValue,
  disabled = false,
  classStr = "col-xl-6",
  classTitle = "col-xl-5 col-form-label mt-2",
  required
}) => {
  return (
    <div className="form-group row">
      {!bold ? (
        <label className={classTitle}>{title}</label>
      ) : (
        <b className={classTitle}>{title}</b>
      )}
      <div className={classStr}>
        {noBox == false && (
          <input
            type="text"
            className="form-control"
            required={required}
            placeholder={placeholder == "" ? title : placeholder}
            id={id}
            ref={refValue}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        )}
      </div>
      <div className="ml-3 mt-3">
        <text>{measure}</text>
      </div>
    </div>
  );
};
const InputArea = ({
  title = "",
  value = "",
  placeholder = "",
  id = "",
  measure = "",
  refValue,
  noBox = false,
  defaultValue,
  classStr = "col-xl-6",
  required
}) => {
  return (
    <div className="form-group row">
      <label className="col-xl-5 col-form-label mt-2">{title}</label>
      <div className={classStr}>
        {noBox == false && (
          <textarea
            className="form-control"
            id={id}
            required={required}
            name="val-suggestions"
            rows="4"
            placeholder={placeholder}
            ref={refValue}
            defaultValue={defaultValue}
          ></textarea>
        )}
      </div>
      <div className="ml-3 mt-3">
        <text>{measure}</text>
      </div>
    </div>
  );
};

const InputRadio = ({
  title = "",
  id = "",
  options = [""],
  checked = "",
  name = "",
  isnote = false,
  note = "",
  mt = "row mt-3",
  refValue,
  refNote,
  ref,
  required
}) => {
  return (
    <fieldset className="form-group">
      <div className={mt}>
        <label className="col-xl-5 col-form-label pt-0">{title}</label>
        <div className="col-xl-6">
          {options.map((value) => {
            return (
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    required={required}
                    name={name}
                    value={value}
                    // id={id}
                    onChange={(e) => {
                      ref.current[refValue] = e.target.value;
                    }}
                    defaultChecked={checked == value ? "checked" : ""}
                  />
                  {value}
                </label>
              </div>
            );
          })}
        </div>
        {isnote && (
          <div className="form-check">
            {" "}
            <textarea
              className="form-control"
              id="val-suggestions"
              name="val-suggestions"
              rows="2"
              placeholder=""
              ref={(e) => {
                ref.current[refNote] = e;
              }}
              defaultValue={note}
            ></textarea>
          </div>
        )}
      </div>
    </fieldset>
  );
};
const InputCheckBox = ({
  title = "",
  id = "",
  options = [""],
  checked = "",
  name = "",
  isnote = false,
  note = "",
  mt = "row mt-3",
  refValue,
  refNote,
  required,
  ref,
}) => {
  return (
    <fieldset className="form-group">
      <div className={mt}>
        <label className="col-xl-5 col-form-label pt-0">{title}</label>
        <div className="col-xl-6">
          {options.map((value) => {
            return (
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name={name}
                    value={value}
                    required={required}
                    // id={id}
                    onChange={(e) => {
                      ref.current[refValue] = e.target.value;
                    }}
                    defaultChecked={checked == value ? "checked" : ""}
                  />
                  {value}
                </label>
              </div>
            );
          })}
        </div>
        {isnote && (
          <div className="form-check">
            {" "}
            <textarea
              className="form-control"
              id="val-suggestions"
              name="val-suggestions"
              rows="2"
              placeholder=""
              ref={(e) => {
                ref.current[refNote] = e;
              }}
              defaultValue={note}
            ></textarea>
          </div>
        )}
      </div>
    </fieldset>
  );
};
export { InputArea, InputBox, DropdownBox,InputCheckBox,InputRadio };
