import React, { Fragment, useState, useRef } from "react";
import _ from "lodash";
import RegisterFields from "../RegisterFields";
import SaleFormFields from "./saleFormFields";

export const SaleEditForm = ({ onChange, initailValues }) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(SaleFormFields, ({ label, name, type, options, required }) => {
      return (
        <RegisterFields
          name={name}
          label={label}
          type={type}
          value={inputRef.current.name}
          options={options}
          required={required}
          initailValue={initailValues[name]}
          disabled={name === "id" ? true : false}
          onChange={onChange(name)}
        />
      );
    });
  };

  return <Fragment>{renderFields()}</Fragment>;
};
