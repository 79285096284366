export default [
  {
    label: "Node",
    name: "node",
    type: "text",
    required: true,
  },
  {
    label: "Sale ID",
    name: "saleId",
    type: "text",
    required: true,
  },
  {
    label: "Name",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Email",
    name: "email",
    type: "text",
  },
  // {
  //   label: "Role",
  //   name: "role",
  //   type: "select",
  //   options: ["Record", "Score"],
  // },
  // {
  //   label: "Status",
  //   name: "status",
  //   type: "select",
  //   options: ["Available", "Unavailable"],
  // },
];
