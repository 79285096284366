import React, { Fragment, useState, useRef } from "react";
import _ from "lodash";
import RegisterFields from "../RegisterFields";
import doctorFormFields from "./doctorFormFields";

export const DoctorForm = (props) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(
      doctorFormFields,
      ({ label, name, type, options, required }) => {
        return (
          <RegisterFields
            name={name}
            label={label}
            type={type}
            value={inputRef.current.name}
            options={options}
            required={required}
            onChange={props.onChange(name)}
          />
        );
      }
    );
  };

  return <Fragment>{renderFields()}</Fragment>;
};
