// Cpaps Reducer

const cpapsReducerDefaultState = [
  // {
  //   id: "12156487513154",
  //   serialNumber: "23455345",
  //   brand: "apex",
  //   model: "xxs",
  //   type: "lm",
  //   vender: "abc",
  //   deviceNo: "45",
  //   lotNo: "76tr54",
  //   status: "ว่าง",
  //   incomingDate: new Date("2023-06-01T00:00:00"),
  //   createdAt: new Date("2023-06-01T00:00:00"),
  // },
];

export default (state = cpapsReducerDefaultState, action) => {
  switch (action.type) {
    case "ADD_CPAP":
      return [...state, action.cpap];
    case "REMOVE_CPAP":
      return state.filter(({ id }) => id !== action.id);
    case "EDIT_CPAP":
      return state.map((cpap) => {
        if (cpap.id === action.id) {
          return {
            ...cpap,
            ...action.updates,
          };
        } else {
          return cpap;
        }
      });
    case "SET_CPAPS":
      return action.cpaps;
    default:
      return state;
  }
};
