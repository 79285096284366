import React, { useState } from "react";
import { Modal, Button, Form, ListGroup } from "react-bootstrap";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";

const EditCustomerModal = ({ show, onHide, customer, nodeId, onUpdate }) => {
  const [customerNameTH, setCustomerNameTH] = useState(customer.CustomerNameTH);
  const [customerNameEN, setCustomerNameEN] = useState(customer.CustomerNameEN || "");
  const [emailList, setEmailList] = useState(customer.emailList || []);
  const [newEmail, setNewEmail] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editEmail, setEditEmail] = useState("");

  const handleSave = async () => {
    const customerRef = doc(db, "nodes", nodeId, "customers", customer.id);
    const updatedData = {
      CustomerNameTH: customerNameTH,
      CustomerNameEN: customerNameEN,
      emailList, // Include the updated email list
    };

    await updateDoc(customerRef, updatedData);
    onUpdate(customer.id, updatedData);
    onHide();
  };

  const handleAddEmail = () => {
    if (newEmail && !emailList.includes(newEmail)) {
      setEmailList([...emailList, newEmail]);
      setNewEmail("");
    }
  };

  const handleEditEmail = () => {
    if (editIndex !== null && editEmail) {
      const updatedEmailList = emailList.map((email, index) =>
        index === editIndex ? editEmail : email
      );
      setEmailList(updatedEmailList);
      setEditIndex(null);
      setEditEmail("");
    }
  };

  const handleDeleteEmail = (index) => {
    const updatedEmailList = emailList.filter((_, i) => i !== index);
    setEmailList(updatedEmailList);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Customer Name (TH)</Form.Label>
            <Form.Control
              type="text"
              value={customerNameTH}
              onChange={(e) => setCustomerNameTH(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Customer Name (EN)</Form.Label>
            <Form.Control
              type="text"
              value={customerNameEN}
              onChange={(e) => setCustomerNameEN(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email List</Form.Label>
            <ListGroup>
              {emailList.map((email, index) => (
                <ListGroup.Item key={index}>
                  {editIndex === index ? (
                    <div className="d-flex">
                      <Form.Control
                        type="text"
                        value={editEmail}
                        onChange={(e) => setEditEmail(e.target.value)}
                      />
                      <Button
                        variant="success"
                        size="sm"
                        onClick={handleEditEmail}
                        className="ml-2"
                      >
                        Save
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => setEditIndex(null)}
                        className="ml-2"
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      <span>{email}</span>
                      <div>
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => {
                            setEditIndex(index);
                            setEditEmail(email);
                          }}
                          className="ml-2"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => handleDeleteEmail(index)}
                          className="ml-2"
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <div className="d-flex mt-2">
              <Form.Control
                type="text"
                placeholder="Add new email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
              <Button variant="primary" onClick={handleAddEmail} className="ml-2">
                Add
              </Button>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCustomerModal;
