import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "../PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { connectStorageEmulator } from "firebase/storage";

const infoData = {
  nap: "",
  firstNap: "",
  secondNap: "",
  thirdNap: "",
  AverageNap: "",
  alc: "",
  alcbrand: "",
  tea: "",
  teabrand: "",
  coffee: "",
  coffeebrand: "",
  soda: "",
  sodabrand: "",
  worry: "",
  worryNote: "",
  tired: "",
  sleepy: "",
  awake: "",
};
const profileData = {
  username: "",
  email: "",
  titleTh: "",
  firstnameTh: "",
  lastnameTh: "",
  titleEn: "",
  firstnameEn: "",
  lastnameEn: "",
  dateOfBirth: "",
  status: "",
  ethnicity: "",
  nationality: "",
  religion: "",
  bloodType: "",
  note: "",
  gender: "",
  occupation: "",
  mobile: "",
  telephone: "",
  address1: "",
  address2: "",
  province: "",
  postcode: "",
  medicineIntolerance: "",
  healthInsurance: "",
  other: "",
  emergencyContact: "",
  relationship: "",
  emergencyContactPhone: "",
};
const preSleepInfoData = {
  assign: false,
  formname: "ข้อมูลก่อนการเริ่มตรวจการนอนหลับ",
  id: "2",
  info: infoData,
  submit: false,
};
const PreSleepInformation = () => {
  // const profileRef = useRef(profileData);
  const preSleepInfoRef = useRef(infoData);
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [preSleepInfoDoc, setPreSleepInfoDoc] = useState(preSleepInfoData);
  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();
  const DropdownBox = ({
    title = "",
    htmlFor = "",
    id = "",
    name = "",
    refvalue,
    placeholder = "Please select",
    options = [""],
    defaultValue,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-lg-4 col-form-label" htmlFor={htmlFor}>
          {title}
          <span className="text-danger"></span>
        </label>
        <div className="col-lg-4">
          <select
            className="form-control"
            id={id}
            name={name}
            ref={refvalue}
            defaultValue={defaultValue}
          >
            {options.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const InputBox = ({
    title = "",
    value = "",
    placeholder = "",
    id = "",
    measure = "",
    refValue,
    noBox = false,
    defaultValue,
    bold = false,
    required,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-5 col-form-label mt-2">
          {bold ? <b>{title}</b> : <>{title}</>}
        </label>
        <div className="col-xl-6">
          {noBox == false && (
            <input
              type="text"
              required={required}
              className="form-control"
              placeholder={placeholder == "" ? title : placeholder}
              id={id}
              ref={refValue}
              defaultValue={defaultValue}
            />
          )}
        </div>
        <div className="ml-3 mt-3">
          <text>{measure}</text>
        </div>
      </div>
    );
  };
  const InputArea = ({
    title = "",
    value = "",
    placeholder = "",
    id = "",
    measure = "",
    refValue,
    noBox = false,
    defaultValue,
    required,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-5 col-form-label mt-2">{title}</label>
        <div className="col-xl-6">
          {noBox == false && (
            <textarea
              className="form-control"
              id={id}
              required={required}
              name="val-suggestions"
              rows="4"
              placeholder={placeholder}
              ref={refValue}
              defaultValue={defaultValue}
            ></textarea>
          )}
        </div>
        <div className="ml-3 mt-3">
          <text>{measure}</text>
        </div>
      </div>
    );
  };

  const InputRadio = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    required,
    refNote,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        required={required}
                        name={name}
                        value={value}
                        // id={id}
                        onChange={(e) => {
                          preSleepInfoRef.current[refValue] = e.target.value;
                          console.log(preSleepInfoRef.current[refValue]);
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  preSleepInfoRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };
  const InputCheckBox = ({
    title = "",
    id = "",
    options = [""],
    checked = [""],
    name = "",
    refValue,
    mt = "row mt-3",
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-4 col-form-label pt-0">{title}</label>
          <div className="col-xl-2">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          // demographicRef.current[refValue] = e.target.value;
                          console.log(e.target.value);
                        }}
                        defaultChecked={
                          checked.includes(value) ? "checked" : ""
                        }
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </fieldset>
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    var info = {
      nap: preSleepInfoRef.current["nap"],
      firstNap: preSleepInfoRef.current["firstNap"].value,
      secondNap: preSleepInfoRef.current["secondNap"].value,
      thirdNap: preSleepInfoRef.current["thirdNap"].value,
      AverageNap: preSleepInfoRef.current["AverageNap"].value,
      alc: preSleepInfoRef.current["alc"],
      alcbrand: "",
      tea: preSleepInfoRef.current["tea"],
      teabrand: "",
      coffee: preSleepInfoRef.current["coffee"],
      coffeebrand: "",
      soda: preSleepInfoRef.current["soda"],
      sodabrand: preSleepInfoRef.current["sodabrand"].value,
      worry: preSleepInfoRef.current["worry"],
      worryNote: preSleepInfoRef.current["worryNote"].value,
      tired: preSleepInfoRef.current["tired"],
      sleepy: preSleepInfoRef.current["sleepy"],
      awake: preSleepInfoRef.current["awake"],
    };

    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };
  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      setPreSleepInfoDoc(docSnapForm.data());

      preSleepInfoRef.current["nap"] = docSnapForm.data().info.nap;
      preSleepInfoRef.current["alc"] = docSnapForm.data().info.alc;
      preSleepInfoRef.current["tea"] = docSnapForm.data().info.tea;
      preSleepInfoRef.current["coffee"] = docSnapForm.data().info.coffee;
      preSleepInfoRef.current["soda"] = docSnapForm.data().info.soda;
      preSleepInfoRef.current["worry"] = docSnapForm.data().info.worry;
      preSleepInfoRef.current["tired"] = docSnapForm.data().info.tired;
      preSleepInfoRef.current["sleepy"] = docSnapForm.data().info.sleepy;
      preSleepInfoRef.current["awake"] = docSnapForm.data().info.awake;

      setLoading(false);
      console.log("preSleepInfoRef: ", preSleepInfoRef);
      return;
    }

    const ref = await doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);
  console.log(preSleepInfoDoc);

  return (
    <Fragment>
      <Toast />
      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>
              ข้อมูลก่อนการเริ่มตรวจการนอนหลับ (Pre-sleep information)
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {!isLoading ? (
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-7">
                      <h4 className="mb-4">
                        <b>ข้อมูลก่อนการเริ่มตรวจการนอนหลับ</b>
                      </h4>
                      <InputRadio
                        title="วันนี้ท่านได้นอนงีบหลังจากตื่นนอนตอนเช้านี้ใช่หรือไม่"
                        options={["ใช่", "ไม่ใช่"]}
                        required={true}
                        name="วันนี้ท่านได้นอนงีบหลังจากตื่นนอนตอนเช้านี้ใช่หรือไม่"
                        checked={preSleepInfoDoc.info.nap}
                        refValue={"nap"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้าใช่ เวลากี่โมงบ้าง"
                        noBox={true}
                      ></InputBox>
                      <InputBox
                        title="ครั้งแรก"
                        refValue={(el) =>
                          (preSleepInfoRef.current["firstNap"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.firstNap}
                      ></InputBox>
                      <InputBox
                        title="ครั้งที่สอง"
                        refValue={(el) =>
                          (preSleepInfoRef.current["secondNap"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.secondNap}
                      ></InputBox>
                      <InputBox
                        title="ครั้งที่ 3"
                        refValue={(el) =>
                          (preSleepInfoRef.current["thirdNap"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.thirdNap}
                      ></InputBox>
                      <InputBox
                        title="ท่านงีบเฉลี่ยครั้งละ"
                        measure="นาที"
                        refValue={(el) =>
                          (preSleepInfoRef.current["AverageNap"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.AverageNap}
                      ></InputBox>
                      <InputBox
                        title="ท่านได้ดื่มสิ่งเหล่านี้ในช่วง 24 ขั่วโมงที่ผ่านมาหรือไม่"
                        noBox={true}
                        bold={true}
                      ></InputBox>
                      <InputRadio
                        title="สุรา แอลกอฮอลล์"
                        required={true}
                        options={["ดื่ม", "ไม่ดื่ม"]}
                        name="สุรา แอลกอฮอลล์"
                        checked={preSleepInfoDoc.info.alc}
                        refValue={"alc"}
                      ></InputRadio>

                      <InputRadio
                        title="ชา"
                        required={true}
                        options={["ดื่ม", "ไม่ดื่ม"]}
                        name="ชา"
                        checked={preSleepInfoDoc.info.tea}
                        refValue={"tea"}
                      ></InputRadio>

                      <InputRadio
                        title="กาแฟ"
                        required={true}
                        options={["ดื่ม", "ไม่ดื่ม"]}
                        name="กาแฟ"
                        checked={preSleepInfoDoc.info.coffee}
                        refValue={"coffee"}
                      ></InputRadio>
                      <InputRadio
                        title="น้ำอัดลม"
                        required={true}
                        options={["ดื่ม", "ไม่ดื่ม"]}
                        name="น้ำอัดลม"
                        checked={preSleepInfoDoc.info.soda}
                        refValue={"soda"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้าดื่ม กรุณาระบุยี่ห้อ"
                        refValue={(el) =>
                          (preSleepInfoRef.current["sodabrand"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.sodabrand}
                      ></InputBox>
                      <InputRadio
                        title="ท่านได้มีความเครียด วิตกกังวล หรือความเศร้าเกี่ยวกับเรื่องใด ๆ ก็ตามในวันนี้หรือไม่"
                        options={["มี", "ไม่มี"]}
                        required={true}
                        name="ท่านได้มีความเครียด วิตกกังวล หรือความเศร้าเกี่ยวกับเรื่องใด ๆ ก็ตามในวันนี้หรือไม่"
                        checked={preSleepInfoDoc.info.worry}
                        refValue={"worry"}
                      ></InputRadio>
                      <InputArea
                        title="ถ้ามีความวิตกกังวลหรือความเศร้า ช่วยอธิบายรายละเอียด"
                        refValue={(el) =>
                          (preSleepInfoRef.current["worryNote"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.worryNote}
                      ></InputArea>
                      <InputBox
                        title="โปรดอธิบายความรู้สึกของท่านในขณะนี้"
                        bold={true}
                        noBox={true}
                      ></InputBox>
                      <InputRadio
                        title="ตอนนี้ท่านรู้สึกเหนื่อยมากแค่ไหน"
                        required={true}
                        options={[
                          "ไม่เลย",
                          "นิดหน่อย",
                          "มากพอสมควร",
                          "มากถึงที่สุด",
                        ]}
                        name="ตอนนี้ท่านรู้สึกเหนื่อยมากแค่ไหน"
                        checked={preSleepInfoDoc.info.tired}
                        refValue={"tired"}
                      ></InputRadio>
                      <InputRadio
                        title="ตอนนี้ท่านรู้สึกง่วงมากแค่ไหน"
                        required={true}
                        options={[
                          "ไม่เลย",
                          "นิดหน่อย",
                          "มากพอสมควร",
                          "มากถึงที่สุด",
                        ]}
                        name="ตอนนี้ท่านรู้สึกง่วงมากแค่ไหน"
                        checked={preSleepInfoDoc.info.sleepy}
                        refValue={"sleepy"}
                      ></InputRadio>
                      <InputRadio
                        title="ตอนนี้ท่านรู้สึกตื่นมากแค่ไหน"
                        required={true}
                        options={[
                          "ไม่เลย",
                          "นิดหน่อย",
                          "มากพอสมควร",
                          "มากถึงที่สุด",
                        ]}
                        name="ตอนนี้ท่านรู้สึกตื่นมากแค่ไหน"
                        checked={preSleepInfoDoc.info.awake}
                        refValue={"awake"}
                      ></InputRadio>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        บันทึกข้อมูล
                      </button>
                      <button
                        onClick={() => {
                          history.push(`/patient-page/${id}`);
                        }}
                        type="submit"
                        className="btn btn-light ml-2"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default PreSleepInformation;
