import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Modal,
  Button,
  Dropdown,
  Table,
} from "react-bootstrap";

import { db } from "../../../firebase";
import Toast from "../toast/SetToast";

import {
  query,
  endAt,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  where,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { notifyError, notifyTopRight } from "../toast/SetToast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

const nodeList = ["BD"];
const typeList = [
  "CPAP Trial Follow Up",
  "CPAP Follow Up 1M",
  "CPAP Follow Up 3M",
  "CPAP Follow Up 6M",
  "CPAP Follow Up 1Y",
  "CPAP Cleaning",
  "CPAP Maintenance",
  "CPAP Repair",
  "CPAP Annual",
  "Cognitive Behavior Therapy",
  "Sell Products",
  "Others",
];
const statusList = [
  "Follow Up Schedule",
  "Connecting 1",
  "Connecting 2",
  "Connecting 3",
  "Report CPAP",
  "CPAP-Consult",
  "Sale Follow Up",
  "Ship",
  "Ship [CPAP Purchase]",
  "Cancel",
  "Failure",
];
const colorList = [
  // "#00CEAB",
  // light blue
  // "#F4B400",
  // // Google Yellow
  "#005DEA",
  // briht blue
  "#00CEAB",
  "#00CEAB",
  "#00CEAB",
  "#711685", //report CPAP
  "#F4B400", //CPAP consult
  "#FF99CC", //Sale Follow Up
  "#64a338",
  "#64a338",
  // ship green
  "#a8a8a8",
  // cancel gray
  "#dd0102",
  // failure red
];

const orderList = ["R1", "R2", "R3", "R4", "R5", "จองคิว"];
const paymentPrivilages = [
  "กรุณาเลือก",
  "ชำระเงินเอง",
  "ประกันสุขภาพถ้วนหน้า",
  "ประกันสังคม",
  "กรมบัญชีกลาง",
  "กรุงเทพมหานคร",
  "ต้นสังกัด",
  "นักศึกษา มธ.",
  "โครงการวิจัยรับค่าบริการล่วงหน้า",
  "องค์การปกครองส่วนท้องถิ่น",
  "โครงการตรวจสุขภาพรับค่าบริการล่วงหน้า",
  "ผ่อนชำระ/นิติกร",
  "อุบัติเหตุและฉุกเฉินนอกโรงพยาบาล(EMS)",
  "ปลัดกระทรวงสาธารณะสุข",
  "โครงการ 85 ปี",
  "อื่นๆ",
];
const eventTmp = {
  title: "",
  start: "",
  id: "",
  extendedProps: {
    order: "",
    hn: "",
    type: "",
    status: "",
    firstname: "",
    lastname: "",
    nationalId: "",
    healthInsurance: "กรุณาเลือก",
    phone: "",
    note: "",
    node: "",
    customer: "",
    items: [],
    totalValue: 0,
    time: "",
    technician: "",
    doctor: "",
  },
};
export const PatientDropDownForm = ({
  title = "",
  htmlFor = "",
  id = "",
  name = "",
  refvalue,
  placeholder = "Please select",
  selected = "",
  options = [""],
  onChange,
}) => {
  return (
    <div className="form-group row">
      <label className="col-lg-3 col-form-label" htmlFor={htmlFor}>
        {title}
        <span className="text-danger"></span>
      </label>
      <div className="col-lg-5">
        <select
          className="form-control"
          id={id}
          name={name}
          ref={refvalue}
          onChange={onChange}
        >
          {options.map((value) => (
            <option
              value={value}
              selected={selected == value ? "selected" : ""}
            >
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
export const PatientTextInfo = ({ title = "", value = "", bold = false }) => {
  return (
    <div className="form-group row">
      <label className="col-lg-3 col-form-label" htmlFor="val-username">
        {title}
      </label>
      <div className="col-lg-8">
        {bold ? (
          <>
            <b>{value}</b>
          </>
        ) : (
          <>
            <text>{value}</text>
          </>
        )}
      </div>
    </div>
  );
};
export const PatientLinkInfo = ({
  title = "",
  value = "",
  bold = false,
  path,
}) => {
  return (
    <div className="form-group row">
      <label className="col-lg-3 col-form-label" htmlFor="val-username">
        {title}
      </label>
      <div className="col-lg-8">
        {bold ? (
          <>
            <Link to={path}>
              <b>{value}</b>
            </Link>
          </>
        ) : (
          <>
            <Link to={path}>
              <text>{value}</text>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
export const PatientInputText = ({ title, refvalue, defaultValue }) => {
  return (
    <div className="form-group row">
      <label className="col-lg-3 col-form-label" htmlFor="val-username">
        {title}
      </label>

      <div className="col-lg-5">
        <input
          type="text"
          className="form-control"
          // id="val-hn"
          // name="val-hn"
          placeholder={title}
          ref={refvalue}
          defaultValue={defaultValue}
          // ref={emailRef}
          // value=""
        />
      </div>
    </div>
  );
};

export const PatientInputTextArea = ({ title, refvalue, defaultValue }) => {
  return (
    <div className="form-group row">
      <label className="col-lg-3 col-form-label" htmlFor="val-username">
        {title}
      </label>

      <div className="col-lg-7">
        <textarea
          className="form-control"
          rows="4"
          placeholder={title}
          ref={refvalue}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

const setEvent = async (eventAdd, oldEvent = undefined) => {
  console.log(eventAdd);
  try {
    if (
      eventAdd.extendedProps.uuid == undefined ||
      eventAdd.extendedProps.uuid == null
    ) {
      var color = undefined;
      if (eventAdd.color == undefined) {
        color = eventAdd.backgroundColor;
      } else {
        color = eventAdd.color;
      }
      const ref = doc(collection(db, "followup"));
      await setDoc(ref, {
        title: eventAdd.title,
        id: eventAdd.id,
        start: new Date(eventAdd.start),
        end: eventAdd.end,
        color: color,
        extendedProps: {
          order: eventAdd.extendedProps.order,
          hn: eventAdd.extendedProps.hn,
          type: eventAdd.extendedProps.type,
          status: eventAdd.extendedProps.status,
          firstname: eventAdd.extendedProps.firstname,
          lastname: eventAdd.extendedProps.lastname,
          // nationalId: eventAdd.extendedProps.nationalId,
          // healthInsurance: eventAdd.extendedProps.healthInsurance,
          phone: eventAdd.extendedProps.phone,
          // node: eventAdd.extendedProps.node,
          // customer: eventAdd.extendedProps.customer,
          note: eventAdd.extendedProps.note,
          items: eventAdd.extendedProps.items,
          totalValue: eventAdd.extendedProps.totalValue,
          time: eventAdd.extendedProps.time,
          doctor: eventAdd.extendedProps.doctor,
          technician: eventAdd.extendedProps.technician,
          createBy: eventAdd.extendedProps.createBy
        },
      });
      console.log("setDoc OK !");
      eventAdd.extendedProps.uuid = ref.id;
      const refUpdate = doc(db, "followup", eventAdd.extendedProps.uuid);

      await updateDoc(refUpdate, {
        title: eventAdd.title,
        id: eventAdd.id,
        start: new Date(eventAdd.start),
        color: color,
        extendedProps: {
          order: eventAdd.extendedProps.order,
          hn: eventAdd.extendedProps.hn,
          type: eventAdd.extendedProps.type,
          status: eventAdd.extendedProps.status,
          uuid: eventAdd.extendedProps.uuid,
          firstname: eventAdd.extendedProps.firstname,
          lastname: eventAdd.extendedProps.lastname,
          // nationalId: eventAdd.extendedProps.nationalId,
          // healthInsurance: eventAdd.extendedProps.healthInsurance,
          phone: eventAdd.extendedProps.phone,
          // node: eventAdd.extendedProps.node,
          // customer: eventAdd.extendedProps.customer,
          note: eventAdd.extendedProps.note,
          items: eventAdd.extendedProps.items,
          totalValue: eventAdd.extendedProps.totalValue,
          time: eventAdd.extendedProps.time,
          doctor: eventAdd.extendedProps.doctor,
          technician: eventAdd.extendedProps.technician,
          createBy: eventAdd.extendedProps.createBy
        },
      });
    } else {
      var color = undefined;
      if (eventAdd.color == undefined) {
        color = eventAdd.backgroundColor;
      } else {
        color = eventAdd.color;
      }
      const refUpdate = doc(db, "followup", eventAdd.extendedProps.uuid);

      await updateDoc(refUpdate, {
        title: eventAdd.title,
        id: eventAdd.id,
        start: new Date(eventAdd.start),
        color: color,
        extendedProps: {
          order: eventAdd.extendedProps.order,
          hn: eventAdd.extendedProps.hn,
          type: eventAdd.extendedProps.type,
          status: eventAdd.extendedProps.status,
          uuid: eventAdd.extendedProps.uuid,
          firstname: eventAdd.extendedProps.firstname,
          lastname: eventAdd.extendedProps.lastname,
          // nationalId: eventAdd.extendedProps.nationalId,
          // healthInsurance: eventAdd.extendedProps.healthInsurance,
          phone: eventAdd.extendedProps.phone,
          // node: eventAdd.extendedProps.node,
          // customer: eventAdd.extendedProps.customer,
          note: eventAdd.extendedProps.note,
          items: eventAdd.extendedProps.items,
          totalValue: eventAdd.extendedProps.totalValue,
          time: eventAdd.extendedProps.time,
          doctor: eventAdd.extendedProps.doctor,
          technician: eventAdd.extendedProps.technician,
          createBy: eventAdd.extendedProps.createBy
        },
      });
    }
    // notifyTopRight("Update booking success !");
    return "OK";
  } catch (err) {
    console.log(err);
    // notifyError("Please fill out patient information !");
    return "Err";
  }
};
export {
  setEvent,
  eventTmp,
  paymentPrivilages,
  orderList,
  colorList,
  statusList,
  typeList,
  nodeList,
};
