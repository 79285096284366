import { getApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);

// Get a Firebase app instance
const firebaseApp = getApp();

// Initialize Firestore with experimental settings
const db = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true,
});

// Initialize other services
const auth = getAuth();
const functions = getFunctions(undefined, "asia-southeast1");
const storage = getStorage(firebaseApp, "gs://braindynamics-197809");

export { db, auth, functions, storage };
