import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function PrivateRoute({
  component: Component,
  disableRoles: disableRoles,
  ...rest
}) {
  const { currentUser } = useAuth();
  // const currentRole = currentUser.roles[0];

  return (
    <Route
      {...rest}
      // render={(props) => {
      //   return currentUser ? (
      //     disableRoles ? (
      //       !disableRoles.includes(currentRole) ? (
      //         <Component {...props} />
      //       ) : (
      //         <></>
      //       )
      //     ) : (
      //       <Component {...props} />
      //     )
      //   ) : (
      //     <Redirect to={"/page-login"} />
      //     // )
      //   );
      // }}
      render={(props) => {
        return currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/page-login"} />
          // )
        );
      }}
    ></Route>
  );
}
