const profileData = {
  hn: "",
  email: "",
  titleTh: "",
  firstnameTh: "",
  lastnameTh: "",
  titleEn: "",
  firstnameEn: "",
  lastnameEn: "",
  dateOfBirth: "",
  status: "",
  ethnicity: "",
  nationality: "",
  religion: "",
  bloodType: "",
  note: "",
  gender: "",
  occupation: "",
  mobile: "",
  telephone: "",
  address1: "",
  address2: "",
  province: "",
  postcode: "",
  medicineIntolerance: "",
  healthInsurance: "",
  other: "",
  emergencyContact: "",
  relationship: "",
  emergencyContactPhone: "",
};

export default profileData;
