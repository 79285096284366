import React, { useEffect, useContext, useState } from "react";
import { db, auth, functions } from "../firebase";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import {
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  inMemoryPersistence,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updateEmail,
  updatePassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";

import { setDoc, doc } from "firebase/firestore";

export const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const addAdminRole = httpsCallable(functions, "addAdminRole");
  const addPatientRole = httpsCallable(functions, "addPatientRole");

  async function login(email, password, remember) {
    return await setPersistence(
      auth,
      remember ? browserLocalPersistence : browserSessionPersistence
    )
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        return error;
      });
  }

  async function logout() {
    return await signOut(auth)
      .then(() => {
        console.log("Logout Success");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateUserEmail(email) {
    return updateEmail(auth.currentUser, email);
  }

  function updateUserPassword(password) {
    return updatePassword(auth.currentUser, password);
  }

  function updateUserdisplayName(displayName) {
    return updateProfile(auth.currentUser, {
      displayName: displayName,
    });
  }

  function updateUserPhoto(photo) {
    return updateProfile(auth.currentUser, {
      photoURL: photo,
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        await user.getIdTokenResult().then((idTokenResult) => {
          user.roles = idTokenResult.claims.roles;
          setCurrentUser(user);
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);
        // return () => unsubscribe;
      }
    });
    return () => unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    updateUserEmail,
    updateUserPassword,
    updateUserdisplayName,
    updateUserPhoto,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
