import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "../PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import profileData from "../../Patient/formData/profileData";
import { InputBox, InputArea, DropdownBox } from "../formData/inputComponents";
import {
  postQuestionnaires,
  postQuestionnairesQuestions,
  postQuestionnairesData,
  postQuestionnairesKeys,
} from "../formData/postQuestionnairesData";
const PostQuestionnaires = () => {
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const postQuestionnairesRef = useRef(postQuestionnairesData);
  const [postQuestionnairesDoc, setPostQuestionnairesDoc] =
    useState(postQuestionnaires);
  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();
  const questions = postQuestionnairesQuestions;
  const keys = postQuestionnairesKeys;

  const InputRadio = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
    required,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={name}
                        value={value}
                        required={required}
                        // id={id}
                        onChange={(e) => {
                          postQuestionnairesRef.current[refValue] =
                            e.target.value;
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  postQuestionnairesRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };
  const InputCheckBox = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        value={value}
                        // id={id}
                        onChange={(e) => {
                          if (e.target.checked) {
                            postQuestionnairesRef.current[refValue] =
                              e.target.value;
                          } else {
                            postQuestionnairesRef.current[refValue] = "";
                          }
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </fieldset>
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    var info = {
      Q1: postQuestionnairesRef.current[keys[0]].value,
      Q2: postQuestionnairesRef.current[keys[1]].value,
      Q3: postQuestionnairesRef.current[keys[2]].value,
      Q4: postQuestionnairesRef.current[keys[3]],
      Q5: postQuestionnairesRef.current[keys[4]],
      Q6: postQuestionnairesRef.current[keys[5]],
      Q6_1: postQuestionnairesRef.current[keys[6]].value,
      Q7: postQuestionnairesRef.current[keys[7]],
      Q8: postQuestionnairesRef.current[keys[8]],
      Q8_1: postQuestionnairesRef.current[keys[9]],
      Q8_2: postQuestionnairesRef.current[keys[10]],
      Q8_3: postQuestionnairesRef.current[keys[11]],
      Q8_4: postQuestionnairesRef.current[keys[12]],
      Q8_5: postQuestionnairesRef.current[keys[13]],
      Q8_6: postQuestionnairesRef.current[keys[14]],
      Q8_7: postQuestionnairesRef.current[keys[15]],
      Q8_8: postQuestionnairesRef.current[keys[16]],
      Q8_9: postQuestionnairesRef.current[keys[17]],
      Q8_10: postQuestionnairesRef.current[keys[18]],
      Q8_11: postQuestionnairesRef.current[keys[19]],
      Q8_11_note: postQuestionnairesRef.current[keys[20]].value,
    };
    console.log(postQuestionnaires.formname);
    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };

  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      setPostQuestionnairesDoc(docSnapForm.data());
      setLoading(false);
      postQuestionnairesRef.current["Q4"] = postQuestionnairesDoc.info["Q4"];
      postQuestionnairesRef.current["Q5"] = postQuestionnairesDoc.info["Q5"];
      postQuestionnairesRef.current["Q6"] = postQuestionnairesDoc.info["Q6"];
      postQuestionnairesRef.current["Q7"] = postQuestionnairesDoc.info["Q7"];
      postQuestionnairesRef.current["Q8"] = postQuestionnairesDoc.info["Q8"];
      postQuestionnairesRef.current["Q8_1"] =
        postQuestionnairesDoc.info["Q8_1"];
      postQuestionnairesRef.current["Q8_2"] =
        postQuestionnairesDoc.info["Q8_2"];
      postQuestionnairesRef.current["Q8_3"] =
        postQuestionnairesDoc.info["Q8_3"];
      postQuestionnairesRef.current["Q8_4"] =
        postQuestionnairesDoc.info["Q8_4"];
      postQuestionnairesRef.current["Q8_5"] =
        postQuestionnairesDoc.info["Q8_5"];
      postQuestionnairesRef.current["Q8_6"] =
        postQuestionnairesDoc.info["Q8_6"];
      postQuestionnairesRef.current["Q8_7"] =
        postQuestionnairesDoc.info["Q8_7"];
      postQuestionnairesRef.current["Q8_8"] =
        postQuestionnairesDoc.info["Q8_8"];
      postQuestionnairesRef.current["Q8_9"] =
        postQuestionnairesDoc.info["Q8_9"];
      postQuestionnairesRef.current["Q8_10"] =
        postQuestionnairesDoc.info["Q8_10"];
      postQuestionnairesRef.current["Q8_11"] =
        postQuestionnairesDoc.info["Q8_11"];
      return;
    }

    const ref = await doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);
  console.log("postQuestionnairesDoc outside:", postQuestionnairesDoc);
  return (
    <Fragment>
      <Toast />
      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>{postQuestionnaires.formname}</Card.Title>
          </Card.Header>
          <Card.Body>
            {!isLoading ? (
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-7">
                      <InputBox
                        title={postQuestionnairesQuestions[keys[0]]}
                        id={postQuestionnairesQuestions[keys[0]]}
                        placeholder=""
                        refValue={(el) =>
                          (postQuestionnairesRef.current[keys[0]] = el)
                        }
                        defaultValue={postQuestionnairesDoc.info[keys[0]]}
                        required={true}
                        // classStr="col-xl-4"
                      ></InputBox>
                      <InputBox
                        title={postQuestionnairesQuestions[keys[1]]}
                        id={postQuestionnairesQuestions[keys[1]]}
                        placeholder=""
                        refValue={(el) =>
                          (postQuestionnairesRef.current[keys[1]] = el)
                        }
                        defaultValue={postQuestionnairesDoc.info[keys[1]]}
                        required={true}
                        // classStr="col-xl-4"
                      ></InputBox>
                      <InputBox
                        title={postQuestionnairesQuestions[keys[2]]}
                        id={postQuestionnairesQuestions[keys[2]]}
                        placeholder=""
                        refValue={(el) =>
                          (postQuestionnairesRef.current[keys[2]] = el)
                        }
                        defaultValue={postQuestionnairesDoc.info[keys[2]]}
                        required={true}
                        // classStr="col-xl-4"
                      ></InputBox>

                      <InputRadio
                        title={postQuestionnairesQuestions[keys[3]]}
                        name={postQuestionnairesQuestions[keys[3]]}
                        options={["ฝัน", "ไม่ฝัน"]}
                        checked={postQuestionnairesDoc.info[keys[3]]}
                        refValue={keys[3]}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={postQuestionnairesQuestions[keys[4]]}
                        name={postQuestionnairesQuestions[keys[4]]}
                        options={[
                          "สดชื่นขึ้น",
                          "เพลียกว่าเดิม",
                          "เหมือนเดิมก่อนที่จะหลับ",
                        ]}
                        checked={postQuestionnairesDoc.info[keys[4]]}
                        refValue={keys[4]}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={postQuestionnairesQuestions[keys[5]]}
                        name={postQuestionnairesQuestions[keys[5]]}
                        options={["ใช่", "ไม่ใช่"]}
                        checked={postQuestionnairesDoc.info[keys[5]]}
                        refValue={keys[5]}
                        required={true}
                      ></InputRadio>
                      <InputArea
                        title={postQuestionnairesQuestions[keys[6]]}
                        // classStr="col-xl-4"
                        refValue={(el) =>
                          (postQuestionnairesRef.current[keys[6]] = el)
                        }
                        defaultValue={postQuestionnairesDoc.info[keys[6]]}
                      ></InputArea>
                      <InputRadio
                        title={postQuestionnairesQuestions[keys[7]]}
                        name={postQuestionnairesQuestions[keys[7]]}
                        options={[
                          "ดีกว่ามาก",
                          "ดีกว่า",
                          "เหมือนเดิม",
                          "แย่ลง",
                          "แย่ลงมาก",
                        ]}
                        checked={postQuestionnairesDoc.info[keys[7]]}
                        refValue={keys[7]}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={postQuestionnairesQuestions[keys[8]]}
                        name={postQuestionnairesQuestions[keys[8]]}
                        options={[
                          "ดีขึ้น",
                          "เท่าเดิม",
                          "แย่ลง",
                          "ไม่ได้ใส่ CPAP",
                        ]}
                        checked={postQuestionnairesDoc.info[keys[8]]}
                        refValue={keys[8]}
                        required={true}
                      ></InputRadio>
                      <InputCheckBox
                        title="ถ้าแย่ลง, กรุณาระบุสาเหตุ (สามารถตอบได้มากกว่า 1 ข้อ)"
                        options={[postQuestionnairesQuestions[keys[9]]]}
                        name={keys[9]}
                        checked={postQuestionnairesDoc.info[keys[9]]}
                        refValue={keys[9]}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={[postQuestionnairesQuestions[keys[10]]]}
                        name={postQuestionnairesQuestions[keys[10]]}
                        checked={postQuestionnairesDoc.info[keys[10]]}
                        refValue={keys[10]}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={[postQuestionnairesQuestions[keys[11]]]}
                        name={postQuestionnairesQuestions[keys[11]]}
                        checked={postQuestionnairesDoc.info[keys[11]]}
                        refValue={keys[11]}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={[postQuestionnairesQuestions[keys[12]]]}
                        name={postQuestionnairesQuestions[keys[12]]}
                        checked={postQuestionnairesDoc.info[keys[12]]}
                        refValue={keys[12]}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={[postQuestionnairesQuestions[keys[13]]]}
                        name={postQuestionnairesQuestions[keys[13]]}
                        checked={postQuestionnairesDoc.info[keys[13]]}
                        refValue={keys[13]}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={[postQuestionnairesQuestions[keys[14]]]}
                        name={postQuestionnairesQuestions[keys[14]]}
                        checked={postQuestionnairesDoc.info[keys[14]]}
                        refValue={keys[14]}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={[postQuestionnairesQuestions[keys[15]]]}
                        name={postQuestionnairesQuestions[keys[15]]}
                        checked={postQuestionnairesDoc.info[keys[15]]}
                        refValue={keys[15]}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={[postQuestionnairesQuestions[keys[16]]]}
                        name={postQuestionnairesQuestions[keys[16]]}
                        checked={postQuestionnairesDoc.info[keys[16]]}
                        refValue={keys[16]}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={[postQuestionnairesQuestions[keys[17]]]}
                        name={postQuestionnairesQuestions[keys[17]]}
                        checked={postQuestionnairesDoc.info[keys[17]]}
                        refValue={keys[17]}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={[postQuestionnairesQuestions[keys[18]]]}
                        name={postQuestionnairesQuestions[keys[18]]}
                        checked={postQuestionnairesDoc.info[keys[18]]}
                        refValue={keys[18]}
                      ></InputCheckBox>
                      <InputCheckBox
                        title=""
                        options={[postQuestionnairesQuestions[keys[19]]]}
                        name={postQuestionnairesQuestions[keys[19]]}
                        checked={postQuestionnairesDoc.info[keys[19]]}
                        refValue={keys[19]}
                      ></InputCheckBox>
                      <InputBox
                        title={postQuestionnairesQuestions[keys[20]]}
                        id={postQuestionnairesQuestions[keys[20]]}
                        placeholder=""
                        refValue={(el) =>
                          (postQuestionnairesRef.current[keys[20]] = el)
                        }
                        defaultValue={postQuestionnairesDoc.info[keys[20]]}
                        // classStr="col-xl-4"
                      ></InputBox>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        บันทึกข้อมูล
                      </button>
                      <button
                        onClick={() => {
                          history.push(`/patient-page/${id}`);
                        }}
                        type="submit"
                        className="btn btn-light ml-2"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default PostQuestionnaires;
