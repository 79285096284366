import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

const OrderModal = (props) => {
  console.log(props)
  const PatientTextInfo = ({ title = "", value = "", bold = false }) => {
    return (
      <div className="form-group row">
        <label className="col-lg-3 col-form-label" htmlFor="val-username">
          {title}
        </label>
        <div className="col-lg-8">
          {bold ? (
            <>
              <b>{value}</b>
            </>
          ) : (
            <>
              <text>{value}</text>
            </>
          )}
        </div>
      </div>
    );
  };
  const handleOnHideModal = () => {
    props.onHideModal();
  };
  return (
    <Modal
      className="fade bd-example-modal-lg"
      show={props.createModal}
      onHide={handleOnHideModal}
      size="xl"
    >
      <Modal.Header>
        <Modal.Title>Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body col-lg-12">
          <PatientTextInfo
            title="Order"
            value={props.orderInfo.extendedProps.order}
            bold={true}
          />
          <PatientTextInfo
            title="Node"
            value={props.orderInfo.extendedProps.node}
            bold={true}
          />
          <PatientTextInfo
            title="Customer"
            value={props.orderInfo.extendedProps.customer}
            bold={true}
          />
          <PatientTextInfo
            title="HN"
            value={props.orderInfo.extendedProps.hn}
            bold={true}
          />
          <PatientTextInfo
            title="Full Name"
            value={
              props.orderInfo.extendedProps.firstname +
              " " +
              props.orderInfo.extendedProps.lastname
            }
            bold={true}
          />
          <PatientTextInfo
            title="National ID"
            value={props.orderInfo.extendedProps.nationalId}
            bold={true}
          />
          <PatientTextInfo
            title="Phone"
            value={props.orderInfo.extendedProps.phone}
            bold={true}
          />
          <PatientTextInfo
            title="Health Insurance"
            value={props.orderInfo.extendedProps.healthInsurance}
            bold={true}
          />
          <PatientTextInfo
            title="Type"
            value={props.orderInfo.extendedProps.type}
            bold={true}
          />
          <PatientTextInfo
            title="Status"
            value={props.orderInfo.extendedProps.status}
            bold={true}
          />
          <PatientTextInfo
            title="Technician"
            value={props.orderInfo.extendedProps.technician}
            bold={true}
          />
          <PatientTextInfo
            title="Doctor"
            value={props.orderInfo.extendedProps.doctor}
            bold={true}
          />
          <PatientTextInfo
            title="Date"
            value={props.orderInfo.start.toDate().toLocaleString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
            bold={true}
          />
          {props.orderInfo.extendedProps.status == "Post-Consult" && (
            <PatientTextInfo
              title="Time"
              value={props.orderInfo.extendedProps.time}
              bold={true}
            />
          )}
          <PatientTextInfo
            title="Note"
            value={props.orderInfo.extendedProps.note}
            bold={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
export default OrderModal;
