import React from "react";

import EventCalendar from "./EventCalendar";

import PageTitle from "../../layouts/PageTitle";

const Followup = () => {
  return (
    <div className="h-80">
      <PageTitle activeMenu="Calendar" motherMenu="Followup" motherLink="/followup-calendar" />

      <EventCalendar />
    </div>
  );
};

export default Followup;
