const shortSAQLIFormTitle = "ดัชนีคุณภาพชีวิตของผู้ป่วยโรคนอนกรน";

const shortSAQLIQuestions = {
  Q1: "1. มากแค่ไหน ที่ท่านต้องกระตุ้นตัวเองให้ตื่นตัวในวันธรรมดา เช่น ทำงาน เรียน ดูแลเด็กทำงานบ้าน",
  Q2: "2. ใช้เวลามากแค่ไหนที่ท่านต้องใช้พลังงานทั้งหมดของตัวเอง เพื่อให้กิจกรรมสำคัญของท่านสำเร็จ เช่น ทำงาน เรียน เลี้ยงลูก ทำงานบ้าน",
  Q3: "3. ท่านมีความลำบากมากเพียงใด ในการพยายามทำกิจกรรมอื่น ๆ เช่น ออกกำลังกาย พักผ่อน",
  Q4: "4. ท่านมีความลำบากมากเพียงใด ในการฝืนตนเองไม่ให้ผลอยหลับไป",
  Q5: "5. ท่านรู้สึกมีปัญหามากเพียงใด เมื่อมีคนบอกว่าท่านกรนน่ารำคาญมาก",
  Q6: "6. ความขัดแย้งหรือการถกเถียงกันบ่อยครั้ง สร้างปัญหาให้ท่านมากเพียงใด",
  Q7: "7. ท่านต้องหาคำแก้ตัว เวลาท่านเหนื่อยบ่อยครั้งเพียงใด",
  Q8: "8. ท่านไม่ประสงค์จำทำกิจกรรมครอบครัว และ/หรือ เพื่อนบ่อยครั้งเพียงใด",
  Q9: "9. ท่านรู้สึกไม่เบิกบาน หดหู่ ท้อถอย หรือสิ้นหวัง บ่อยครั้งเพียงใด",
  Q10: "10. ท่านรู้สึกไม่อดทนใจร้อนบ่อยครั้งไหม",
  Q11: "11. ท่านมีปัญามากน้อยเพียงใด เวลาท่านต้องจัดการกิจวัตรประจำวัน",
  Q12: "12. ท่านมีปัญหามากน้อยเพียงใด เวลาท่านหมดเรี่ยวแรก",
  Q13: "13. ท่านมีปัญหามากน้อยเพียงใด เวลาท่านเหนื่อยเปลี้ย",
  Q14: "14. ท่านมีปัญหามากน้อยเพียงใด เวลาท่านตื่นนอนแล้วรู้สึกไม่สดชื่น",
};

const shortSAQLIData = {
  Q1: "",
  Q2: "",
  Q3: "",
  Q4: "",
  Q5: "",
  Q6: "",
  Q7: "",
  Q8: "",
  Q9: "",
  Q10: "",
  Q11: "",
  Q12: "",
  Q13: "",
  Q14: "",
};

const shortSAQLIChoices = [
  "ไม่หรือไม่มี",
  "น้อย",
  "น้อยถึงปานกลาง",
  "ปานกลาง",
  "ปานกลางถึงมาก",
  "มาก",
  "มากที่สุด",
];

const shortSAQLI = {
  assign: false,
  formname: shortSAQLIFormTitle,
  id: "8",
  info: shortSAQLIData,
  submit: false,
};

const shortSAQLIKeys = Object.keys(shortSAQLIData);

export {
  shortSAQLIQuestions,
  shortSAQLIData,
  shortSAQLI,
  shortSAQLIKeys,
  shortSAQLIChoices,
};
