import moment from "moment";

// Filters Reducer

const pagesReducerDefaultState = {
  itemsPerPage: 0,
  currentPage: 1,
  totalPages: 0,
  firstPage: null,
  latestPage: null,
};

export default (state = pagesReducerDefaultState, action) => {
  switch (action.type) {
    case "SET_ITEMS_PER_PAGE":
      return {
        ...state,
        itemsPerPage: action.itemsPerPage,
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case "SET_FIRST_PAGE":
      return {
        ...state,
        firstPage: action.firstPage,
      };
    case "SET_TOTAL_PAGES":
      return {
        ...state,
        firstPage: action.firstPage,
        latestPage: action.latestPage,
        totalPages: action.totalPages,
      };
    case "SET_RESET_PAGE":
      return pagesReducerDefaultState;

    default:
      return state;
  }
};
