import React, { Fragment, useRef } from "react";
import _ from "lodash";
import RegisterFields from "./RegisterFields";
import formFields from "./formFields";

export const EditForm = ({ onChange, initailValues, onEdit }) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(formFields, ({ label, name, type, options, required }) => {
      return (
        <RegisterFields
          name={name}
          label={label}
          type={type}
          value={inputRef.current.name}
          options={options}
          required={required}
          initailValue={
            type !== "date"
              ? initailValues[name]
              : initailValues[name]
              ? initailValues[name].toDate().toLocaleDateString("en-CA")
              : ""
          }
          disabled={name === "serialNumber" ? true : onEdit}
          onChange={onChange(name)}
        />
      );
    });
  };

  return <Fragment>{renderFields()}</Fragment>;
};
