import React, { useState, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import logo_long from "../../images/logo/logo_long.png";
import Toast from "../components/toast/SetToast";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../components/toast/SetToast";
import { getMessageFromErrorCode } from "../ErrorMessage";

const Login = ({ history }) => {
  const [loginData, setLoginData] = useState({});
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [remember, setRemember] = useState(true);

  const handleBlur = (e) => {
    const newLoginData = { ...loginData };
    newLoginData[e.target.name] = e.target.value;
    setLoginData(newLoginData);
  };
  const submitHandler = (e) => {
    e.preventDefault();

    try {
      login(emailRef.current.value, passwordRef.current.value, remember).then(
        (data) => {
          if (data.user !== undefined) {
            if (["super_admin", "sale"].includes(data.user.roles[0])) {
              window.location.pathname = "/dashboard";
            } else {
              window.location.pathname = "/";
            }
          } else {
            notifyError(getMessageFromErrorCode(data.code));
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="authincation h-100 p-meddle">
      <Toast />
      <div className="container h-100">
        <div
          className="row justify-content-center align-items-center"
          style={{ height: "75vh" }}
        >
          <div className="col-md-6">
            <div
              className="authincation-content"
              style={{ boxShadow: "2px 2px 5px rgba(0,0,0,0.25)" }}
            >
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div
                      className="mb-4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100px",
                      }}
                    >
                      <img src={logo_long} />
                    </div>
                    <h4 className="text-center mb-4">
                      <b>BDSleep Study Management</b>
                    </h4>
                    <form
                      action=""
                      onSubmit={(e) => {
                        submitHandler(e);
                      }}
                    >
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="username"
                          className="form-control"
                          placeholder="Email"
                          name="Username"
                          onChange={handleBlur}
                          ref={emailRef}
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          onChange={handleBlur}
                          ref={passwordRef}
                        />
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="basic_checkbox_1"
                              onChange={() =>
                                setRemember((remember) => !remember)
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="basic_checkbox_1"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <Link to="/page-forgot-password">
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <div className="text-center">
                        <input
                          type="submit"
                          value="Log in"
                          className="btn btn-primary btn-block"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
