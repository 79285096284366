export default [
  {
    label: "Node Name",
    name: "nodename",
    type: "text",
    required: true,
  },
  {
    label: "Node Code",
    name: "nodecode",
    type: "text",
    required: true,
  },
];
