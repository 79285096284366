const daytimeBehaviorFormTitle = "ข้อมูลเกี่ยวกับพฤติกรรมนอนกลางวัน";

const daytimeBehaviorQuestions = {
  Q1: "มีความรู้สึกไม่สบายในขา เช่น เหมือนมีอะไรไต่ คลานอยู่ในขา",
  Q2: "ความรู้สึกไม่สบายในขาเป็นมากตอนนั่งหรือนอนเฉย ๆ ไม่ทำอะไร",
  Q3: "ความรู้สึกไม่สบายในขาดีขึ้นเวลาขยับ เช่นขยับขาหรือลุกขึ้นเดิน",
  Q4: "ความรู้สึกไม่สบายในขามักจะเป็นมากเวลากลางคือน เช่น เวลากำลังจะนอน",
  Q5: "ท่านมีญาติใกล้ชิดมาด้วยหรือไม่",
  Q6: "ขณะกำลังนั่งและอ่านหนังสือ",
  Q7: "ขณะกำลังดูโทรทัศน์",
  Q8: "ขณะกำลังนั่งเฉย ๆ ในที่สาธารณะ เช่น ในโรงภาพยนต์ หรือที่ประชุมสัมนา",
  Q9: "ขณะกำลังนั่งเป็นผู้โดยสารในรถ นานกว่า 1 ชั่วโมงอย่างต่อเนื่อง",
  Q10: "ขณะกำลังนอนเอนหลังเพื่อพักผ่อนในตอนบ่ายถ้ามีโอกาส",
  Q11: "ขณะนั่งและกำลังพูดคุยกับผู้อื่น",
  Q12: "ขณะกำลังนั่งเงียบ ๆ หลังอาหารกลางวัน โดยที่ไม่ได้ดื่มแอลกอฮอร์",
  Q13: "ขณะกำลังขับรถ แต่หยุดรถเพื่อรอสัญญาณจราจร นาน 2-3 นาที",
  Q14: "คะแนนรวมกันทุกข้อ",
  Q15: "ท่านมีอาการอ่อนแรงของกล้ามเนื้อทันทีสัมพันธ์กับการเปลี่ยนแปลงของอารมณ์ เช่น หัวเราะ ร้องไห้ หรือ โกรธ หรือไม่",
  Q16: "ถ้ามี กรุณาระบุความถี่ในระยะเวลา 1 เดือนที่ผ่านมา",
};

const daytimeBehaviorData = {
  discomfortLeg: "",
  discomfortLegWhileSit: "",
  comfortLegWhileActive: "",
  moreDiscomfortWhileSleep: "",
  relative: "",
  readingScore: "",
  watchingTVScore: "",
  sittingScore: "",
  sitInCarScore: "",
  daytimeRestScore: "",
  sitAndTalkingScore: "",
  afterLunchScore: "",
  drivingScore: "",
  totalScore: "",
  muscleWeakness: "",
  muscleWeaknessNote: "",
};

const daytimeBehavior = {
  assign: false,
  formname: daytimeBehaviorFormTitle,
  id: "5",
  info: daytimeBehaviorData,
  submit: false,
};

export { daytimeBehaviorQuestions, daytimeBehaviorData, daytimeBehavior };
