import React, { useState, Component, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Button, Col, Table, Card } from "react-bootstrap";
import Calendar from "react-calendar";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import PageTitle from "./../../layouts/PageTitle";

//import Images:
import avatar from "../../../images/avatar/1.jpg";
import avatar1 from "../../../images/avatar/2.jpg";
import avatar2 from "../../../images/avatar/3.jpg";
import { useAuth } from "../../../context/AuthContext";
import { db, storage } from "../../../firebase";
import {
  query,
  endAt,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  where,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
  collectionGroup,
  Timestamp,
  getDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getBlob,
  deleteObject,
} from "firebase/storage";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";
// import { useAuth } from "../../../context/AuthContext";

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [bookingMonthCount, setBookingMonthCount] = useState(0);
  const [cpapTrialMonthCount, setCPAPTrialMonthCount] = useState(0);
  const [cpapSaleMonthCount, setCPAPSaleMonthCount] = useState(0);
  const [cpapSaleMonthWaitingCount, setCPAPSaleMonthWaitingCount] = useState(0);
  const [bookingToday, setBookingToday] = useState([]);
  const [cpapListToday, setCPAPListToday] = useState([]);
  const [bookingHFN, setBookingHFN] = useState(0);
  const [bookingFN, setBookingFN] = useState(0);
  const [bookingSN, setBookingSN] = useState(0);
  const [bookingTitrat, setBookingTitrat] = useState(0);

  const FormatDate = (date) => {
    const [month, day, year] = date.split("/");
    return `${day}-${month}-${year}`;
  };

  const GetBookingToday = async () => {
    const TodayDateString = new Date().toISOString().slice(0, 10);
    const TodayDate = Timestamp.fromDate(
      new Date(TodayDateString + "T00:00:00")
    );
    const TomorrowDate = Timestamp.fromDate(
      new Date(TodayDateString + "T23:59:59")
    );
    // const statusFilter = ["Sleep Test Scheduled"]
    var bookingsCollectionGroupRef = collectionGroup(db, "bookings");
    var queryConstraints = [];
    queryConstraints.push(orderBy("start"));
    queryConstraints.push(orderBy("extendedProps.customer"));
    queryConstraints.push(orderBy("extendedProps.order"));
    queryConstraints.push(where("start", ">=", TodayDate));
    queryConstraints.push(where("start", "<=", TomorrowDate));
    queryConstraints.push(
      where("extendedProps.status", "==", "Sleep Test Scheduled")
    );

    let queryHandler = query(bookingsCollectionGroupRef, ...queryConstraints);
    const tempBooking = [];
    const docSnap = await getDocs(queryHandler);
    if (docSnap.empty) {
      console.log("No documents found");
    } else {
      docSnap.forEach((booking) => {
        const bookingData = booking.data();
        const dateStart = new Date(bookingData.start.toDate());
        dateStart.setHours(dateStart.getHours() + 7);
        bookingData.start = dateStart.toISOString().slice(0, 10);
        tempBooking.push({ ...bookingData });
      });
    }
    setBookingToday(tempBooking);
  };

  const GetBookingThisMonth = async () => {
    const exceptStatus = ["Cancel", "Failure"];
    const today = new Date();
    
    // Set the time of the first day of the month to 00:00:00
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0);
    
    // Set the time of the last day of the month to 23:59:59
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
    
    // Convert these to Firestore-compatible Timestamps
    const firstDayString = firstDayOfMonth.toISOString(); // This will include the time as well
    const lastDayString = lastDayOfMonth.toISOString(); // This will include the time as well
    
    var bookingsCollectionGroupRef = collectionGroup(db, "bookings");
    var queryConstraints = [];
    queryConstraints.push(orderBy("extendedProps.latestSleepStudyDate"));
    queryConstraints.push(
      where("extendedProps.latestSleepStudyDate", ">=", firstDayString)
    );
    queryConstraints.push(
      where("extendedProps.latestSleepStudyDate", "<=", lastDayString)
    );
    queryConstraints.push(
      where("extendedProps.status", "not-in", exceptStatus)
    );
    let queryHandler = query(bookingsCollectionGroupRef, ...queryConstraints);
    const countData = await getCountFromServer(queryHandler);
    const count = countData.data().count;
    setBookingMonthCount(count);
    

    var queryType = [];
    queryType.push(orderBy("extendedProps.latestSleepStudyDate"));
    queryType.push(
      where("extendedProps.latestSleepStudyDate", ">=", firstDayString)
    );
    queryType.push(
      where("extendedProps.latestSleepStudyDate", "<=", lastDayString)
    );
    queryType.push(where("extendedProps.status", "not-in", exceptStatus));

    var queryTypeHFN = [...queryType];
    var queryTypeFN = [...queryType];
    var queryTypeSN = [...queryType];
    var queryTypeTitrat = [...queryType];

    queryTypeHFN.push(
      where("extendedProps.type", "==", "Home Full Night Sleep Study")
    );
    queryTypeFN.push(
      where("extendedProps.type", "==", "Full Night Sleep Study")
    );
    queryTypeSN.push(
      where("extendedProps.type", "==", "Split Night Sleep Study")
    );
    queryTypeTitrat.push(where("extendedProps.type", "==", "CPAP Titration"));

    let queryHandlerHFN = query(bookingsCollectionGroupRef, ...queryTypeHFN);
    const countDataHFN = await getCountFromServer(queryHandlerHFN);
    const countHFN = countDataHFN.data().count;
    setBookingHFN(countHFN);

    let queryHandlerFN = query(bookingsCollectionGroupRef, ...queryTypeFN);
    const countDataFN = await getCountFromServer(queryHandlerFN);
    const countFN = countDataFN.data().count;
    setBookingFN(countFN);

    let queryHandlerSN = query(bookingsCollectionGroupRef, ...queryTypeSN);
    const countDataSN = await getCountFromServer(queryHandlerSN);
    const countSN = countDataSN.data().count;
    setBookingSN(countSN);

    let queryHandlerTitrat = query(
      bookingsCollectionGroupRef,
      ...queryTypeTitrat
    );
    const countDataTitrat = await getCountFromServer(queryHandlerTitrat);
    const countTitrat = countDataTitrat.data().count;
    setBookingTitrat(countTitrat);
  };

  const GetCPAPTrialThisMonth = async () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const firstDayTimestamp = Timestamp.fromDate(
      new Date(firstDayOfMonth.toISOString().slice(0, 10) + "T00:00:00")
    );
    const lastDayTimestamp = Timestamp.fromDate(
      new Date(lastDayOfMonth.toISOString().slice(0, 10) + "T23:59:59")
    );

    var cpapCollectionRef = collection(db, "borrowings");
    var queryConstraints = [];
    queryConstraints.push(orderBy("BorrowDate"));
    queryConstraints.push(where("BorrowDate", ">=", firstDayTimestamp));
    queryConstraints.push(where("BorrowDate", "<=", lastDayTimestamp));
    queryConstraints.push(where("Activity", "==", "ยืมเครื่อง"));

    let queryHandler = query(cpapCollectionRef, ...queryConstraints);
    const querySnapshot = await getDocs(queryHandler);

    // Use a Set to filter out duplicates based on the HN field
    const uniqueHNs = new Set();
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.HN) {
        uniqueHNs.add(data.HN);
      }
    });

    const uniqueCount = uniqueHNs.size;
    setCPAPTrialMonthCount(uniqueCount);
  };

  const GetCPAPSaleThisMonth = async () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    const firstDayTimestamp = Timestamp.fromDate(
      new Date(firstDayOfMonth.toISOString().slice(0, 10) + "T00:00:00")
    );
    const lastDayTimestamp = Timestamp.fromDate(
      new Date(lastDayOfMonth.toISOString().slice(0, 10) + "T23:59:59")
    );
    var cpapCollectionRef = collection(db, "orders");
    var queryConstraints = [];
    queryConstraints.push(orderBy("receiptDate"));
    queryConstraints.push(where("receiptDate", ">=", firstDayTimestamp));
    queryConstraints.push(where("receiptDate", "<=", lastDayTimestamp));
    queryConstraints.push(where("activity", "==", "purchase"));
    queryConstraints.push(where("status", "==", "เสร็จสิ้น"));

    let queryHandler = query(cpapCollectionRef, ...queryConstraints);
    const countData = await getCountFromServer(queryHandler);
    const count = countData.data().count;
    setCPAPSaleMonthCount(count);

    var cpapCollectionWaitingRef = collection(db, "orders");
    var queryConstraintsWaiting = [];
    queryConstraintsWaiting.push(orderBy("receiptDate"));
    queryConstraintsWaiting.push(where("receiptDate", ">=", firstDayTimestamp));
    queryConstraintsWaiting.push(where("receiptDate", "<=", lastDayTimestamp));
    queryConstraintsWaiting.push(where("activity", "==", "purchase"));
    queryConstraintsWaiting.push(where("status", "==", "กำลังดำเนินการ"));

    let queryHandlerWaiting = query(cpapCollectionWaitingRef, ...queryConstraintsWaiting);
    const countDataWaiting = await getCountFromServer(queryHandlerWaiting);
    const countWaiting = countDataWaiting.data().count;
    setCPAPSaleMonthWaitingCount(countWaiting);
  };
  const GetCpapListToday = async () => {
    const TodayDateString = new Date().toISOString().slice(0, 10);
    const TodayDate = Timestamp.fromDate(
      new Date(TodayDateString + "T00:00:00")
    );
    const TomorrowDate = Timestamp.fromDate(
      new Date(TodayDateString + "T23:59:59")
    );
    const cpapCollectionGroupRef = collection(db, "cpaps");

    const query1 = query(
      cpapCollectionGroupRef,
      orderBy("LastDueDate"),
      where("Activity", "==", "ยืมเครื่อง"),
      // where("LastDueDate", "<=", TodayDate),
      where("LastDueDate", "<=", TomorrowDate)
    );

    // Second query: LastDueDate is null
    const query2 = query(
      cpapCollectionGroupRef,
      orderBy("LastDueDate"),
      where("Activity", "==", "ยืมเครื่อง"),
      where("LastDueDate", "==", null)
    );

    // Execute both queries and combine results
    const [querySnapshot1, querySnapshot2] = await Promise.all([
      getDocs(query1),
      getDocs(query2),
    ]);

    const combinedResults = [];
    querySnapshot1.forEach((doc) => {
      const data = doc.data();
      // Convert Timestamp to Date or string for rendering
      if (data.LastDueDate) {
        data.LastDueDate = data.LastDueDate.toDate().toLocaleDateString();
      }
      combinedResults.push(data);
    });
    querySnapshot2.forEach((doc) => {
      const data = doc.data();
      // Convert Timestamp to Date or string for rendering
      if (data.LastDueDate) {
        data.LastDueDate = data.LastDueDate.toDate().toLocaleDateString();
      }
    });
    setCPAPListToday(combinedResults);
    // console.log(combinedResults);
  };
  const getThaiMonth = (monthNumber) => {
    const thaiMonths = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    return thaiMonths[monthNumber];
  };

  useEffect(async () => {
    if (currentUser) {
      // console.log("Fetching data...")
      await GetBookingToday();
      await GetBookingThisMonth();
      await GetCPAPTrialThisMonth();
      await GetCPAPSaleThisMonth();
      await GetCpapListToday();
    }
  }, []);
  return (
    <React.Fragment>
      <div className="mb-3">
        <h2 className="text-center">
          ภาพรวมประจำเดือน <strong>{getThaiMonth(new Date().getMonth())}</strong>
        </h2>
      </div>
      <div className="col-xl-12 col-xxl-12">
        <div className="row">
          <Link
            className="col-xl-3 col-lg-3 col-sm-3"
            to="/booking-calendar"
            // onClick={() => console.log("Kokkak")}
          >
            <div className="widget-stat card bg-primary pt-4">
              <Col className="media justify-content-center mb-3">
                <span>
                  <i className="flaticon-381-calendar-1 text-white"></i>
                </span>
              </Col>
              <Col>
                <h3 className="text-white text-center">{`จำนวนเคสทั้งหมด ${bookingMonthCount}`}</h3>
              </Col>
            </div>
          </Link>
          <Link
            className="col-xl-3 col-lg-3 col-sm-3"
            to="#"
            // onClick={() => console.log("Kokkak")}
          >
            <div className="widget-stat card bg-info pt-4 pb-4">
              {/* <Col className="media justify-content-center mb-3">
                <span>
                  <i className="flaticon-381-box-1 text-white"></i>
                </span>
              </Col> */}
              <Col>
                <h3 className="text-white text-center">
                  {`Full Night ${bookingFN}`}
                </h3>
              </Col>
              <Col>
                <h3 className="text-white text-center">
                  {`Split Night ${bookingSN}`}
                </h3>
              </Col>
              <Col>
                <h3 className="text-white text-center">
                  {`Home Full Night ${bookingHFN}`}
                </h3>
              </Col>
              <Col>
                <h3 className="text-white text-center">
                  {`CPAP Titration ${bookingTitrat}`}
                </h3>
              </Col>
            </div>
          </Link>
          <Link
            className="col-xl-3 col-lg-3 col-sm-3"
            // to="/trial-calendar"
            to={
              currentUser.roles[0] === "super_admin" ? "/trial-calendar" : "#"
            }

            // onClick={() => console.log("Kokkak")}
          >
            <div className="widget-stat card bg-primary pt-4">
              <Col className="media justify-content-center mb-3">
                <span>
                  <i className="flaticon-381-box-2 text-white"></i>
                </span>
              </Col>
              <Col>
                <h3 className="text-white text-center">{`ยืม-เช่า เครื่อง ${cpapTrialMonthCount}`}</h3>
              </Col>
            </div>
          </Link>
          <Link
            className="col-xl-3 col-lg-3 col-sm-3"
            to={currentUser.roles[0] === "super_admin" ? "/order-list" : "#"}
            // onClick={() => console.log("Kokkak")}
          >
            <div className="widget-stat card bg-info pt-4">
              <Col className="media justify-content-center mb-3">
                <span>
                  <i className="flaticon-381-briefcase text-white"></i>
                </span>
              </Col>
              <Col>
                <h3 className="text-white text-center">{`ขายเครื่องสำเร็จ ${cpapSaleMonthCount}`}</h3>
                <h3 className="text-white text-center">{`กำลังดำเนินการ ${cpapSaleMonthWaitingCount}`}</h3>
              </Col>
            </div>
          </Link>
        </div>
      </div>
      <div className="col-xl-12 col-xxl-12">
        <div className="row">
          <Card className="col-xl-6 col-lg-6 col-sm-6">
            <h4 className="mt-3 mb-3 ml-1">
              <text className="text-primary">นัดตรวจวันนี้</text>
            </h4>
            <Table className="table-responsive-12 mb-0 table-striped">
              <thead>
                <tr className="bg-primary text-white">
                  <th>ลำดับ</th>
                  {/* <th>Customer</th> */}
                  <th>Order</th>
                  <th>ชื่อ - นามสกุล</th>
                  <th>เบอร์ติดต่อ</th>
                  <th>Technician</th>
                </tr>
              </thead>
              <tbody>
                {bookingToday.map((booking, index) => {
                  return (
                    <tr>
                      <td>
                        <h4>{index + 1}</h4>
                      </td>
                      {/* <td>{booking.extendedProps.customer}</td> */}
                      <td>{booking.extendedProps.order}</td>
                      <td>
                        <a href={`/patient-page/${booking.extendedProps.hn}`}>
                          {booking.extendedProps.firstname}{" "}
                          {booking.extendedProps.lastname}
                        </a>
                      </td>
                      <td>{booking.extendedProps.phone}</td>
                      <td>{booking.extendedProps.techrecord}</td>
                    </tr>
                  );
                })}
                {bookingToday.length < 10 &&
                  [...Array(10 - bookingToday.length)].map((booking, index) => {
                    return (
                      <tr>
                        <td>
                          <h4>{index + bookingToday.length + 1}</h4>
                        </td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
          <Card className="col-xl-6 col-lg-6 col-sm-6">
            <h4 className="mt-3 mb-3 ml-1">
              <text className="text-primary">เลยกำหนดคืนเครื่อง</text>
            </h4>
            <Table className="table-responsive-12 mb-0 table-striped">
              <thead>
                <tr className="bg-primary text-white">
                  <th>ลำดับ</th>
                  <th>หมายเลขเครื่อง</th>
                  <th>ชื่อ - นามสกุล</th>
                  <th>เบอร์ติดต่อ</th>
                  <th>วันที่นัดคืน</th>
                </tr>
              </thead>
              <tbody>
                {cpapListToday.map((cpap, index) => {
                  return (
                    <tr>
                      <td>
                        <h4>{index + 1}</h4>
                      </td>
                      <td>
                        <a href={`/cpap-page/${cpap.SerialNumber}`}>
                          {cpap.SerialNumber}
                        </a>
                      </td>
                      <td>
                        <a href={`/patient-page/${cpap.HN}`}>
                          {cpap.LastFirstName} {cpap.LastLastName}
                        </a>
                      </td>
                      <td>{cpap.LastTel}</td>
                      <td>{FormatDate(cpap.LastDueDate)}</td>
                    </tr>
                  );
                })}
                {cpapListToday.length < 10 &&
                  [...Array(10 - cpapListToday.length)].map((cpap, index) => {
                    return (
                      <tr>
                        <td>
                          <h4>{index + cpapListToday.length + 1}</h4>
                        </td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
