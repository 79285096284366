import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Toast from "../../toast/SetToast";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../toast/SetToast";
import { useFirestore } from "../../../../hook/useFirestore";

import { TechEditForm } from "./TechEditForm";

const roleList = [
  { role: "Record", status: true },
  { role: "Score", status: true },
];

const TechEdit = (props) => {
  const { updateDocument } = useFirestore("sleeptechnicians");
  const [checked, setChecked] = useState([]);
  const inputRef = useRef({
    techId: "",
    name: "",
    fullname: "",
    email: "",
    tel: "",
    node: "",
    roles: [],
    certificate: "",
    status: "",
    accessToken: "",
  });

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };

  const handleChecked = (e, idx) => {
    if (e.target.checked) {
      let o = Object.assign({}, checked[idx], {
        ...checked[idx],
        status: true,
      });
      const updateList = [
        ...checked.slice(0, idx),
        o,
        ...checked.slice(idx + 1),
      ];

      setChecked(updateList);
    } else {
      let o = Object.assign({}, checked[idx], {
        ...checked[idx],
        status: false,
      });
      const updateList = [
        ...checked.slice(0, idx),
        o,
        ...checked.slice(idx + 1),
      ];

      setChecked(updateList);
    }
  };

  const handleOnHideModal = () => {
    props.hide();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      techId: inputRef.current.techId
        ? inputRef.current.techId
        : props.item.techId,
      name: inputRef.current.name ? inputRef.current.name : props.item.name,
      fullname: inputRef.current.fullname
        ? inputRef.current.fullname
        : props.item.fullname,
      email: inputRef.current.email ? inputRef.current.email : props.item.email,
      tel: inputRef.current.tel ? inputRef.current.tel : props.item.tel,
      node: inputRef.current.node ? inputRef.current.node : props.item.node,
      roles: checked ? checked : props.item.roles,
      certificate: inputRef.current.certificate
        ? inputRef.current.certificate
        : props.item.certificate,
      status: inputRef.current.status
        ? inputRef.current.status
        : props.item.status,
    };
    updateDocument(props.item.id, formData)
      .then(() => {
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      })
      .catch((err) => {
        notifyError("บันทึกข้อมูลผิดพลาด ", err);
      });

    props.hide();

    inputRef.current.techId = "";
    inputRef.current.name = "";
    inputRef.current.fullname = "";
    inputRef.current.email = "";
    inputRef.current.tel = "";
    inputRef.current.node = "";
    inputRef.current.roles = "";
    inputRef.current.certificate = "";
    inputRef.current.status = "";
  };

  useEffect(() => {
    setChecked(props.item.roles);
  }, [props.item]);

  return (
    <Fragment>
      <Toast />
      <Modal
        className="fade bd-example-modal-lg"
        show={props.show}
        onHide={handleOnHideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Edit Technician Datails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TechEditForm
            onChange={handleChange}
            initailValues={props.item}
            onChecked={handleChecked}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>

          <Button
            className="sm ml-2"
            variant="light"
            onClick={handleOnHideModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default TechEdit;
