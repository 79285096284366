const berlinQuestionnaireTitle = "แบบทดสอบเบอร์ลินฉบับภาษาไทย"

const berlinQuestionnaireData = {
    "คุณนอนกรนหรือไม่" : "",
    "ความดังของการกรน": "",
    "ความถี่ของการกรน": "",
    "การกรนของคุณรบกวนคนอื่นหรือไม่": "",
    "อาการหยุดหายใจขณะนอนหลับของคุณได้ถูกสังเกตว่าเป็นบ่อยแค่ไหน": "",
    "คุณยังรู้สึกอ่อนเพลียหลังจากได้นอนหลับไปแล้วหรือไม่": "",
    "คุณรู้สึกอ่อนเพลียในช่วงที่ตื่นอยู่หรือไม่": "",
    "คุณเคยงีบหลับขณะขับรถหรือไม่": "",
    "อาการงีบหลับเกิดบ่อยแค่ไหน": "",
    "คุณมีความดันโลหิตสูงหรือไม่": ""
}

const berlinQuestionnaireChoices = {
    "คุณนอนกรนหรือไม่" : ["ใช่","ไม่","ไม่ทราบ"],
    "ความดังของการกรน": ["ดังเท่ากับการหายใจ","ดังเท่ากับการพูด","ดังกว่าการพูด","ดังมาก"],
    "ความถี่ของการกรน": ["เกือบทุกวัน","3-4 ครั้งต่อสัปดาห์","1-2 ครั้งต่อสัปดาห์","1-2 ครั้งต่อเดือน","ไม่เคยหรือเกือบจะไม่เคย"],
    "การกรนของคุณรบกวนคนอื่นหรือไม่": ["ใช่","ไม่","ไม่ทราบ"],
    "อาการหยุดหายใจขณะนอนหลับของคุณได้ถูกสังเกตว่าเป็นบ่อยแค่ไหน": ["เกือบทุกวัน","3-4 ครั้งต่อสัปดาห์","1-2 ครั้งต่อสัปดาห์","1-2 ครั้งต่อเดือน","ไม่เคยหรือเกือบจะไม่เคย"],
    "คุณยังรู้สึกอ่อนเพลียหลังจากได้นอนหลับไปแล้วหรือไม่": ["เกือบทุกวัน","3-4 ครั้งต่อสัปดาห์","1-2 ครั้งต่อสัปดาห์","1-2 ครั้งต่อเดือน","ไม่เคยหรือเกือบจะไม่เคย"],
    "คุณรู้สึกอ่อนเพลียในช่วงที่ตื่นอยู่หรือไม่": ["เกือบทุกวัน","3-4 ครั้งต่อสัปดาห์","1-2 ครั้งต่อสัปดาห์","1-2 ครั้งต่อเดือน","ไม่เคยหรือเกือบจะไม่เคย"],
    "คุณเคยงีบหลับขณะขับรถหรือไม่": ["ใช่","ไม่"],
    "อาการงีบหลับเกิดบ่อยแค่ไหน": ["เกือบทุกวัน","3-4 ครั้งต่อสัปดาห์","1-2 ครั้งต่อสัปดาห์","1-2 ครั้งต่อเดือน","ไม่เคยหรือเกือบจะไม่เคย"],
    "คุณมีความดันโลหิตสูงหรือไม่": ["ใช่","ไม่","ไม่ทราบ"]
}


export {berlinQuestionnaireData , berlinQuestionnaireTitle , berlinQuestionnaireChoices }