import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "../PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import profileData from "../../Patient/formData/profileData";
import {
  STOPBANGQuestionnaireData,
  STOPBANGQuestionnaireTitle,
  STOPBANGQuestionnaireChoices,
  STOPBANGQuestionnaireQuestions,
} from "../formData/STOPBANGQuestionnaireData";

const formTitle = STOPBANGQuestionnaireTitle;
const infoData = STOPBANGQuestionnaireData;
const stopbangKeys = Object.keys(STOPBANGQuestionnaireData);

const Questions = STOPBANGQuestionnaireQuestions;
const Choices = STOPBANGQuestionnaireChoices;

const FormStates = {
  assign: false,
  formname: formTitle,
  id: "7",
  submit: false,
  info: infoData,
};

const StopbangQuestionnaire = () => {
  const FormRef = useRef(infoData);
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [FormDoc, setFormDoc] = useState(FormStates);
  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();
  const DropdownBox = ({
    title = "",
    htmlFor = "",
    id = "",
    name = "",
    refvalue,
    placeholder = "Please select",
    options = [""],
    defaultValue,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-lg-4 col-form-label" htmlFor={htmlFor}>
          {title}
          <span className="text-danger"></span>
        </label>
        <div className="col-lg-4">
          <select
            className="form-control"
            id={id}
            name={name}
            ref={refvalue}
            defaultValue={defaultValue}
          >
            {options.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const InputBox = ({
    title = "",
    value = "",
    placeholder = "",
    id = "",
    measure = "",
    refValue,
    noBox = false,
    defaultValue,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-4 col-form-label mt-2">{title}</label>
        <div className="col-xl-4">
          {noBox == false && (
            <input
              type="text"
              className="form-control"
              // placeholder={placeholder == "" ? title : placeholder}
              id={id}
              ref={refValue}
              defaultValue={defaultValue}
            />
          )}
        </div>
        <div className="ml-3 mt-3">
          <text>{measure}</text>
        </div>
      </div>
    );
  };
  const InputRadio = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
    required,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={name}
                        value={value}
                        required={required}
                        onChange={(e) => {
                          if (typeof refValue == "string") {
                            FormRef.current[refValue] = e.target.value;
                          } else {
                            FormRef.current[refValue[0]][refValue[1]] =
                              e.target.value;
                          }
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  FormRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };
  const InputCheckBox = ({
    title = "",
    id = "",
    options = [""],
    checked = [""],
    name = "",
    refValue,
    mt = "row mt-3",
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-4 col-form-label pt-0">{title}</label>
          <div className="col-xl-2">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          console.log(e.target.value);
                        }}
                        defaultChecked={
                          checked.includes(value) ? "checked" : ""
                        }
                      />
                      {value}
                      {console.log(title)}
                      {console.log(checked)}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </fieldset>
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    var info = STOPBANGQuestionnaireData;

    Object.keys(info).forEach((key) => {
      info[key] = FormRef.current[key];
      console.log(key, info[key]);
    });

    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };
  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      console.log(docSnapForm.data());
      console.log(FormStates.formname);
      setFormDoc(docSnapForm.data());
      setLoading(false);

      // set up Ref for all radio  input
      Object.keys(docSnapForm.data().info).forEach((key) => {
        FormRef.current[key] = docSnapForm.data().info[key];
      });

      return;
    }

    const ref = await doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);

  console.log(FormDoc);

  return (
    <Fragment>
      <Toast />

      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>{formTitle}</Card.Title>
          </Card.Header>
          <Card.Body>
            {!isLoading ? (
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-7">
                      <InputRadio
                        title={Questions[0]}
                        name={stopbangKeys[0]}
                        options={Choices[stopbangKeys[0]]}
                        checked={FormDoc.info[stopbangKeys[0]]}
                        refValue={stopbangKeys[0]}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={Questions[1]}
                        name={stopbangKeys[1]}
                        options={Choices[stopbangKeys[1]]}
                        checked={FormDoc.info[stopbangKeys[1]]}
                        refValue={stopbangKeys[1]}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={Questions[2]}
                        name={stopbangKeys[2]}
                        options={Choices[stopbangKeys[2]]}
                        checked={FormDoc.info[stopbangKeys[2]]}
                        refValue={stopbangKeys[2]}
                        required={true}
                      ></InputRadio>

                      <InputRadio
                        title={Questions[3]}
                        name={stopbangKeys[3]}
                        options={Choices[stopbangKeys[3]]}
                        checked={FormDoc.info[stopbangKeys[3]]}
                        refValue={stopbangKeys[3]}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={Questions[4]}
                        name={stopbangKeys[4]}
                        options={Choices[stopbangKeys[4]]}
                        checked={FormDoc.info[stopbangKeys[4]]}
                        refValue={stopbangKeys[4]}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={Questions[5]}
                        name={stopbangKeys[5]}
                        options={Choices[stopbangKeys[5]]}
                        checked={FormDoc.info[stopbangKeys[5]]}
                        refValue={stopbangKeys[5]}
                        required={true}
                      ></InputRadio>

                      <InputRadio
                        title={Questions[6]}
                        name={stopbangKeys[6]}
                        options={Choices[stopbangKeys[6]]}
                        checked={FormDoc.info[stopbangKeys[6]]}
                        refValue={stopbangKeys[6]}
                        required={true}
                      ></InputRadio>

                      <InputRadio
                        title={Questions[7]}
                        name={stopbangKeys[7]}
                        options={Choices[stopbangKeys[7]]}
                        checked={FormDoc.info[stopbangKeys[7]]}
                        refValue={stopbangKeys[7]}
                        required={true}
                      ></InputRadio>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        บันทึกข้อมูล
                      </button>
                      <button
                        onClick={() => {
                          history.push(`/patient-page/${id}`);
                        }}
                        type="submit"
                        className="btn btn-light ml-2"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default StopbangQuestionnaire;
