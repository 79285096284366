import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Badge, Card } from "react-bootstrap";

import PerfectScrollbar from "react-perfect-scrollbar";
import filepicture from "../../../images/file.png";
import { useAuth } from "../../../context/AuthContext";
const PatientNoteItem = ({ note, onDelete, onEdit, onViewFile }) => {
  const { currentUser } = useAuth();
  const handleDate = (date) => {
    if (date !== undefined) {
      try {
        var datetime = new Date(
          date.toDate().setHours(new Date(date.toDate()).getHours() + 7)
        )
          .toISOString()
          .replace("T", " ")
          .slice(0, 19);
        return datetime;
      } catch {
        var newDate = new Date(date);
        var hours = newDate.getHours() + 7;
        newDate = new Date(newDate.setHours(hours));
        var newString = newDate.toISOString().replace("T", " ").slice(0, 19);

        return newString;
      }
    } else {
      return "";
    }
  };

  const handleOnDelete = (data) => {
    onDelete(data);
  };

  const handleOnEdit = (data) => {
    onEdit(data);
  };

  const handleOnViewFile = (hn, id, file) => {
    onViewFile(hn, id, file);
  };

  return (
    <>
      <div className="col-xl-9 col-lg-12">
        <div className="card border-0 pb-0">
          <div className="card-body">
            <PerfectScrollbar
              id="Note-01"
              // className="widget-media dz-scroll height800 ps ps--active-y"
              className="widget-media dz-scroll ps ps--active-y"
            >
              <ul className="timeline ">
                {note.map((item, index) => {
                  return (
                    <>
                      <li key={item.id}>
                        <div className="timeline-panel border-0">
                          <div className="media-body">
                            {/* <h5 className="mb-0">
                              {item.createBy}{" "}
                              <small className="text-muted">
                                {handleDate(item.createAt)}
                              </small>
                            </h5> */}

                            {/* <Card.Header>
                              <div className="col-lg-12 ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Title..."
                                  onChange={titleEditBoxHandler}
                                  defaultValue={noteElement.title}
                                ></input>
                              </div>
                            </Card.Header> */}

                            {item.title !== "" ? (
                              <Card.Header className="bg-primary  pt-3 pb-2 ">
                                <h5 className="text-white">{item.title}</h5>
                              </Card.Header>
                            ) : (
                              ""
                            )}
                            <Card.Body className="pt-3 pb-3 border-left border-right border-light border-bottom ">
                              <Card.Text className="text-black">
                                {item.detail}
                              </Card.Text>
                            </Card.Body>

                            <Card.Footer className=" bg-transparent border-0 pl-0 pr-0 pt-2">
                              {item.filename.map((file, index) => {
                                return (
                                  <Link
                                    className="text-muted"
                                    key={index}
                                    to="#"
                                    onClick={() =>
                                      handleOnViewFile(item.hn, item.id, file)
                                    }
                                  >
                                    <Badge variant="outline-light badge-rounded mr-1 mt-1">
                                      <span>
                                        <h6 className="mb-0">
                                          <img
                                            src={filepicture}
                                            alt="profile"
                                            width={"20"}
                                            height={"20"}
                                            className="mr-1"
                                          />
                                          {file}
                                        </h6>
                                      </span>
                                    </Badge>
                                  </Link>
                                );
                              })}
                              {!["sale", "staff","sleep_technician"].includes(
                                currentUser.roles[0]
                              ) && (
                                <Dropdown className="dropdown float-right">
                                  <Dropdown.Toggle
                                    variant="warning light"
                                    className=" i-false p-0 sharp"
                                  >
                                    <svg
                                      width="18px"
                                      height="18px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        />
                                        <circle
                                          fill="#000000"
                                          cx="5"
                                          cy="12"
                                          r="2"
                                        />
                                        <circle
                                          fill="#000000"
                                          cx="12"
                                          cy="12"
                                          r="2"
                                        />
                                        <circle
                                          fill="#000000"
                                          cx="19"
                                          cy="12"
                                          r="2"
                                        />
                                      </g>
                                    </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dropdown-menu">
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={() => {
                                        handleOnEdit(item);
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="dropdown-item"
                                      onClick={() => {
                                        handleOnDelete(item);
                                      }}
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                              <Card.Text className="float-right">
                                {" "}
                                <span className="row">
                                  <h7>Created by: </h7>
                                  <h7 className="text-black mr-4">
                                    {item.createBy}
                                  </h7>
                                </span>
                                <span className="row">
                                  <h7 className="mr-4">
                                    {" "}
                                    {handleDate(item.createAt)}
                                  </h7>
                                </span>
                              </Card.Text>
                            </Card.Footer>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientNoteItem;
