import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { db } from "../../../firebase";
import PerfectScrollbar from "react-perfect-scrollbar";
import swal from "sweetalert";
import {
  where,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";

import { Button, Modal } from "react-bootstrap";
import OrderDetailModal from "./OrderDetailModal";
import { startEditOrder } from "../../../actions/orders";

const ProductActivity = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [logs, setLogs] = useState([]);
  const [latestDoc, setLatestDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [orderView, setOrderView] = useState([]);
  const itemsPerPage = 10;

  const onDelete = (index) => {
    setLogs(logs.filter(({ id }) => id !== logs[index].id));
    const ref = doc(
      db,
      "products",
      logs[index].productId,
      "logs",
      logs[index].id
    );
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteDoc(ref).then(() => {
          swal(`Poof! Your file has been deleted!`, {
            icon: "success",
          });
        });
      } else {
        swal("Canceled");
      }
    });
  };

  const handleOnClick = async (receiptNo) => {
    const tempActivity = [];
    let text = {};
    const docSnap = await getDocs(
      query(collection(db, "orders"), where("receiptNo", "==", receiptNo))
    );
    docSnap.forEach((doc) => {
      text = {
        id: doc.id,
        receiptNo: doc.data().receiptNo,
        hn: doc.data().hn,
        amount: doc.data().amount,
        accessories: doc.data().accessories,
        refHospital: doc.data().refHospital,
        refPhysician: doc.data().refPhysician,
        status: doc.data().status,
        receiptDate: doc.data().receiptDate,
        warrantyStartDate: doc.data().warrantyStartDate,
        warrantyEndDate: doc.data().warrantyEndDate,
        activity: doc.data().activity,
        note: doc.data().note,
        productId: doc.data().productId,
        sales: doc.data().sales,
      };

      tempActivity.push({ ...text });
    });
    setOrderView(tempActivity[0]);
    setShowModal(true);
  };
  const handleOnHide = (sn) => {
    setShowModal(false);
  };
  const getNextHistory = async () => {
    const historyQuery = query(
      collection(db, "products", props.cpap.id, "logs"),
      orderBy("createdAt", "desc"),
      startAfter(latestDoc),
      limit(itemsPerPage)
    );

    const docSnap = await getDocs(historyQuery);
    const history = [];
    docSnap.forEach((item) => {
      var tmp = item.data();
      history.push(tmp);
    });
    // listCpapHistoryTmp.reverse();
    console.log("history: ", history);

    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    if (docSnap.empty) {
      setHasMore(false);
    }
    setIsLoading(false);
  };

  useEffect(async () => {
    setIsLoading(true);
    const queryRef = query(
      collection(db, "products", props.cpap.id, "logs"),
      orderBy("createdAt", "desc"),
      limit(itemsPerPage)
    );

    let tempArray = [];
    const docSnap = await getDocs(queryRef);

    docSnap.forEach((doc) => {
      const text = {
        id: doc.id,
        ...doc.data(),
      };
      tempArray.push(text);
    });
    setLogs(tempArray);
    setLatestDoc(docSnap.docs[docSnap.docs.length - 1]);
    setIsLoading(false);
  }, []);
  // console.log(logs);

  return (
    <Fragment>
      <OrderDetailModal
        show={showModal}
        hide={handleOnHide}
        order={orderView}
        onEditSubmit={(id, updates) => props.startEditOrder(id, updates)}
      />
      <div className="col-xl-5">
        <div className="card-header border-0 pb-0">
          <h4 className="card-title">Timeline</h4>
        </div>
        <div className="card-body">
          <PerfectScrollbar
            style={{ height: "100%" }}
            id="DZ_W_TimeLine"
            className="widget-timeline dz-scroll 370 ps ps--active-y"
          >
            <ul className="timeline">
              {logs.map((item, index) => {
                return (
                  <li>
                    <div className="timeline-badge primary"></div>

                    <span className="timeline-panel text-muted" to="#">
                      <Button
                        variant=""
                        className="close"
                        ata-dismiss="alert"
                        aria-label="Close"
                        onClick={() => onDelete(index)}
                      >
                        <span>
                          <i className="mdi mdi-close"></i>
                        </span>
                      </Button>
                      <h6>
                        {item.createdAt.toDate().toLocaleString("en-GB", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </h6>

                      <strong className="text-primary">{item.activity}</strong>
                      {item.hn && (
                        <h6 className="mt-2">
                          HN:{" "}
                          <Link to={"/patient-page/" + item.hn}>{item.hn}</Link>
                        </h6>
                      )}
                      {item.firstname && (
                        <h6 className="mt-2">
                          Name: {item.firstname} {item.lastname}
                        </h6>
                      )}
                      <h6 className="mt-2">
                        Receipt No.{" "}
                        <Link
                          to="#"
                          onClick={() => handleOnClick(item.receiptNo)}
                        >
                          {item.receiptNo}
                        </Link>
                      </h6>
                      <h6 className="mt-2">CreatedBy: {item.createdBy}</h6>
                    </span>
                  </li>
                );
              })}
            </ul>
          </PerfectScrollbar>
        </div>
        <Button onClick={getNextHistory} disabled={!hasMore}>
          Load More
        </Button>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  startEditOrder: (id, updates) => dispatch(startEditOrder(id, updates)),
});

export default connect(null, mapDispatchToProps)(ProductActivity);
