import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "../PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import {
  sleepHabitData,
  sleepHabitFormTitle,
  sleepHabitQuestions,
} from "../../Patient/formData/sleepHabitData";
import profileData from "../../Patient/formData/profileData";

const infoData = sleepHabitData;
const formTitle = sleepHabitFormTitle;

const FormStates = {
  assign: false,
  formname: formTitle,
  id: "3",
  info: infoData,
  submit: false,
};

const SleepHabit = () => {
  const FormRef = useRef(infoData);
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [sleepHabitDoc, setsleepHabitDoc] = useState(FormStates);
  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();
  const DropdownBox = ({
    title = "",
    htmlFor = "",
    id = "",
    name = "",
    refvalue,
    placeholder = "Please select",
    options = [""],
    defaultValue,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-lg-5 col-form-label" htmlFor={htmlFor}>
          {title}
          <span className="text-danger"></span>
        </label>
        <div className="col-lg-6">
          <select
            className="form-control"
            id={id}
            name={name}
            ref={refvalue}
            defaultValue={defaultValue}
          >
            {options.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const InputBox = ({
    title = "",
    value = "",
    placeholder = "",
    id = "",
    measure = "",
    refValue,
    noBox = false,
    defaultValue,
    required,
    type = "text",
    pattern,
    step,
    min,
    max,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-5 col-form-label mt-2">{title}</label>
        <div className="col-xl-6">
          {noBox == false && (
            <input
              type={type}
              step={step}
              pattern={pattern}
              min={min}
              max={max}
              required={required}
              className="form-control"
              // placeholder={placeholder == "" ? title : placeholder}
              id={id}
              ref={refValue}
              defaultValue={defaultValue}
            />
          )}
        </div>
        <div className="ml-3 mt-3">
          <text>{measure}</text>
        </div>
      </div>
    );
  };
  const InputRadio = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
    required,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        required={required}
                        type="radio"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          if (typeof refValue == "string") {
                            FormRef.current[refValue] = e.target.value;
                          } else {
                            FormRef.current[refValue[0]][refValue[1]] =
                              e.target.value;
                          }
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  FormRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };
  const InputCheckBox = ({
    title = "",
    id = "",
    options = [""],
    checked = [""],
    name = "",
    refValue,
    mt = "row mt-3",
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          // FormRef.current[refValue] = e.target.value;
                          console.log(e.target.value);
                        }}
                        defaultChecked={
                          checked.includes(value) ? "checked" : ""
                        }
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </fieldset>
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    var info = {
      workday: {
        sleep_time: FormRef.current["workday"]["sleep_time"].value,
        sleep_latency: FormRef.current["workday"]["sleep_latency"].value,
        wake_after_sleep: FormRef.current["workday"]["wake_after_sleep"],
        wake_after_sleep_numbers:
          FormRef.current["workday"]["wake_after_sleep_numbers"].value,
        wake_after_sleep_onset_durations:
          FormRef.current["workday"]["wake_after_sleep_onset_durations"].value,
        wake_time: FormRef.current["workday"]["wake_time"].value,
        total_sleep_time: FormRef.current["workday"]["total_sleep_time"].value,
      },
      holiday: {
        sleep_time: FormRef.current["holiday"]["sleep_time"].value,
        sleep_latency: FormRef.current["holiday"]["sleep_latency"].value,
        wake_after_sleep: FormRef.current["holiday"]["wake_after_sleep"],
        wake_after_sleep_numbers:
          FormRef.current["holiday"]["wake_after_sleep_numbers"].value,
        wake_after_sleep_onset_durations:
          FormRef.current["holiday"]["wake_after_sleep_onset_durations"].value,
        wake_time: FormRef.current["holiday"]["wake_time"].value,
        total_sleep_time: FormRef.current["holiday"]["total_sleep_time"].value,
      },
      shift_work: FormRef.current["shift_work"],
      morning_shift_work: {
        days_per_week:
          FormRef.current["morning_shift_work"]["days_per_week"].value,
        start_time: FormRef.current["morning_shift_work"]["start_time"].value,
        end_time: FormRef.current["morning_shift_work"]["end_time"].value,
      },
      afternoon_shift_work: {
        days_per_week:
          FormRef.current["afternoon_shift_work"]["days_per_week"].value,
        start_time: FormRef.current["afternoon_shift_work"]["start_time"].value,
        end_time: FormRef.current["afternoon_shift_work"]["end_time"].value,
      },
      night_shift_work: {
        days_per_week:
          FormRef.current["night_shift_work"]["days_per_week"].value,
        start_time: FormRef.current["night_shift_work"]["start_time"].value,
        end_time: FormRef.current["night_shift_work"]["end_time"].value,
      },
      daytime_nap: FormRef.current["daytime_nap"],
      nap_time: FormRef.current["nap_time"].value,
      nap_times_per_week: FormRef.current["nap_times_per_week"].value,
      nap_duration_each_time: FormRef.current["nap_duration_each_time"].value,
    };

    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };
  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      console.log('patientInfo.hn',patientInfo.hn)
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      console.log(docSnapForm.data());
      console.log(FormStates.formname);
      setsleepHabitDoc(docSnapForm.data());
      setLoading(false);

      FormRef.current["workday"]["wake_after_sleep"] =
        docSnapForm.data().info["workday"]["wake_after_sleep"];

      FormRef.current["holiday"]["wake_after_sleep"] =
        docSnapForm.data().info["holiday"]["wake_after_sleep"];

      FormRef.current["shift_work"] = docSnapForm.data().info["shift_work"];

      FormRef.current["daytime_nap"] = docSnapForm.data().info["daytime_nap"];
      return;
    }
    console.log(id)
    const ref = await doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);

  return (
    <Fragment>
      <Toast />

      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>{formTitle}</Card.Title>
          </Card.Header>
          <Card.Body>
            {!isLoading ? (
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-7">
                      <h4 className="mb-4">
                        <u>
                          <b>วันธรรมดา</b>
                        </u>
                      </h4>
                      <InputBox
                        title={sleepHabitQuestions["Q1"]}
                        type="time"
                        required={true}
                        refValue={(el) =>
                          (FormRef.current["workday"]["sleep_time"] = el)
                        }
                        defaultValue={sleepHabitDoc.info.workday.sleep_time}
                      ></InputBox>
                      <InputBox
                        required={true}
                        title={sleepHabitQuestions["Q2"]}
                        refValue={(el) =>
                          (FormRef.current["workday"]["sleep_latency"] = el)
                        }
                        defaultValue={sleepHabitDoc.info.workday.sleep_latency}
                      ></InputBox>
                      <InputRadio
                        required={true}
                        title={sleepHabitQuestions["Q3"]}
                        name="workday-wake_after_sleep"
                        options={["ตื่น", "ไม่ตื่น"]}
                        checked={sleepHabitDoc.info.workday.wake_after_sleep}
                        refValue={["workday", "wake_after_sleep"]}
                      ></InputRadio>
                      <InputBox
                        title={sleepHabitQuestions["Q31"]}
                        refValue={(el) =>
                          (FormRef.current["workday"][
                            "wake_after_sleep_numbers"
                          ] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.workday.wake_after_sleep_numbers
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q32"]}
                        refValue={(el) =>
                          (FormRef.current["workday"][
                            "wake_after_sleep_onset_durations"
                          ] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.workday
                            .wake_after_sleep_onset_durations
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q4"]}
                        type="time"
                        required={true}
                        refValue={(el) =>
                          (FormRef.current["workday"]["wake_time"] = el)
                        }
                        defaultValue={sleepHabitDoc.info.workday.wake_time}
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q5"]}
                        required={true}
                        refValue={(el) =>
                          (FormRef.current["workday"]["total_sleep_time"] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.workday.total_sleep_time
                        }
                      ></InputBox>

                      <h4 className="mb-4 pt-4">
                        <u>
                          <b>
                            วันหยุด (เสาร์ อาทิตย์ วันนักขัตฤกษ์
                            หรือวันที่ไม่ได้ทำงาน)
                          </b>
                        </u>
                      </h4>
                      <InputBox
                        title={sleepHabitQuestions["Q1"]}
                        type="time"
                        required={true}
                        refValue={(el) =>
                          (FormRef.current["holiday"]["sleep_time"] = el)
                        }
                        defaultValue={sleepHabitDoc.info.holiday.sleep_time}
                      ></InputBox>
                      <InputBox
                      required={true}
                        title={sleepHabitQuestions["Q2"]}
                        refValue={(el) =>
                          (FormRef.current["holiday"]["sleep_latency"] = el)
                        }
                        defaultValue={sleepHabitDoc.info.holiday.sleep_latency}
                      ></InputBox>
                      <InputRadio
                        title={sleepHabitQuestions["Q3"]}
                        required={true}
                        name="holiday-wake_after_sleep"
                        options={["ตื่น", "ไม่ตื่น"]}
                        checked={sleepHabitDoc.info.holiday.wake_after_sleep}
                        refValue={["holiday", "wake_after_sleep"]}
                      ></InputRadio>
                      <InputBox
                        title={sleepHabitQuestions["Q31"]}
                        refValue={(el) =>
                          (FormRef.current["holiday"][
                            "wake_after_sleep_numbers"
                          ] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.holiday.wake_after_sleep_numbers
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q32"]}
                        refValue={(el) =>
                          (FormRef.current["holiday"][
                            "wake_after_sleep_onset_durations"
                          ] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.holiday
                            .wake_after_sleep_onset_durations
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q4"]}
                        type="time"
                        required={true}
                        refValue={(el) =>
                          (FormRef.current["holiday"]["wake_time"] = el)
                        }
                        defaultValue={sleepHabitDoc.info.holiday.wake_time}
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q5"]}
                        required={true}
                        refValue={(el) =>
                          (FormRef.current["holiday"]["total_sleep_time"] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.holiday.total_sleep_time
                        }
                      ></InputBox>

                      <h4 className="mb-4 pt-4">
                        <u>
                          <b>
                            ทำงานเป็นกะ หากท่านมิได้ทำงานเป็นกะให้ข้ามส่วนนี้ไป
                          </b>
                        </u>
                      </h4>
                      <InputRadio
                        title={sleepHabitQuestions["Q7"]}
                        options={["ทำ", "ไม่ทำ"]}
                        required={true}
                        name="shift_work"
                        checked={sleepHabitDoc.info.shift_work}
                        refValue={"shift_work"}
                      ></InputRadio>
                      <InputBox
                        title={sleepHabitQuestions["Q71"]}
                        refValue={(el) =>
                          (FormRef.current["morning_shift_work"][
                            "days_per_week"
                          ] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.morning_shift_work.days_per_week
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q72"]}
                        type="time"
                        refValue={(el) =>
                          (FormRef.current["morning_shift_work"]["start_time"] =
                            el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.morning_shift_work.start_time
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q73"]}
                        type="time"
                        refValue={(el) =>
                          (FormRef.current["morning_shift_work"]["end_time"] =
                            el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.morning_shift_work.end_time
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q74"]}
                        refValue={(el) =>
                          (FormRef.current["afternoon_shift_work"][
                            "days_per_week"
                          ] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.afternoon_shift_work.days_per_week
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q75"]}
                        type="time"
                        refValue={(el) =>
                          (FormRef.current["afternoon_shift_work"][
                            "start_time"
                          ] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.afternoon_shift_work.start_time
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q76"]}
                        type="time"
                        refValue={(el) =>
                          (FormRef.current["afternoon_shift_work"]["end_time"] =
                            el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.afternoon_shift_work.end_time
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q77"]}
                        refValue={(el) =>
                          (FormRef.current["night_shift_work"][
                            "days_per_week"
                          ] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.night_shift_work.days_per_week
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q78"]}
                        type="time"
                        refValue={(el) =>
                          (FormRef.current["night_shift_work"]["start_time"] =
                            el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.night_shift_work.start_time
                        }
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q79"]}
                        type="time"
                        refValue={(el) =>
                          (FormRef.current["night_shift_work"]["end_time"] = el)
                        }
                        defaultValue={
                          sleepHabitDoc.info.night_shift_work.end_time
                        }
                      ></InputBox>

                      <h4 className="mb-4 pt-4">
                        <u>
                          <b>การงีบหลับ</b>
                        </u>
                      </h4>
                      <InputRadio
                        title={sleepHabitQuestions["Q8"]}
                        name="daytime_nap"
                        required={true}
                        options={["งีบ", "ไม่งีบ"]}
                        checked={sleepHabitDoc.info.daytime_nap}
                        refValue={"daytime_nap"}
                      ></InputRadio>
                      <InputBox
                        title={sleepHabitQuestions["Q9"]}
                        type="time"
                        refValue={(el) => (FormRef.current["nap_time"] = el)}
                        defaultValue={sleepHabitDoc.info.nap_time}
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q10"]}
                        refValue={(el) =>
                          (FormRef.current["nap_times_per_week"] = el)
                        }
                        defaultValue={sleepHabitDoc.info.nap_times_per_week}
                      ></InputBox>
                      <InputBox
                        title={sleepHabitQuestions["Q11"]}
                        refValue={(el) =>
                          (FormRef.current["nap_duration_each_time"] = el)
                        }
                        defaultValue={sleepHabitDoc.info.nap_duration_each_time}
                      ></InputBox>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        บันทึกข้อมูล
                      </button>
                      <button
                        onClick={() => {
                          history.push(`/patient-page/${id}`);
                        }}
                        type="submit"
                        className="btn btn-light ml-2"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default SleepHabit;
