import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from "redux";
import notesReducer from "../reducers/notes";
import reportsReducer from "../reducers/reports";
import cpapsReducer from "../reducers/cpaps";
import ordersReducer from "../reducers/orders";
import filtersReducer from "../reducers/filters";
import paginationReducer from "../reducers/pagination";
import productFilesReducer from "../reducers/productFiles";

import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      notes: notesReducer,
      reports: reportsReducer,
      cpaps: cpapsReducer,
      orders: ordersReducer,
      filters: filtersReducer,
      productFiles: productFilesReducer,
      pages: paginationReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};
