export default [
  {
    label: "Node",
    name: "node",
    type: "text",
    required: true,
  },
  {
    label: "Doctor ID",
    name: "doctorId",
    type: "text",
    required: true,
  },
  {
    label: "Name",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Fullname EN",
    name: "fullnameEn",
    type: "text",
  },
  {
    label: "Fullname TH",
    name: "fullnameTh",
    type: "text",
  },
  {
    label: "Token",
    name: "accessToken",
    type: "text",
  },
  {
    label: "Email",
    name: "email",
    type: "text",
  },
  {
    label: "Status",
    name: "status",
    type: "select",
    options: ["Available", "Unavailable"],
  },
];
