import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "../PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import profileData from "../../Patient/formData/profileData";
import { InputBox, InputArea, DropdownBox } from "../formData/inputComponents";
import {
  shortSAQLIQuestions,
  shortSAQLIData,
  shortSAQLI,
  shortSAQLIKeys,
  shortSAQLIChoices,
} from "../formData/shortSAQLIData";

const ShortSAQLI = () => {
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const formRef = useRef(shortSAQLIData);
  const [formDoc, setFormDoc] = useState(shortSAQLI);
  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();
  const questions = shortSAQLIQuestions;
  const Keys = shortSAQLIKeys;

  const InputRadio = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
    required,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={name}
                        value={value}
                        required={required}
                        // id={id}
                        onChange={(e) => {
                          formRef.current[refValue] = e.target.value;
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  formRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };
  const InputCheckBox = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        value={value}
                        // id={id}
                        onChange={(e) => {
                          if (e.target.checked) {
                            formRef.current[refValue] = e.target.value;
                          } else {
                            formRef.current[refValue] = "";
                          }
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </fieldset>
    );
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    var info = shortSAQLIData;

    Object.keys(info).forEach((key) => {
      info[key] = formRef.current[key];
    });
    console.log(info);
    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };

  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      setFormDoc(docSnapForm.data());
      setLoading(false);
      Object.keys(docSnapForm.data().info).forEach((key) => {
        formRef.current[key] = docSnapForm.data().info[key];
      });
      return;
    }

    const ref = await doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);
  console.log(Keys);
  return (
    <Fragment>
      <Toast />
      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>
              {shortSAQLI.formname}
              {" Short SAQLI"}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {!isLoading ? (
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-7">
                      <InputBox
                        title="แบบสอบถามนี้ เพื่อศึกษาผลจากโรคนอนกรน และ/หรือ การหยุดหายใจขณะนอนหลับของท่านว่ามีผลต่อกิจวัตรประจำวัน อารมณ์ความรู้สึก กิจกรรมทางสังคม หรืออื่น ๆ"
                        // bold={true}
                        classTitle="col-xl-12 col-form-label mt-2"
                        noBox={true}
                      ></InputBox>
                      <InputBox
                        title="ท่านเป็นอย่างไร และรู้สึกอย่างไร ในช่วงระยะเวลา 1 เดือน ที่ผ่านมา ให้ตอบคำถามต่อไปนี้"
                        bold={true}
                        classTitle="col-xl-12 col-form-label mt-2"
                        noBox={true}
                      ></InputBox>
                      {Keys.map((el, i) => {
                        return (
                          <InputRadio
                            title={questions[Keys[i]]}
                            name={questions[Keys[i]]}
                            options={shortSAQLIChoices}
                            checked={formDoc.info[Keys[i]]}
                            refValue={Keys[i]}
                            required={true}
                          ></InputRadio>
                        );
                      })}
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        บันทึกข้อมูล
                      </button>
                      <button
                        onClick={() => {
                          history.push(`/patient-page/${id}`);
                        }}
                        type="submit"
                        className="btn btn-light ml-2"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default ShortSAQLI;
