import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import Paginator from "./Paginator";
import {
  query,
  getDocs,
  where,
  collectionGroup
} from "firebase/firestore";
import { db } from "../../../firebase";

const ProductOrderItem = ( {
  orders,
  onEdit,
  onDelete,
  onDownload,
  totalPages,
  onNextPage,
  onPrevPage,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDate = (date) => {
    if (date != undefined) {
      try {
        var datetime = new Date(date.toDate()).toLocaleDateString();
        return datetime;
      } catch {
        var newDate = new Date(date);
        var hours = newDate.getHours() + 7;
        newDate = new Date(newDate.setHours(hours));
        var newString = newDate.toLocaleDateString();
        return newString;
      }
    } else {
      return "";
    }
  };
  const shortenText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + "...";
    }
    return text;
  }
  

  
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table mb-0 table-striped">
                  <thead>
                    <tr>
                      <th>Receipt No.</th>
                      <th>Serial Number</th>
                      <th>HN</th>
                      <th>Name</th>
                      <th>Activity</th>
                      <th>Ref Customer</th>
                      <th>Receipt Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody id="orders-table">
                    {!loading ? (
                      orders.map((order, index) => {
                        var variantData = "";
                        var newDateOptions = {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        };
                          // const matchingSnQuery = query(
                          //   collectionGroup(db, "cpaps"),
                          //   where("SerialNumber", "==", order.productSn)
                          // );
                          // const querySnapshot = getDocs(matchingSnQuery);
                        
                        if (order.status == "เสร็จสิ้น") {
                          variantData = "success light";
                        } else if (order.status == "กำลังดำเนินการ") {
                          variantData = "warning light";
                        } else if (order.status == "ยกเลิกใช้งาน") {
                          variantData = "danger dark";
                        }
                        return (
                          <tr className="btn-reveal-trigger">
                            <td className="py-2">
                            <Link
                                  to="#"
                                  onClick={() => onEdit(order.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={order.accessories +  "\n฿" + order.amount }>
                                  {order.receiptNo}</Link></td>
                            <td className="py-2">
                              <Link to={"/product-page/" + order.productSn}>
                                {order.productSn}
                              </Link>
                            </td>
                            <td className="py-2">
                              <Link
                                // target="_blank"
                                to={"/patient-page/" + order.hn}
                              >
                                {order.hn}
                              </Link>
                            </td>
                            <td className="py-2">
                              {order.firstname
                                ? `${order.firstname} ${order.lastname}`
                                : ""}
                            </td>
                            <td className="py-2">{order.activity}</td>
                            <td className="py-2">{order.refCustomer ? shortenText(order.refCustomer,20) : shortenText(order.refHospital,20)}</td>
                            <td className="py-2">
                              {handleDate(order.receiptDate)}
                            </td>
                            <td className="py-2">
                              <Badge variant={variantData}>
                                {order.status}
                              </Badge>
                            </td>

                            <td className="py-2 text-right">
                              <div className="d-flex">
                                <Link
                                  className="btn btn-primary shadow btn-xs sharp mr-2"
                                  to="#"
                                  onClick={() => onDownload(order.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Export CPAP Receipt and Warranty"
                                >
                                  <i className="fa fa-file-pdf-o"></i>
                                </Link>
                                <Link
                                  className="btn btn-info shadow btn-xs sharp mr-2"
                                  to="#"
                                  onClick={() => onEdit(order.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit order details"
                                >
                                  <i className="fa fa-pencil"></i>
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() => onDelete(order.id)}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Cancel order"
                                >
                                  <i className="fa fa-close"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div class="media d-flex align-items-center">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden"></span>
                        </div>
                      </div>
                    )}
                  </tbody>
                </table>
                <Paginator
                  totalPages={totalPages}
                  next={onNextPage}
                  prev={onPrevPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductOrderItem;
