import React, { Fragment, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import { setDoc, getDoc, updateDoc, doc, collection } from "firebase/firestore";
import {
  TextInputForm,
  TextInputNotRequiredForm,
  DropDownForm,
} from "./CpapUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";

import {
  CpapRegisterData,
  CpapRegisterChoices,
} from "./formdata/CpapRegisterData";

const CpapRegister = () => {
  const CpapRef = useRef(CpapRegisterData);
  const Choices = CpapRegisterChoices;
  const [brandOther, setBrandOther] = useState("");
  const [typeOther, setTypeOther] = useState("");
  const currentUser = useAuth();

  const displayName = currentUser.currentUser.displayName;
  console.log("displayName:", displayName);

  const submitHandler = async (e) => {
    // try {
    e.preventDefault();

    const ref = doc(db, "cpaps", CpapRef.current["SerialNumber"].value);

    let info = CpapRegisterData;

    const CpapKeys = Object.keys(CpapRegisterData);

    Object.keys(info).forEach((key) => {
      if (["LastBorrowDate", "LastDueDate", "LastReturnDate"].includes(key)) {
        info[key] = null;
      } else if (
        [
          "LastHN",
          "LastBorrowCondition",
          "LastReturnCondition",
          "LastNote",
          "LastTel",
          "LastFirstName",
          "LastLastName",
        ].includes(key)
      ) {
        info[key] = "";
      } else {
        CpapRef.current[key].value !== null
          ? (info[key] = CpapRef.current[key].value)
          : (info[key] = "");
      }
    });

    info["CreatedDateTime"] = new Date();
    info["UpdatedDateTime"] = new Date();
    info["CreatedBy"] = displayName;

    info["Status"] = "ว่าง";
    info["Activity"] = "ลงทะเบียน";

    if (info["Brand"] != "Other") {
      info["BrandOther"] = "";
    }
    if (info["Type"] != "Other") {
      info["TypeOther"] = "";
    }

    console.log(info);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        notifyError("Serial Number ซ้ำในระบบ");
        return;
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
        return;
      }
    } else {
      await setDoc(ref, info);

      const cpapBorrowingTmp = {
        SerialNumber: info.SerialNumber,
        Status: info["Status"],
        Activity: info["Activity"],
        HN: "",
        Tel: "",
        FirstName: "",
        LastName: "",
        Admin: "",
        Mask: "",
        MaskSize: "",
        Humidifier: "",
        BorrowDate: null,
        DueDate: null,
        ReturnDate: null,
        BorrowCondition: "",
        ReturnCondition: "",
        Note: "",
        CreateAt: new Date(),
      };
      console.log("tmp: ", cpapBorrowingTmp);
      const refBorrow = doc(
        collection(db, "cpaps", info["SerialNumber"], "borrowing")
      );
      const docSnapSet = await setDoc(refBorrow, cpapBorrowingTmp)
        .then((data) => {
          notifyTopRight("บันทึกข้อมูลสำเร็จ");
          console.log("data", data);
          return;
        })
        .catch((err) => {
          notifyError("บันทึกข้อมูลผิดพลาด, ", err);
          // console.log("err", err);
          return;
        });
    }
    // } catch (err) {
    //   notifyError(err);
    //   return;
    // }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Register CPAP" motherMenu="CPAP" />
      <Toast />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">CPAP Registration Form</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={submitHandler}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <TextInputForm
                        title="Serial Number"
                        type="text"
                        id="cpap-serialnumber"
                        name="cpap-serialnumber"
                        placeholder=""
                        refvalue={(el) =>
                          (CpapRef.current["SerialNumber"] = el)
                        }
                      />
                      <TextInputNotRequiredForm
                        title="CPAP ID"
                        type="cpap-id"
                        id="cpap-id"
                        name="cpap-id"
                        placeholder=""
                        refvalue={(el) => (CpapRef.current["CpapID"] = el)}
                      />
                      <DropDownForm
                        title="Brand"
                        htmlFor="cpap-brand"
                        id="cpap-brand"
                        name="cpap-brand"
                        placeholder="กดเพื่อเลือก"
                        options={Choices["Brand"]}
                        onChange={(el) => setBrandOther(el.target.value)}
                        refvalue={(el) => (CpapRef.current["Brand"] = el)}
                      />
                      {brandOther == "Other" && (
                        <TextInputForm
                          title="Brand Other"
                          type="cpap-brandother"
                          id="cpap-brandother"
                          name="cpap-brandother"
                          placeholder=""
                          refvalue={(el) =>
                            (CpapRef.current["BrandOther"] = el)
                          }
                        />
                      )}
                      <TextInputNotRequiredForm
                        title="Model"
                        type="cpap-model"
                        id="cpap-model"
                        name="cpap-model"
                        placeholder=""
                        refvalue={(el) => (CpapRef.current["Model"] = el)}
                      />
                      <DropDownForm
                        title="Type"
                        htmlFor="cpap-type"
                        id="cpap-type"
                        name="cpap-type"
                        placeholder="กดเพื่อเลือก"
                        options={Choices["Type"]}
                        onChange={(el) => setTypeOther(el.target.value)}
                        refvalue={(el) => (CpapRef.current["Type"] = el)}
                      />
                      {typeOther == "Other" && (
                        <TextInputForm
                          title="Type Other"
                          type="cpap-typeother"
                          id="cpap-typeother"
                          name="cpap-typeother"
                          placeholder=""
                          refvalue={(el) => (CpapRef.current["TypeOther"] = el)}
                        />
                      )}
                      <TextInputNotRequiredForm
                        title="Vendor"
                        type="cpap-vendor"
                        id="cpap-vendor"
                        name="cpap-vendor"
                        placeholder=""
                        refvalue={(el) => (CpapRef.current["Vendor"] = el)}
                      />
                      <TextInputNotRequiredForm
                        title="Device No (DN)"
                        type="cpap-device-no"
                        id="cpap-device-no"
                        name="cpap-device-no"
                        placeholder=""
                        refvalue={(el) => (CpapRef.current["DeviceNo"] = el)}
                      />
                      <TextInputNotRequiredForm
                        title="Lot No"
                        type="cpap-lot-no"
                        id="cpap-lot-no"
                        name="cpap-lot-no"
                        placeholder=""
                        refvalue={(el) => (CpapRef.current["LotNo"] = el)}
                      />
                      <br /> <br />
                      <TextInputNotRequiredForm
                        title="Incoming Date"
                        id="cpap-incoming"
                        name="cpap-incoming"
                        type="date"
                        refvalue={(el) =>
                          (CpapRef.current["IncomingDate"] = el)
                        }
                      />
                      <TextInputNotRequiredForm
                        title="Warranty Start"
                        id="cpap-WarrantyStart"
                        name="cpap-WarrantyStart"
                        type="date"
                        refvalue={(el) =>
                          (CpapRef.current["WarrantyStart"] = el)
                        }
                      />
                      <TextInputNotRequiredForm
                        title="Warranty End"
                        id="cpap-WarrantyEnd"
                        name="cpap-WarrantyEnd"
                        type="date"
                        refvalue={(el) => (CpapRef.current["WarrantyEnd"] = el)}
                      />
                      <DropDownForm
                        title="Maintenance Period"
                        htmlFor="cpap-MaintenancePeriod"
                        id="cpap-MaintenancePeriod"
                        name="cpap-MaintenancePeriod"
                        placeholder="กดเพื่อเลือก"
                        options={Choices["MaintenancePeriod"]}
                        refvalue={(el) =>
                          (CpapRef.current["MaintenancePeriod"] = el)
                        }
                      />
                      <TextInputNotRequiredForm
                        title="Condition"
                        id="cpap-Condition"
                        name="cpap-Condition"
                        refvalue={(el) => (CpapRef.current["Condition"] = el)}
                      />
                      <br /> <br />
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CpapRegister;
