import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import ProductItem from "./ProductItem";
import selectCpaps from "../../../selectors/cpaps";
import DetailModal from "./DetailModal";
import {
  startSetCpaps,
  startEditCpap,
  startRemoveCpap,
} from "../../../actions/cpaps";
import PageTitle from "../../layouts/PageTitle";

const ProductList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState({});

  const handleOnEdit = (id) => {
    setEdit(props.cpaps.find((c) => c.id === id));
    setShowModal(true);
  };

  const handleOnHide = (sn) => {
    setShowModal(false);
  };

  const handleOnDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        props.startRemoveCpap({ id });
        swal(`Poof! Your file has been deleted!`, {
          icon: "success",
        });
      } else {
        swal("Canceled");
      }
    });
  };

  useEffect(() => {
    props.startSetCpaps();
  }, []);
  return (
    <Fragment>
      <PageTitle activeMenu="Product List" motherMenu="Sales" />
      <DetailModal
        show={showModal}
        hide={handleOnHide}
        cpap={edit}
        onEditSubmit={(id, updates) => props.startEditCpap(id, updates)}
      />
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <Link
          to="/product-register"
          className="btn btn-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Product Registration"
        >
          + New Product
        </Link>
        <div className="input-group search-area ml-auto d-inline-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search here"
          />
          <div className="input-group-append">
            <span className="input-group-text c-pointer">
              <i className="flaticon-381-search-2"></i>
            </span>
          </div>
        </div>
      </div>
      <ProductItem
        cpaps={props.cpaps}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  cpaps: selectCpaps(state.cpaps, { text: "" }),
});

const mapDispatchToProps = (dispatch) => ({
  startSetCpaps: () => dispatch(startSetCpaps()),
  startEditCpap: (id, updates) => dispatch(startEditCpap(id, updates)),
  startRemoveCpap: (id) => dispatch(startRemoveCpap(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
