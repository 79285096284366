import { db } from "../firebase";
import {
  setDoc,
  getDocs,
  getDoc,
  collection,
  updateDoc,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";

import uuid from "uuid";
// ADD CPAP
export const addCpap = (cpap) => ({
  type: "ADD_CPAP",
  cpap,
});

export const startAddCpap = (cpapData = {}) => {
  return async (dispatch) => {
    const {
      serialNumber = null,
      brand = "",
      model = "",
      description = "",
      type = "",
      vendor = "",
      deviceNo = "",
      lotNo = "",
      incomingDate = 0,
      status = "",
      note = "",
      createdBy = "",
      updatedAt = 0,
      createdAt = 0,
    } = cpapData;
    const cpap = {
      serialNumber,
      brand,
      model,
      description,
      type,
      vendor,
      deviceNo,
      lotNo,
      incomingDate,
      status,
      note,
      createdBy,
      updatedAt,
      createdAt,
    };
    // console.log("cpap =>", cpap);

    const docRef = doc(collection(db, "products"));
    await setDoc(docRef, cpap)
      .then(() => {
        // console.log("set doc", docRef);
        updateDoc(doc(db, "products", docRef.id), {
          id: docRef.id,
        });
        dispatch(
          addCpap({
            id: docRef.id,
            ...cpap,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// REMOVE CPAP
export const removeCpap = ({ id } = {}) => ({
  type: "REMOVE_CPAP",
  id,
});

export const startRemoveCpap = ({ id }) => {
  return async (dispatch) => {
    const docRef = doc(db, "products", id);
    return await deleteDoc(docRef).then(() => {
      dispatch(removeCpap({ id }));
    });
  };
};

// EDIT CPAP
export const editCpap = (id, updates) => ({
  type: "EDIT_CPAP",
  id,
  updates,
});

export const startEditCpap = (id, updates) => {
  return async (dispatch) => {
    const docRef = doc(db, "products", id);

    // console.log("startEditCpap", updates);
    const f = [];

    return await updateDoc(docRef, updates).then(() => {
      dispatch(editCpap(id, updates));
    });
  };
};

// SET CPAP
export const setCpap = (cpap) => ({
  type: "SET_CPAP",
  cpap,
});
export const startSetCpap = (id) => {
  return async (dispatch) => {
    const docRef = doc(db, "products", id);

    return await getDoc(docRef).then((snapshot) => {
      dispatch(setCpap(snapshot.data()));
      console.log(snapshot.data());
    });
  };
};

// SET CPAPS
export const setCpaps = (cpaps) => ({
  type: "SET_CPAPS",
  cpaps,
});
export const startSetCpaps = () => {
  return async (dispatch) => {
    const docRef = collection(db, "products");
    return await getDocs(docRef).then((snapshot) => {
      const cpaps = [];
      snapshot.forEach(async (childSnapshot) => {
        cpaps.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });

      dispatch(setCpaps(cpaps));
    });
  };
};
