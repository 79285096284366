import React from "react";

import EventCalendar from "./EventCalendar";

import PageTitle from "../../layouts/PageTitle";

const Booking = () => {
  return (
    <div className="h-80">
      <PageTitle activeMenu="Calendar" motherMenu="Booking" />
      <EventCalendar />
    </div>
  );
};

export default Booking;
