import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useAuth } from "../../../context/AuthContext";
import PageTitle from "../../layouts/PageTitle";
import { db } from "../../../firebase";
import { getDocs, getDoc, collection, doc } from "firebase/firestore";
import NodeRegister from "./NodeRegister";
import EditCustomerModal from "./EditCustomerModal";
import EditNodeModal from "./EditNodeModal";

const NodeList = () => {
  const [nodes, setNodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth(); // Access currentUser from useAuth hook
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
  const [showEditNodeModal, setShowEditNodeModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [nodeEdit, setNodeEdit] = useState({});

  const addNode = (node) => {
    setNodes((prevNodes) => [node, ...prevNodes]);
  };

  const getAllNodes = async () => {
    try {
      const nodesSnapshot = await getDocs(collection(db, "nodes"));
      const nodePromises = nodesSnapshot.docs.map(async (doc) => {
        const nodeData = doc.data();
        const customersSnapshot = await getDocs(
          collection(db, "nodes", doc.id, "customers")
        );
        const customersData = customersSnapshot.docs.map((customerDoc) => ({
          ...customerDoc.data(),
          emailList: customerDoc.data().emailList || [], // Ensure emailList is included
          id: customerDoc.id, // Include the ID for editing purposes
        }));
        return { ...nodeData, customers: customersData, id: doc.id }; // Include the node ID
      });

      const allNodes = await Promise.all(nodePromises);
      return allNodes;
    } catch (error) {
      console.error("Error fetching nodes:", error);
      throw error; // or handle it as needed
    }
  };

  const handleOnNewNode = () => {
    setShowRegisterModal(true);
  };

  const handleOnRegisterHide = () => {
    setShowRegisterModal(false);
  };

  const handleEditCustomer = (customer, nodeId) => {
    setSelectedCustomer(customer);
    setSelectedNodeId(nodeId);
    setShowEditCustomerModal(true);
  };

  const handleCustomerUpdate = (customerId, updatedData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.id === selectedNodeId) {
          return {
            ...node,
            customers: node.customers.map((customer) =>
              customer.id === customerId
                ? { ...customer, ...updatedData }
                : customer
            ),
          };
        }
        return node;
      })
    );
  };

  const handleEditNode = (nodeId) => {
    const node = nodes.find((c) => c.id === nodeId);
    setNodeEdit(node);
    setSelectedNodeId(nodeId);
    setShowEditNodeModal(true);
  };

  const handleNodeUpdate = (nodeId, updatedData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId ? { ...node, ...updatedData } : node
      )
    );
  };

  useEffect(() => {
    const fetchNodes = async () => {
      const currentAdmin = await getDoc(
        doc(db, "admins", currentUser.displayName)
      );
      getAllNodes()
        .then((allNodes) => {
          if (currentAdmin.data().role === "super_admin") {
            setNodes(allNodes);
          } else {
            const currentNodeCode = currentAdmin.data().node;
            const node = allNodes.filter(
              (doc) => doc.nodecode === currentNodeCode
            );
            setNodes(node);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching nodes:", error);
        });
    };

    fetchNodes();
  }, [currentUser]);

  return (
    <React.Fragment>
      <PageTitle activeMenu="Our Nodes" motherMenu="Node" />
      <Button
        onClick={handleOnNewNode}
        className="btn btn-danger"
        data-toggle="tooltip"
        data-placement="top"
        title="Item Registration"
      >
        + New Node
      </Button>
      {!isLoading && (
        <>
          <div className="col-xl-12 col-xxl-12">
            <div className="row">
              {nodes.map((node, index) => (
                <div className="card col-xl-6 col-lg-6 col-sm-6" key={index}>
                  <div className="card-body height600">
                    <PatientTextInfo
                      title={"Node Name: "}
                      value={node.nodename}
                      bold={true}
                    />
                    <PatientTextInfo
                      title={"Code: "}
                      value={node.nodecode}
                      bold={true}
                    />
                    {/* <Button
                      size="sm"
                      variant="link"
                      onClick={() => handleEditNode(node.id)}
                    >
                      Edit Node
                    </Button> */}
                    {/* Pass currentUser as a prop */}
                    <NodeCustomerInfo
                      title={"Refer: "}
                      value={node.customers}
                      nodeId={node.id}
                      onEditCustomer={handleEditCustomer}
                      currentUser={currentUser}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <NodeRegister
        item={addNode}
        show={showRegisterModal}
        hide={handleOnRegisterHide}
      />
      {showEditCustomerModal && selectedCustomer && (
        <EditCustomerModal
          show={showEditCustomerModal}
          onHide={() => setShowEditCustomerModal(false)}
          customer={selectedCustomer}
          nodeId={selectedNodeId}
          onUpdate={handleCustomerUpdate}
        />
      )}
      {showEditNodeModal && (
        <EditNodeModal
          show={showEditNodeModal}
          onHide={() => setShowEditNodeModal(false)}
          node={nodeEdit}
          nodeId={selectedNodeId}
          onUpdate={handleNodeUpdate}
        />
      )}
    </React.Fragment>
  );
};

const PatientTextInfo = ({ title = "", value = "", bold = false }) => (
  <div className="form-group row">
    <h1 className="col-lg-3 col-form-label" htmlFor="val-username">
      {title}
    </h1>
    <div className="col-lg-9 mt-2">
      {bold ? <b>{value}</b> : <text>{value}</text>}
    </div>
  </div>
);

const NodeCustomerInfo = ({
  title = "",
  value = [],
  nodeId,
  onEditCustomer,
  currentUser, // Accept currentUser as a prop
}) => (
  <div className="form-group row">
    <h1 className="col-lg-3 col-form-label" htmlFor="val-username">
      {title}
    </h1>
    <div className="col-lg-9">
      {value.map((customer, index) => (
        <div className="mt-2" key={index}>
          {customer.CustomerNameTH}{" "}
          {currentUser.roles[0] === "super_admin" && (
            <Button
              size="sm"
              variant="link"
              onClick={() => onEditCustomer(customer, nodeId)}
            >
              Edit
            </Button>
          )}
        </div>
      ))}
    </div>
  </div>
);

export default NodeList;
