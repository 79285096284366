import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { CpapTable } from "./CpapUtils";

class CpapList extends Component {
  constructor(props) {
    super(props);
    this.state = { textSearch: "", sortBy: "Type", statusBy: "ทั้งหมด" };
  }

  onClick = (e) => {
    e.preventDefault();
    console.log("onclick..");
  };

  onSearchChange = (e) => {
    setTimeout(() => {
      this.setState({ textSearch: e.target.value });
    }, 800);
  };

  onFilterChange = (option) => {
    this.setState({ sortBy: `${option}` });
  };

  onStatusChange = (option) => {
    this.setState({ statusBy: `${option}` });
  };

  render() {
    // var FilterOption = "Type" ;

    // this.state.sortBy = FilterOption ;

    return (
      <React.Fragment>
        <PageTitle activeMenu="Search CPAP" motherMenu="CPAP" motherLink="/cpap-list" />
        <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
          <Link to="/cpap-register" className="btn btn-danger">
            + New CPAP
          </Link>
          <div className="input-group search-area ml-auto d-inline-flex">
            <input
              type="text"
              className="form-control"
              placeholder="Search here"
              onChange={this.onSearchChange}
            />
            <div className="input-group-append">
              <span className="input-group-text c-pointer">
                <i className="flaticon-381-search-2"></i>
              </span>
            </div>
          </div>
          <Dropdown className="ml-3 width200" onSelect={this.onFilterChange}>
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
              <i className="flaticon-381-controls-3 "></i> by{" "}
              {this.state.sortBy == "LastDueDate"
                ? "Due Date"
                : this.state.sortBy}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item href="#" eventKey={"Type"}>
                Type
              </Dropdown.Item>
              <Dropdown.Item href="#" eventKey={"Brand"}>
                Brand
              </Dropdown.Item>
              <Dropdown.Item href="#" eventKey={"Model"}>
                Model
              </Dropdown.Item>
              <Dropdown.Item href="#" eventKey={"LastDueDate"}>
                Due Date
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="ml-1 width200" onSelect={this.onStatusChange}>
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
              <i className="flaticon-381-menu-1"></i> {this.state.statusBy}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item href="#" eventKey={"ทั้งหมด"}>
                ทั้งหมด
              </Dropdown.Item>
              <Dropdown.Item href="#" eventKey={"ว่าง"}>
                ว่าง
              </Dropdown.Item>
              <Dropdown.Item href="#" eventKey={"ไม่ว่าง"}>
                ไม่ว่าง
              </Dropdown.Item>
              <Dropdown.Item href="#" eventKey={"ยกเลิกใช้งาน"}>
                ยกเลิกใช้งาน
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <CpapTable
          text={this.state.textSearch}
          sortBy={this.state.sortBy}
          statusBy={this.state.statusBy}
        />
      </React.Fragment>
    );
  }
}

export default CpapList;
