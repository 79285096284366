import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import { useAuth } from "../../../context/AuthContext";
import swal from "sweetalert";

import { functions } from "../../../firebase";

import {
  startAddReport,
  startSetReports,
  startRemoveReport,
  startEditReport,
} from "../../../actions/reports";
import { httpsCallable } from "firebase/functions";
import selectReports from "../../../selectors/reports";
import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import PatientReportForm from "./PatientReportForm";
import PatientReportItem from "./PatientReportItem";
const PatientReport = ({
  profile,
  reportsList,
  startAddReport,
  startSetReports,
  startRemoveReport,
}) => {
  const getSignedUrl = httpsCallable(functions, "getSignedUrl");
  const { currentUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [reportEditData, setReportEditData] = useState(null);
  const [isLoding, setIsLoading] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [reports, setReports] = useState([]);

  const [fileUrl, setFileUrl] = useState(null);

  const onSubmit = async (files) => {
    if (!isEdit) {
      const report = {
        hn: profile.hn,
        createBy: currentUser.displayName,
        createAt: new Date(),
      };
      startAddReport(report, files);
    } else {
      setIsEdit(false);
    }
  };

  const onDelete = ({ hn, id }) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        startRemoveReport({ hn, id });

        swal(`Poof! Your file has been deleted!`, {
          icon: "success",
        });
      } else {
        swal("Canceled");
      }
    });
  };

  const onEdit = (data) => {
    setIsEdit(true);
    setReportEditData(data);
  };

  const onCancelEdit = () => {
    setIsEdit(false);
  };

  const onDownLoad = (hn, id, file) => {
    console.log(id);
    getSignedUrl({
      folder: "reports",
      hn,
      id,
      filename: file,
    })
      .then((result) => {
        fetch(result.data).then((res) => {
          res.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);

            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = `${file}`;
            alink.click();
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onViewFile = (hn, id, file) => {
    console.log(id);
    getSignedUrl({
      folder: "reports",
      hn,
      id,
      filename: file,
    })
      .then((result) => {
        setFileUrl(result.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setCreateModal(true);
  };

  useEffect(() => {
    setIsLoading(true);
    startSetReports(profile.hn);
    setIsLoading(false);
    // console.log("reportList", reportsList);
  }, [profile]);

  return (
    <Fragment>
      <Toast />
      <Modal
        className="fade bd-example-modal-lg"
        show={createModal}
        onHide={() => setCreateModal(false)}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <div
            className="card-body mx-0 p-0 col-lg-12"
            style={{ height: "85vh" }}
          >
            <iframe
              className="col-lg-12 m-0 p-0"
              src={fileUrl + "#toolbar=1"}
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <PatientReportForm hn={profile.hn} onSubmit={onSubmit} />
      <text>
      Please upload the Sleep Study Report (SS-123-report-signed) and Medical Certificate (SS-123-medical-certificate-signed)
      </text>
      {/* {!isEdit ? (
        <PatientReportForm
          onSubmit={onSubmit}
          hn={profile.hn}
          createBy={currentUser.displayName}
        />
      ) : (
        <PatientReportEditForm
          onSubmit={onSubmit}
          onCancel={onCancelEdit}
          hn={profile.hn}
          createBy={currentUser.displayName}
          note={noteEditData}
        />
      )} */}
      {!isLoding && (
        <PatientReportItem
          reports={reportsList}
          hn={profile.hn}
          onEdit={onEdit}
          onDelete={onDelete}
          onViewFile={onViewFile}
          onDownLoad={onDownLoad}
        />
      )}
    </Fragment>
  );
};

// export default PatientReport;
const mapStateToProps = (state, props) => ({
  reportsList: selectReports(state.reports),
});

const mapDispatchToProps = (dispatch) => ({
  startSetReports: (reports) => dispatch(startSetReports(reports)),
  startEditReport: (id, report, files) =>
    dispatch(startEditReport(id, report, files)),
  startRemoveReport: (data) => dispatch(startRemoveReport(data)),
  startAddReport: (report, files) => dispatch(startAddReport(report, files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientReport);
