import React, { Fragment, useState, useRef } from "react";
import _ from "lodash";
import RegisterFields from "./RegisterFields";
import orderFormFields from "./orderFormFields";

export const OrderEditForm = ({ onChange, initailValues }) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(
      orderFormFields,
      ({ label, name, type, options, required }) => {
        return (
          <RegisterFields
            name={name}
            label={label}
            type={type}
            value={inputRef.current.name}
            options={options}
            required={required}
            initailValue={
              type !== "date"
                ? initailValues[name]
                : initailValues[name]
                ? initailValues[name].toDate().toLocaleDateString("en-CA")
                : ""
            }
            disabled={
              name === "receiptNo" || name === "hn" || name === "refHospital" || name === "refCustomer"
                ? true
                : false
            }
            onChange={onChange(name)}
          />
        );
      }
    );
  };

  return <Fragment>{renderFields()}</Fragment>;
};
