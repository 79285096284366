import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { OrderEditForm } from "./OrderEditForm";
import { db } from "../../../firebase";
import { setDoc, getDoc, updateDoc, doc, collection } from "firebase/firestore";

const DetailModal = (props) => {
  const inputRef = useRef({
    receiptNo: null,
    hn: null,
    amount: null,
    salePackage: null,
    mask: null,
    maskSize: null,
    receiptDate: null,
    refHospital: null,
    refPhysician: null,
    note: null,
    status: null,
    activity: null,
    note: null,
  });

  // console.log(props.order);

  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };
  const handleOnHideModal = () => {
    props.hide();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ref = doc(db, "patients", props.order.hn);
    const docSnap = await getDoc(ref);

    const formData = {
      receiptNo: inputRef.current.receiptNo
        ? inputRef.current.receiptNo
        : props.order.receiptNo,
      hn: inputRef.current.hn ? inputRef.current.hn : props.order.hn,
      amount: inputRef.current.amount
        ? inputRef.current.amount
        : props.order.amount,
      salePackage: inputRef.current.salePackage
        ? inputRef.current.salePackage
        : props.order.salePackage ? props.order.salePackage : "",
      mask: inputRef.current.mask ? inputRef.current.mask : props.order.mask,
      maskSize: inputRef.current.maskSize
        ? inputRef.current.maskSize
        : props.order.maskSize,
      refHospital:
        docSnap.data().refHn === undefined ? "" : docSnap.data().refHn,
      refPhysician: inputRef.current.refPhysician
        ? inputRef.current.refPhysician
        : props.order.refPhysician,
      status: inputRef.current.status
        ? inputRef.current.status
        : props.order.status,
      receiptDate: inputRef.current.receiptDate
        ? new Date(inputRef.current.receiptDate)
        : props.order.receiptDate,
      activity: inputRef.current.activity
        ? inputRef.current.activity
        : props.order.activity,
      sales: inputRef.current.sales
        ? inputRef.current.sales
        : props.order.sales ? props.order.sales : "",
      note: inputRef.current.note ? inputRef.current.note : props.order.note,
      productId: props.order.productId,
      updatedAt: new Date(),

      firstname: docSnap.data().firstnameTh,
      lastname: docSnap.data().lastnameTh,
      nodeRef:
        docSnap.data().nodeRef === undefined ? "" : docSnap.data().nodeRef,
      refCustomer: docSnap.data().refCustomer === undefined ? "" : docSnap.data().refCustomer,
      mobile: docSnap.data().mobile,
      address: docSnap.data().address1,
    };
    console.log(formData);
    props.onEditSubmit(props.order.id, formData);
    props.hide();
  };

  return (
    <Fragment>
      <Modal
        className="fade bd-example-modal-lg"
        show={props.show}
        onHide={handleOnHideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Edit Order Datails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderEditForm onChange={handleChange} initailValues={props.order} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>

          <Button
            className="sm ml-2"
            variant="light"
            onClick={handleOnHideModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default DetailModal;
