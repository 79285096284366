const nightTimeHabitData = {
    position: "",
    snore: "",
    snoreLevel: "",
    dream: "",
    muscleWeakness: "",
    hallucination: "",
    apnea: "",
    nocturia: "",
    headache: "",
    dryThroat: "",
    daydream: "",
    daydreamTalk: "",
    daydreamWalk: "",
    daydreamScream: "",
    daydreamEat: "",
    daydreamSex: "",
    daydreamOther: "",
    daydreamOtherNote: "",
    daydreamLikeDream: "",
    daydreamLikePunch: "",
    daydreamLikeOther: "",
    daydreamLikeOtherNote: "",
    spasm: "",
    spasmNeck: "",
    spasmArm: "",
    spasmLeg: "",
    spasmBody: "",
    spasmFace: "",
    spasmOther: "",
    spasmOtherNote: "",
    bruxism: "",
  };

  export default nightTimeHabitData