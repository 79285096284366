import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../toast/SetToast";
import Toast from "../../toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import {
  daytimeBehavior,
  daytimeBehaviorQuestions,
} from "../formData/daytimeBehaviorData";
import { InputBox, InputArea, DropdownBox } from "../formData/inputComponents";
import profileData from "../formData/profileData";

const DaytimeBehavior = () => {
  const daytimeBehaviorRef = useRef(daytimeBehavior.info);
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [daytimeBehaviorDoc, setDaytimeBehaviorDoc] = useState(daytimeBehavior);
  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();

  const InputRadio = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
    required,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        required={required}
                        className="form-check-input"
                        type="radio"
                        name={name}
                        value={value}
                        // id={id}
                        onChange={(e) => {
                          daytimeBehaviorRef.current[refValue] = e.target.value;
                          console.log(daytimeBehaviorRef.current[refValue]);
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  daytimeBehaviorRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    var info = {
      discomfortLeg: daytimeBehaviorRef.current["discomfortLeg"],
      discomfortLegWhileSit:
        daytimeBehaviorRef.current["discomfortLegWhileSit"],
      comfortLegWhileActive:
        daytimeBehaviorRef.current["comfortLegWhileActive"],
      moreDiscomfortWhileSleep:
        daytimeBehaviorRef.current["moreDiscomfortWhileSleep"],
      relative: daytimeBehaviorRef.current["relative"],
      readingScore: daytimeBehaviorRef.current["readingScore"].value,
      watchingTVScore: daytimeBehaviorRef.current["watchingTVScore"].value,
      sittingScore: daytimeBehaviorRef.current["sittingScore"].value,
      sitInCarScore: daytimeBehaviorRef.current["sitInCarScore"].value,
      daytimeRestScore: daytimeBehaviorRef.current["daytimeRestScore"].value,
      sitAndTalkingScore:
        daytimeBehaviorRef.current["sitAndTalkingScore"].value,
      afterLunchScore: daytimeBehaviorRef.current["afterLunchScore"].value,
      drivingScore: daytimeBehaviorRef.current["drivingScore"].value,
      totalScore: daytimeBehaviorRef.current["totalScore"].value,
      muscleWeakness: daytimeBehaviorRef.current["muscleWeakness"],
      muscleWeaknessNote:
        daytimeBehaviorRef.current["muscleWeaknessNote"].value,
    };

    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };
  const NarcolepsyScore = () => {
    var narcolepsyList = [
      Number(daytimeBehaviorRef.current["readingScore"].value),
      Number(daytimeBehaviorRef.current["watchingTVScore"].value),
      Number(daytimeBehaviorRef.current["sittingScore"].value),
      Number(daytimeBehaviorRef.current["sitInCarScore"].value),
      Number(daytimeBehaviorRef.current["daytimeRestScore"].value),
      Number(daytimeBehaviorRef.current["sitAndTalkingScore"].value),
      Number(daytimeBehaviorRef.current["afterLunchScore"].value),
      Number(daytimeBehaviorRef.current["drivingScore"].value),
    ];
    var totalScore = 0;
    for (let i = 0; i < narcolepsyList.length; i++) {
      totalScore += narcolepsyList[i];
    }
    // console.log("totalScore: ", totalScore);
    daytimeBehaviorRef.current["totalScore"].value = totalScore;
  };
  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      console.log(docSnapForm.data());
      console.log(daytimeBehavior.formname);
      setDaytimeBehaviorDoc(docSnapForm.data());
      setLoading(false);
      daytimeBehaviorRef.current["discomfortLeg"] =
        docSnapForm.data().info.discomfortLeg;
      daytimeBehaviorRef.current["discomfortLegWhileSit"] =
        docSnapForm.data().info.discomfortLegWhileSit;
      daytimeBehaviorRef.current["comfortLegWhileActive"] =
        docSnapForm.data().info.comfortLegWhileActive;
      daytimeBehaviorRef.current["moreDiscomfortWhileSleep"] =
        docSnapForm.data().info.moreDiscomfortWhileSleep;
      daytimeBehaviorRef.current["relative"] = docSnapForm.data().info.relative;
      daytimeBehaviorRef.current["muscleWeakness"] =
        docSnapForm.data().info.muscleWeakness;

      return;
    }

    const ref = await doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);

  return (
    <Fragment>
      <Toast />
      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>
              ข้อมูลเกี่ยวกับพฤติกรรมนอนกลางวัน (Day time behavior)
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {!isLoading ? (
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-7">
                      <h4 className="mb-4">
                        <b>ปกติท่านมีอาการเหล่านี้หรือไม่</b>
                      </h4>
                      <InputRadio
                        title={daytimeBehaviorQuestions.Q1}
                        options={["มี", "ไม่มี"]}
                        name={daytimeBehaviorQuestions.Q1}
                        checked={daytimeBehaviorDoc.info.discomfortLeg}
                        refValue={"discomfortLeg"}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={daytimeBehaviorQuestions.Q2}
                        options={["มี", "ไม่มี"]}
                        name={daytimeBehaviorQuestions.Q2}
                        checked={daytimeBehaviorDoc.info.discomfortLegWhileSit}
                        refValue={"discomfortLegWhileSit"}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={daytimeBehaviorQuestions.Q3}
                        options={["มี", "ไม่มี"]}
                        name={daytimeBehaviorQuestions.Q3}
                        checked={daytimeBehaviorDoc.info.comfortLegWhileActive}
                        refValue={"comfortLegWhileActive"}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={daytimeBehaviorQuestions.Q4}
                        options={["มี", "ไม่มี"]}
                        name={daytimeBehaviorQuestions.Q4}
                        checked={
                          daytimeBehaviorDoc.info.moreDiscomfortWhileSleep
                        }
                        refValue={"moreDiscomfortWhileSleep"}
                        required={true}
                      ></InputRadio>
                      <InputRadio
                        title={daytimeBehaviorQuestions.Q5}
                        options={["มี", "ไม่มี"]}
                        name={daytimeBehaviorQuestions.Q5}
                        checked={daytimeBehaviorDoc.info.relative}
                        refValue={"relative"}
                        required={true}
                      ></InputRadio>
                      <fieldset className="form-group">
                        <div className="row mt-3">
                          <div className="col-xl-5 col-form-label pt-0">
                            <label>
                              ท่านหรือผู้ป่วย
                              สังเกตเห็นความเป็นไปได้แค่ไหนที่คุณจะง่วงจนงีบหรือเผลอหลับในสถานการณ์ต่าง
                              ๆ ดังต่อไปนี้ โดยที่ไม่ใช่เพียงแค่รู้สึกอ่อนเพลีย
                              ทั้งนี้หมายถึงการดำเนินชีวิตปกติของคุณในช่วงที่ผ่านมาไม่นาน
                            </label>
                            <b>
                              และแม้ว่าคุณจะไม่ได้ทำสิ่งต่างๆที่ว่าในช่วงไม่นานมานี้
                              ให้ลองนึกว่า
                              สถานการณ์ข้างงล่างนี้จะมีผลต่อคุณอย่างไร{" "}
                            </b>
                            <label className="mt-1">
                              กรุณาใช้เกณฑ์การให้คะแนนด้านข้างเพื่อเลือกคะแนนที่เหมาะสมที่สุดสำหรับแต่ละสถานการณ์:
                            </label>
                          </div>
                          <div className="col-xl-6">
                            <label>
                              0 = ไม่มีความเป็นไปได้ที่จะงีบหรือเผลอหลับ
                            </label>
                            <label>
                              1 = มีความเป็นไปได้ที่จะงีบหรือเผลอหลับ เล็กน้อย
                              (นาน ๆ ครั้ง)
                            </label>
                            <label>
                              2 = มีความเป็นไปได้ที่จะงีบหรือเผลอหลับ ปานกลาง
                            </label>
                            <label>
                              3 = มีความเป็นไปได้ที่จะงีบหรือเผลอหลับ สูง
                              (ประจำ)
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      <DropdownBox
                        title={daytimeBehaviorQuestions.Q6}
                        id={daytimeBehaviorQuestions.Q6}
                        name={daytimeBehaviorQuestions.Q6}
                        classStr="col-lg-4"
                        placeholder="กดเพื่อเลือก"
                        onChange={NarcolepsyScore}
                        options={["0", "1", "2", "3"]}
                        required={true}
                        refvalue={(el) =>
                          (daytimeBehaviorRef.current["readingScore"] = el)
                        }
                        defaultValue={daytimeBehaviorDoc.info.readingScore}
                      />
                      <DropdownBox
                        title={daytimeBehaviorQuestions.Q7}
                        id={daytimeBehaviorQuestions.Q7}
                        name={daytimeBehaviorQuestions.Q7}
                        classStr="col-lg-4"
                        placeholder="กดเพื่อเลือก"
                        onChange={NarcolepsyScore}
                        options={["0", "1", "2", "3"]}
                        required={true}
                        refvalue={(el) =>
                          (daytimeBehaviorRef.current["watchingTVScore"] = el)
                        }
                        defaultValue={daytimeBehaviorDoc.info.watchingTVScore}
                      />
                      <DropdownBox
                        title={daytimeBehaviorQuestions.Q8}
                        id={daytimeBehaviorQuestions.Q8}
                        name={daytimeBehaviorQuestions.Q8}
                        classStr="col-lg-4"
                        placeholder="กดเพื่อเลือก"
                        onChange={NarcolepsyScore}
                        options={["0", "1", "2", "3"]}
                        required={true}
                        refvalue={(el) =>
                          (daytimeBehaviorRef.current["sittingScore"] = el)
                        }
                        defaultValue={daytimeBehaviorDoc.info.sittingScore}
                      />
                      <DropdownBox
                        title={daytimeBehaviorQuestions.Q9}
                        id={daytimeBehaviorQuestions.Q9}
                        name={daytimeBehaviorQuestions.Q9}
                        classStr="col-lg-4"
                        placeholder="กดเพื่อเลือก"
                        onChange={NarcolepsyScore}
                        options={["0", "1", "2", "3"]}
                        required={true}
                        refvalue={(el) =>
                          (daytimeBehaviorRef.current["sitInCarScore"] = el)
                        }
                        defaultValue={daytimeBehaviorDoc.info.sitInCarScore}
                      />
                      <DropdownBox
                        title={daytimeBehaviorQuestions.Q10}
                        id={daytimeBehaviorQuestions.Q10}
                        name={daytimeBehaviorQuestions.Q10}
                        classStr="col-lg-4"
                        placeholder="กดเพื่อเลือก"
                        onChange={NarcolepsyScore}
                        options={["0", "1", "2", "3"]}
                        required={true}
                        refvalue={(el) =>
                          (daytimeBehaviorRef.current["daytimeRestScore"] = el)
                        }
                        defaultValue={daytimeBehaviorDoc.info.daytimeRestScore}
                      />
                      <DropdownBox
                        title={daytimeBehaviorQuestions.Q11}
                        id={daytimeBehaviorQuestions.Q11}
                        name={daytimeBehaviorQuestions.Q11}
                        classStr="col-lg-4"
                        placeholder="กดเพื่อเลือก"
                        onChange={NarcolepsyScore}
                        options={["0", "1", "2", "3"]}
                        required={true}
                        refvalue={(el) =>
                          (daytimeBehaviorRef.current["sitAndTalkingScore"] =
                            el)
                        }
                        defaultValue={
                          daytimeBehaviorDoc.info.sitAndTalkingScore
                        }
                      />
                      <DropdownBox
                        title={daytimeBehaviorQuestions.Q12}
                        id={daytimeBehaviorQuestions.Q12}
                        name={daytimeBehaviorQuestions.Q12}
                        classStr="col-lg-4"
                        placeholder="กดเพื่อเลือก"
                        onChange={NarcolepsyScore}
                        options={["0", "1", "2", "3"]}
                        required={true}
                        refvalue={(el) =>
                          (daytimeBehaviorRef.current["afterLunchScore"] = el)
                        }
                        defaultValue={daytimeBehaviorDoc.info.afterLunchScore}
                      />
                      <DropdownBox
                        title={daytimeBehaviorQuestions.Q13}
                        id={daytimeBehaviorQuestions.Q13}
                        name={daytimeBehaviorQuestions.Q13}
                        classStr="col-lg-4"
                        placeholder="กดเพื่อเลือก"
                        onChange={NarcolepsyScore}
                        options={["0", "1", "2", "3"]}
                        required={true}
                        refvalue={(el) =>
                          (daytimeBehaviorRef.current["drivingScore"] = el)
                        }
                        defaultValue={daytimeBehaviorDoc.info.drivingScore}
                      />
                      <InputBox
                        title={daytimeBehaviorQuestions.Q14}
                        id={daytimeBehaviorQuestions.Q14}
                        measure="คะแนน"
                        placeholder=""
                        bold={true}
                        refValue={(el) =>
                          (daytimeBehaviorRef.current["totalScore"] = el)
                        }
                        defaultValue={daytimeBehaviorDoc.info.totalScore}
                        disabled={true}
                        classStr="col-xl-4"
                      ></InputBox>
                      <InputRadio
                        title={daytimeBehaviorQuestions.Q15}
                        options={["มี", "ไม่มี"]}
                        required={true}
                        name={daytimeBehaviorQuestions.Q15}
                        checked={daytimeBehaviorDoc.info.muscleWeakness}
                        refValue={"muscleWeakness"}
                      ></InputRadio>
                      <InputArea
                        title={daytimeBehaviorQuestions.Q16}
                        classStr="col-xl-6"
                        refValue={(el) =>
                          (daytimeBehaviorRef.current["muscleWeaknessNote"] =
                            el)
                        }
                        defaultValue={
                          daytimeBehaviorDoc.info.muscleWeaknessNote
                        }
                      ></InputArea>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        บันทึกข้อมูล
                      </button>
                      <button
                        onClick={() => {
                          history.push(`/patient-page/${id}`);
                        }}
                        type="submit"
                        className="btn btn-light ml-2"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default DaytimeBehavior;
