const STOPBANGQuestionnaireTitle = "STOP BANG Questionnaire";

const STOPBANGQuestionnaireData = {
  snored: "",
  tired: "",
  observed: "",
  bloodpressure: "",
  bmi: "",
  age: "",
  neck: "",
  gender: "",
};

const STOPBANGQuestionnaireQuestions = [
  "1. ท่านกรนดังกว่าเสียงพูด หรือดังขนาดที่สามารถได้ยินผ่านประตูที่ปิดหรือไม่",
  "2. ท่านรู้สึกเหนื่อย เพลีย หรือง่วงนอนตอนกลางวันหรือไม่",
  "3. เคยมีใครเห็นว่าท่านหยุดหายใจตอนนอนหลับหรือไม่",
  "4. ท่านมีโรคความดันโลหิตสูงหรือกำลังได้รับการรักษาโรคความดันโลหิตสูงหรือไsม่",
  "5. ท่านมี BMI มากกว่า 35 kg/m2",
  "6. ท่าอายุมากกว่าน 50 ปี",
  "7. ท่านมีรอบคอกว้างกว่า 40 cm วัดที่ระดับ thyroid cartilage จุดที่ protrude ที่สุดตรงคอ",
  "8. ท่านเป็นเพศชายใช่หรือไม่",
];

const STOPBANGQuestionnaireChoices = {
  snored: ["ใช่", "ไม่ใช่"],
  tired: ["ใช่", "ไม่ใช่"],
  observed: ["ใช่", "ไม่ใช่"],
  bloodpressure: ["ใช่", "ไม่ใช่"],
  bmi: ["ใช่", "ไม่ใช่"],
  age: ["ใช่", "ไม่ใช่"],
  neck: ["ใช่", "ไม่ใช่"],
  gender: ["ใช่", "ไม่ใช่"],
};

export {
  STOPBANGQuestionnaireData,
  STOPBANGQuestionnaireTitle,
  STOPBANGQuestionnaireChoices,
  STOPBANGQuestionnaireQuestions,
};
