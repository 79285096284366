import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/AuthContext";

import { Button } from "react-bootstrap";
import { EditForm } from "./EditForm";

const ProductDetail = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stateEdit, setStateEdit] = useState(true);
  const inputRef = useRef({
    serialNumber: null,
    brand: null,
    model: null,
    description: null,
    type: null,
    vendor: null,
    deviceNo: null,
    lotNo: null,
    incomingDate: null,
    note: null,
  });
  // console.log(props.cpap);
  const handleChange = (name) => (e) => {
    inputRef.current[name] = e.target.value;
  };
  const submitHandle = (e) => {
    e.preventDefault();
    const formData = {
      serialNumber: inputRef.current.serialNumber
        ? inputRef.current.serialNumber
        : props.cpap.serialNumber,
      brand: inputRef.current.brand ? inputRef.current.brand : props.cpap.brand,
      model: inputRef.current.model ? inputRef.current.model : props.cpap.model,
      description:
        inputRef.current.description != undefined
          ? inputRef.current.description
          : props.cpap.description,
      type: inputRef.current.type ? inputRef.current.type : props.cpap.type,
      vendor: inputRef.current.vendor
        ? inputRef.current.vendor
        : props.cpap.vendor,
      deviceNo:
        inputRef.current.deviceNo != undefined
          ? inputRef.current.deviceNo
          : props.cpap.deviceNo,
      lotNo:
        inputRef.current.lotNo != undefined
          ? inputRef.current.lotNo
          : props.cpap.lotNo,
      incomingDate: inputRef.current.incomingDate
        ? new Date(inputRef.current.incomingDate)
        : props.cpap.incomingDate,
      note:
        inputRef.current.note != undefined
          ? inputRef.current.note
          : props.cpap.note,
      updatedAt: new Date(),
    };
    props.onEdit({ ...formData, id: props.cpap.id });
    setStateEdit(!stateEdit);
  };
  return (
    <Fragment>
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <Button
          // onClick={() => {setStateEdit(!stateEdit)}}
          onClick={() => {
            // if (stateEdit == false) {
            //   Object.keys(props.cpap).forEach((key) => {
            //     try {
            //       CpapRef.current[key].value = CpapInfo[key];
            //     } catch (err) {
            //       CpapRef.current[key] = CpapInfo[key];
            //     }
            //   });
            //   setType(CpapInfo.Type);
            //   setBrand(CpapInfo.Brand);
            // }
            setStateEdit(!stateEdit);
          }}
          className={stateEdit ? "btn btn-dark" : "btn btn-blue"}
        >
          <i className="flaticon-381-clock mr-2"></i>Edit
        </Button>
      </div>

      {!isLoading && (
        <div className="row">
          <div className="col-lg-12">
            <div className="form-validation">
              <form
                className="form-valide"
                action="#"
                method="post"
                onSubmit={submitHandle}
              >
                <div className="row">
                  <div className="col-xl-6">
                    <EditForm
                      onChange={handleChange}
                      initailValues={props.cpap}
                      onEdit={stateEdit}
                    />
                    {!stateEdit && (
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ProductDetail;
