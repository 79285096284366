import React, { Fragment, useState, useRef } from "react";
import _ from "lodash";
import RegisterFields from "../RegisterFields";
import techFormFields from "./techFormFields";

export const TechEditForm = ({ onChange, initailValues, onChecked }) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(techFormFields, ({ label, name, type, options, required }) => {
      return (
        <RegisterFields
          name={name}
          label={label}
          type={type}
          value={inputRef.current.name}
          options={options}
          required={required}
          initailValue={initailValues[name]}
          disabled={name === "id" ? true : false}
          onChange={onChange(name)}
          checked={initailValues["roles"].map((item, idx) => item.status)}
          onChecked={onChecked}
        />
      );
    });
  };

  return <Fragment>{renderFields()}</Fragment>;
};
