import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "../PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";

const infoData = {
  gender: "",
  dateOfBirth: "",
  age: "",
  weight: "",
  height: "",
  mostWeightEver: "",
  weightFiveYearsAgo: "",

  embolism: "",
  heartattack: "",
  highBloodPressure: "",
  bloodClots: "",
  diabetes: "",
  hyperlipidemia: "",
  kidneyDisease: "",
  fattyLiver: "",
  overactiveThyroid: "",
  underactiveThtroid: "",
  pale: "",
  ironDeficiency: "",
  thalassemia: "",
  asthma: "",
  emphysema: "",
  pulmonaryHypertension: "",
  parkinson: "",
  muscleWeakness: "",
  depressed: "",
  other: "",
  otherNote: "",
  regularMedicine: "",
  regularMedicineNote: "",

  smoke: "",
  smokeTotalYear: "",
  smokePerDay: "",
  stopSmokeYear: "",
  stopSmokeTotalYear: "",
  stopSmokePerDay: "",

  alc: "",
  alcTotalYear: "",
  alcPerDay: "",
  stopAlcYear: "",
  stopAlcTotalYear: "",
  stopAlcPerDay: "",

  coffee: "",
  coffeePerDay: "",
  coffeePerWeek: "",

  soda: "",
  sodaPerDay: "",
  sodaPerWeek: "",

  apnea: "",
  apneaDetail: "",
  snore: "",
  snoreDetail: "",
  unusuallySleepy: "",
  unusuallySleepyDetail: "",
  insomnia: "",
  insomniaDetail: "",
};
const profileData = {
  username: "",
  email: "",
  titleTh: "",
  firstnameTh: "",
  lastnameTh: "",
  titleEn: "",
  firstnameEn: "",
  lastnameEn: "",
  dateOfBirth: "",
  status: "",
  ethnicity: "",
  nationality: "",
  religion: "",
  bloodType: "",
  note: "",
  gender: "",
  occupation: "",
  mobile: "",
  telephone: "",
  address1: "",
  address2: "",
  province: "",
  postcode: "",
  medicineIntolerance: "",
  healthInsurance: "",
  other: "",
  emergencyContact: "",
  relationship: "",
  emergencyContactPhone: "",
};
const demographicData = {
  assign: false,
  formname: "ข้อมูลพื้นฐาน",
  id: "1",
  info: infoData,
  submit: false,
};
const Demographic = () => {
  // const profileRef = useRef(profileData);
  const demographicRef = useRef(infoData);
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [demographicDoc, setDemographicDoc] = useState(demographicData);
  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();
  // console.log("uuid: ", uuid);
  const DropdownBox = ({
    title = "",
    htmlFor = "",
    id = "",
    name = "",
    refvalue,
    placeholder = "Please select",
    options = [""],
    defaultValue,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-4 col-form-label" htmlFor={htmlFor}>
          {title}
          <span className="text-danger"></span>
        </label>
        <div className="col-xl-4">
          <select
            className="form-control"
            id={id}
            name={name}
            ref={refvalue}
            defaultValue={defaultValue}
          >
            {options.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const InputBox = ({
    title = "",
    value = "",
    placeholder = "",
    id = "",
    measure = "",
    refValue,
    noBox = false,
    defaultValue,
    required,
    type = "text",
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-4 col-form-label mt-2">{title}</label>
        <div className="col-xl-4">
          {noBox == false && (
            <input
              type={type}
              className="form-control"
              placeholder={placeholder == "" ? title : placeholder}
              id={id}
              ref={refValue}
              defaultValue={defaultValue}
              required={required}
            />
          )}
        </div>
        <div className="ml-3 mt-3">
          <text>{measure}</text>
        </div>
      </div>
    );
  };
  const InputRadio = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    refNote,
    required,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-4 col-form-label pt-0">{title}</label>
          <div className="col-xl-2">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={name}
                        value={value}
                        required={required}
                        // id={id}
                        onChange={(e) => {
                          demographicRef.current[refValue] = e.target.value;
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  demographicRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };
  const InputCheckBox = ({
    title = "",
    id = "",
    options = [""],
    checked = [""],
    name = "",
    refValue,
    mt = "row mt-3",
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-4 col-form-label pt-0">{title}</label>
          <div className="col-xl-2">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          // demographicRef.current[refValue] = e.target.value;
                          console.log(e.target.value);
                        }}
                        defaultChecked={
                          checked.includes(value) ? "checked" : ""
                        }
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </fieldset>
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    var info = {
      gender: demographicRef.current["gender"].value,
      dateOfBirth: demographicRef.current["dateOfBirth"].value,
      age: demographicRef.current["age"].value,
      weight: demographicRef.current["weight"].value,
      height: demographicRef.current["height"].value,
      mostWeightEver: demographicRef.current["mostWeightEver"].value,
      weightFiveYearsAgo: demographicRef.current["weightFiveYearsAgo"].value,

      embolism: demographicRef.current["embolism"],
      heartattack: demographicRef.current["heartattack"],
      highBloodPressure: demographicRef.current["highBloodPressure"],
      bloodClots: demographicRef.current["bloodClots"],
      diabetes: demographicRef.current["diabetes"],
      hyperlipidemia: demographicRef.current["hyperlipidemia"],
      kidneyDisease: demographicRef.current["kidneyDisease"],
      fattyLiver: demographicRef.current["fattyLiver"],
      overactiveThyroid: demographicRef.current["overactiveThyroid"],
      underactiveThtroid: demographicRef.current["underactiveThtroid"],
      pale: demographicRef.current["pale"],
      ironDeficiency: demographicRef.current["ironDeficiency"],
      thalassemia: demographicRef.current["thalassemia"],
      asthma: demographicRef.current["asthma"],
      emphysema: demographicRef.current["emphysema"],
      pulmonaryHypertension: demographicRef.current["pulmonaryHypertension"],
      parkinson: demographicRef.current["parkinson"],
      muscleWeakness: demographicRef.current["muscleWeakness"],
      depressed: demographicRef.current["depressed"],
      other: demographicRef.current["other"],
      otherNote: demographicRef.current["otherNote"].value,
      regularMedicine: demographicRef.current["regularMedicine"],
      regularMedicineNote: demographicRef.current["regularMedicineNote"].value,
      smoke: demographicRef.current["smoke"],
      smokeTotalYear: demographicRef.current["smokeTotalYear"].value,
      smokePerDay: demographicRef.current["smokePerDay"].value,
      stopSmokeYear: demographicRef.current["stopSmokeYear"].value,
      stopSmokeTotalYear: demographicRef.current["stopSmokeTotalYear"].value,
      stopSmokePerDay: demographicRef.current["stopSmokePerDay"].value,
      alc: demographicRef.current["alc"],
      alcTotalYear: demographicRef.current["alcTotalYear"].value,
      alcPerDay: demographicRef.current["alcPerDay"].value,
      stopAlcYear: demographicRef.current["stopAlcYear"].value,
      stopAlcTotalYear: demographicRef.current["stopAlcTotalYear"].value,
      stopAlcPerDay: demographicRef.current["stopAlcPerDay"].value,
      coffee: demographicRef.current["coffee"],
      coffeePerDay: demographicRef.current["coffeePerDay"].value,
      coffeePerWeek: demographicRef.current["coffeePerWeek"].value,
      soda: demographicRef.current["soda"],
      sodaPerDay: demographicRef.current["sodaPerDay"].value,
      sodaPerWeek: demographicRef.current["sodaPerWeek"].value,
      apnea: demographicRef.current["apnea"],
      apneaDetail: demographicRef.current["apneaDetail"],
      snore: demographicRef.current["snore"],
      snoreDetail: demographicRef.current["snoreDetail"],
      unusuallySleepy: demographicRef.current["unusuallySleepy"],
      unusuallySleepyDetail: demographicRef.current["unusuallySleepyDetail"],
      insomnia: demographicRef.current["insomnia"],
      insomniaDetail: demographicRef.current["insomniaDetail"],
    };
    // console.log("info: ", info);
    // console.log("hn: ", patientInfo.hn);
    // console.log("uuid: ", uuid);
    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        // console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };
  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      // console.log('patientInfo.hn: ',patientInfo.hn)
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      // console.log(docSnapForm.data());
      setDemographicDoc(docSnapForm.data());
      // demographicRef.current["gender"] = docSnapForm.data().info.gender;
      // demographicRef.current["dateOfBirth"] =
      //   docSnapForm.data().info.dateOfBirth;
      // demographicRef.current["age"] = docSnapForm.data().info.age;
      // demographicRef.current["weight"] = docSnapForm.data().info.weight;
      // demographicRef.current["height"] = docSnapForm.data().info.height;
      // demographicRef.current["mostWeightEver"] =
      //   docSnapForm.data().info.mostWeightEver;
      // demographicRef.current["weightFiveYearsAgo"] =
      //   docSnapForm.data().info.weightFiveYearsAgo;

      demographicRef.current["embolism"] = docSnapForm.data().info.embolism;
      demographicRef.current["heartattack"] =
        docSnapForm.data().info.heartattack;
      demographicRef.current["highBloodPressure"] =
        docSnapForm.data().info.highBloodPressure;
      demographicRef.current["bloodClots"] = docSnapForm.data().info.bloodClots;
      demographicRef.current["diabetes"] = docSnapForm.data().info.diabetes;
      demographicRef.current["hyperlipidemia"] =
        docSnapForm.data().info.hyperlipidemia;
      demographicRef.current["kidneyDisease"] =
        docSnapForm.data().info.kidneyDisease;
      demographicRef.current["fattyLiver"] = docSnapForm.data().info.fattyLiver;
      demographicRef.current["overactiveThyroid"] =
        docSnapForm.data().info.overactiveThyroid;
      demographicRef.current["underactiveThtroid"] =
        docSnapForm.data().info.underactiveThtroid;
      demographicRef.current["pale"] = docSnapForm.data().info.pale;
      demographicRef.current["ironDeficiency"] =
        docSnapForm.data().info.ironDeficiency;
      demographicRef.current["thalassemia"] =
        docSnapForm.data().info.thalassemia;
      demographicRef.current["asthma"] = docSnapForm.data().info.asthma;
      demographicRef.current["emphysema"] = docSnapForm.data().info.emphysema;
      demographicRef.current["pulmonaryHypertension"] =
        docSnapForm.data().info.pulmonaryHypertension;
      demographicRef.current["parkinson"] = docSnapForm.data().info.parkinson;
      demographicRef.current["muscleWeakness"] =
        docSnapForm.data().info.muscleWeakness;
      demographicRef.current["depressed"] = docSnapForm.data().info.depressed;
      demographicRef.current["other"] = docSnapForm.data().info.other;
      // demographicRef.current["otherNote"] = docSnapForm.data().info.otherNote;
      demographicRef.current["regularMedicine"] =
        docSnapForm.data().info.regularMedicine;

      // demographicRef.current["regularMedicineNote"] =
      //   docSnapForm.data().info.regularMedicineNote;
      demographicRef.current["smoke"] = docSnapForm.data().info.smoke;
      // demographicRef.current["smokeTotalYear"] =
      //   docSnapForm.data().info.smokeTotalYear;
      // demographicRef.current["smokePerDay"] =
      //   docSnapForm.data().info.smokePerDay;
      // demographicRef.current["stopSmokeYear"] =
      //   docSnapForm.data().info.stopSmokeYear;
      // demographicRef.current["stopSmokeTotalYear"] =
      //   docSnapForm.data().info.stopSmokeTotalYear;
      // demographicRef.current["stopSmokePerDay"] =
      //   docSnapForm.data().info.stopSmokePerDay;
      demographicRef.current["alc"] = docSnapForm.data().info.alc;
      // demographicRef.current["alcTotalYear"] =
      //   docSnapForm.data().info.alcTotalYear;
      // demographicRef.current["alcPerDay"] = docSnapForm.data().info.alcPerDay;
      // demographicRef.current["stopAlcYear"] =
      //   docSnapForm.data().info.stopAlcYear;
      // demographicRef.current["stopAlcTotalYear"] =
      //   docSnapForm.data().info.stopAlcTotalYear;
      // demographicRef.current["stopAlcPerDay"] =
      // docSnapForm.data().info.stopAlcPerDay;
      demographicRef.current["coffee"] = docSnapForm.data().info.coffee;
      // demographicRef.current["coffeePerDay"] =
      //   docSnapForm.data().info.coffeePerDay;
      // demographicRef.current["coffeePerWeek"] =
      //   docSnapForm.data().info.coffeePerWeek;
      demographicRef.current["soda"] = docSnapForm.data().info.soda;
      // demographicRef.current["sodaPerDay"] = docSnapForm.data().info.sodaPerDay;
      // demographicRef.current["sodaPerWeek"] =
      //   docSnapForm.data().info.sodaPerWeek;
      demographicRef.current["apnea"] = docSnapForm.data().info.apnea;
      demographicRef.current["apneaDetail"] =
        docSnapForm.data().info.apneaDetail;
      demographicRef.current["snore"] = docSnapForm.data().info.snore;
      demographicRef.current["snoreDetail"] =
        docSnapForm.data().info.snoreDetail;
      demographicRef.current["unusuallySleepy"] =
        docSnapForm.data().info.unusuallySleepy;
      demographicRef.current["unusuallySleepyDetail"] =
        docSnapForm.data().info.unusuallySleepyDetail;
      demographicRef.current["insomnia"] = docSnapForm.data().info.insomnia;
      demographicRef.current["insomniaDetail"] =
        docSnapForm.data().info.insomniaDetail;

      setLoading(false);

      return;
    }

    const ref = await doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);
  console.log(demographicDoc);

  return (
    <Fragment>
      <Toast />

      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>ข้อมูลพื้นฐาน (Demographic)</Card.Title>
          </Card.Header>
          <Card.Body>
            {!isLoading ? (
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-7">
                      <h4 className="mb-4">
                        <b>ข้อมูลส่วนตัว</b>
                      </h4>
                      <DropdownBox
                        title="เพศ"
                        htmlFor="gender"
                        id="เพศ"
                        name="เพศ"
                        placeholder="กดเพื่อเลือก"
                        options={["ชาย", "หญิง"]}
                        refvalue={(el) =>
                          (demographicRef.current["gender"] = el)
                        }
                        defaultValue={demographicDoc.info.gender}
                      />
                      <InputBox
                        title="วันเกิด"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["dateOfBirth"] = el)
                        }
                        defaultValue={demographicDoc.info.dateOfBirth}
                        type="date"
                      ></InputBox>
                      <InputBox
                        title="อายุ"
                        measure="ปี"
                        refValue={(el) => (demographicRef.current["age"] = el)}
                        defaultValue={demographicDoc.info.age}
                        required={true}
                      ></InputBox>
                      <InputBox
                        title="ส่วนสูง"
                        measure="เซนติเมตร"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["height"] = el)
                        }
                        defaultValue={demographicDoc.info.height}
                      ></InputBox>
                      <InputBox
                        title="น้ำหนัก"
                        measure="กิโลกรัม"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weight"] = el)
                        }
                        defaultValue={demographicDoc.info.weight}
                      ></InputBox>
                      <InputBox
                        title="เคยหนักมากสุด"
                        measure="กิโลกรัม"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["mostWeightEver"] = el)
                        }
                        defaultValue={demographicDoc.info.mostWeightEver}
                      ></InputBox>
                      <InputBox
                        title="น้ำหนักเมื่อ 5 ปีก่อน"
                        measure="กิโลกรัม"
                        required={true}
                        refValue={(el) =>
                          (demographicRef.current["weightFiveYearsAgo"] = el)
                        }
                        defaultValue={demographicDoc.info.weightFiveYearsAgo}
                      ></InputBox>
                      <h4 className="mt-5 mb-4">
                        <b>โรคประจำตัว</b>
                      </h4>{" "}
                      <InputRadio
                        title="เส้นเลือดอุดตัน"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="เส้นเลือดอุดตัน"
                        checked={demographicDoc.info.embolism}
                        refValue={"embolism"}
                      ></InputRadio>
                      <InputRadio
                        title="หัวใจวาย"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="หัวใจวาย"
                        checked={demographicDoc.info.heartattack}
                        refValue={"heartattack"}
                      ></InputRadio>
                      <InputRadio
                        title="ความดันโลหิตสูง"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ความดันโลหิตสูง"
                        checked={demographicDoc.info.highBloodPressure}
                        refValue={"highBloodPressure"}
                      ></InputRadio>
                      <InputRadio
                        title="เส้นเลือดในสมองอุดตัน"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="เส้นเลือดในสมองอุดตัน"
                        checked={demographicDoc.info.bloodClots}
                        refValue={"bloodClots"}
                      ></InputRadio>
                      <InputRadio
                        title="เบาหวาน"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="เบาหวาน"
                        checked={demographicDoc.info.diabetes}
                        refValue={"diabetes"}
                      ></InputRadio>
                      <InputRadio
                        title="ไขมันในเลือดสูง"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ไขมันในเลือดสูง"
                        checked={demographicDoc.info.hyperlipidemia}
                        refValue={"hyperlipidemia"}
                      ></InputRadio>
                      <InputRadio
                        title="โรคไต"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="โรคไต"
                        checked={demographicDoc.info.kidneyDisease}
                        refValue={"kidneyDisease"}
                      ></InputRadio>
                      <InputRadio
                        title="ไขมันในตับ"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ไขมันในตับ"
                        checked={demographicDoc.info.fattyLiver}
                        refValue={"fattyLiver"}
                      ></InputRadio>
                      <InputRadio
                        title="ไทรอยด์ทำงานมาก"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ไทรอยด์ทำงานมาก"
                        checked={demographicDoc.info.overactiveThyroid}
                        refValue={"overactiveThyroid"}
                      ></InputRadio>
                      <InputRadio
                        title="ไทรอยด์ทำงานน้อย"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ไทรอยด์ทำงานน้อย"
                        checked={demographicDoc.info.underactiveThtroid}
                        refValue={"underactiveThtroid"}
                      ></InputRadio>
                      <InputRadio
                        title="ซีด"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ซีด"
                        checked={demographicDoc.info.pale}
                        refValue={"pale"}
                      ></InputRadio>
                      <InputRadio
                        title="ธาตุเหล็กต่ำ"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ธาติเหล็กต่ำ"
                        checked={demographicDoc.info.ironDeficiency}
                        refValue={"ironDeficiency"}
                      ></InputRadio>
                      <InputRadio
                        title="ธาลัสซีเมีย"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ธาลัสซีเมีย"
                        checked={demographicDoc.info.thalassemia}
                        refValue={"thalassemia"}
                      ></InputRadio>
                      <InputRadio
                        title="หืด"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="หืด"
                        checked={demographicDoc.info.asthma}
                        refValue={"asthma"}
                      ></InputRadio>
                      <InputRadio
                        title="ถุงลมโป่งพอง"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ถุงลมโป่งพอง"
                        checked={demographicDoc.info.emphysema}
                        refValue={"emphysema"}
                      ></InputRadio>
                      <InputRadio
                        title="ความดันเลือดปอดสูง"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ความดันเลือดปอดสูง"
                        checked={demographicDoc.info.pulmonaryHypertension}
                        refValue={"pulmonaryHypertension"}
                      ></InputRadio>
                      <InputRadio
                        title="พาร์กินสัน"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="พาร์กินสัน"
                        checked={demographicDoc.info.parkinson}
                        refValue={"parkinson"}
                      ></InputRadio>
                      <InputRadio
                        title="กล้ามเนื้ออ่อนแรง"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="กล้ามเนื้ออ่อนแรง"
                        checked={demographicDoc.info.muscleWeakness}
                        refValue={"muscleWeakness"}
                      ></InputRadio>
                      <InputRadio
                        title="ซึมเศร้า"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ซึมเศร้า"
                        checked={demographicDoc.info.depressed}
                        refValue={"depressed"}
                      ></InputRadio>
                      <InputRadio
                        title="อื่นๆ"
                        options={["มี", "ไม่มี"]}
                        name="อื่นๆ"
                        isnote={true}
                        note={demographicDoc.info.othotherNoteer}
                        refNote={"otherNote"}
                        checked={demographicDoc.info.other}
                        refValue={"other"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านมียาที่รับประทานเป็นประจำหรือไม่"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ท่านมียาที่รับประทานเป็นประจำหรือไม่"
                        isnote={true}
                        note={demographicDoc.info.regularMedicineNote}
                        refNote={"regularMedicineNote"}
                        checked={demographicDoc.info.regularMedicine}
                        refValue={"regularMedicine"}
                      ></InputRadio>
                    </div>
                    <div className="col-xl-7">
                      <h4 className="mb-4">
                        <b>ข้อมูลอื่นๆ</b>
                      </h4>{" "}
                      <InputRadio
                        title="ท่านสูบบุหรี่หรือไม่"
                        required={true}
                        options={["สูบ", "เคยสูบ", "ไม่สูบ"]}
                        name="ท่านสูบบุหรี่หรือไม่"
                        checked={demographicDoc.info.smoke}
                        refValue={"smoke"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้าสูบ"
                        placeholder="สูบมา"
                        measure="ปี"
                        refValue={(el) =>
                          (demographicRef.current["smokeTotalYear"] = el)
                        }
                        defaultValue={demographicDoc.info.smokeTotalYear}
                      ></InputBox>
                      <InputBox
                        title="สูบวันละ"
                        placeholder="วันละ"
                        measure="มวน"
                        refValue={(el) =>
                          (demographicRef.current["smokePerDay"] = el)
                        }
                        defaultValue={demographicDoc.info.smokePerDay}
                      ></InputBox>
                      <InputBox
                        title="ถ้าเคยสูบ หยุดมา"
                        placeholder="หยุดมา"
                        measure="ปี"
                        refValue={(el) =>
                          (demographicRef.current["stopSmokeYear"] = el)
                        }
                        defaultValue={demographicDoc.info.stopSmokeYear}
                      ></InputBox>
                      <InputBox
                        title="ถ้าเคยสูบ เคยสูบมา"
                        placeholder="เคยสูบมา"
                        measure="ปี"
                        refValue={(el) =>
                          (demographicRef.current["stopSmokeTotalYear"] = el)
                        }
                        defaultValue={demographicDoc.info.stopSmokeTotalYear}
                      ></InputBox>
                      <InputBox
                        title="ถ้าเคยสูบ สูบวันละ"
                        placeholder="สูบวันละ"
                        measure="มวน"
                        refValue={(el) =>
                          (demographicRef.current["stopSmokePerDay"] = el)
                        }
                        defaultValue={demographicDoc.info.stopSmokePerDay}
                      ></InputBox>
                      <InputRadio
                        title="ท่านดื่มสุราหรือไม่"
                        required={true}
                        options={["ดื่ม", "เคยดื่ม", "ไม่ดื่ม"]}
                        name="ท่านดื่มสุราหรือไม่"
                        checked={demographicDoc.info.alc}
                        refValue={"alc"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้าดื่ม ดื่มมา"
                        placeholder="ดื่มมา"
                        measure="ปี"
                        refValue={(el) =>
                          (demographicRef.current["alcTotalYear"] = el)
                        }
                        defaultValue={demographicDoc.info.alcTotalYear}
                      ></InputBox>
                      <InputBox
                        title="ถ้าดื่ม ดื่มวันละ"
                        placeholder="วันละ"
                        measure="แก้ว"
                        refValue={(el) =>
                          (demographicRef.current["alcPerDay"] = el)
                        }
                        defaultValue={demographicDoc.info.alcPerDay}
                      ></InputBox>
                      <InputBox
                        title="ถ้าเคยดื่ม หยุดมา"
                        placeholder="หยุดมา"
                        measure="ปี"
                        refValue={(el) =>
                          (demographicRef.current["stopAlcYear"] = el)
                        }
                        defaultValue={demographicDoc.info.stopAlcYear}
                      ></InputBox>
                      <InputBox
                        title="ถ้าเคยดื่ม เคยดื่มมา"
                        placeholder="เคยดื่มมา"
                        measure="ปี"
                        refValue={(el) =>
                          (demographicRef.current["stopAlcTotalYear"] = el)
                        }
                        defaultValue={demographicDoc.info.stopAlcTotalYear}
                      ></InputBox>
                      <InputBox
                        title="ถ้าเคยดื่ม ดื่มวันละ"
                        placeholder="วันละ"
                        measure="แก้ว"
                        refValue={(el) =>
                          (demographicRef.current["stopAlcPerDay"] = el)
                        }
                        defaultValue={demographicDoc.info.stopAlcPerDay}
                      ></InputBox>
                      <InputRadio
                        title="ท่านดื่มกาแฟหรือไม่"
                        required={true}
                        options={["ดื่ม", "ไม่ดื่ม"]}
                        name="ท่านดื่มกาแฟหรือไม่"
                        checked={demographicDoc.info.coffee}
                        refValue={"coffee"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้าดื่ม ดื่มวันละ"
                        placeholder="ดื่มวันละ"
                        measure="แก้ว"
                        refValue={(el) =>
                          (demographicRef.current["coffeePerDay"] = el)
                        }
                        defaultValue={demographicDoc.info.coffeePerDay}
                      ></InputBox>
                      <InputBox
                        title="ถ้าดื่ม ดื่มสัปดาห์ละ"
                        placeholder="ดื่มสัปดาห์ละ"
                        measure="แก้ว"
                        refValue={(el) =>
                          (demographicRef.current["coffeePerWeek"] = el)
                        }
                        defaultValue={demographicDoc.info.coffeePerWeek}
                      ></InputBox>
                      <InputRadio
                        title="ท่านดื่มน้ำอัดลมหรือไม่"
                        required={true}
                        options={["ดื่ม", "ไม่ดื่ม"]}
                        name="ท่านดื่มน้ำอัดลมหรือไม่"
                        checked={demographicDoc.info.soda}
                        refValue={"soda"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้าดื่ม ดื่มวันละ"
                        placeholder="ดื่มวันละ"
                        measure="แก้ว"
                        refValue={(el) =>
                          (demographicRef.current["sodaPerDay"] = el)
                        }
                        defaultValue={demographicDoc.info.sodaPerDay}
                      ></InputBox>
                      <InputBox
                        title="ถ้าดื่ม ดื่มสัปดาห์ละ"
                        placeholder="ดื่มสัปดาห์ละ"
                        measure="แก้ว"
                        refValue={(el) =>
                          (demographicRef.current["sodaPerWeek"] = el)
                        }
                        defaultValue={demographicDoc.info.sodaPerWeek}
                      ></InputBox>
                      <h4 className="mt-5 mb-4">
                        <b>ข้อมูลครอบครัว</b>
                      </h4>{" "}
                      <InputRadio
                        title="ท่านมีประวัติคนในครอบครัวเป็นโรคหยุดหายใจหรือไม่"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ท่านมีประวัติคนในครอบครัวเป็นโรคหยุดหายใจหรือไม่"
                        checked={demographicDoc.info.apnea}
                        refValue={"apnea"}
                      ></InputRadio>
                      <InputRadio
                        title="ถ้ามีคนเป็นโรคหยุดหายใจขณะหลับ กรุณาระบุ"
                        options={["บิดา", "มารดา", "พี่น้อง", "ลูก", "ไม่มี"]}
                        name="ถ้ามีคนเป็นโรคหยุดหายใจขณะหลับ กรุณาระบุ"
                        checked={demographicDoc.info.apneaDetail}
                        refValue={"apneaDetail"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านมีประวัติคนในครอบครัวที่กรนหรือไม่"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ท่านมีประวัติคนในครอบครัวที่กรนหรือไม่"
                        checked={demographicDoc.info.snore}
                        refValue={"snore"}
                      ></InputRadio>
                      <InputRadio
                        title="ถ้ามีคนนอนกรน กรุณาระบุ"
                        options={["บิดา", "มารดา", "พี่น้อง", "ลูก", "ไม่มี"]}
                        name="ถ้ามีคนนอนกรน กรุณาระบุ"
                        checked={demographicDoc.info.snoreDetail}
                        refValue={"snoreDetail"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านมีประวัติคนในครอบครัวที่ง่วงนอนมากผิดปกติหรือไม่หรือไม่"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ท่านมีประวัติคนในครอบครัวที่ง่วงนอนมากผิดปกติหรือไม่หรือไม่"
                        checked={demographicDoc.info.unusuallySleepy}
                        refValue={"unusuallySleepy"}
                      ></InputRadio>
                      <InputRadio
                        title="ถ้ามีคนง่วงนอนมากผิดปกติ กรุณาระบุ"
                        options={["บิดา", "มารดา", "พี่น้อง", "ลูก", "ไม่มี"]}
                        name="ถ้ามีคนง่วงนอนมากผิดปกติ กรุณาระบุ"
                        checked={demographicDoc.info.unusuallySleepyDetail}
                        refValue={"unusuallySleepyDetail"}
                      ></InputRadio>
                      <InputRadio
                        title="ท่านมีประวัติคนในครอบครัวที่นอนไม่หลับหรือไม่หรือไม่"
                        required={true}
                        options={["มี", "ไม่มี"]}
                        name="ท่านมีประวัติคนในครอบครัวที่นอนไม่หลับหรือไม่หรือไม่"
                        checked={demographicDoc.info.insomnia}
                        refValue={"insomnia"}
                      ></InputRadio>
                      <InputRadio
                        title="ถ้ามีคนที่นอนไม่หลับ กรุณาระบุ"
                        options={["บิดา", "มารดา", "พี่น้อง", "ลูก", "ไม่มี"]}
                        name="ถ้ามีคนที่นอนไม่หลับ กรุณาระบุ"
                        checked={demographicDoc.info.insomniaDetail}
                        refValue={"insomniaDetail"}
                      ></InputRadio>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        บันทึกข้อมูล
                      </button>
                      <button
                        onClick={() => {
                          history.push(`/patient-page/${id}`);
                        }}
                        type="submit"
                        className="btn btn-light ml-2"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Demographic;
