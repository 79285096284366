import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import { useAuth } from "../../../../context/AuthContext";
import swal from "sweetalert";

import { functions } from "../../../../firebase";

import {
  startSetFiles,
  startRemoveFile,
  startAddFile,
} from "../../../../actions/productFiles";
import { httpsCallable } from "firebase/functions";
import selectFiles from "../../../../selectors/productFiles";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../toast/SetToast";
import Toast from "../../toast/SetToast";
import ProductFileUploadForm from "./ProductFileUploadForm";
import ProductFileItem from "./ProductFileItem";

const ProductFiles = ({
  cpap,
  filesList,
  startAddFile,
  startSetFiles,
  startRemoveFile,
}) => {
  const getSignedUrl = httpsCallable(functions, "getSignedUrl");
  const { currentUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoding, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [createModal, setCreateModal] = useState(false);

  const onSubmit = async (files) => {
    if (!isEdit) {
      const data = {
        productId: cpap.id,
        createBy: currentUser.displayName,
        createAt: new Date(),
      };
      startAddFile(data, files);
    } else {
      setIsEdit(false);
    }
  };

  const onDelete = ({ productId, id }) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        startRemoveFile({ productId, id });
        swal(`Poof! Your file has been deleted!`, {
          icon: "success",
        });
      } else {
        swal("Canceled");
      }
    });
  };

  const onDownLoad = (productId, id, file) => {
    getSignedUrl({
      folder: "cpapfiles",
      hn: productId, // send productId -> hn for get file url, bug in cloud function
      id,
      filename: file,
    })
      .then((result) => {
        fetch(result.data).then((res) => {
          res.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = `${file}`;
            alink.click();
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onViewFile = (productId, id, file) => {
    getSignedUrl({
      folder: "cpapfiles",
      hn: productId, // send productId -> hn for get file url, bug in cloud function
      id,
      filename: file,
    })
      .then((result) => {
        setFileUrl(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setCreateModal(true);
  };

  useEffect(() => {
    setIsLoading(true);
    startSetFiles(cpap.id);
    setIsLoading(false);
  }, [cpap]);

  return (
    <Fragment>
      <Toast />
      <Modal
        className="fade bd-example-modal-lg"
        show={createModal}
        onHide={() => setCreateModal(false)}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>File</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <div
            className="card-body mx-0 p-0 col-lg-12"
            style={{ height: "85vh" }}
          >
            <iframe
              className="col-lg-12 m-0 p-0"
              src={fileUrl + "#toolbar=1"}
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <ProductFileUploadForm onSubmit={onSubmit} />
      {!isLoding && (
        <ProductFileItem
          files={filesList}
          productId={cpap.id}
          onDelete={onDelete}
          onViewFile={onViewFile}
          onDownLoad={onDownLoad}
        ></ProductFileItem>
      )}
    </Fragment>
  );
};

// export default PatientReport;
const mapStateToProps = (state, props) => ({
  filesList: selectFiles(state.productFiles),
});

const mapDispatchToProps = (dispatch) => ({
  startSetFiles: (id) => dispatch(startSetFiles(id)),
  startRemoveFile: (id) => dispatch(startRemoveFile(id)),
  startAddFile: (data, files) => dispatch(startAddFile(data, files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductFiles);
